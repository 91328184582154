import { useContext, useEffect, useState } from "react";
import "./styles.scss";

import { useAuth } from "contexts/auth/useAuth";
import { useEntity } from "contexts/entity/useEntity";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

import Button from "components/ui-kit/Buttons/Button";
import {
  notificationError,
  notificationSuccess
} from "utils/custom_notifications";
import helpers from "api/helpers/helpers";
import { getCompanyToFetch, shouldFilterByCompany } from "utils/post_utils";
import ThemeContext from "contexts/theme/ThemeContext";
import NewsTopSection from "components/Newsfeed/NewsTopSection";
import NewsHorizontalSection from "components/Newsfeed/NewsHorizontalSection";
import CenterLayout from "layouts/CenterLayout";
import flashApi from "api/flashApi";
import { faNewspaper } from "@fortawesome/free-solid-svg-icons";
import EmptyData from "components/ui-kit/EmptyData";
import permissions_utils from "utils/permissions_utils";
import NewsfeedPost from "models/NewsfeedPost";
import PostParameters from "models/PostParameters";
import NewsfeedType from "models/NewsfeedType";
import HeaderTitle from "components/HeaderTitle";

type PromiseState = "pending" | "fulfilled" | "rejected";

interface PromiseResult<T> {
  status: PromiseState;
  value?: T;
  reason?: any;
}

const NewsfeedPage = () => {
  const navigate = useNavigate();
  const { dark } = useContext(ThemeContext);
  const { t, i18n } = useTranslation();
  const auth = useAuth();
  const entities = useEntity();
  const companyId = auth?.user?.company?.id;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [featuredPosts, setFeaturedPosts] = useState<NewsfeedPost[]>([]);
  const [posts, setPosts] = useState<NewsfeedPost[]>([]);
  const [draftPosts, setDraftPosts] = useState<NewsfeedPost[]>([]);
  const [postsOfType, setPostsOfType] = useState<
    {
      type: NewsfeedType;
      posts: NewsfeedPost[];
    }[]
  >([]);
  const companyToFetch = getCompanyToFetch(entities, companyId);

  const can = (permission: string) => {
    return permissions_utils.can(permission, auth);
  };

  // Function to get posts from the API
  const getPosts = async (params: PostParameters = {}) => {
    try {
      let parameters: PostParameters = params;
      if (shouldFilterByCompany(entities, can)) {
        params.company_id = companyToFetch;
      }

      parameters.sort_by = "created";
      parameters.order_by = "desc";

      const { data } = await flashApi.getPosts(parameters);
      return data;
    } catch (err) {
      throw err;
    }
  };

  // Function to get post types from the API
  const getPostTypes = async () => {
    try {
      let params = {};
      if (shouldFilterByCompany(entities, can)) {
        params = { company_id: companyToFetch };
      }
      const { data } = await flashApi.getPostTypes(params);
      return data;
    } catch (err) {
      throw err;
    }
  };

  // Handles the types of posts response
  const handleTypesResponse = async (typesResponse: PromiseResult<any>) => {
    // If the response is not successful, show an error notification and return an empty object
    if (typesResponse.status !== "fulfilled") {
      notificationError(helpers.formatErrorMessage(typesResponse.reason), dark);
      return [];
    }

    // Get and filter the types of posts with post_count more than 0
    const typesToFetch: NewsfeedType[] = typesResponse.value.filter(
      (typeObj: NewsfeedType) => typeObj.post_count > 0
    );
    // Make concurrent API requests for each filtered type
    const postsByTypeResponses: PromiseResult<any>[] = await Promise.allSettled(
      typesToFetch.map((typeObj: NewsfeedType) =>
        getPosts({
          type: typeObj.type.toLowerCase(),
          published: true,
          limit: 10
        })
      )
    );

    // Map the responses to an object with type keys and array of post objects as values
    let postsByType: {
      type: NewsfeedType;
      posts: NewsfeedPost[];
    }[] = [];
    typesToFetch.forEach((typeObj: NewsfeedType, i: number) => {
      if (
        postsByTypeResponses[i].status === "fulfilled" &&
        postsByTypeResponses[i].value.data.length > 0
      ) {
        postsByType.push({
          type: typeObj,
          posts: postsByTypeResponses[i].value.data
        });
      }
    });
    return postsByType;
  };

  // Handles the featured posts response
  const handleFeaturedResponse = (
    featuredResponse: PromiseResult<any>,
    allPosts: NewsfeedPost[]
  ) => {
    // If featured posts were successfully retrieved and there is at least one, set them as featuredPosts
    if (
      featuredResponse.status === "fulfilled" &&
      featuredResponse.value.data.length > 0
    ) {
      setFeaturedPosts(featuredResponse.value.data);
      return allPosts;
    }

    // If no featured posts were retrieved, use the latest post as the featured post
    if (allPosts.length > 0) {
      setFeaturedPosts([allPosts[0]]);
      return allPosts.slice(1); // Remove the used post
    }
    return allPosts;
  };

  // Handles the recent posts response
  const handlePostsResponse = (recentPostsResponse: PromiseResult<any>) => {
    // If recent posts were successfully retrieved, return them
    if (recentPostsResponse.status === "fulfilled") {
      return recentPostsResponse.value.data;
    }
    return [];
  };

  // Loads posts from the server
  const loadPosts = async () => {
    setIsLoading(true);
    try {
      const postsPromises = [
        getPosts({ featured: true, published: true, limit: 10 }),
        getPosts({ featured: false, published: true, limit: 4 }),
        getPostTypes()
      ];
      if (can("read unpublished feed")) {
        postsPromises.push(getPosts({ published: false, limit: 10 }));
      }

      // Concurrently request for featured posts, recent posts, and types of posts
      const [
        featuredResponse,
        recentPostsResponse,
        typesResponse,
        draftPostsResponse
      ] = await Promise.allSettled(postsPromises);

      // Process the types of posts response and update the state
      const postsByType = await handleTypesResponse(typesResponse);
      setPostsOfType(postsByType);

      // Process the recent posts response
      const allPosts = handlePostsResponse(recentPostsResponse);

      // Process the draft posts response
      let draftPosts: NewsfeedPost[] = [];
      if (draftPostsResponse) {
        draftPosts = handlePostsResponse(draftPostsResponse);
      }

      // Process the featured posts response and update the state
      const featuredPosts = handlePostsResponse(featuredResponse);

      // Update the state with the remaining posts
      setFeaturedPosts(featuredPosts);
      setPosts(allPosts);
      setDraftPosts(draftPosts);
    } catch (err) {
      // If there was an error retrieving posts, show an error notification
      notificationError(helpers.formatErrorMessage(err), dark);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadPosts();
  }, [companyToFetch]);

  const writePost = () => {
    let user = auth?.user;
    let companyToDraft;
    if (can("create default posts")) {
      companyToDraft = companyToFetch ?? 0;
    } else {
      companyToDraft = user?.company?.id;
    }

    // Get the current user language and create an empty post
    let default_language = i18n.language;

    flashApi
      .createEmptyPost(user?.id, [companyToDraft], default_language)
      .then((response) => {
        let response_data: NewsfeedPost = response.data;
        if (response_data) {
          let id = response_data.id;
          notificationSuccess(t("post_page.new_post_created"), dark);
          navigate(`/feed/posts/${id}/new`);
        }
      })
      .catch((err) => {
        notificationError(helpers.formatErrorMessage(err), dark);
        console.log(err);
      });
  };

  return (
    <CenterLayout>
      <main className="news-feed-page">
        <div className="news-feed-page__header">
          <HeaderTitle
            title={t("pages.feed")}
            canGoBack={false}
            descriptionLink={"https://help.not-me.com/newsfeed-2.0"}
            permissionDescriptionLink={"create post"}
          />
          {can("create post") && (
            <Button onClick={() => writePost()}>{t("feed.write_post")}</Button>
          )}
        </div>
        <div className="news-feed">
          {/* Top section */}
          <NewsTopSection
            loading={isLoading}
            featuredPosts={featuredPosts}
            recentPosts={posts}
          />
          {/* Shelves */}
          <div className="news-feed__shelves-container">
            {postsOfType.map((postOfType) => (
              <NewsHorizontalSection
                key={postOfType.type.type}
                posts={postOfType.posts}
                loading={isLoading}
                title={postOfType.type.type_name}
                params={{ type: postOfType.type.type }}
              />
            ))}
            {draftPosts && draftPosts.length > 0 && (
              <NewsHorizontalSection
                key="Drafts"
                posts={draftPosts}
                loading={isLoading}
                title={t("post_page.drafts")}
                params={{ published: "false" }}
              />
            )}
          </div>
        </div>
        {!posts.length &&
          !featuredPosts.length &&
          !draftPosts.length &&
          !Object.keys(postsOfType).length &&
          !isLoading && (
            <EmptyData
              title={t("feed.no_posts")}
              description={t("feed.no_posts_organization")}
              icon={faNewspaper}
            />
          )}
      </main>
    </CenterLayout>
  );
};

export default NewsfeedPage;
