import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

import Button from "components/ui-kit/Buttons/Button";

const ReportDeleteModal: React.FC<{
  Modal: React.FC<{
    children: React.ReactNode;
  }>;
  close: () => void;
  deleteReport: () => void;
  loading: boolean;
}> = ({ Modal, close, deleteReport, loading }) => {
  const { t } = useTranslation();

  return (
    <Modal>
      <div className="notme-modal notme-alert">
        <div className="notme-modal-header">
          <h1>{t("report_page.delete_report")}</h1>
          <span className="notme-modal-header-close" onClick={close}>
            <FontAwesomeIcon icon={faTimes} className="times-icon" />
          </span>
        </div>
        <div className="notme-modal-body">
          <h6>{t("report_page.delete_report_description")}</h6>
        </div>
        <div className="notme-modal-footer">
          <Button onClick={deleteReport} loading={loading} context="red">
            {t("action_button.delete")}
          </Button>
          <Button outline onClick={close}>
            {t("action_button.cancel")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ReportDeleteModal;
