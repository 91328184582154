import React, { useState, useContext } from "react";

import { useTranslation } from "react-i18next";
import ThemeContext from "contexts/theme/ThemeContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  notificationError,
  notificationSuccess
} from "utils/custom_notifications";
import userApi from "api/userApi";
import helpers from "api/helpers/helpers";

import Button from "components/ui-kit/Buttons/Button";

const EmailVerificationModal: React.FC<{
  Modal: React.FC<{
    children: React.ReactNode;
  }>;
  open: () => void;
  close: () => void;
  isOpen: boolean;
  email: string;
}> = ({ Modal, close, email }) => {
  const { dark } = useContext(ThemeContext);
  const { t } = useTranslation();

  const [isSendingEmail, setIsSendingEmail] = useState<boolean>(false);

  const sendConfirmationMail = () => {
    setIsSendingEmail(true);
    userApi
      .verifyMail(email)
      .then(() => {
        notificationSuccess(
          t("authentication_pages.confirmation_mail_sent"),
          dark
        );
        close();
      })
      .catch((err) => {
        notificationError(helpers.formatErrorMessage(err), dark);
      })
      .finally(() => {
        setIsSendingEmail(false);
      });
  };

  return (
    <Modal>
      <div className="notme-modal">
        <div className="notme-modal-header">
          <h1>{t("authentication_pages.verify_email_address")}</h1>
          <span className="notme-modal-header-close" onClick={close}>
            <FontAwesomeIcon icon={faTimes} className="times-icon" />
          </span>
        </div>
        <h6 style={{ fontSize: 14 }}>
          {t("authentication_pages.verify_email_address_description")}
        </h6>
        <div className="notme-modal-footer">
          <Button
            context="blue"
            onClick={sendConfirmationMail}
            loading={isSendingEmail}
          >
            {t("authentication_pages.resend_confirmation")}
          </Button>
          <Button context="blue" outline onClick={close}>
            {t("action_button.cancel")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default EmailVerificationModal;
