import { useContext, useEffect, useState } from "react";
import "./styles.scss";
import Button from "components/ui-kit/Buttons/Button";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import ThemeContext from "contexts/theme/ThemeContext";
import CenterLayout from "layouts/CenterLayout";
import HeaderTitle from "components/HeaderTitle";
import flashApi from "api/flashApi";
import companyApi from "api/companyApi";
import Company from "models/Company";
import {
  notificationError,
  notificationSuccess
} from "utils/custom_notifications";
import helpers from "api/helpers/helpers";
import { useParams } from "react-router-dom";
import Loader from "components/ui-kit/Loader";
import Avatar from "components/ui-kit/Avatar";
import { useAuth } from "contexts/auth/useAuth";
import Tag from "components/ui-kit/Tag";
import dayjs from "dayjs";
import permissions_utils from "utils/permissions_utils";
import userApi from "api/userApi";
import Voice from "models/Voice";
import VoicePriority from "models/app/VoicePriority";
import {
  getPriority,
  getStatusLabel,
  statusToContext
} from "utils/voice_utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMessage,
  faNewspaper,
  faUserShield,
  faUserTimes
} from "@fortawesome/free-solid-svg-icons";
import EmptyData from "components/ui-kit/EmptyData";
import CompanyLogo from "components/ui-kit/CompanyLogo";
import CommentInput from "../../components/Flash/CommentInput";
import Comment from "models/Comment";
import User from "models/User";
import { AxiosResponse } from "axios";
import ReactionItems from "../../components/Flash/ReactionItems";
import { aggregateReactions } from "utils/reaction_utils";
import { ReactionCount } from "models/app/ReactionCount";
import CommentSection from "components/Flash/CommentSection";

const CompanyHeader = ({
  isLoadingCompany,
  voiceCompany
}: {
  isLoadingCompany: boolean;
  voiceCompany: Company | undefined;
}) => {
  if (isLoadingCompany || !voiceCompany) {
    return <Loader style={{ height: "42px", width: "42px" }} />;
  } else {
    return <CompanyLogo size={42} company={voiceCompany} />;
  }
};

const UserHeader = ({
  isLoadingUser,
  displayedUser,
  voice,
  t,
  userLeftCompany
}: {
  isLoadingUser: boolean;
  displayedUser: {
    firstname: string;
    lastname: string;
    id: number;
  } | null;
  voice: any;
  t: any;
  userLeftCompany: boolean;
}) => {
  if (voice?.is_anonymous || userLeftCompany) {
    return (
      <div className="just-sayin-detail__header-info">
        <FontAwesomeIcon
          icon={voice?.is_anonymous ? faUserShield : faUserTimes}
          size="1x"
        />
        <p>
          {t(
            voice?.is_anonymous
              ? "sent_anonymously"
              : "just_sayin_page.user_left_organization"
          )}
        </p>
      </div>
    );
  } else {
    if (isLoadingUser || !displayedUser) {
      return <Loader style={{ height: "44px", width: "180px" }} />;
    } else {
      return (
        <div className="just-sayin-detail__header-info">
          <Avatar
            name={displayedUser?.firstname + " " + displayedUser?.lastname}
            id={displayedUser?.id || 0}
            className="topbar-dropdown-item-user-avatar"
          />
          <p>{displayedUser?.firstname + " " + displayedUser?.lastname}</p>
        </div>
      );
    }
  }
};

const JustSayinDetail = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const { dark } = useContext(ThemeContext);
  const { t } = useTranslation();

  const { voiceId } = useParams<{ voiceId?: string }>();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isLoadingCompany, setIsLoadingCompany] = useState<boolean>(true);
  const [isLoadingUser, setIsLoadingUser] = useState<boolean>(false);
  const [isLoadingUsers, setIsLoadingUsers] = useState<boolean>(false);
  const [isAddingComment, setIsAddingComment] = useState<boolean>(false);

  const [voice, setVoice] = useState<Voice | null>(null);
  const [voiceCompany, setVoiceCompany] = useState<Company | undefined>(
    undefined
  );
  const [comments, setComments] = useState<Comment[]>([]);
  const [reactions, setReactions] = useState<ReactionCount[]>([]);
  const [users, setUsers] = useState<{
    [key: number]: User | null;
  }>([]);
  const [voicePriority, setVoicePriority] = useState<VoicePriority | null>(
    null
  );
  const [displayedUser, setDisplayedUser] = useState<{
    firstname: string;
    lastname: string;
    id: number;
  } | null>(null);
  const [userLeftCompany, setUserLeftCompany] = useState<boolean>(false);

  const can = (permission: string) => {
    return permissions_utils.can(permission, auth);
  };

  /* To be used once the delete feature is re-enabled */
  const handleDelete = async () => {
    try {
      setIsUpdating(true);
      // If it's the authenticated user, delete the voice. Otherwise, mark it as closed.
      if (auth?.user?.id === displayedUser?.id) {
        await flashApi.deleteVoice(voiceId);
        notificationSuccess(t("just_sayin_page.just_sayin_deleted"), dark);
      } else if (can("update company voices")) {
        await flashApi.updateVoice(voiceId, { status: "closed" });
        notificationSuccess(t("just_sayin_page.just_sayin_closed"), dark);
      }
      navigate("/just-sayin");
    } catch (error) {
      notificationError(helpers.formatErrorMessage(error), dark);
    } finally {
      setIsUpdating(false);
    }
  };

  const addReaction = async (name: any) => {
    try {
      await flashApi.createVoiceReaction(voiceId, { name });
    } catch (error) {
      notificationError(helpers.formatErrorMessage(error), dark);
    }
  };

  const deleteReaction = async () => {
    try {
      await flashApi.deleteVoiceReaction(voiceId);
    } catch (error) {
      notificationError(helpers.formatErrorMessage(error), dark);
    }
  };

  const handleCommentSubmit = async (comment: string) => {
    try {
      setIsAddingComment(true);
      const createdComment = await flashApi.createVoiceComment(voiceId, {
        comment
      });
      setComments([...comments, createdComment.data]);
    } catch (error) {
      notificationError(helpers.formatErrorMessage(error), dark);
    } finally {
      setIsAddingComment(false);
    }
  };

  const handleCommentDelete = async (commentId: number) => {
    try {
      await flashApi.deleteVoiceComment(voiceId, commentId);
      setComments(comments.filter((comment) => comment.id !== commentId));
    } catch (error) {
      notificationError(helpers.formatErrorMessage(error), dark);
    }
  };

  const fetchUserDetails = async (userIds: string[]) => {
    setIsLoadingUsers(true);
    try {
      const newUserIds = userIds.filter(
        (userId: any) => !users[userId] && userId !== auth?.user?.id
      );
      if (newUserIds.length === 0) {
        return; // No new users to fetch
      }
      const userDetails = await Promise.all(
        newUserIds.map((userId) => userApi.getUserById(userId))
      );

      const newUsers = userDetails.reduce(
        (acc: any, user: AxiosResponse<User>) => {
          const userData = user.data;
          acc[userData.id] = userData;
          return acc;
        },
        {}
      );

      setUsers((prevUsers) => ({ ...prevUsers, ...newUsers }));
    } catch (_) {
    } finally {
      setIsLoadingUsers(false);
    }
  };

  const addLoggedInUserToState = async () => {
    if (auth?.user?.id && !users[auth?.user?.id]) {
      // Update the state by merging with the existing users
      setUsers((prevUsers) => ({
        ...prevUsers,
        [auth?.user?.id!]: auth.user!
      }));
    }
  };

  useEffect(() => {
    const loadVoice = async () => {
      try {
        setIsLoading(true);
        const [voiceResult, commentsResult, reactionsResult] =
          await Promise.allSettled([
            flashApi.getVoiceById(voiceId),
            flashApi.getVoiceComments(voiceId),
            flashApi.getVoiceReactions(voiceId)
          ]);

        if (voiceResult.status === "fulfilled") {
          setVoice(voiceResult.value.data);
          setVoicePriority(
            getPriority(voiceResult.value.data.priority) ?? null
          );
        } else {
          notificationError(
            helpers.formatErrorMessage(voiceResult.reason),
            dark
          );
        }

        if (commentsResult.status === "fulfilled") {
          const fetchedComments = commentsResult?.value.data?.data;
          setComments(fetchedComments);
          const uniqueUserIds: string[] = Array.from(
            fetchedComments.reduce((acc: any, comment: Comment) => {
              if (comment.user_id != null) {
                acc.add(comment.user_id);
              }
              return acc;
            }, new Set<string>())
          );

          fetchUserDetails(uniqueUserIds);
        }

        if (reactionsResult.status === "fulfilled") {
          const fetchedReactions = reactionsResult?.value.data?.data;

          // Use reduce to process reactions into the final array of aggregated reactions
          const aggregatedReactions = aggregateReactions(
            fetchedReactions,
            auth?.user!
          );
          setReactions(aggregatedReactions);
        }
      } catch (error) {
        notificationError(helpers.formatErrorMessage(error), dark);
      } finally {
        setIsLoading(false);
      }
    };
    if (voiceId) {
      loadVoice();
    }
  }, [voiceId]);

  useEffect(() => {
    const getVoiceCompany = async () => {
      const getCompanyMethod = can("view company voices")
        ? companyApi.getCompanyById
        : companyApi.getUserCompanyById;

      try {
        setIsLoadingCompany(true);
        const companyResponse = await getCompanyMethod(voice?.company_id);
        setVoiceCompany(companyResponse.data);
      } catch (_) {
      } finally {
        setIsLoadingCompany(false);
      }
    };

    const updateVoiceStatus = async () => {
      try {
        const updateVoiceResponse = await flashApi.updateVoice(voice?.id, {
          status: "in_progress"
        });
        setVoice({
          ...voice!,
          status: "in_progress"
        });
      } catch (_) {}
    };

    const getUser = async () => {
      try {
        setIsLoadingUser(true);
        const userResponse = await userApi.adminGetUserById(voice?.user_id);
        setDisplayedUser({
          firstname: userResponse.data.firstname,
          lastname: userResponse.data.lastname,
          id: userResponse.data.id
        });
      } catch (error: any) {
        if (error.response && error.response.status === 403) {
          setUserLeftCompany(true);
        }
      } finally {
        setIsLoadingUser(false);
      }
    };

    if (voice) {
      getVoiceCompany();
      if (can("update company voices")) {
        if (voice.status === "new") {
          updateVoiceStatus();
        }
        if (voice.user_id) {
          getUser();
        }
      } else {
        setDisplayedUser({
          firstname: auth?.user?.firstname || "",
          lastname: auth?.user?.lastname || "",
          id: auth?.user?.id || 0
        });
      }
    }
  }, [voice]);

  useEffect(() => {
    if (auth?.user) {
      addLoggedInUserToState();
    }
  }, [auth?.user]);

  const canShowMarkClosedButton = () => {
    return voice && voice.status !== "closed" && can("update company voices");
  };

  const parsedDate = voice ? dayjs(voice.created).format("LL") : "";

  return (
    <CenterLayout>
      <main className="just-sayin-detail">
        <div className="just-sayin-header">
          <HeaderTitle title={t("pages.just_sayin")} canGoBack={true} />
          {canShowMarkClosedButton() && (
            <Button disabled={isUpdating} onClick={handleDelete} context="blue">
              {t("just_sayin_page.mark_as_closed")}
            </Button>
          )}
          {can("update voice") && can("view app") && (
            <Button
              disabled={isUpdating}
              onClick={() => {
                navigate(`/just-sayin/${voiceId}/edit`);
              }}
              context="blue"
            >
              {t("action_button.edit")}
            </Button>
          )}
        </div>

        <div className="just-sayin-detail__header">
          <UserHeader
            isLoadingUser={isLoadingUser}
            displayedUser={displayedUser}
            voice={voice}
            t={t}
            userLeftCompany={userLeftCompany}
          />
          <div className="just-sayin-detail__header-status">
            {!isLoading && voice ? (
              <>
                {voicePriority && (
                  <Tag round subtle context="card">
                    <FontAwesomeIcon
                      icon={voicePriority.icon}
                      className="voice-cell__tag-icon"
                      color={`var(--${voicePriority.color})`}
                    />
                    <span className="voice-cell__tag-text">
                      {t(voicePriority.title)}
                    </span>
                  </Tag>
                )}
                <Tag
                  round
                  className="voice-cell__tag"
                  context={statusToContext[voice.status]}
                  subtle
                >
                  {t(getStatusLabel(voice.status, can("view company voices")))}
                </Tag>
              </>
            ) : (
              <Loader style={{ height: "27px", width: "70px" }} />
            )}
            <CompanyHeader
              isLoadingCompany={isLoadingCompany}
              voiceCompany={voiceCompany}
            />
          </div>
        </div>
        {!isLoading && voice && (
          <>
            <div className="just-sayin-detail__content">
              <h2 className="just-sayin-detail__title">{voice.title}</h2>
              <p>{voice.content}</p>
            </div>
            <div className="just-sayin-detail__footer">
              <div className="just-sayin-detail__footer-info">
                <div className="just-sayin-detail__footer-reactions">
                  <ReactionItems
                    initialReactions={reactions}
                    addReaction={addReaction}
                    deleteReaction={deleteReaction}
                  />
                  <Tag
                    round
                    subtle
                    context="grey"
                    className="just-sayin-detail__footer-tag"
                  >
                    {voice.type}
                  </Tag>
                </div>
                <p className="subtitle">{parsedDate}</p>
              </div>
            </div>
          </>
        )}

        {isLoading && !voice && (
          <>
            <>
              <Loader style={{ height: "33px", width: "320px" }} />
              <Loader style={{ height: "24px", width: "100px" }} />
            </>
            <div className="just-sayin-detail__header">
              <Loader style={{ height: "27px", width: "60px" }} />
              <Loader style={{ height: "27px", width: "140px" }} />
            </div>
          </>
        )}

        {!isLoading && !voice && (
          <EmptyData
            title={t("just_sayin_page.no_voice_found")}
            description={t("just_sayin_page.no_voice_description")}
            icon={faNewspaper}
          />
        )}

        <CommentSection
          comments={comments}
          isLoading={isLoading}
          isAddingComment={isAddingComment}
          isLoadingUsers={isLoadingUsers}
          users={users}
          authUser={auth?.user!}
          isVoiceAnonymous={voice?.is_anonymous || false}
          userKey="user_id"
          showAnonymityBanner={
            (voice?.is_anonymous && can("view app")) || false
          }
          onDelete={handleCommentDelete}
        />

        <CommentInput onSubmit={handleCommentSubmit} />
      </main>
    </CenterLayout>
  );
};

export default JustSayinDetail;
