import React, { useState } from "react";
import "./styles.scss";
import permissions_utils from "utils/permissions_utils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Auth from "contexts/auth/useAuth";

import Button from "components/ui-kit/Buttons/Button";
import url_utils from "utils/url_utils";

const ReportSubmitModal: React.FC<{
  Modal: React.FC<{
    children: React.ReactNode;
  }>;
  close: () => void;
  isAnonymously?: boolean | null;
  report_id?: number;
  auth: Auth | null;
  updateReport: (params: any, successMessage: string) => Promise<unknown>;
}> = ({ Modal, close, isAnonymously, report_id, auth, updateReport }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const submitReport = () => {
    setLoading(true);
    updateReport({ status_id: 3 }, t("report_page.report_submitted"))
      .then(() => {
        if (can("view dashboard")) {
          let dashboard_url = url_utils.getDashboardUrl();
          window.location.replace(`${dashboard_url}/reports/${report_id}`);
        } else {
          navigate(-1);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const can = (permission: string) => {
    return permissions_utils.can(permission, auth);
  };

  const reporterIsUser = () => {
    return auth?.user?.role === "user";
  };

  const getMessage = () => {
    if (!reporterIsUser()) {
      return t("report_page.about_to_submit");
    } else {
      return isAnonymously
        ? t("report_page.about_to_submit_anon")
        : t("report_page.about_to_submit_non_anon");
    }
  };

  return (
    <Modal>
      <div className="notme-modal notme-alert">
        <div className="notme-modal-header">
          <h1>{t("report_page.submit_report")}</h1>
          <span className="notme-modal-header-close" onClick={close}>
            <FontAwesomeIcon icon={faTimes} className="times-icon" />
          </span>
        </div>
        <div className="notme-modal-body">
          <h6>{getMessage()}</h6>
        </div>
        <div className="notme-modal-footer">
          <Button onClick={submitReport} loading={loading}>
            {t("action_button.submit")}
          </Button>
          <Button outline onClick={close}>
            {t("action_button.cancel")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ReportSubmitModal;
