import {
  createRef,
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useState
} from "react";
import "./styles.scss";
import img_other from "assets/img/other.svg";
import Answer from "models/Answer";
import StepComponentProps from "models/app/StepComponentProps";
import Category from "models/Category";

import { useTranslation } from "react-i18next";

import TagSelect from "components/TagSelect";
import CategoryCell from "./components/CategoryCell";
import CarouselArrow from "components/CarouselArrow";

export interface ReportStepTagsProps {
  is_unique?: boolean;
  color?: string;
  answers: Answer[];
  user_answers: Answer[];
  categories?: Category[];
}

const ReportStepTags = forwardRef(
  (props: ReportStepTagsProps, ref: Ref<StepComponentProps>) => {
    useImperativeHandle(ref, () => ({
      check,
      setupData,
      getFormattedData,
      getData
    }));
    const { t } = useTranslation();

    const [value, setValue] = useState<Answer[]>([]);

    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(true);
    const [categoryCounter, setCategoryCounter] = useState(
      new Map<number, number[]>()
    );

    const categoryRef = createRef<HTMLElement>();

    const [currentCategoryIndex, setCurrentCategoryIndex] = useState<
      number | null
    >(null);

    useEffect(() => {
      setupData();
    }, [props.user_answers]);

    const setupData = () => {
      if (props.user_answers) {
        selectAnswer(props.user_answers);
      } else {
        setValue([]);
      }

      if (props.categories && props.categories.length > 0) {
        setCurrentCategoryIndex(props.categories[0].id);
        addOtherCategory();
      } else {
        setCurrentCategoryIndex(null);
      }
    };
    const check = () => {
      return value.length > 0;
    };
    const getFormattedData = () => {
      return { existing: value.map((x) => x.id) };
    };
    const getData = () => {
      return value;
    };

    const selectAnswer = (answers: Answer[]) => {
      setValue(answers);
      setupCategoryCounter(answers);
    };

    //MARK - Category functions
    const addOtherCategory = () => {
      if (props.categories && props.categories?.length > 0) {
        if (props.answers.filter((e) => e.category_id === null).length > 0) {
          props.categories.push({
            id: null,
            name: t("report_page.other"),
            icon: img_other
          });
        }
      }
    };

    const selectCategory = (index: number) => {
      if (props.categories) {
        let category_id = props.categories[index].id;
        setCurrentCategoryIndex(category_id);
      }
    };

    const setupCategoryCounter = (answers: Answer[]) => {
      let counter = new Map<number, number[]>();
      answers.forEach((answer) => {
        if (answer.category_id) {
          if (counter.has(answer.category_id)) {
            counter.get(answer.category_id)?.push(answer.id);
          } else {
            counter.set(answer.category_id, [answer.id]);
          }
        }
      });
      setCategoryCounter(counter);
    };

    const countCategorySelected = (id: number | null) => {
      if (id) {
        return categoryCounter.get(id)?.length || 0;
      } else {
        return 0;
      }
    };

    const scrollHorizontal = (right: boolean) => {
      if (categoryRef && categoryRef.current) {
        let to = categoryRef.current.offsetWidth - (60 * 2 + 26 * 2);
        if (!right) {
          to = to * -1;
        }
        categoryRef.current.scroll({
          left: categoryRef.current.scrollLeft + to,
          behavior: "smooth"
        });
      }
    };

    const scrollRight = () => {
      scrollHorizontal(true);
    };

    const scrollLeft = () => {
      scrollHorizontal(false);
    };

    const handleScroll = (e: any) => {
      if (categoryRef && categoryRef.current) {
        if (categoryRef.current.scrollLeft <= 40) {
          setCanScrollLeft(false);
        } else {
          if (!canScrollLeft) {
            setCanScrollLeft(true);
          }
        }

        if (
          categoryRef.current.scrollLeft >=
          categoryRef.current.scrollWidth - categoryRef.current.offsetWidth - 40
        ) {
          setCanScrollRight(false);
        } else {
          if (!canScrollRight) {
            setCanScrollRight(true);
          }
        }
      }
    };

    return (
      <div className="report-step-tags">
        {props?.categories && props.categories.length > 0 ? (
          <div className="categories-container">
            <div
              className="categories"
              ref={categoryRef as React.RefObject<HTMLDivElement>}
              onScroll={handleScroll}
            >
              {props.categories.map((category, index) => (
                <CategoryCell
                  key={index}
                  category={category}
                  index={index}
                  context={props.color}
                  selected={category.id === currentCategoryIndex}
                  nb_selected={countCategorySelected(category.id)}
                  selectCategory={selectCategory}
                />
              ))}
            </div>
            {canScrollLeft ? (
              <CarouselArrow
                direction="left"
                color={props.color}
                onClick={scrollLeft}
              />
            ) : null}
            {canScrollRight ? (
              <CarouselArrow
                direction="right"
                color={props.color}
                onClick={scrollRight}
              />
            ) : null}
          </div>
        ) : null}
        {props?.answers ? (
          <TagSelect
            availableAnswers={props?.answers.filter(
              (x) => x.category_id === currentCategoryIndex
            )}
            answersSelected={value}
            setAnswersSelected={selectAnswer}
            max={props.is_unique ? 1 : null}
            context={props.color}
          />
        ) : null}
      </div>
    );
  }
);

export default ReportStepTags;
