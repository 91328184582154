import React, { useMemo, useState } from "react";
import { useAuth } from "contexts/auth/useAuth";
import { useTranslation } from "react-i18next";
import { useModal } from "react-hooks-use-modal";

import "./styles.scss";
import Avatar from "components/ui-kit/Avatar";
import User from "models/User";
import dayjs from "dayjs";
import clsx from "clsx";
import Comment from "models/Comment";
import permissions_utils from "utils/permissions_utils";
import Button from "components/ui-kit/Buttons/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Tag from "components/ui-kit/Tag";

interface CommentCellProps {
  commentData: Comment;
  user: User | null;
  viewer: User | null | undefined;
  onDelete: (id: number) => Promise<void>;
  isVoiceAnonymous: boolean;
}

const CommentCell: React.FC<CommentCellProps> = ({
  commentData,
  user,
  viewer,
  onDelete,
  isVoiceAnonymous
}) => {
  const { comment, created } = commentData;
  const { t } = useTranslation();
  const auth = useAuth();
  const userName = user
    ? `${user.firstname} ${user.lastname}`
    : t("just_sayin_page.anonymous");

  const isViewerComment = user && user.id === viewer?.id;
  const formattedDate = dayjs(created).fromNow();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [Modal, openModal, closeModal] = useModal("root", {
    closeOnOverlayClick: true
  });

  const can = (permission: string) => {
    return permissions_utils.can(permission, auth);
  };

  const canDeleteComment = useMemo(
    () =>
      can("delete company voice interaction") ||
      (can("delete voice own interaction") && isViewerComment),
    [auth, isViewerComment]
  );

  const deleteComment = async () => {
    setIsLoading(true);
    await onDelete(commentData.id);
    setIsLoading(false);
    closeModal();
  };

  const headerNameClass = clsx("comment__header-name", {
    "comment__header-name--viewer": isViewerComment
  });

  return (
    <div className="comment">
      <Avatar name={userName} id={user?.id || 0} className="comment__avatar" />
      <div className="comment__content">
        <div className="comment__header">
          <div className={headerNameClass}>
            {isViewerComment ? t("you") : userName}
            {isViewerComment && isVoiceAnonymous && (
              <Tag round subtle context="light_blue" className="comment__tag">
                {t("just_sayin_page.anonymous")}
              </Tag>
            )}
          </div>
          <div className="comment__header-right">
            {canDeleteComment && (
              <Button
                outline
                context="grey"
                className="comment__header-delete"
                onClick={openModal}
              >
                <FontAwesomeIcon
                  icon={faTimes}
                  color="var(--grey)"
                  className="comment__header-delete-icon"
                />
              </Button>
            )}
            <div className="comment__header-date">{formattedDate}</div>
          </div>
        </div>
        <div className="comment__body">{comment}</div>
      </div>
      <Modal>
        <div className="notme-modal notme-alert">
          <div className="notme-modal-header">
            <h1>{t("just_sayin_page.delete_comment")}</h1>
            <span className="notme-modal-header-close" onClick={closeModal}>
              <FontAwesomeIcon icon={faTimes} className="times-icon" />
            </span>
          </div>
          <div className="notme-modal-body">
            <h6>{t("just_sayin_page.delete_comment_description")}</h6>
          </div>
          <div className="notme-modal-footer">
            <Button onClick={deleteComment} loading={isLoading} context="red">
              {t("action_button.yes_delete")}
            </Button>
            <Button outline onClick={closeModal}>
              {t("action_button.cancel")}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CommentCell;
