import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import "./styles.scss";
import Company, { CompanyObject } from "models/Company";
import logo from "assets/img/logo_dark.png";
import InformationPopover from "components/ui-kit/InformationPopover";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuildingCircleXmark } from "@fortawesome/free-solid-svg-icons";

export interface CompanyLogoProps {
  company?: Company;
  size?: number;
  showName?: boolean;

  className?: string;

  otherProps?: Object;
}

const CompanyLogo: React.FC<CompanyLogoProps> = ({
  company,
  size = 36,
  showName = true,
  className,
  ...otherProps
}) => {
  const baseClass = "company-logo";

  const rootClass = clsx(baseClass, className);
  if (!company) {
    company = new CompanyObject("#NotMe Solutions", logo, 1);
  }

  return (
    <InformationPopover text={company.name} disabled={!showName}>
      {company.logo ? (
        <img
          alt={"Company logo"}
          className={rootClass}
          height={size}
          width={size}
          src={company?.logo}
        />
      ) : (
        <div
          className={clsx(rootClass, `${baseClass}-icon`)}
          style={{ width: size + "px", height: size + "px" }}
        >
          <FontAwesomeIcon icon={faBuildingCircleXmark} />
        </div>
      )}
    </InformationPopover>
  );
};

CompanyLogo.displayName = "CompanyLogo";

CompanyLogo.propTypes = {
  company: PropTypes.any
};

export default CompanyLogo;
