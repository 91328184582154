import React, { useContext } from "react";
import "./styles.scss";

import { Outlet } from "react-router-dom";
import { useAuth } from "contexts/auth/useAuth";
import ThemeContext from "contexts/theme/ThemeContext";
import clsx from "clsx";

import Sidebar from "components/Sidebar";
import ContentOverlay from "layouts/ContentOverlay";

const AppLayout: React.FC = ({}) => {
  const { sidebarExpanded, showSidebar } = useContext(ThemeContext);
  const auth = useAuth();

  if (auth?.isLoggedIn && auth?.isPhoneVerified) {
    return (
      <div
        className={clsx(
          "app-layout",
          !sidebarExpanded ? "app-layout-reduced" : null
        )}
      >
        <div
          className={clsx(
            !showSidebar ? "app-layout-sidebar-show" : null,
            "app-layout-sidebar"
          )}
        >
          <Sidebar />
        </div>
        <div className="app-layout-container">
          <ContentOverlay />
          <Outlet />
        </div>
      </div>
    );
  }
  return <Outlet />;
};

export default AppLayout;
