import React, { useEffect, useRef, useState } from "react";
import NewsfeedPost from "models/NewsfeedPost";
import "./styles.scss";
import { NavLink } from "react-router-dom";

import { useTranslation } from "react-i18next";
import CarouselArrow from "components/CarouselArrow";
import NewsCell from "../NewsCell";

export interface NewsHorizontalSectionProps {
  posts: NewsfeedPost[];
  loading?: boolean;
  title: string;
  params?: { [key: string]: string };
}

/**
 * NewsHorizontalSection component
 * This component renders a horizontal list of NewsCell components.
 * It also includes a scroll button that scrolls the list to the right.
 */
const NewsHorizontalSection: React.FC<NewsHorizontalSectionProps> = ({
  posts = [],
  loading,
  title,
  params
}) => {
  const { t } = useTranslation();
  // Create a reference to the scroll container element
  const scrollRef = useRef<HTMLDivElement>(null);
  const [canScrollLeft, setCanScrollLeft] = useState<boolean>(false);
  const [canScrollRight, setCanScrollRight] = useState<boolean>(false);

  // Check if the container can scroll right when posts change
  useEffect(() => {
    if (scrollRef.current) {
      setCanScrollRight(
        scrollRef.current.scrollWidth > scrollRef.current.clientWidth
      );
    }
  }, [posts]);

  // Check if the container can scroll left or right when the scroll position changes
  const handleScroll = () => {
    if (scrollRef.current) {
      setCanScrollLeft(scrollRef.current.scrollLeft > 0);
      setCanScrollRight(
        scrollRef.current.scrollWidth - scrollRef.current.scrollLeft >
          scrollRef.current.clientWidth
      );
    }
  };

  // Handle the scroll button click
  const handleScrollClick = (direction: string) => {
    if (scrollRef.current) {
      const containerWidth = scrollRef.current.clientWidth;
      const newScrollPosition =
        direction === "right"
          ? Math.min(
              scrollRef.current.scrollLeft + containerWidth,
              scrollRef.current.scrollWidth
            )
          : Math.max(scrollRef.current.scrollLeft - containerWidth, 0);

      scrollRef.current.scrollTo({
        left: newScrollPosition,
        behavior: "smooth"
      });
    }
  };

  // Skeleton loading array
  const loadingPosts = new Array(5).fill({});

  const showMoreLink = () => {
    const url_params = new URLSearchParams(params).toString();
    return `/feed/posts/list?${url_params}`;
  };

  return (
    <div className="notme-news-section-horizontal">
      <div className="notme-news-section-horizontal__header">
        <h5 className="header-section-title">{title?.toLocaleLowerCase()}</h5>
        {posts.length > 5 && (
          <div className="notme-news-section-horizontal__header-link">
            <NavLink
              to={showMoreLink()}
              className="notme-news-section-horizontal__header-link-text"
            >
              {t("feed.show_more")}
            </NavLink>
          </div>
        )}
      </div>
      <div
        className="notme-news-section-horizontal__container"
        ref={scrollRef}
        onScroll={handleScroll}
      >
        {(loading ? loadingPosts : posts).map((post, index) => (
          <NewsCell
            key={loading ? `loading-${index}` : post.id}
            layout="horizontal"
            post={post}
            loading={loading}
          />
        ))}
      </div>
      {!loading && (
        <div className="notme-news-section-horizontal__buttons">
          {canScrollLeft && (
            <CarouselArrow
              direction="left"
              onClick={() => handleScrollClick("left")}
            />
          )}
          {canScrollRight && (
            <CarouselArrow
              direction="right"
              onClick={() => handleScrollClick("right")}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default NewsHorizontalSection;
