import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

import Button from "components/ui-kit/Buttons/Button";

interface ActionModalProps {
  Modal: React.FC<{
    children: React.ReactNode;
  }>;
  close: () => void;
  title: string;
  description: string;
  children: React.ReactNode;
}

const ActionModal: React.FC<ActionModalProps> = ({
  Modal,
  close,
  title,
  description,
  children
}) => {
  const { t } = useTranslation();

  return (
    <Modal>
      <div className="notme-modal notme-alert">
        <div className="notme-modal-header">
          <h1>{title}</h1>
          <span className="notme-modal-header-close" onClick={close}>
            <FontAwesomeIcon icon={faTimes} className="times-icon" />
          </span>
        </div>
        <div className="notme-modal-body">
          <h6>{description}</h6>
        </div>
        <div className="notme-modal-footer">
          {children}
          <Button outline onClick={close}>
            {t("action_button.cancel")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ActionModal;
