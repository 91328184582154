import React, { useState } from "react";
import "./styles.scss";

import { useModal } from "react-hooks-use-modal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faImage, faPlus } from "@fortawesome/free-solid-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { Editor, FloatingMenu } from "@tiptap/react";
import DocumentUploaderModal from "../DocumentUploaderModal";
import YoutubeModal from "../YoutubeModal";
import NewsfeedFile from "models/NewsfeedFile";

export interface FloatingMenuEditorProps {
  editor: Editor;
  postId: number;
  onFileAdded: (file: NewsfeedFile) => void;
}

const FloatingMenuEditor: React.FC<FloatingMenuEditorProps> = ({
  editor,
  postId,
  onFileAdded
}) => {
  const [showEmbedButtons, setShowEmbedButtons] = useState(false);
  const [isImageSelected, setIsImageSelected] = useState(false);

  const [
    DocumentUploaderModalContainer,
    openDocumentUploaderModal,
    closeDocumentUploaderModal
  ] = useModal("root", {
    closeOnOverlayClick: true
  });

  const [YoutubeModalContainer, openYoutubeModal, closeYoutubeModal] = useModal(
    "root",
    {
      closeOnOverlayClick: true
    }
  );

  return (
    <FloatingMenu
      className="floating-menu-editor"
      editor={editor}
      tippyOptions={{
        duration: 100,
        placement: "right",
        onHide(instance) {
          setShowEmbedButtons(false);
        },
        zIndex: 999,
        arrow: false,
        theme: "hidden"
      }}
    >
      <span
        className="floating-menu-button"
        onClick={() => setShowEmbedButtons(!showEmbedButtons)}
      >
        <FontAwesomeIcon
          icon={faPlus}
          className={`floating-menu-icon ${
            showEmbedButtons ? "floating-menu-icon-rotate" : ""
          }`}
        />
      </span>
      {showEmbedButtons && (
        <div className="floating-menu-buttons">
          <span
            className="floating-menu-button"
            onClick={() => {
              setShowEmbedButtons(true);
              openYoutubeModal();
            }}
          >
            <FontAwesomeIcon icon={faYoutube} className="floating-menu-icon" />
          </span>
          <span
            className="floating-menu-button"
            onClick={() => {
              setIsImageSelected(true);
              setShowEmbedButtons(true);
              openDocumentUploaderModal();
            }}
          >
            <FontAwesomeIcon icon={faImage} className="floating-menu-icon" />
          </span>
          <span
            className="floating-menu-button"
            onClick={() => {
              setIsImageSelected(false);
              setShowEmbedButtons(true);
              openDocumentUploaderModal();
            }}
          >
            <FontAwesomeIcon icon={faFile} className="floating-menu-icon" />
          </span>
        </div>
      )}
      <DocumentUploaderModal
        Modal={DocumentUploaderModalContainer}
        editor={editor}
        postId={postId}
        isImage={isImageSelected}
        close={closeDocumentUploaderModal}
        onFileAdded={onFileAdded}
      />
      <YoutubeModal
        Modal={YoutubeModalContainer}
        editor={editor}
        close={closeYoutubeModal}
      />
    </FloatingMenu>
  );
};

export default FloatingMenuEditor;
