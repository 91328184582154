import { useEffect, useState } from "react";
import "./styles.scss";

import { useAuth } from "contexts/auth/useAuth";
import { useTranslation } from "react-i18next";
import {
  Outlet,
  useNavigate,
  useLocation,
  useSearchParams
} from "react-router-dom";
import { useModal } from "react-hooks-use-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { AxiosResponse } from "axios";
import permissions_utils from "utils/permissions_utils";
import companyApi from "api/companyApi";
import DropdownOption from "models/app/DropdownOption";
import DropdownSortOption, {
  dropdownSortOptionToRecord
} from "models/app/DropdownSortOption";

import CenterLayout from "layouts/CenterLayout";
import HeaderTitle from "components/HeaderTitle";
import Button from "components/ui-kit/Buttons/Button";
import DropdownSort from "components/ui-kit/Dropdowns/DropdownSort";
import SectionTabsGroup from "components/SectionTabsGroup";
import PermissionModal from "./components/PermissionModal";

type Child = {
  label: string;
  type: string;
  permission: string;
};

type PermissionsFilterCount = {
  members?: number;
  invited?: number;
};

const PermissionsPage = () => {
  const auth = useAuth();
  const { t } = useTranslation();
  const LIMIT = 10;

  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const path = pathname.split("/").pop();

  const membersSortOption: DropdownOption[] = [
    { value: "date_joined", label: t("dropdown_options.newest") },
    { value: "name", label: t("dropdown_options.alphabetical") }
  ];

  const [childrens, setChildrens] = useState<Child[]>([]);
  const [filterCounts, setFilterCounts] = useState<PermissionsFilterCount>({});
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [initialSortBy, setInitialSortBy] = useState<DropdownOption>(
    membersSortOption[0]
  );
  const [initialIsOrderDesc, setInitialIsOrderDesc] = useState<boolean>(true);

  const [searchParams, setSearchParams] = useSearchParams();

  const [PermissionModalContainer, openPermissionModal, closePermissionModal] =
    useModal("root", {
      closeOnOverlayClick: false
    });

  const pages = [
    {
      label: t("pages.settings.members"),
      type: "members",
      permission: "read company members",
      count: 0
    },
    {
      label: t("pages.settings.invited"),
      type: "invited",
      permission: "invite company subscribers",
      count: 0
    }
  ];

  const initialSortOption: DropdownSortOption = {
    sort_by: "date_joined",
    order_by: "desc"
  };

  const getCurrentPage = childrens.find((page) => page.type === path);

  useEffect(() => {
    generateChildrens();
  }, []);

  useEffect(() => {
    if (searchParams.size === 0) {
      const params = {
        ...dropdownSortOptionToRecord(initialSortOption),
        offset: "0",
        limit: LIMIT.toString()
      };
      if (path === "permissions" && childrens.length > 0) {
        navigate(`/settings/permissions/${childrens[0].type}`);
      }
      setSearchParams(params);
    } else {
      const sort_by = searchParams.get("sort_by");
      const order_by = searchParams.get("order_by");

      setInitialSortBy(
        sort_by === "name" ? membersSortOption[1] : membersSortOption[0]
      );
      setInitialIsOrderDesc(order_by === "desc" ? true : false);
    }
  }, [searchParams]);

  const generateChildrens = () => {
    const childs: Child[] = [];
    let counts = {};

    let promises: Promise<AxiosResponse<any, any>>[] = [];

    pages.forEach((page) => {
      if (can(page.permission)) {
        childs.push(page);
        promises.push(getPermissionsUsersRequest(page.type));
        counts = { ...counts, [page.type]: page.count };
      }
    });

    if (childs.length > 0 && path === "permissions") {
      navigate(`/settings/permissions/${childs[0].type}`);
    }

    Promise.all(promises).then((results) => {
      results.forEach((result, index) => {
        if (result.data["invites"] === undefined) {
          counts = { ...counts, members: result.data.page.total };
        } else {
          counts = { ...counts, invited: result.data.page.total };
        }
      });
      setFilterCounts(counts);
    });

    setChildrens(childs);
    setFilterCounts(counts);
  };

  const getPermissionsUsersRequest = (type: string) => {
    const params = {
      offset: 0,
      limit: 0
    };
    if (type === "members") {
      return companyApi.getCompanyDashboardUsers(params);
    } else {
      return companyApi.getCompanyDashboardInvited(params);
    }
  };

  const onSortSelected = (option: DropdownSortOption) => {
    setSearchParams(dropdownSortOptionToRecord(option));
  };

  const updateInvitedTabNumber = () => {
    if (filterCounts) {
      setFilterCounts({
        ...filterCounts,
        invited: filterCounts.invited ? filterCounts.invited + 1 : 1
      });
    }
  };

  const updateInvitedList = () => {
    setShouldRefresh(true);
  };

  const updateCount = (
    type: keyof PermissionsFilterCount,
    shouldIncrement: boolean
  ) => {
    const val = filterCounts[type];
    if (val) {
      setFilterCounts({
        ...filterCounts,
        [type]: val + (shouldIncrement ? 1 : -1)
      });
    }
  };

  const can = (permission: string) => {
    return permissions_utils.can(permission, auth);
  };

  return (
    <CenterLayout>
      <main className="permissions-page">
        <div className="permissions-page-header">
          <div className="permissions-page-header-text">
            <HeaderTitle
              title={t("pages.settings.permissions")}
              description={t("settings_sections.permissions.description")}
              descriptionLink={"https://help.not-me.com/permissions-video"}
              canGoBack={false}
            />
          </div>
          <div className="permissions-page-header-buttons">
            <DropdownSort
              sortOptions={membersSortOption}
              initialSortBy={initialSortBy}
              initialOrderDesc={initialIsOrderDesc}
              onSelect={onSortSelected}
            />
            {can("invite company subscribers") && (
              <Button onClick={openPermissionModal}>
                <FontAwesomeIcon icon={faUserPlus} />
              </Button>
            )}
          </div>
        </div>
        <SectionTabsGroup
          filters={childrens}
          filterCounts={filterCounts}
          activeFilter={getCurrentPage ? getCurrentPage?.type : ""}
          hideEmptyFilters={false}
          setActiveFilter={(filter) => {
            navigate(`/settings/permissions/${filter}?${searchParams}`);
          }}
        />
        <Outlet context={[updateCount, shouldRefresh, setShouldRefresh]} />
        <PermissionModal
          Modal={PermissionModalContainer}
          close={closePermissionModal}
          updateInvitedTabNumber={updateInvitedTabNumber}
          updateInvitedList={updateInvitedList}
        />
      </main>
    </CenterLayout>
  );
};

export default PermissionsPage;
