import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import "./styles.scss";

import { ReportInfo } from "models/ReportInfo";
import Card from "components/ui-kit/Card";
import ReportStepIcon from "components/ReportStepIcon";
import {
  faUserShield,
  faCheck,
  faCircleNotch
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import reportApi from "api/reportApi";
import { useTranslation } from "react-i18next";
import helpers from "api/helpers/helpers";
import {
  notificationError,
  notificationSuccess
} from "utils/custom_notifications";
import ThemeContext from "contexts/theme/ThemeContext";

const ReportAnonymously: React.FC<{
  report: ReportInfo;
  isAnonymously: boolean | null;
  setIsAnonymously: Dispatch<SetStateAction<boolean | null>>;
}> = ({ report, isAnonymously, setIsAnonymously }) => {
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  const { dark } = useContext(ThemeContext);

  const updateReportAnonymously = () => {
    if (!loading) {
      setLoading(true);
      reportApi
        .updateReport(report.id, { is_anonymous: !isAnonymously })
        .then((response) => {
          setLoading(false);
          setIsAnonymously(!isAnonymously);
          if (response.status === 200) {
            notificationSuccess(
              isAnonymously
                ? t("report_page.report_not_sent_anonymously")
                : t("report_page.report_sent_anonymously"),
              dark
            );
          }
        })
        .catch((err) => {
          setLoading(false);
          notificationError(helpers.formatErrorMessage(err), dark);
        });
    }
  };

  const ReportAnonymouslyIcon = () => {
    if (loading) {
      return <FontAwesomeIcon icon={faCircleNotch} spin />;
    }
    if (isAnonymously) {
      return <FontAwesomeIcon icon={faCheck} />;
    }
    return null;
  };

  return (
    <Card className="report-anonymously">
      <div className="report-anonymously-body">
        <ReportStepIcon icon={faUserShield} color="var(--dark_blue)" />
        <p className="report-anonymously-body-text">
          {t("report_page.report_anonymously")}
        </p>
      </div>
      <div
        className={clsx(
          "checkbox-container",
          isAnonymously ? "checkbox-container-toggle" : "",
          loading ? "checkbox-container-loading" : ""
        )}
        onClick={updateReportAnonymously}
      >
        <ReportAnonymouslyIcon />
      </div>
    </Card>
  );
};

export default ReportAnonymously;
