import Voice from "models/Voice";
import {
  faAngleDown,
  faAngleUp,
  faEquals
} from "@fortawesome/free-solid-svg-icons";

type StatusToVoiceType = {
  [key in Voice["status"]]: string;
};

enum PriorityLevels {
  Low = 0,
  Medium = 1,
  High = 2
}

const voice_priority = [
  {
    priority: PriorityLevels.Low,
    color: "green",
    icon: faAngleDown,
    title: "just_sayin_page.just_sayin_priority.low"
  },
  {
    priority: PriorityLevels.Medium,
    color: "light_red",
    icon: faEquals,
    title: "just_sayin_page.just_sayin_priority.medium"
  },
  {
    priority: PriorityLevels.High,
    color: "red",
    icon: faAngleUp,
    title: "just_sayin_page.just_sayin_priority.high"
  }
];

export const getPriorities = () => voice_priority;

export const getPriority = (priority: number) =>
  voice_priority.find((element) => element.priority === priority);

export const statusToContext: StatusToVoiceType = {
  new: "blue",
  in_progress: "dark_blue",
  closed: "dark_grey"
};

export const getStatusLabel = (status: string, isAdmin: boolean): string => {
  const baseKey = "just_sayin_page.status.";

  const statusKeys: {
    [x: string]: string;
  } = {
    new: isAdmin ? "new" : "sent",
    in_progress: "opened",
    closed: "closed"
  };

  return baseKey + statusKeys[status];
};
