import React, { useEffect, useState } from "react";
import "./styles.scss";

import { useTranslation } from "react-i18next";
import { useEditor, EditorContent, Extension } from "@tiptap/react";

import BubbleMenuEditor from "./BubbleMenuEditor";
import FloatingMenuEditor from "./FloatingMenuEditor";
import Underline from "@tiptap/extension-underline";
import Blockquote from "@tiptap/extension-blockquote";
import Image from "@tiptap/extension-image";
import Placeholder from "@tiptap/extension-placeholder";
import StarterKit from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";
import { DocumentNode, ImageNode, YoutubeNode } from "utils/tiptap_utils";
import NewsfeedFile from "models/NewsfeedFile";
import { Plugin, PluginKey } from "@tiptap/pm/state";
import { Step, StepMap } from "@tiptap/pm/transform";

export interface EditorProps {
  content: any;
  editable?: boolean;
  postId: number;
  files: NewsfeedFile[];
  onChange: (content: any) => void;
  onChangeEditorRef?: (editor: any) => void;
}

const Editor: React.FC<EditorProps> = ({
  content,
  editable = true,
  postId,
  files,
  onChange,
  onChangeEditorRef
}) => {
  const { t } = useTranslation();

  var deleting = false;

  const EventHandler = Extension.create({
    name: "eventHandler",

    addProseMirrorPlugins() {
      return [
        new Plugin({
          props: {
            handleKeyDown: (view, event) => {
              if (event.key === "Backspace") {
                deleting = true;
              }

              return false;
            }
          },
          filterTransaction(transaction, state) {
            let result = true;
            const replaceSteps: number[] = [];
            transaction.steps.forEach((step: Step, index) => {
              if (step.toJSON().stepType === "replace" && deleting) {
                replaceSteps.push(index);
              }
            });

            replaceSteps.forEach((index) => {
              const map: any = transaction.mapping.maps[index];
              const oldStart = map.ranges[0];
              const oldEnd = map.ranges[0] + map.ranges[1];
              state.doc.nodesBetween(oldStart, oldEnd, (node) => {
                if (
                  node.type.name === "image" ||
                  node.type.name === "document" ||
                  node.type.name === "youtube"
                ) {
                  result = false;
                }
              });
            });
            deleting = false;
            return result;
          }
        })
      ];
    }
  });

  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Blockquote,
      Image,
      Link.configure({
        openOnClick: false
      }),
      Placeholder.configure({
        placeholder: t("post_page.write_something")
      }),
      YoutubeNode.configure({
        isEditable: editable
      }),
      ImageNode.configure({
        isEditable: editable,
        files: files
      }),
      DocumentNode.configure({
        isEditable: editable,
        files: files
      }),
      EventHandler
    ],
    editable,
    content: content,
    editorProps: {
      attributes: {
        class: "editor"
      }
    }
  });

  useEffect(() => {
    if (editor) {
      editor.on("update", () => {
        onChange(editor.getJSON());
      });

      // Pass editor reference to the parent component
      onChangeEditorRef?.(editor);
    }
  }, [editor, onChange, onChangeEditorRef]);

  const onFileAdded = (file: NewsfeedFile) => {
    files.push(file);
  };

  return (
    <div className="editor-container">
      {editor && <BubbleMenuEditor editor={editor} />}
      {editor && (
        <FloatingMenuEditor
          editor={editor}
          postId={postId}
          onFileAdded={onFileAdded}
        />
      )}
      <EditorContent className="editor" editor={editor} />
    </div>
  );
};

export default Editor;
