import React, { useState, useContext, useEffect } from "react";

import { useAuth } from "contexts/auth/useAuth";
import { useTranslation } from "react-i18next";
import ThemeContext from "contexts/theme/ThemeContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  notificationError,
  notificationSuccess
} from "utils/custom_notifications";
import helpers from "api/helpers/helpers";
import companyApi from "api/companyApi";

import Input from "components/ui-kit/Input";
import Button from "components/ui-kit/Buttons/Button";

const OrganizationSizeModal: React.FC<{
  Modal: React.FC<{
    children: React.ReactNode;
  }>;
  close: () => void;
  company_id: number | null;
  organizationSize?: number;
  updateHeadcount: (headcount: number) => void;
}> = ({
  Modal,
  close,
  company_id = null,
  organizationSize,
  updateHeadcount
}) => {
  const auth = useAuth();
  const { dark } = useContext(ThemeContext);
  const { t } = useTranslation();

  const [headcount, setHeadcount] = useState<number | null>(null);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setHeadcount(organizationSize || null);
  }, [organizationSize]);

  const closeModal = () => {
    setHeadcount(organizationSize || null);
    close();
  };

  const editHeadcount = async () => {
    if (!isHeadcountValid()) return;
    let params = {
      headcount: headcount
    };

    setLoading(true);

    try {
      const response = await companyApi.updateCompanyById(company_id, params);
      notificationSuccess(
        t("settings_sections.general.headcount_updated"),
        dark
      );
      updateHeadcount(headcount!);
    } catch (err) {
      notificationError(helpers.formatErrorMessage(err), dark);
    } finally {
      closeModal();
      setLoading(false);
    }
  };

  const isHeadcountValid = () => {
    return (
      headcount &&
      headcount <= 1000000 &&
      headcount > 0 &&
      headcount !== organizationSize
    );
  };

  const onNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = !Number.isNaN(e.target.valueAsNumber)
      ? e.target.valueAsNumber
      : 0;
    setHeadcount(value);
  };

  return (
    <Modal>
      <div className="notme-modal">
        <div className="notme-modal-header">
          <h1>{t("settings_sections.general.organization_size")}</h1>
          <span className="notme-modal-header-close" onClick={closeModal}>
            <FontAwesomeIcon icon={faTimes} className="times-icon" />
          </span>
        </div>
        <Input
          type="number"
          label={t("settings_sections.general.organization_size")}
          value={headcount ? headcount.toString() : ""}
          onChange={onNumberChange}
        />

        <div className="notme-modal-footer">
          <Button
            context="blue"
            onClick={editHeadcount}
            disabled={!isHeadcountValid()}
            loading={loading}
          >
            {t("action_button.save")}
          </Button>
          <Button context="blue" outline onClick={closeModal}>
            {t("action_button.cancel")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default OrganizationSizeModal;
