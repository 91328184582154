import React from "react";

import { useAuth } from "./useAuth";
import { Navigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import permissions_utils from "../../utils/permissions_utils";
import mainRoutes from "navigation/Main.routes";

interface PropType {
  component: React.FC;
  permissions?: string[];
  requiresCompany?: boolean;
}

const RequireAuth: React.FC<PropType> = ({
  component: Component,
  permissions,
  requiresCompany
}) => {
  const auth = useAuth();

  const navigateToFallback = () => {
    let path = "";

    for (const route of mainRoutes) {
      if (
        route.meta?.permissions?.every(
          (permission) =>
            !route.meta?.external && permissions_utils.can(permission, auth)
        )
      ) {
        path = route.path || "";
        break;
      }
    }
    return <Navigate to={"/" + path} />;
  };

  // 1: Check if user logged in
  if (auth?.isLoggedIn) {
    // 2: Check if user is fully loaded
    if (auth?.permissions === null || auth?.user === null) {
      return (
        <div className="loading-center">
          <FontAwesomeIcon icon={faCircleNotch} spin size="2x" />
        </div>
      );
    }
    // 3: Check if phone is verified
    else if (!auth?.isPhoneVerified) {
      return <Navigate to="/verify-phone" />;
    } else if (requiresCompany && !permissions_utils.hasCompany(auth)) {
      return navigateToFallback();
    } else {
      // 4: Check if route require permission
      if (permissions) {
        let permissionBlock = false;

        // 5: Check if users permissions are empty (Not super Admin)
        if (auth.permissions && auth.permissions.length > 0) {
          permissions.forEach((permission) => {
            if (!permissions_utils.can(permission, auth)) {
              permissionBlock = true;
            }
          });
        }

        if (!permissionBlock) {
          // 5a: If user can access the route => Show required route
          return <Component />;
        } else {
          // 5b: If user can't access the route => Go to main screen
          return navigateToFallback();
        }
      } else {
        // 5c: If route doesn't require permission => Show required route
        return <Component />;
      }
    }
  }
  return <Navigate to="/login" />;
};

export default RequireAuth;
