import { useState, useContext, useEffect } from "react";
import "./styles.scss";

import { useAuth } from "../../contexts/auth/useAuth";
import { useTranslation } from "react-i18next";
import ThemeContext from "../../contexts/theme/ThemeContext";
import {
  notificationError,
  notificationSuccess
} from "utils/custom_notifications";
import regex_utils from "utils/regex_utils";
import helpers from "api/helpers/helpers";

import AuthLayout, { AuthQuery } from "layouts/AuthLayout";
import Input, { OptionType } from "components/ui-kit/Input";
import Button from "components/ui-kit/Buttons/Button";
import Checkbox from "components/ui-kit/Checkbox";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PasswordCheckerInput, {
  PasswordData
} from "components/ui-kit/PasswordCheckerInput";

function SignUpPage() {
  const { dark } = useContext(ThemeContext);
  const { t, i18n } = useTranslation();
  const auth = useAuth();

  const locationState = useLocation().state as AuthQuery;
  const canGoBack =
    locationState && locationState.canGoBack ? locationState.canGoBack : false;

  const navigate = useNavigate();

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isTerms, setIsTerms] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [availableLanguages, setAvailableLanguages] = useState<OptionType[]>(
    []
  );
  const [selectedLanguage, setSelectedLanguage] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const getLanguageList = () => {
    const languages = Object.entries(i18n.services.resourceStore.data);
    const languageOptions: OptionType[] = [];
    languages.forEach((element: any) => {
      const meta = element[1].meta;
      languageOptions.push({
        key: meta.key,
        value: meta.title,
        title: meta.title
      });
      if (meta.key === i18n.language) {
        setSelectedLanguage(meta.title);
      }
    });
    setAvailableLanguages(languageOptions);
  };

  const updateLanguage = (e: any) => {
    const language_selected = availableLanguages[e.target.selectedIndex];
    setSelectedLanguage(language_selected.value);
    i18n.changeLanguage(language_selected.key);
  };

  const handleSignUp = () => {
    setIsLoading(true);
    auth
      ?.signUp({
        firstname,
        lastname,
        email,
        password,
        terms_accepted: isTerms,
        privacy_accepted: isTerms,
        locale: i18n.language
      })
      .then((response) => {
        notificationSuccess(t("authentication_pages.account_created"), dark, {
          autoClose: 5000
        });
        navigate("/login");
      })
      .catch((err) => {
        notificationError(helpers.formatErrorMessage(err), dark);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handlePasswordChange = (passwordData: PasswordData) => {
    setPassword(passwordData.password);
    setIsPasswordValid(passwordData.isValid);
  };

  const signupButtonDisabled = () => {
    return (
      firstname.length === 0 ||
      lastname.length === 0 ||
      email.length === 0 ||
      regex_utils.emailRegex.test(email) === false ||
      !isPasswordValid ||
      isTerms === false
    );
  };

  useEffect(() => {
    getLanguageList();
  }, []);

  return (
    <AuthLayout
      title={t("authentication_pages.sign_up")}
      className="sign-up-page"
      canGoBack={canGoBack}
    >
      <form className="auth-layout-inputs">
        <Input
          type="text"
          label={t("user_data.first_name")}
          value={firstname}
          onChange={(e) => setFirstname(e.target.value)}
        />
        <Input
          type="text"
          label={t("user_data.last_name")}
          value={lastname}
          onChange={(e) => setLastname(e.target.value)}
        />
        <Input
          type="email"
          label={t("user_data.personal_email")}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <PasswordCheckerInput
          label={t("user_data.password")}
          firstname={firstname}
          lastname={lastname}
          onPasswordChange={handlePasswordChange}
        />
        <Input
          label={t("user_data.language")}
          inputType="select"
          value={selectedLanguage}
          options={availableLanguages}
          onChange={updateLanguage}
        />
        <Checkbox checked={isTerms} onChange={() => setIsTerms(!isTerms)}>
          {t("authentication_pages.i_agree_to_the") + " "}
          <a href="https://not-me.com/terms-of-use" target="_blank">
            {t("authentication_pages.terms_of_use")}
          </a>
          {" " + t("authentication_pages.and") + " "}
          <a href="https://not-me.com/privacy-policy" target="_blank">
            {t("authentication_pages.privacy_policy")}
          </a>
        </Checkbox>
      </form>
      <div className="auth-layout-buttons">
        <Button
          full
          onClick={handleSignUp}
          loading={isLoading}
          disabled={signupButtonDisabled()}
        >
          {t("authentication_pages.sign_up")}
        </Button>
        <p>
          {t("authentication_pages.already_user")}{" "}
          <Link to={"/login"}>{t("authentication_pages.log_in")}</Link>
        </p>
      </div>
    </AuthLayout>
  );
}

export default SignUpPage;
