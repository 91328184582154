// import i18n from "@/utils/i18n/i18n";
import Cookies from "js-cookie";
import url_utils from "utils/url_utils";

const default_headers = {
  "Content-Type": "application/json",
  Accept: "application/json"
  // "Accept-Language": i18n.t("language")
};

const formatErrorMessage = (error) => {
  if (
    error !== null &&
    error?.response !== null &&
    error?.response?.data !== null
  )
    var err = error?.response?.data;
  if (typeof err?.message === "string") {
    return err?.message;
  } else if (err?.message?.constructor === Object) {
    var message = "";
    Object.keys(err?.message).forEach(function (key) {
      message += err?.message[key] + " ";
    });
    return message;
  } else {
    return "Error";
  }
};

const configureOptions = (isAuth = false) => {
  let options = {
    headers: default_headers
  };
  const language = localStorage.getItem("i18nextLng");
  options.headers["Accept-Language"] = language;
  if (isAuth) {
    const tokenName = url_utils.getTokenName();
    const token = Cookies.get(tokenName);
    options.headers["Authorization"] = "Bearer " + token;
  }
  return options;
};

const helpers = {
  formatErrorMessage,
  configureOptions
};

export default helpers;
