import { useEffect, useState } from "react";
import "./styles.scss";
import Report from "models/Report";
import permissions_utils from "utils/permissions_utils";

import { useAuth } from "contexts/auth/useAuth";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleNotch,
  faClipboardList
} from "@fortawesome/free-solid-svg-icons";
import userApi from "api/userApi";

import ReportCell from "./components/ReportCell";
import CompanyBanner from "./components/CompanyBanner";
import SpeakUpButton from "./components/SpeakUpButton";
import { Link, useLocation } from "react-router-dom";
import url_utils from "utils/url_utils";
import CenterLayout from "layouts/CenterLayout";
import EmptyData from "components/ui-kit/EmptyData";
import HeaderTitle from "components/HeaderTitle";

const MainPage = () => {
  const auth = useAuth();
  const { t } = useTranslation();
  const location = useLocation();

  const [isReportsLoading, setIsReportsLoading] = useState(false);
  const [reports, setReports] = useState<Report[]>([]);

  useEffect(() => {
    getReports();
  }, []);

  const setupGreetingsText = () => {
    let date = new Date();
    let currentHour = date.getHours();
    if (currentHour >= 0 && currentHour < 5) {
      return t("main_page.greeting_evening");
    }
    if (currentHour >= 5 && currentHour < 12) {
      return t("main_page.greeting_morning");
    } else if (currentHour >= 12 && currentHour < 17) {
      return t("main_page.greeting_afternoon");
    } else if (currentHour >= 17) {
      return t("main_page.greeting_evening");
    }
  };

  const getReports = () => {
    setIsReportsLoading(true);
    userApi
      .getReports()
      .then((response: any) => {
        if (response.status === 200) {
          setReports(response.data.reports);
          setIsReportsLoading(false);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsReportsLoading(false);
      });
  };

  const reportContentPath = (report: Report) => {
    if (can("view dashboard") && report.status_id > 2) {
      let dashboard_url = url_utils.getDashboardUrl();
      return `${dashboard_url}/reports/${report.id}`;
    } else {
      return { pathname: `report/${report.id}` };
    }
  };

  const can = (permission: string) => {
    return permissions_utils.can(permission, auth);
  };

  const ReportsList = () => {
    return (
      <>
        <h5 className="header-section-title">{t("main_page.reports")}</h5>
        <div className="reports-list">
          {!isReportsLoading ? (
            reports.length > 0 ? (
              reports.map((report: Report, index: number) => (
                <Link
                  key={index}
                  reloadDocument={true}
                  to={reportContentPath(report)}
                  style={{ textDecoration: "none" }}
                >
                  <ReportCell report={report} key={index} />
                </Link>
              ))
            ) : (
              <EmptyData
                title={t("main_page.no_reports_yet")}
                description={
                  can("create own report")
                    ? t("main_page.create_first_report")
                    : t("main_page.create_first_organization_report")
                }
                icon={faClipboardList}
              />
            )
          ) : (
            <div className="reports-loading">
              <FontAwesomeIcon icon={faCircleNotch} spin size="2x" />
            </div>
          )}
        </div>
      </>
    );
  };

  const greeting_text = setupGreetingsText();
  return (
    <CenterLayout>
      <main style={{ padding: "1rem 0" }} className="main-page">
        <div className="greeting-header">
          <h5 className="header-section-title">{greeting_text}</h5>
          <h3 className="capitalize">{auth?.user?.firstname} 👋</h3>
        </div>
        <div className="main-title">
          <HeaderTitle
            title={
              can("create third-party report")
                ? t("main_page.walk_in_reports")
                : t("main_page.my_reports")
            }
            canGoBack={false}
            description={
              can("create third-party report")
                ? t("main_page.walk_in_reports_description")
                : undefined
            }
          />
          {can("create own report") || can("create company report") ? (
            <SpeakUpButton location={location} />
          ) : null}
        </div>
        {!can("create third-party report") ? (
          <CompanyBanner
            company={auth?.user?.company}
            company_added_at={auth?.user?.company_added_at}
            company_email={auth?.user?.company_email}
          />
        ) : null}
        <ReportsList />
      </main>
    </CenterLayout>
  );
};

export default MainPage;
