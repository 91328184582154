import { useContext, useEffect, useMemo, useState } from "react";
import "./styles.scss";

import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/auth/useAuth";
import ThemeContext from "contexts/theme/ThemeContext";
import permissions_utils from "utils/permissions_utils";
import sanitizeHtml from "sanitize-html";

import CenterLayout from "layouts/CenterLayout";
import HeaderTitle from "components/HeaderTitle";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import SettingSection from "components/SettingSection";
import ProgressCircular from "components/ui-kit/ProgressCircular";
import Button from "components/ui-kit/Buttons/Button";
import Input, { OptionType } from "components/ui-kit/Input";
import companyApi from "api/companyApi";
import {
  notificationError,
  notificationSuccess
} from "utils/custom_notifications";
import helpers from "api/helpers/helpers";
import CompanyInfo from "models/CompanyInfo";
import { EntityType } from "models/app/EntityType";
import { useEntity } from "contexts/entity/useEntity";

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline"],
    [{ align: [] }],
    ["blockquote"],
    [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
    [{ indent: "-1" }, { indent: "+1" }],
    ["clean"]
  ]
};

const WelcomePage = () => {
  const auth = useAuth();
  const { dark } = useContext(ThemeContext);
  const { t, i18n } = useTranslation();
  const entity = useEntity();

  const [company, setCompany] = useState<CompanyInfo | null>();
  const [value, setValue] = useState("");
  const [entitySelected, setEntitySelected] = useState<EntityType | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState<OptionType | null>(
    null
  );
  const [availableLanguages, setAvailableLanguages] = useState<OptionType[]>(
    []
  );

  useEffect(() => {
    if (entitySelected && entitySelected.locales.length > 0) {
      getIntro(entitySelected.locales[0], true);
    } else {
      getIntro("en", true);
    }
  }, [entitySelected]);

  useEffect(() => {
    if (
      can("update any company") &&
      entity &&
      entity.entitiesSelected &&
      Array.isArray(entity.entitiesSelected) &&
      entity.entitiesSelected.length > 0
    ) {
      setEntitySelected(entity.entitiesSelected[0]);
    } else {
      setEntitySelected(null);
    }
  }, [entity]);

  const isTextUpdated = () => {
    if (!company || !company.report_intro) return false;
    return (
      sanitizeHtml(value.replace(/\s/g, "")) ===
      sanitizeHtml(company.report_intro.replace(/\s/g, ""))
    );
  };

  const [percentage] = useMemo(() => {
    return [Math.min((sanitizeHtml(value).length / 1025) * 100, 100)];
  }, [value]);

  const [isButtonsDisabled] = useMemo(() => {
    return [isTextUpdated() || percentage >= 100 || isLoading];
  }, [isTextUpdated, percentage, isLoading]);

  const getIntro = async (
    language?: string,
    shouldUpdateAvailableLanguage?: boolean
  ) => {
    setIsLoading(true);
    setValue("");

    try {
      const companyResponse = await companyApi.getCompanyById(
        entitySelected ? entitySelected.id : null,
        {},
        { "Accept-Language": language ? language : selectedLanguage?.key }
      );

      const companyData = companyResponse.data;
      const reportIntro = sanitizeHtml(companyData.report_intro || "");
      setValue(reportIntro);
      setCompany({
        ...companyData,
        report_intro: reportIntro
      });
      if (shouldUpdateAvailableLanguage) {
        updateLanguageList(companyData);
      }
    } catch (error) {
      notificationError(helpers.formatErrorMessage(error), dark);
    } finally {
      setIsLoading(false);
    }
  };

  const saveIntro = async () => {
    setIsSaving(true);

    const params = {
      report_intro: sanitizeHtml(value)
    };

    try {
      await companyApi.updateCompanyById(
        entitySelected ? entitySelected.id : null,
        params,
        {
          "Accept-Language": selectedLanguage?.key
        }
      );

      notificationSuccess(
        t("settings_sections.welcome.welcome_message_updated"),
        dark
      );
      if (company) {
        company.report_intro = JSON.parse(JSON.stringify(value));
      }
    } catch (error) {
      notificationError(helpers.formatErrorMessage(error), dark);
    } finally {
      setIsSaving(false);
    }
  };

  const updateLanguageList = (company: CompanyInfo) => {
    const companyLocales =
      Array.isArray(company?.locales) && company?.locales.length! > 0
        ? company?.locales
        : ["en"];

    const languages = Object.entries(i18n.services.resourceStore.data);
    const languageOptions: OptionType[] = languages.reduce(
      (acc: OptionType[], element: any) => {
        const meta = element[1].meta;
        if (companyLocales!.includes(meta.key) || !company?.id) {
          let languageOption = {
            key: meta.key,
            value: meta.title,
            title: meta.title
          };
          acc.push(languageOption);
          if (meta.key === i18n.language) {
            setSelectedLanguage(languageOption);
          }
        }
        return acc;
      },
      []
    );
    setAvailableLanguages(languageOptions);
  };

  const handleCancel = () => {
    if (company) {
      setValue(company.report_intro || "");
    }
  };

  const updateLanguage = (e: any) => {
    const language_selected =
      availableLanguages[e.target.options.selectedIndex];
    setSelectedLanguage(language_selected);
    getIntro(language_selected.key);
  };

  const can = (permission: string) => {
    return permissions_utils.can(permission, auth);
  };

  return (
    <CenterLayout>
      <main className="welcome-page">
        <HeaderTitle
          title={t("pages.settings.welcome_message")}
          descriptionLink={"https://help.not-me.com/settings-customization"}
          canGoBack={false}
        />
        <SettingSection
          title={t("settings_sections.welcome.welcome_section_title")}
          description={t(
            "settings_sections.welcome.welcome_section_description"
          )}
        >
          <div className="welcome-page-container">
            <ReactQuill
              theme="snow"
              value={value}
              onChange={setValue}
              modules={modules}
              readOnly={
                (!can("update company profile") &&
                  !can("update any company")) ||
                isLoading
              }
            />
            <div className="welcome-page-bar">
              <ProgressCircular
                className="welcome-page-bar-progress-circular"
                percentage={percentage}
                size={28}
              />
              {selectedLanguage && (
                <Input
                  inputType="select"
                  className="welcome-page-language-selector"
                  value={selectedLanguage!.value}
                  options={availableLanguages}
                  onChange={updateLanguage}
                />
              )}
              {(can("update company profile") || can("update any company")) && (
                <div className="welcome-page-bar-button-container">
                  <Button context="red" outline onClick={handleCancel}>
                    {t("action_button.cancel")}
                  </Button>
                  <Button
                    disabled={isButtonsDisabled}
                    loading={isSaving}
                    onClick={saveIntro}
                  >
                    {t("action_button.save")}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </SettingSection>
      </main>
    </CenterLayout>
  );
};

export default WelcomePage;
