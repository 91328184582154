import { useState, useContext } from "react";
import "./styles.scss";

import ThemeContext from "../../contexts/theme/ThemeContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { notificationError } from "utils/custom_notifications";
import helpers from "api/helpers/helpers";
import challengeApi from "api/challengeApi";

import AuthLayout from "layouts/AuthLayout";
import Input from "components/ui-kit/Input";
import Button from "components/ui-kit/Buttons/Button";
import { CountryData } from "react-phone-input-2";
import { isValidPhoneNumber, CountryCode } from "libphonenumber-js";

function VerifyPhonePage() {
  const { dark } = useContext(ThemeContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [phone, setPhone] = useState("");
  const [formattedPhone, setFormattedPhone] = useState("");
  const [isValidPhone, setIsValidPhone] = useState(isValidPhoneNumber(phone));

  const [isLoading, setIsLoading] = useState(false);

  const handleSendPhone = () => {
    setIsLoading(true);
    challengeApi
      .postPhone("+" + phone.replace(/\s/g, ""))
      .then((response) => {
        navigate("/challenge", { replace: true });
      })
      .catch((err) => {
        notificationError(helpers.formatErrorMessage(err), dark);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const sendPhoneButtonDisabled = () => {
    return !isValidPhone;
  };

  const onPhoneChange = (
    value: any,
    data: any,
    event: any,
    formattedValue: any
  ) => {
    setPhone(value);
    setFormattedPhone(formattedValue);

    setIsValidPhone(
      isValidPhoneNumber(
        formattedValue,
        (data as CountryData).countryCode.toUpperCase() as CountryCode
      )
    );
  };

  return (
    <AuthLayout
      title={t("authentication_pages.check_phone_title")}
      className="verify-phone-page"
    >
      <h6>{t("authentication_pages.check_phone_subtitle")}</h6>
      <div className="auth-layout-inputs">
        <Input
          type="phone"
          inputType="phone"
          label={t("user_data.phone_number")}
          value={phone}
          onPhoneChange={onPhoneChange}
          onEnterPressed={() =>
            !sendPhoneButtonDisabled() && !isLoading && handleSendPhone()
          }
        />
        <h5>{t("authentication_pages.check_phone_confirm_text")}</h5>
      </div>
      <div className="auth-layout-buttons">
        <Button
          full
          onClick={handleSendPhone}
          loading={isLoading}
          disabled={sendPhoneButtonDisabled()}
        >
          {t("authentication_pages.check_phone_text_button")}
        </Button>
      </div>
    </AuthLayout>
  );
}

export default VerifyPhonePage;
