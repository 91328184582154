import React from "react";
import "./styles.scss";
import Report from "models/Report";

import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import Card from "components/ui-kit/Card";
import ReportStatusTag from "components/ReportStatusTag";
import ReportStatusIcon from "components/ReportStatusIcon";
import CompanyLogo from "components/ui-kit/CompanyLogo";

const ReportCell: React.FC<{ report: Report }> = ({ report }) => {
  const { t } = useTranslation();
  return (
    <Card className="report-cell">
      <div className="report-cell-info">
        <div className="report-name">
          <div className="report-name-status-icon">
            <ReportStatusIcon status_id={report.status_id} />
          </div>
          <h4 className="report-name-text">
            {t("report_page.report")} #{report.id}
          </h4>
        </div>
        <div className="report-status">
          <ReportStatusTag status_id={report.status_id} />
        </div>
      </div>
      <div className="report-cell-company-date">
        <div className="report-company">
          <CompanyLogo size={42} company={report.company} />
        </div>
        <div className="report-date">
          <h6>{dayjs(report.created_at).format("ll")}</h6>
        </div>
      </div>
    </Card>
  );
};

export default ReportCell;
