import React from "react";
import "./styles.scss";
import Company from "models/Company";

import { useTranslation } from "react-i18next";
import { useModal } from "react-hooks-use-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faInfo,
  faPencil
} from "@fortawesome/free-solid-svg-icons";

import Card from "components/ui-kit/Card";
import CompanyLogo from "components/ui-kit/CompanyLogo";
import AddCompanyModal from "./components/AddCompanyModal";
import CompanyModal from "./components/CompanyModal";
import IconCircleButton from "components/IconCircleButton";

const CompanyBanner: React.FC<{
  company?: Company;
  company_added_at?: string;
  company_email?: string;
}> = ({ company, company_added_at, company_email }) => {
  const { t } = useTranslation();
  const [Modal, open, close, isOpen] = useModal("root", {
    closeOnOverlayClick: company !== null
  });

  if (company && company_added_at) {
    return (
      <Card className="company-banner">
        <div className="company-banner-body">
          <div className="company-banner-body-text">
            <h5 className="company-banner-body-title">
              <FontAwesomeIcon icon={faBuilding} color={"var(--green)"} />
              {t("main_page.your_organization")}
            </h5>
            <div className="company-banner-body-icon-text">
              <CompanyLogo company={company}></CompanyLogo>
              <p
                dangerouslySetInnerHTML={{
                  __html: t("main_page.organization_linked_to", {
                    organization: company.name
                  })
                }}
              />
            </div>
          </div>
          <IconCircleButton icon={faInfo} onClick={open} />
        </div>
        <CompanyModal
          company={company}
          company_added_at={company_added_at}
          company_email={company_email}
          Modal={Modal}
          open={open}
          close={close}
          isOpen={isOpen}
        />
      </Card>
    );
  } else {
    return (
      <Card className="company-banner">
        <div className="company-banner-body">
          <div className="company-banner-body-text">
            <h5 className="company-banner-body-title">
              <FontAwesomeIcon icon={faBuilding} color={"var(--green)"} />
              {t("organization_modal.link_organization")}
            </h5>
            <p>
              {t("organization_modal.link_organization_description_first")}
              <FontAwesomeIcon icon={faPencil} />
              {t("organization_modal.link_organization_description_second")}
            </p>
          </div>
          <IconCircleButton icon={faPencil} onClick={open} />
        </div>
        <AddCompanyModal
          Modal={Modal}
          open={open}
          close={close}
          isOpen={isOpen}
        />
      </Card>
    );
  }
};

export default CompanyBanner;
