import React, { useState } from "react";
import "./styles.scss";

import { useTranslation } from "react-i18next";

import { BubbleMenu, Editor, isTextSelection } from "@tiptap/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBold,
  faHeading,
  faItalic,
  faLink,
  faQuoteRight,
  faStrikethrough,
  faTimes,
  faTrash,
  faUnderline
} from "@fortawesome/free-solid-svg-icons";

export interface BubbleMenuEditorProps {
  editor: Editor;
}

const BubbleMenuEditor: React.FC<BubbleMenuEditorProps> = ({ editor }) => {
  const { t } = useTranslation();

  const [isLinkEdition, setIsLinkEdition] = useState(false);
  const [link, setLink] = useState("");

  return (
    <BubbleMenu
      editor={editor}
      tippyOptions={{
        animateFill: true,
        duration: 100,
        moveTransition: "transform .2s ease-out",
        onHide(instance) {
          setLink("");
          setIsLinkEdition(false);
        },
        theme: "hidden"
      }}
      shouldShow={({ editor, view, state, from, to }) => {
        const { doc, selection } = state;
        const { empty } = selection;
        const isEmptyTextBlock =
          !doc.textBetween(from, to).length && isTextSelection(state.selection);
        const hasEditorFocus = view.hasFocus();
        if (
          !hasEditorFocus ||
          empty ||
          isEmptyTextBlock ||
          !editor.isEditable ||
          editor.isActive("image") ||
          editor.isActive("youtube") ||
          editor.isActive("document")
        ) {
          return false;
        }
        return true;
      }}
    >
      <div className="bubble-menu-editor">
        {!isLinkEdition ? (
          <>
            <button
              className={`bubble-menu-editor-button ${
                editor.isActive("bold") ? "is-active" : ""
              }`}
              onClick={() => editor.chain().focus().toggleBold().run()}
            >
              <FontAwesomeIcon icon={faBold} />
            </button>
            <button
              className={`bubble-menu-editor-button ${
                editor.isActive("italic") ? "is-active" : ""
              }`}
              onClick={() => editor.chain().focus().toggleItalic().run()}
            >
              <FontAwesomeIcon icon={faItalic} />
            </button>
            <button
              className={`bubble-menu-editor-button ${
                editor.isActive("underline") ? "is-active" : ""
              }`}
              onClick={() => editor.chain().focus().toggleUnderline().run()}
            >
              <FontAwesomeIcon icon={faUnderline} />
            </button>
            <button
              className={`bubble-menu-editor-button ${
                editor.isActive("strike") ? "is-active" : ""
              }`}
              onClick={() => editor.chain().focus().toggleStrike().run()}
            >
              <FontAwesomeIcon icon={faStrikethrough} />
            </button>
            <div className="separator" />
            <button
              className={`bubble-menu-editor-button bubble-menu-editor-button-heading ${
                editor.isActive("heading", { level: 1 }) ? "is-active" : ""
              }`}
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 1 }).run()
              }
            >
              <FontAwesomeIcon icon={faHeading} />1
            </button>
            <button
              className={`bubble-menu-editor-button bubble-menu-editor-button-heading ${
                editor.isActive("heading", { level: 2 }) ? "is-active" : ""
              }`}
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 2 }).run()
              }
            >
              <FontAwesomeIcon icon={faHeading} />2
            </button>
            <button
              className={`bubble-menu-editor-button bubble-menu-editor-button-heading ${
                editor.isActive("heading", { level: 3 }) ? "is-active" : ""
              }`}
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 3 }).run()
              }
            >
              <FontAwesomeIcon icon={faHeading} />3
            </button>
            <button
              className={`bubble-menu-editor-button bubble-menu-editor-button-heading ${
                editor.isActive("heading", { level: 4 }) ? "is-active" : ""
              }`}
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 4 }).run()
              }
            >
              <FontAwesomeIcon icon={faHeading} />4
            </button>
            <div className="separator" />
            <button
              className={`bubble-menu-editor-button ${
                editor.isActive("link") ? "is-active" : ""
              }`}
              onClick={() => {
                if (editor.isActive("link")) {
                  setLink(editor.getAttributes("link").href);
                }
                setIsLinkEdition(true);
              }}
            >
              <FontAwesomeIcon icon={faLink} />
            </button>
            <button
              className={`bubble-menu-editor-button ${
                editor.isActive("blockquote") ? "is-active" : ""
              }`}
              onClick={() => editor.chain().focus().toggleBlockquote().run()}
            >
              <FontAwesomeIcon icon={faQuoteRight} />
            </button>
          </>
        ) : (
          <div className="bubble-menu-editor-link-container">
            <input
              className="bubble-menu-editor-link-input"
              type="link"
              placeholder={t("post_page.paste_or_type_link")}
              value={link}
              onChange={(e) => setLink(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  editor
                    .chain()
                    .focus()
                    .extendMarkRange("link")
                    .setLink({ href: link })
                    .run();
                  setLink("");
                  setIsLinkEdition(false);
                }
              }}
            />
            {editor.isActive("link") ? (
              <button
                className={`bubble-menu-editor-button`}
                onClick={() => {
                  editor
                    .chain()
                    .focus()
                    .extendMarkRange("link")
                    .unsetLink()
                    .run();
                  setLink("");
                  setIsLinkEdition(false);
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            ) : null}
            <button
              className={`bubble-menu-editor-button`}
              onClick={() => {
                setLink("");
                setIsLinkEdition(false);
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        )}
      </div>
    </BubbleMenu>
  );
};

export default BubbleMenuEditor;
