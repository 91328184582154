import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";

export interface ImageLoadingProps {
  src?: string;
  alt?: string;
  className?: string;
  wrapperClassName?: string;
  loading?: boolean;
}

const ImageLoading: React.FC<ImageLoadingProps> = ({
  src,
  alt,
  className,
  wrapperClassName,
  loading
}) => {
  const [isLoadingImage, setIsLoadingImage] = useState<boolean>(
    Boolean(src) || Boolean(loading)
  );
  const [hasError, setHasError] = useState<boolean>(
    Boolean(!src) && Boolean(!loading)
  );

  const prevSrcRef = useRef(src);

  const handleError = () => {
    setHasError(true);
    setIsLoadingImage(false);
  };

  const handleLoad = () => {
    setIsLoadingImage(false);
  };

  useEffect(() => {
    // Reset the loading and error states when the src prop changes
    if (src && prevSrcRef.current !== src) {
      setIsLoadingImage(true);
      setHasError(false);
    }
    prevSrcRef.current = src;
  }, [src, loading]);

  return (
    <div
      className={clsx(
        "image-loading__wrapper",
        { "image-loading__wrapper--loading": isLoadingImage },
        wrapperClassName
      )}
    >
      <img
        className={clsx(
          "image-loading__image",
          {
            "image-loading__image--loaded": !isLoadingImage,
            "image-loading__image--error": hasError
          },
          className
        )}
        src={src}
        alt={alt}
        onError={handleError}
        onLoad={handleLoad}
      />
      <FontAwesomeIcon
        icon={faImage}
        className={clsx("image-loading__icon", {
          "image-loading__icon--show": hasError
        })}
        size="2x"
      />
    </div>
  );
};

export default ImageLoading;
