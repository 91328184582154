import React from "react";
import "./styles.scss";

import { useAuth } from "contexts/auth/useAuth";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import Invite from "models/Invite";
import role_utils from "utils/role_utils";
import {
  faEnvelope,
  faPencil,
  faTimes
} from "@fortawesome/free-solid-svg-icons";

import IconCircleButton from "components/IconCircleButton";
import Tag from "components/ui-kit/Tag";
import Card from "components/ui-kit/Card";
import Avatar from "components/ui-kit/Avatar";
import permissions_utils from "utils/permissions_utils";

export interface InviteCellProps {
  invite: Invite;
  showEditInviteModal: (invite: Invite) => void;
  showResendInviteModal: (invite: Invite) => void;
  showDeleteInviteModal: (invite: Invite) => void;
}

const InviteCell: React.FC<InviteCellProps> = ({
  invite,
  showEditInviteModal,
  showResendInviteModal,
  showDeleteInviteModal
}) => {
  const auth = useAuth();
  const { t } = useTranslation();

  const referrerName = invite.referrer
    ? `${invite.referrer.firstname} ${invite.referrer.lastname}`
    : null;

  const referrerDate = t("settings_sections.permissions.invited_on", {
    date: dayjs(invite.created_at).format("LL")
  });

  const referrerText =
    referrerDate +
    (referrerName
      ? ` ${t("settings_sections.permissions.by")} ${referrerName}`
      : "");

  const can = (permission: string) => {
    return permissions_utils.can(permission, auth);
  };

  return (
    <Card className="permission-invite-cell">
      <div className="permission-invite-cell-info-left">
        <Avatar
          className="permission-invite-cell-avatar"
          name={invite.email}
          id={invite.id}
        />
        <div className="permission-invite-cell-email-role-container">
          <div className="permission-invite-cell-email-container">
            <p>{invite.email}</p>
            <p className="permission-invite-cell-referrer-text">
              {referrerText}
            </p>
          </div>
          <div className="permission-invite-cell-role-container">
            <Tag
              context={role_utils.getRoleColor(invite.role)}
              round
              small
              subtle
            >
              {t("role." + invite.role)}
            </Tag>
          </div>
        </div>
      </div>
      {can("invite company subscribers") && (
        <div className="permission-invite-cell-buttons-container">
          <IconCircleButton
            color="var(--light_blue)"
            outline={false}
            icon={faPencil}
            onClick={() => showEditInviteModal(invite)}
          />
          <IconCircleButton
            icon={faEnvelope}
            outline={false}
            onClick={() => showResendInviteModal(invite)}
          />
          <IconCircleButton
            color="var(--red)"
            icon={faTimes}
            onClick={() => showDeleteInviteModal(invite)}
          />
        </div>
      )}
    </Card>
  );
};

export default InviteCell;
