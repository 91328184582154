import { Node } from "@tiptap/core";
import { ReactNodeViewRenderer, mergeAttributes } from "@tiptap/react";
import PostDocument from "pages/PostPage/components/PostDocument";
import PostImage from "pages/PostPage/components/PostImage";
import PostYoutube from "pages/PostPage/components/PostYoutube";

export const YoutubeNode = Node.create({
  name: "youtube",
  inline: false,
  group: "block",
  atom: true,
  content: "text*",
  parseHTML() {
    return [
      {
        tag: "div.youtube-container"
      }
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ["react-component", mergeAttributes(HTMLAttributes)];
  },
  addNodeView() {
    let postYoutube = PostYoutube;
    postYoutube.defaultProps = {
      isEditable: this.options.isEditable
    };
    return ReactNodeViewRenderer(PostYoutube);
  }
});

export const ImageNode = Node.create({
  name: "image",
  group: "block",
  atom: true,
  content: "text*",
  parseHTML(this) {
    return [
      {
        tag: "img"
      }
    ];
  },
  renderHTML(this, { node }) {
    let imageUrl = "";
    node.content.forEach((contentNode: any) => {
      if (contentNode.type.name === "text") {
        imageUrl = contentNode.text;
      }
    });
    return ["img", { src: imageUrl, alt: "" }];
  },
  addNodeView(this) {
    let postImage = PostImage;
    postImage.defaultProps = {
      isEditable: this.options.isEditable,
      files: this.options.files
    };
    return ReactNodeViewRenderer(postImage);
  }
});

export const DocumentNode = Node.create({
  name: "document",
  group: "block",
  atom: true,
  content: "text*",
  addAttributes() {
    return {
      content: {
        default: []
      }
    };
  },
  parseHTML() {
    return [
      {
        tag: "react-component"
      }
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ["react-component", mergeAttributes(HTMLAttributes)];
  },
  addNodeView(this) {
    let postDocument = PostDocument;
    postDocument.defaultProps = {
      isEditable: this.options.isEditable,
      files: this.options.files
    };
    return ReactNodeViewRenderer(postDocument);
  }
});
