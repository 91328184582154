import {
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useState
} from "react";
import "./styles.scss";
import StepComponentProps from "models/app/StepComponentProps";

import { useTranslation } from "react-i18next";

import Input from "components/ui-kit/Input";

export interface ReportStepSentenceProps {
  color?: string;
  user_answer: string;
}

const ReportStepSentence = forwardRef(
  (props: ReportStepSentenceProps, ref: Ref<StepComponentProps>) => {
    const { color } = props;
    useImperativeHandle(ref, () => ({ check, setupData, getFormattedData }));
    const { t } = useTranslation();

    const [value, setValue] = useState<string>("");

    useEffect(() => {
      setupData();
    }, [props.user_answer]);

    const setupData = () => {
      if (props.user_answer) {
        setValue(props.user_answer);
      }
    };
    const check = () => {
      return value.trim() !== "";
    };
    const getFormattedData = () => {
      return { new: value.trimStart() !== "" ? [value.trimStart()] : [] };
    };

    return (
      <div className="report-step-sentence">
        <Input
          type="text"
          label={t("report_page.type_here")}
          maxLength={255}
          showLength={true}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        ></Input>
      </div>
    );
  }
);

export default ReportStepSentence;
