import React, { useState, useEffect } from "react";
import "./styles.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import { ZxcvbnResult, zxcvbn, zxcvbnOptions } from "@zxcvbn-ts/core";
import * as zxcvbnCommonPackage from "@zxcvbn-ts/language-common";
import * as zxcvbnEnPackage from "@zxcvbn-ts/language-en";
import { useTranslation } from "react-i18next";
import Input from "../Input";

interface PasswordFeedbackType {
  suggestions: string[];
  warnings: string[];
}

export interface PasswordData {
  password: string;
  isValid: boolean;
}

interface PasswordCheckerInputProps {
  /** Indicate what the user should write */
  label?: string;
  /** User's firstname */
  firstname: string;
  /** User's lastname */
  lastname: string;
  backgroundColor?: string;
  reference?: string;

  onPasswordChange?: (passwordData: PasswordData) => void;
}

const PasswordCheckerInput: React.FC<PasswordCheckerInputProps> = ({
  label,
  firstname,
  lastname,
  backgroundColor,
  reference,
  onPasswordChange = (e) => {}
}) => {
  const baseClass = "notme-password-checker-input";
  const { t } = useTranslation();

  const [password, setPassword] = useState("");
  const [hidden, setHidden] = useState(true);
  const [passwordScore, setPasswordScore] = useState(0);
  const [passwordFeedback, setPasswordFeedback] =
    useState<PasswordFeedbackType>({
      suggestions: [],
      warnings: []
    });

  const options = {
    graphs: zxcvbnCommonPackage.adjacencyGraphs,
    dictionary: {
      ...zxcvbnCommonPackage.dictionary,
      ...zxcvbnEnPackage.dictionary
    }
  };

  const passwordConditions = [
    {
      test: (password: string, strength: ZxcvbnResult) => password.length < 8,
      errorMessage: "password_minimum_requirements"
    },
    {
      test: (password: string, strength: ZxcvbnResult) =>
        password.toLowerCase().includes(firstname.toLowerCase()) ||
        password.toLowerCase().includes(lastname.toLowerCase()),
      errorMessage: "password_name_requirements"
    },
    {
      test: (password: string, strength: ZxcvbnResult) => strength.score <= 2,
      errorMessage: "password_strength_requirements"
    },
    {
      test: (password: string, strength: ZxcvbnResult) =>
        strength.feedback.warning,
      errorMessage: (strength: ZxcvbnResult) => strength.feedback.warning || ""
    }
  ];

  const progressBarTitleColor = [
    {
      color: "red",
      title: t("authentication_pages.password_very_weak")
    },
    {
      color: "light_red",
      title: t("authentication_pages.password_weak")
    },
    {
      color: "blue",
      title: t("authentication_pages.password_average")
    },
    {
      color: "dark_green",
      title: t("authentication_pages.password_strong")
    },
    {
      color: "green",
      title: t("authentication_pages.password_very_strong")
    }
  ];

  useEffect(() => {
    zxcvbnOptions.setOptions(options);
  }, []);

  const getPasswordInformation = (
    strength: ZxcvbnResult
  ): PasswordFeedbackType => {
    const failedConditions = passwordConditions.filter((condition) =>
      condition.test(password, strength)
    );

    const warnings = failedConditions.map((condition) => {
      if (typeof condition.errorMessage === "function") {
        return condition.errorMessage(strength);
      } else {
        return condition.errorMessage;
      }
    });

    return {
      suggestions: strength.feedback.suggestions,
      warnings: warnings || []
    };
  };

  const validatePassword = (inputValue: string) => {
    const strength: ZxcvbnResult = zxcvbn(inputValue);
    const passwordInfo = getPasswordInformation(strength);

    setPasswordScore(strength.score);
    setPasswordFeedback(passwordInfo);
    return passwordInfo;
  };

  useEffect(() => {
    const passwordInfo = validatePassword(password);
    const isPasswordValid = passwordInfo.warnings.length === 0;
    onPasswordChange({ password, isValid: isPasswordValid });
  }, [password, onPasswordChange]);

  const feedbackWarning = (title: string, warnings: string[], color: string) =>
    warnings &&
    warnings.length > 0 && (
      <div className="feedback-item">
        <p className="feedback-title-label">
          {t(`password_requirements.${title}.title`)}:
        </p>
        {warnings.map((warning, index) => (
          <div key={`${title}-${index}`} className="feedback-label-icon">
            <FontAwesomeIcon
              color={`var(--${color})`}
              className="feedback-icon"
              size="sm"
              icon={faCheckCircle}
            />
            <p className="feedback-label" style={{ color: `var(--${color})` }}>
              {t(`password_requirements.${title}.${warning}`)}
            </p>
          </div>
        ))}
      </div>
    );

  return (
    <div className={baseClass}>
      <Input
        label={label}
        type="password"
        placeholder={"••••••••"}
        onChange={(e) => setPassword(e.target.value)}
        reference={reference}
        maxLength={64}
        showLength={false}
        value={password}
      />
      <div className="progress">
        <div
          className="progress-bar"
          role="progressbar"
          style={{
            width: `${((passwordScore + 1) / 5) * 100}%`,
            backgroundColor: `var(--${progressBarTitleColor[passwordScore].color})`
          }}
          aria-valuenow={20}
          aria-valuemin={0}
          aria-valuemax={100}
        ></div>
      </div>
      <p
        className="password-score-indicator"
        style={{
          color: `var(--${progressBarTitleColor[passwordScore].color})`
        }}
      >
        {progressBarTitleColor[passwordScore].title}
      </p>
      {(passwordFeedback.warnings.length > 0 ||
        passwordFeedback.suggestions.length > 0) && (
        <div
          className="feedback-container"
          style={
            backgroundColor
              ? { backgroundColor: `var(--${backgroundColor})` }
              : {}
          }
        >
          {feedbackWarning("warnings", passwordFeedback.warnings, "red")}
          {feedbackWarning("suggestions", passwordFeedback.suggestions, "text")}
        </div>
      )}
    </div>
  );
};

export default PasswordCheckerInput;
