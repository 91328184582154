import React, { useState, useEffect } from "react";
import "./styles.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import ReportQuestionnaireStep from "models/app/ReportQuestionnaireStep";
import ReportAnswer from "models/ReportAnswer";
import clsx from "clsx";
import ReportStepQuestion from "models/ReportStepQuestion";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import ReportStep from "models/ReportStep";
import { geocodeByPlaceId } from "react-google-places-autocomplete";

const ReportStepModal: React.FC<{
  questionnaireStep?: ReportQuestionnaireStep;
  Modal: React.FC<{
    children: React.ReactNode;
  }>;
  open: () => void;
  close: () => void;
  isOpen: boolean;
}> = ({ questionnaireStep, Modal, open, close, isOpen }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  const [reportStepComputed, setReportStepComputed] = useState<
    ReportStep | undefined
  >(undefined);

  useEffect(() => {
    setReportStepComputed(undefined);
    setupAnswers();
  }, [questionnaireStep]);

  function setupAnswer(question: ReportStepQuestion) {
    let report_answers = question.answers;
    let answerByCategories = new Map<string, ReportAnswer[]>();
    let answers = "";
    let type = question.question.type;
    question.answers.forEach((element, index) => {
      let answer = element.answer.answer;
      if (type === 2) {
        answer = dayjs(answer).format("LL");
      }
      if (index === report_answers.length - 1) {
        if (element.answer?.category?.name) {
          answerByCategories.has(element.answer.category.name)
            ? answerByCategories
                .get(element.answer.category.name)
                ?.push(element)
            : answerByCategories.set(element.answer.category.name, [
                { ...element }
              ]);
        } else if (answerByCategories) {
          let other = t("report_page.other");
          answerByCategories.has(other)
            ? answerByCategories.get(other)?.push(element)
            : answerByCategories.set(other, [{ ...element }]);
        }
        answers += answer + ".";
      } else {
        if (type === 3) {
          answers += answer + ",\n";
        } else {
          if (element.answer?.category?.name) {
            answerByCategories.has(element.answer.category.name)
              ? answerByCategories
                  .get(element.answer.category.name)
                  ?.push(element)
              : answerByCategories.set(element.answer.category.name, [
                  { ...element }
                ]);
          } else if (answerByCategories) {
            let other = t("report_page.other");

            answerByCategories.has(other)
              ? answerByCategories.get(other)?.push(element)
              : answerByCategories.set(other, [{ ...element }]);
          }
          answers += answer + ", ";
        }
      }
    });

    return answerByCategories.size > 1 ? (
      <>
        {Array.from(answerByCategories.keys()).map((item, idx) => (
          <div key={idx}>
            <p className="report-step-modal-category">{item}</p>
            <p className="report-step-modal-answer">
              {answerByCategories
                .get(item)
                ?.map((current_answer: any, index: number) => (
                  <>
                    {current_answer.answer.answer +
                      (index + 1 === answerByCategories.get(item)?.length
                        ? idx + 1 === Object.keys(answerByCategories).length
                          ? "."
                          : "."
                        : ", ")}
                  </>
                ))}
            </p>
          </div>
        ))}
      </>
    ) : (
      <p className="report-step-modal-answer">{answers}</p>
    );
  }

  const setupAnswers = () => {
    setIsLoading(true);
    if (questionnaireStep?.report_step) {
      let report_step = JSON.parse(
        JSON.stringify(questionnaireStep?.report_step)
      ) as ReportStep;
      let is_type_3 = false;
      report_step?.report_step_questions.forEach(
        (report_step_question, key) => {
          if (report_step_question.question.type === 3) {
            is_type_3 = true;
          }
        }
      );
      if (is_type_3) {
        report_step?.report_step_questions.forEach(
          (report_step_question, key) => {
            if (report_step_question.question.type === 3) {
              let promises = report_step_question.answers.map(
                (element, index) => {
                  return setupPromise(element.answer.answer).then(
                    (location) => {
                      if (location != null) {
                        element.answer.answer = location as string;
                        return element;
                      }
                    }
                  );
                }
              );
              Promise.all(promises)
                .then((results) => {
                  setIsLoading(false);
                  setReportStepComputed(report_step);
                })
                .catch((err) => {
                  setIsLoading(false);
                });
            }
          }
        );
      } else {
        setIsLoading(false);
        setReportStepComputed(report_step);
      }
    }
  };

  const setupPromise = (place_id: string) => {
    return new Promise((resolve, reject) => {
      geocodeByPlaceId(place_id)
        .then((results) => {
          resolve(results[0].formatted_address);
        })
        .catch((error) => {
          resolve("");
        });
    });
  };

  return (
    <Modal>
      {questionnaireStep ? (
        <div className={clsx("notme-modal", "report-step-modal")}>
          <div className="notme-modal-header">
            <h1>{questionnaireStep.title}</h1>
            <span className="notme-modal-header-close" onClick={close}>
              <FontAwesomeIcon icon={faTimes} className="times-icon" />
            </span>
          </div>
          {!isLoading ? (
            <div className="notme-modal-body">
              <div className="notme-modal-body-content">
                <div className="report-step-modal-answers">
                  {reportStepComputed?.report_step_questions.map(
                    (report_step_question, index) => (
                      <div className="report-step-modal-row" key={index}>
                        <p>{report_step_question.question.question}</p>
                        {setupAnswer(report_step_question)}
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          ) : (
            <p>{t("loading")}</p>
          )}
        </div>
      ) : null}
    </Modal>
  );
};

export default ReportStepModal;
