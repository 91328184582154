import React from "react";
import "./styles.scss";

import Card from "components/ui-kit/Card";
import IconCircleButton from "components/IconCircleButton";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

interface OrganizationEmailCellProps {
  email: string;
  isEditable?: boolean;
  onClick: (email: string) => void;
}

const OrganizationEmailCell: React.FC<OrganizationEmailCellProps> = ({
  email,
  isEditable,
  onClick
}) => {
  return (
    <Card className="contact-cell">
      <p>{email}</p>
      {isEditable && (
        <IconCircleButton
          icon={faTimes}
          color="var(--red)"
          onClick={() => onClick(email)}
        />
      )}
    </Card>
  );
};

interface OrganizationEmailListProps {
  contacts: string[];
  isEditable?: boolean;
  setContactEmails: (emails: string[]) => void;
}

const OrganizationEmailList: React.FC<OrganizationEmailListProps> = ({
  contacts,
  isEditable = true,
  setContactEmails
}) => {
  const deleteEmail = (email: string) => {
    setContactEmails(contacts.filter((e) => e !== email));
  };

  return (
    <div className="contact-list">
      {contacts.map((contactItem) => (
        <OrganizationEmailCell
          email={contactItem}
          key={contactItem}
          isEditable={isEditable}
          onClick={deleteEmail}
        />
      ))}
    </div>
  );
};

export default OrganizationEmailList;
