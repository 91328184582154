import { useContext, useEffect, useState } from "react";
import "./styles.scss";

import { useTranslation } from "react-i18next";
import { faCity } from "@fortawesome/free-solid-svg-icons";
import ThemeContext from "contexts/theme/ThemeContext";
import companyApi from "api/companyApi";
import SubCompany from "models/SubCompany";

import CenterLayout from "layouts/CenterLayout";
import HeaderTitle from "components/HeaderTitle";
import Loader from "components/ui-kit/Loader";
import EntityCell from "./components/EntityCell";
import EmptyData from "components/ui-kit/EmptyData";
import { useEntity } from "contexts/entity/useEntity";

const EntitiesPage = () => {
  const { dark } = useContext(ThemeContext);
  const { t } = useTranslation();
  const entity = useEntity();
  const entitiesList = entity?.entitiesList || [];

  return (
    <CenterLayout>
      <div className="entities-page">
        <main style={{ padding: "1rem 0" }}>
          <HeaderTitle title={t("pages.settings.entities")} canGoBack={false} />
        </main>
        <div className="entities-container">
          {entitiesList.length > 0 &&
            entitiesList.map((company: SubCompany, index: number) => (
              <EntityCell key={company.id} company={company} />
            ))}

          {entitiesList.length === 0 && (
            <EmptyData
              title={t("entities_page.no_entities")}
              description={t("entities_page.no_entities_description")}
              icon={faCity}
            />
          )}
        </div>
      </div>
    </CenterLayout>
  );
};

export default EntitiesPage;
