import React from "react";
import { useTranslation } from "react-i18next";
import ActionModal from "components/ui-kit/Modals/ActionModal";
import Button from "components/ui-kit/Buttons/Button";

const EntitlementsModal: React.FC<{
  Modal: React.FC<{
    children: React.ReactNode;
  }>;
  actionLoading: boolean;
  actionButton: () => void;
  close: () => void;
}> = ({ Modal, actionButton, actionLoading, close }) => {
  const { t } = useTranslation();

  return (
    <ActionModal
      Modal={Modal}
      close={close}
      title={t("entitlements_page.update_entitlements_title")}
      description={t("entitlements_page.update_entitlements_description")}
    >
      <Button onClick={actionButton} loading={actionLoading}>
        {t("action_button.update")}
      </Button>
    </ActionModal>
  );
};

export default EntitlementsModal;
