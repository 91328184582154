import React from "react";
import "./styles.scss";
import { useMediaQuery } from "react-responsive";
import clsx from "clsx";

interface SplitLayoutProps {
  headerComponent?: React.ReactNode;
  leftComponent: React.ReactNode;
  rightComponent: React.ReactNode;
  leftFullWidth?: boolean;
  switchOrderOnMobile?: boolean;
}

const SplitLayout: React.FC<SplitLayoutProps> = ({
  headerComponent,
  leftComponent,
  rightComponent,
  leftFullWidth = false,
  switchOrderOnMobile = false
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  return (
    <div className="split-layout-container">
      {isMobile ? headerComponent : null}
      <div
        className={clsx(
          `split-layout-left ${
            switchOrderOnMobile ? "split-layout-order-2" : ""
          }`,
          leftFullWidth ? "split-layout-left-full-width" : ""
        )}
      >
        {!isMobile ? headerComponent : null}
        {leftComponent}
      </div>
      <div
        className={`split-layout-right ${
          switchOrderOnMobile ? "split-layout-order-1" : ""
        }`}
      >
        {rightComponent}
      </div>
    </div>
  );
};

export default SplitLayout;
