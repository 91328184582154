import React, { useState, useContext } from "react";
import "./styles.scss";

import { useTranslation } from "react-i18next";
import {
  notificationError,
  notificationSuccess
} from "utils/custom_notifications";
import { useAuth } from "contexts/auth/useAuth";
import ThemeContext from "contexts/theme/ThemeContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import userApi from "api/userApi";
import helpers from "api/helpers/helpers";

import Button from "components/ui-kit/Buttons/Button";

const DeleteAccountModal: React.FC<{
  Modal: React.FC<{
    children: React.ReactNode;
  }>;
  close: () => void;
  email?: string;
}> = ({ Modal, close, email }) => {
  const auth = useAuth();
  const { t } = useTranslation();
  const { dark } = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);

  const deleteAccount = () => {
    setLoading(true);
    userApi
      .deleteUser()
      .then((response) => {
        setLoading(false);
        if (response.status === 200) {
          notificationSuccess(
            t("profile_page.account_deleted_confirmation"),
            dark,
            {
              autoClose: 5000
            }
          );
          auth?.logout();
        }
      })
      .catch((err) => {
        notificationError(helpers.formatErrorMessage(err), dark);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal>
      <div className="notme-modal notme-alert">
        <div className="notme-modal-header">
          <h1>{t("profile_page.delete_account")}</h1>
          <span className="notme-modal-header-close" onClick={close}>
            <FontAwesomeIcon icon={faTimes} className="times-icon" />
          </span>
        </div>
        <div className="notme-modal-body">
          <h6>{t("profile_page.delete_account_confirmation")}</h6>
        </div>
        <div className="notme-modal-footer">
          <Button context="red" onClick={deleteAccount} loading={loading}>
            {t("action_button.yes_delete")}
          </Button>
          <Button outline onClick={close}>
            {t("action_button.cancel")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteAccountModal;
