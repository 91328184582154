import React, { useState } from "react";
import "./styles.scss";

import Switch from "components/ui-kit/Switch";
import Entitlements from "models/Entitlements";

interface EntitlementsCellProps {
  entitlement: keyof Entitlements;
  title: string;
  description: string;
  isEnabled: boolean;
  updateEntitlement: (entitlement: keyof Entitlements) => void;
}

const EntitlementsCell: React.FC<EntitlementsCellProps> = ({
  entitlement,
  title,
  description,
  isEnabled,
  updateEntitlement
}) => {
  return (
    <div className="entitlements-cell">
      <div className="entitlements-cell-texts">
        <p className="entitlements-cell-title">{title}</p>
        <p className="entitlements-cell-description">{description}</p>
      </div>

      <div className="entitlements-cell-switch-container">
        <Switch
          checked={isEnabled}
          onClick={() => updateEntitlement(entitlement)}
        />
      </div>
    </div>
  );
};

export default EntitlementsCell;
