import React from "react";
import "./styles.scss";
import Company from "models/Company";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export interface EntitySelectTagProps {
  company?: Company;
  onDelete?: any;
}

const EntitySelectTag: React.FC<EntitySelectTagProps> = ({
  company,
  onDelete
}) => {
  const baseClass = "entity-select-tag";

  return (
    <div className={`${baseClass}-container`}>
      <span className={baseClass}>
        <img
          alt={company?.name}
          className={`${baseClass}-img`}
          height={16}
          width={16}
          src={company?.logo}
        />
        <p>{company?.name}</p>
        <div
          className={`${baseClass}-btn`}
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
        >
          <FontAwesomeIcon icon={faTimes} size="sm" />
        </div>
      </span>
    </div>
  );
};

EntitySelectTag.displayName = "EntitySelectTag";

export default EntitySelectTag;
