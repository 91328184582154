import { OptionType } from "components/ui-kit/Input";
import { TFunction } from "react-i18next";

const role_list = [
  {
    role: "role.admin",
    color: "red"
  },
  {
    role: "role.manager",
    color: "light_blue"
  },
  {
    role: "role.subscriber",
    color: "dark_blue"
  },
  {
    role: "role.editor",
    color: "green"
  },
  {
    role: "role.viewer",
    color: "dark_red"
  },
  {
    role: "role.super admin",
    color: "dark_grey"
  },
  {
    role: "role.customer service",
    color: "dark_green"
  },
  {
    role: "role.user",
    color: "blue"
  }
];

const DASHBOARD_ROLES = ["admin", "manager", "subscriber", "editor", "viewer"];

function getRoleColor(role: string): string {
  const element = role_list?.find((element) => element.role === "role." + role);
  if (element) {
    return element.color;
  } else {
    return "blue";
  }
}

function getUserDashboardRoleOptions(t: TFunction): OptionType[] {
  const roles = DASHBOARD_ROLES;

  const dashboardRoles = roles.map((role) => {
    return {
      key: role,
      value: role,
      title: t("role." + role)
    };
  });

  return dashboardRoles;
}

const role_utils = {
  DASHBOARD_ROLES,
  getRoleColor,
  getUserDashboardRoleOptions
};

export default role_utils;
