import { FC } from "react";
import { useAuth } from "./useAuth";
import { Navigate } from "react-router-dom";

interface PropType {
  component: React.FC;
}

const RequireNoAuth: FC<PropType> = ({ component: Component }) => {
  const auth = useAuth();

  if (auth?.isLoggedIn) return <Navigate to="/" />;
  return <Component />;
};

export default RequireNoAuth;
