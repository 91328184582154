import { OptionType } from "components/ui-kit/Input";
import { TFunction } from "react-i18next";

const industries = [
  "education",
  "energy",
  "entertainment",
  "fashion",
  "festivals",
  "financial",
  "food",
  "gov",
  "health",
  "hospitality",
  "nonprofit",
  "arts",
  "retail",
  "sports",
  "tech",
  "other"
];

export function getIndustries(): string[] {
  return industries;
}

export function getIndustriesOptions(t: TFunction): OptionType[] {
  return industries.map((industry) => ({
    key: industry,
    value: industry,
    title: t("settings_sections.general.industries." + industry)
  }));
}
