import AppRouteObject from "models/app/AppRouteObject";
import RequireAuth from "contexts/auth/RequireAuth";
import {
  faCity,
  faCog,
  faUserFriends,
  faUser,
  faUsers,
  faBuilding
} from "@fortawesome/free-solid-svg-icons";

import General from "pages/SettingsPages/GeneralPage";
import Permissions from "pages/SettingsPages/PermissionsPage";
import PermissionsUsers from "pages/SettingsPages/PermissionsUsersPage";
import PermissionsTable from "pages/SettingsPages/PermissionsTablePage";
import Welcome from "pages/SettingsPages/WelcomePage";
import Profile from "pages/ProfilePage";
import Entities from "pages/EntitiesPage";
import Entitlements from "pages/EntitlementsPage";

const settingsRoutes: AppRouteObject[] = [
  {
    path: "settings/general",
    meta: {
      title: "pages.settings.general",
      icon: faCog,
      color: "green",
      permissions: ["view dashboard", "read company profile"],
      isUniqueEntity: true
    },
    children: [
      {
        path: "",
        element: <RequireAuth component={General} />
      }
    ]
  },
  {
    path: "settings/permissions",
    meta: {
      title: "pages.settings.permissions",
      icon: faUserFriends,
      color: "dark_blue",
      permissions: ["view dashboard", "read company members"],
      isUniqueEntity: true
    },
    children: [
      {
        path: "",
        element: <RequireAuth component={Permissions} />,
        children: [
          {
            path: "members",
            element: (
              <RequireAuth
                component={PermissionsUsers}
                permissions={["view dashboard", "read company members"]}
              />
            )
          },
          {
            path: "invited",
            element: (
              <RequireAuth
                component={PermissionsUsers}
                permissions={["view dashboard", "read company members"]}
              />
            )
          }
        ]
      },
      {
        path: "table",
        element: (
          <RequireAuth
            component={PermissionsTable}
            permissions={["view dashboard", "read company members"]}
          />
        )
      }
    ]
  },
  {
    path: "settings/welcome",
    meta: {
      title: "pages.settings.welcome_message",
      icon: faBuilding,
      color: "light_blue",
      permissions: ["view dashboard", "read company profile"],
      isUniqueEntity: true,
      addSeparator: true
    },
    children: [
      {
        path: "",
        element: <RequireAuth component={Welcome} />
      }
    ]
  },
  {
    path: "settings/profile",
    meta: {
      title: "pages.settings.profile",
      icon: faUser,
      color: "blue",
      isUniqueEntity: true,
      addSeparator: true
    },
    children: [
      {
        path: "",
        element: <RequireAuth component={Profile} />
      }
    ]
  },
  {
    path: "settings/entities",
    meta: {
      title: "pages.settings.entities",
      icon: faCity,
      color: "green",
      isUniqueEntity: true,
      permissions: ["update subscribers from any company"]
    },
    children: [
      {
        path: "",
        element: <RequireAuth component={Entities} />
      }
    ]
  },
  {
    path: "settings/entitlements",
    meta: {
      title: "pages.settings.entitlements",
      icon: faUsers,
      color: "light_red",
      isUniqueEntity: true,
      permissions: ["update subscribers from any company"]
    },
    children: [
      {
        path: "",
        element: <RequireAuth component={Entitlements} />
      }
    ]
  }
];

export default settingsRoutes;
