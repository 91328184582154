import React, { useState } from "react";
import "./styles.scss";

import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Editor } from "@tiptap/react";
import url_utils from "utils/url_utils";
import Button from "components/ui-kit/Buttons/Button";
import Input from "components/ui-kit/Input";

const YoutubeModal: React.FC<{
  Modal: React.FC<{
    children: React.ReactNode;
  }>;
  editor: Editor;
  close: () => void;
}> = ({ Modal, editor, close }) => {
  const { t } = useTranslation();

  const [youtubeLink, setYoutubeLink] = useState("");

  const insertYoutubeLink = () => {
    editor.commands.insertContent([
      {
        type: "youtube",
        content: [
          {
            type: "text",
            text: youtubeLink
          }
        ]
      },
      {
        type: "paragraph"
      }
    ]);
    editor.commands.focus();
    closeModal();
  };

  const isValidYoutubeLink = () => {
    return url_utils.isValidVideo(youtubeLink);
  };

  const closeModal = () => {
    setYoutubeLink("");
    close();
  };

  return (
    <Modal>
      <div className="notme-modal youtube-modal">
        <div className="notme-modal-header">
          <h1>{t("post_page.insert_youtube_video")}</h1>
          <span className="notme-modal-header-close" onClick={closeModal}>
            <FontAwesomeIcon icon={faTimes} className="times-icon" />
          </span>
        </div>
        <div className="notme-modal-body">
          <div className="youtube-modal-body">
            <Input
              type="url"
              label={t("post_page.youtube_link")}
              placeholder="e.g. https://www.youtube.com/watch?v=..."
              value={youtubeLink}
              onChange={(e) => setYoutubeLink(e.target.value)}
            />
          </div>
        </div>
        <div className="notme-modal-footer">
          <Button outline onClick={closeModal}>
            {t("action_button.cancel")}
          </Button>
          <Button
            onClick={insertYoutubeLink}
            disabled={!youtubeLink || !isValidYoutubeLink()}
          >
            {t("post_page.add_link")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default YoutubeModal;
