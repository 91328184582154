import { useContext } from "react";
import "./styles.scss";
import logo_dark from "../../assets/img/logo_dark.png";
import logo_white from "../../assets/img/logo_white.png";

import { useAuth } from "../../contexts/auth/useAuth";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useDropdownMenu from "react-accessible-dropdown-menu-hook";
import ThemeContext from "../../contexts/theme/ThemeContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faGear,
  faMoon,
  faSignOut,
  faSun,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import permissions_utils from "utils/permissions_utils";

import { Link } from "react-router-dom";
import Avatar from "components/ui-kit/Avatar";
import { useModal } from "react-hooks-use-modal";
import Button from "components/ui-kit/Buttons/Button";
import Tag from "components/ui-kit/Tag";
import role_utils from "utils/role_utils";

function TopBar() {
  const auth = useAuth();
  const navigate = useNavigate();
  const { dark, toggleDarkMode, toggleShowSidebar } = useContext(ThemeContext);
  const { buttonProps, itemProps, isOpen, setIsOpen } = useDropdownMenu(3);
  const { t } = useTranslation();

  const [Modal, open, close] = useModal("root", {
    preventScroll: true,
    closeOnOverlayClick: true
  });

  const goToSettings = () => {
    setIsOpen(false);
    if (can("view dashboard")) {
      navigate("/settings/general");
    } else {
      navigate("/settings/profile");
    }
  };

  const switchTheme = () => {
    setIsOpen(false);
    toggleDarkMode();
  };

  const logout = () => {
    if (auth?.isLoggedIn) {
      setIsOpen(false);
      close();
      auth.logout();
    }
  };

  const toggleSidebar = () => {
    toggleShowSidebar();
  };

  const can = (permission: string) => {
    return permissions_utils.can(permission, auth);
  };

  return auth?.isLoggedIn ? (
    <nav className="topbar-navbar">
      <div className="navbar-container">
        <div className="navbar-brand-container">
          {auth?.isPhoneVerified && (
            <div className="sidebar-toggle-button" onClick={toggleSidebar}>
              <FontAwesomeIcon icon={faBars} />
            </div>
          )}
          <Link className="navbar-brand" to="/">
            <img
              alt="#NotMe logo"
              src={dark ? logo_white : logo_dark}
              className="img-fluid"
              style={{ height: "28px" }}
            />
          </Link>
        </div>
        <div className="topbar-dropdown-container">
          <button className="topbar-dropdown-button" {...buttonProps}>
            <Avatar
              name={
                auth?.user?.firstname
                  ? auth?.user?.firstname + " " + auth?.user?.lastname
                  : ""
              }
              id={auth?.user?.id || 0}
            />
          </button>
          <div
            className={`topbar-dropdown ${isOpen ? "visible" : ""}`}
            role="menu"
          >
            {auth?.isPhoneVerified ? (
              <>
                {auth?.user ? (
                  <a className="topbar-dropdown-item-user">
                    <Avatar
                      name={auth.user.firstname + " " + auth.user.lastname}
                      id={auth.user.id || 0}
                      className="topbar-dropdown-item-user-avatar"
                    />
                    <div className="topbar-dropdown-item-user-title">
                      <p>{auth.user.firstname + " " + auth.user.lastname}</p>

                      <Tag
                        context={role_utils.getRoleColor(auth.user.role)}
                        round
                        small
                        subtle
                      >
                        {t("role." + auth.user.role)}
                      </Tag>
                    </div>
                  </a>
                ) : null}
                <a
                  className="topbar-dropdown-item"
                  {...itemProps[0]}
                  onClick={goToSettings}
                >
                  <p>{t("pages.settings.settings")}</p>
                  <FontAwesomeIcon icon={faGear} />
                </a>
              </>
            ) : null}
            <a
              className="topbar-dropdown-item"
              {...itemProps[1]}
              onClick={() => switchTheme()}
            >
              <p>
                {dark
                  ? t("top_bar.use_light_mode")
                  : t("top_bar.use_dark_mode")}
              </p>
              <FontAwesomeIcon icon={dark ? faSun : faMoon} />
            </a>
            <a
              className="topbar-dropdown-item"
              {...itemProps[2]}
              onClick={open}
            >
              <p>{t("top_bar.log_out")}</p>
              <FontAwesomeIcon icon={faSignOut} />
            </a>
          </div>
        </div>
      </div>
      <Modal>
        <div className="notme-modal notme-alert">
          <div className="notme-modal-header">
            <h1>{t("top_bar.log_out")}</h1>
            <span className="notme-modal-header-close" onClick={close}>
              <FontAwesomeIcon icon={faTimes} className="times-icon" />
            </span>
          </div>
          <div className="notme-modal-body">
            <p>{t("top_bar.log_out_confirmation")}</p>
          </div>
          <div className="notme-modal-footer">
            <Button context="blue" onClick={logout}>
              {t("action_button.yes")}
            </Button>
            <Button context="blue" outline onClick={close}>
              {t("action_button.cancel")}
            </Button>
          </div>
        </div>
      </Modal>
    </nav>
  ) : null;
}

export default TopBar;
