import React, { useCallback, useContext, useState } from "react";
import "./styles.scss";

import { useTranslation } from "react-i18next";
import { FileRejection, useDropzone } from "react-dropzone";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import ReportEvidenceIcon from "components/ReportEvidenceIcon";
import IconCircleButton from "components/IconCircleButton";
import { notificationError } from "utils/custom_notifications";
import ThemeContext from "contexts/theme/ThemeContext";

type DocumentDropzoneProps = {
  isImage: boolean;
  maxFileSize?: number;
  onFileAdded: (file: File | null) => void;
};

const DocumentDropzone: React.FC<DocumentDropzoneProps> = ({
  isImage,
  maxFileSize,
  onFileAdded
}) => {
  const { t } = useTranslation();
  const { dark } = useContext(ThemeContext);

  const [documentToUpload, setDocumentToUpload] = useState<File | null>(null);

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (fileRejections.length > 0) {
        const error = fileRejections[0].errors[0];
        if (error.code === "file-too-large") {
          notificationError(
            t("post_page.maximum_file_size_allowed", {
              size: maxFileSize + "MB"
            }),
            dark
          );
        }
      } else {
        setDocumentToUpload(acceptedFiles[0]);
        onFileAdded(acceptedFiles[0]);
      }
    },
    []
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: isImage
      ? {
          "image/*": []
        }
      : {},
    maxFiles: 1,
    multiple: false,
    maxSize: maxFileSize ? maxFileSize * 1024 * 1024 : Infinity,
    onDrop
  });

  const clearDocument = () => {
    setDocumentToUpload(null);
    onFileAdded(null);
  };

  if (documentToUpload) {
    return (
      <div className="document-cell">
        <div className="document-cell-body">
          <ReportEvidenceIcon file={documentToUpload} />
          <p className="document-cell-title">{documentToUpload.name}</p>
        </div>
        <IconCircleButton
          icon={faTrashAlt}
          color={"var(--grey)"}
          onClick={() => clearDocument()}
        />
      </div>
    );
  } else {
    return (
      <div {...getRootProps()} className="document-dropzone">
        <input {...getInputProps()} />
        <FontAwesomeIcon icon={faImage} size="2x" />
        <div className="document-dropzone-indicator">
          <p>
            {isImage
              ? t("post_page.click_here_drop_image")
              : t("post_page.click_here_drop_document")}
          </p>
          {maxFileSize && (
            <p className="subtitle document-size-text">
              {t("post_page.maximum_file_size_allowed", {
                size: maxFileSize + "MB"
              })}
            </p>
          )}
        </div>
      </div>
    );
  }
};

export default DocumentDropzone;
