import React from "react";
import Input from "components/ui-kit/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

type LinkOrganizationStepProps = {
  companyPin: string;
  setCompanyPin: (pin: string) => void;
  companyTitle: string;
  setCompanyTitle: (title: string) => void;
  checkOrganizationButtonDisabled: boolean;
  loading: boolean;
  checkOrganization: () => void;
  close: () => void;
};

const LinkOrganizationStep: React.FC<LinkOrganizationStepProps> = ({
  companyPin,
  setCompanyPin,
  companyTitle,
  setCompanyTitle,
  checkOrganizationButtonDisabled,
  loading,
  checkOrganization,
  close
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="notme-modal-header">
        <h1>{t("organization_modal.link_organization")}</h1>
        <span className="notme-modal-header-close" onClick={close}>
          <FontAwesomeIcon icon={faTimes} className="times-icon" />
        </span>
      </div>
      <h6 style={{ fontSize: 14 }}>
        {t("organization_modal.organization_is_subscriber_description")}
      </h6>
      <Input
        label={t("organization_modal.organization_pin")}
        value={companyPin}
        onChange={(e) => setCompanyPin(e.target.value)}
      />
      <Input
        label={t("organization_modal.job_title_optional")}
        placeholder={t("organization_modal.job_title_example")}
        value={companyTitle}
        onChange={(e) => setCompanyTitle(e.target.value)}
        onEnterPressed={() =>
          !checkOrganizationButtonDisabled && !loading && checkOrganization()
        }
      />
    </>
  );
};

export default LinkOrganizationStep;
