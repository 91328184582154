import React, { useEffect, useRef, useState, useContext } from "react";
import "./styles.scss";
import ThemeContext from "contexts/theme/ThemeContext";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileImage } from "@fortawesome/free-regular-svg-icons";

import { ReportInfo } from "models/ReportInfo";
import Button from "components/ui-kit/Buttons/Button";
import ReportFile from "models/ReportFile";
import Card from "components/ui-kit/Card";
import dayjs from "dayjs";
import {
  faCalendarAlt,
  faCheck,
  faTimes,
  faTrashAlt
} from "@fortawesome/free-solid-svg-icons";
import ReportEvidenceIcon from "components/ReportEvidenceIcon";
import IconCircleButton from "components/IconCircleButton";
import reportApi from "api/reportApi";
import { toast } from "react-toastify";
import helpers from "api/helpers/helpers";
import UploadStatus from "models/app/UploadStatus";
import ReportEvidenceCell from "./ReportEvidenceCell";
import {
  notificationError,
  notificationInfo
} from "utils/custom_notifications";
import { useTranslation } from "react-i18next";
import ReportInternalStatus from "models/app/ReportInternalStatus";
import ReportEvidenceList from "./ReportEvidenceList";
import EmptyData from "components/ui-kit/EmptyData";

const ReportEvidence: React.FC<{
  report: ReportInfo;
  status?: ReportInternalStatus | null;
}> = ({ report, status }) => {
  const { dark } = useContext(ThemeContext);
  const { t } = useTranslation();
  const [evidenceList, setEvidenceList] = useState<ReportFile[]>(
    report.report_files
  );
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const [uploadProgress, setUploadProgress] = useState<number>(0);

  const [selectedEvidence, setSelectedEvidence] = useState<ReportFile | null>(
    null
  );

  const [uploadStatus, setUploadStatus] = useState<UploadStatus>(
    UploadStatus.NOTSTARTED
  );

  const isFilesToUploadMaxSize = () => {
    let size = 0;
    filesToUpload.forEach((file) => {
      size += file.size;
    });
    console.log(size / 1024 / 1024);
    if (size / 1024 / 1024 > 15) {
      notificationInfo(
        "The files you are trying to upload are too large.",
        dark
      );
      return false;
    } else {
      return true;
    }
  };

  const inputRef = useRef<HTMLInputElement | null>(null);

  const ReportEvidenceUploaderContent = () => {
    if (uploadStatus === UploadStatus.NOTSTARTED) {
      return (
        <div className="report-evidence-uploader-list">
          {filesToUpload.map((file, index) => (
            <div className="report-evidence-uploader-row" key={index}>
              <div className="report-evidence-uploader-body">
                <ReportEvidenceIcon file={file} />
                <p className="report-evidence-uploader-row-title">
                  {file.name}
                </p>
              </div>
              <IconCircleButton
                icon={faTrashAlt}
                color={"var(--grey)"}
                onClick={() => removeEvidence(index)}
              />
            </div>
          ))}
          <div className="button-container">
            <Button
              onClick={uploadEvidence}
              disabled={
                uploadStatus !== UploadStatus.NOTSTARTED ||
                !isFilesToUploadMaxSize()
              }
            >
              {t("report_page.upload_evidence")}
            </Button>
            <Button
              onClick={resetUpload}
              outline
              context="red"
              disabled={uploadStatus !== UploadStatus.NOTSTARTED}
            >
              {t("action_button.cancel")}
            </Button>
          </div>
        </div>
      );
    } else {
      return (
        <>
          {uploadStatus === UploadStatus.UPLOADING ? (
            <>
              <div style={{ display: "inline-block" }}>
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: `${uploadProgress}%`,
                      backgroundColor: "var(--blue)"
                    }}
                    aria-valuenow={uploadProgress}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
              </div>
              <div className="report-evidence-uploader-status">
                <p className="report-evidence-uploader-title">
                  {t("report_page.upload_in_progress")}
                </p>
              </div>
            </>
          ) : (
            <div className="report-evidence-uploader-status">
              <div className="report-evidence-uploader-icon-container">
                <FontAwesomeIcon icon={faCheck} />
              </div>
              <p className="report-evidence-uploader-title">
                {t("report_page.upload_completed")}
              </p>
            </div>
          )}
        </>
      );
    }
  };

  const ReportEvidenceUploader = () => {
    if (filesToUpload.length > 0) {
      return (
        <Card className="report-evidence-uploader">
          <ReportEvidenceUploaderContent />
        </Card>
      );
    } else {
      return null;
    }
  };

  const onInputClicked = (event: any) => {
    event.target.value = "";
  };

  const onFilePicked = (event: any) => {
    setFilesToUpload([...filesToUpload, ...event.target.files]);
  };

  const addEvidence = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  };

  const removeEvidence = (index: number) => {
    if (index > -1) {
      let newFilesToUpload = filesToUpload.filter((_, i) => i !== index);
      setFilesToUpload(newFilesToUpload);
    }
  };

  const resetUpload = () => {
    setFilesToUpload([]);
    setUploadProgress(0);
    setUploadStatus(UploadStatus.NOTSTARTED);
  };

  const uploadEvidence = () => {
    let formData = new FormData();
    filesToUpload.forEach((file) => {
      formData.append("files[]", file);
    });
    setUploadStatus(UploadStatus.UPLOADING);
    reportApi
      .uploadEvidence(report.id, formData, (progress: ProgressEvent) => {
        setUploadProgress(Math.floor((progress.loaded * 100) / progress.total));
      })
      .then((response) => {
        if (response.status === 200) {
          setUploadStatus(UploadStatus.COMPLETED);

          reportApi.getReport(report.id).then((res) => {
            if (res.status === 200) {
              setEvidenceList(res.data.report_files);
              resetUpload();
            }
          });
        }
      })
      .catch((err) => {
        notificationError(helpers.formatErrorMessage(err), dark);
        setUploadStatus(UploadStatus.FAILED);
      });
  };

  return (
    <div className="report-evidence">
      <div className="report-evidence-title">
        <h5 className="header-section-title">{t("report_page.evidence")}</h5>
        {!(
          report.status_id < 3 &&
          (status === ReportInternalStatus.OUTDATED_QUESTIONNAIRE ||
            status === ReportInternalStatus.INVALID_COMPANY)
        ) ? (
          <Button outline context="blue" onClick={addEvidence}>
            {t("report_page.add_evidence")}
          </Button>
        ) : null}
        <input
          type="file"
          id="evidenceUploader"
          ref={inputRef}
          style={{ display: "none" }}
          multiple
          onClick={onInputClicked}
          onChange={onFilePicked}
        />
      </div>
      {evidenceList.length > 0 || filesToUpload.length > 0 ? (
        <>
          <ReportEvidenceUploader />
          <ReportEvidenceList evidenceList={evidenceList} />
        </>
      ) : (
        <EmptyData
          title={t("report_page.no_evidence_yet")}
          icon={faFileImage}
        />
      )}
    </div>
  );
};

export default ReportEvidence;
