import axios from "axios";
import helpers from "./helpers";
import qs from "qs";

const request_GET = (
  url,
  parameters = null,
  isAuth = false,
  customHeaders = null
) => {
  let config = helpers.configureOptions(isAuth);
  if (customHeaders) {
    config.headers = { ...config.headers, ...customHeaders };
  }
  if (parameters) {
    config.params = parameters;
  }
  return axios.get(url, config);
};

const request_GET_html = (url, isAuth = false) => {
  let config = helpers.configureOptions(isAuth);
  return axios.get(url, {
    ...config,
    headers: {
      "Content-Type": "text/html"
    }
  });
};

const request_POST = (url, parameters, isAuth = false) => {
  let config = helpers.configureOptions(isAuth);
  return axios.post(url, parameters, config);
};

const request_POST_multipart = (
  url,
  parameters,
  onUploadProgress,
  isAuth = false
) => {
  let config = helpers.configureOptions(isAuth);
  return axios.post(url, parameters, {
    onUploadProgress: onUploadProgress,
    headers: {
      ...config.headers,
      "Content-Type": "multipart/form-data"
    }
  });
};

const request_PUT = (url, parameters, isAuth = false, customHeaders = null) => {
  let config = helpers.configureOptions(isAuth);
  if (customHeaders) {
    config.headers = { ...config.headers, ...customHeaders };
  }
  return axios.put(url, qs.stringify(parameters), {
    headers: {
      ...config.headers,
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
};

const request_PUT_json = (url, parameters, isAuth = false) => {
  let config = helpers.configureOptions(isAuth);
  return axios.put(url, qs.stringify(parameters), {
    headers: {
      ...config.headers,
      "Content-Type": "application/json"
    }
  });
};

const request_PATCH = (url, parameters, isAuth = false) => {
  let config = helpers.configureOptions(isAuth);
  return axios.patch(url, qs.stringify(parameters), {
    headers: {
      ...config.headers,
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
};

const request_PATCH_json = (url, parameters, isAuth = false) => {
  let config = helpers.configureOptions(isAuth);
  return axios.patch(url, JSON.stringify(parameters), {
    headers: {
      ...config.headers,
      "Content-Type": "application/json"
    }
  });
};

const request_PATCH_multipart = (
  url,
  parameters,
  onUploadProgress,
  isAuth = false
) => {
  let config = helpers.configureOptions(isAuth);
  return axios.patch(url, parameters, {
    onUploadProgress: onUploadProgress,
    headers: {
      ...config.headers,
      "Content-Type": "multipart/form-data"
    }
  });
};

const request_DELETE = (url, parameters, isAuth = false) => {
  let config = helpers.configureOptions(isAuth);
  return axios.delete(url, {
    params: parameters,
    ...config
  });
};

const request_DELETE_parameters = (url, parameters, isAuth = false) => {
  let config = helpers.configureOptions(isAuth);
  return axios.delete(url, {
    params: parameters,
    ...config,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
};

const request_DELETE_body = (url, body, isAuth = false) => {
  let config = helpers.configureOptions(isAuth);
  return axios.delete(url, {
    ...config,
    data: body
  });
};

const requests = {
  request_GET,
  request_GET_html,
  request_POST,
  request_POST_multipart,
  request_PUT,
  request_PUT_json,
  request_PATCH,
  request_PATCH_json,
  request_PATCH_multipart,
  request_DELETE,
  request_DELETE_parameters,
  request_DELETE_body
};

export default requests;
