import api from "./api";
import constants from "./constants";

const loginUser = (params) => {
  return api.request_post(constants.LOGIN_URL, params);
};

const getReports = () => {
  return api.request_get_auth(constants.REPORTS_URL);
};

const signUp = (content) => {
  return api.request_post(constants.SIGNUP_URL, content);
};

const validateSignUp = (content) => {
  return api.request_post(constants.VALIDATE_SIGNUP_URL, content);
};

const postRecover = (email) => {
  return api.request_post(constants.RECOVER_URL, { email: email });
};

const getTerms = () => {
  return api.request_get(constants.TERMS_URL);
};

const getPrivacy = () => {
  return api.request_get(constants.PRIVACY_URL);
};

const editUser = (editInfo) => {
  return api.request_put_auth(constants.USER_URL, editInfo);
};

const checkCompany = (params) => {
  return api.request_post_auth(constants.CHECK_COMPANY_URL, params);
};

const addCompany = (params) => {
  return api.request_post_auth(constants.ADD_COMPANY_URL, params);
};

const removeCompany = () => {
  return api.request_delete_auth(constants.REMOVE_COMPANY_URL);
};

const verifyMail = (email) => {
  return api.request_post(constants.VERIFY_MAIL_URL, { email: email });
};

const verifyCompanyEmail = (email) => {
  return api.request_post(constants.VERIFY_MAIL_URL, { company_email: email });
};

const getUserData = () => {
  return api.request_get_auth(constants.USER_DATA_URL);
};

const deleteUser = () => {
  return api.request_delete_auth(constants.USER_URL);
};

const getUser = () => {
  return api.request_get_auth(constants.USER_URL);
};

const getUserById = (id) => {
  return api.request_get_auth(`${constants.USER_URL}/${id}`);
};

const adminGetUserById = (id) => {
  return api.request_get_auth(`${constants.DASHBOARD_USER_URL}/${id}`);
};

const getPermissions = () => {
  return api.request_get_auth(constants.USER_PERMISSIONS_URL);
};

const logoutUser = () => {
  return api.request_get_auth(constants.LOGOUT_URL);
};

const userApi = {
  getReports,
  loginUser,
  getTerms,
  getPrivacy,
  signUp,
  validateSignUp,
  postRecover,
  editUser,
  checkCompany,
  addCompany,
  removeCompany,
  verifyMail,
  verifyCompanyEmail,
  getUserData,
  deleteUser,
  getUser,
  getUserById,
  adminGetUserById,
  getPermissions,
  logoutUser
};

export default userApi;
