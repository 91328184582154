import React, { useState, useContext } from "react";
import Company from "models/Company";

import { useAuth } from "contexts/auth/useAuth";
import { useTranslation } from "react-i18next";
import ThemeContext from "contexts/theme/ThemeContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTrashAlt,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import {
  notificationError,
  notificationSuccess
} from "utils/custom_notifications";
import dayjs from "dayjs";
import helpers from "api/helpers/helpers";
import userApi from "api/userApi";

import Button from "components/ui-kit/Buttons/Button";
import CompanyLogo from "components/ui-kit/CompanyLogo";
import IconCircleButton from "components/IconCircleButton";

const CompanyModal: React.FC<{
  company?: Company;
  company_added_at?: string;
  company_email?: string;
  Modal: React.FC<{
    children: React.ReactNode;
  }>;
  open: () => void;
  close: () => void;
  isOpen: boolean;
}> = ({
  company,
  company_added_at,
  company_email,
  Modal,
  open,
  close,
  isOpen
}) => {
  const auth = useAuth();
  const { dark } = useContext(ThemeContext);
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const removeCompany = () => {
    setLoading(true);
    userApi
      .removeCompany()
      .then((response) => {
        if (response.status === 200) {
          auth
            ?.getUser()
            .then((res) => {
              notificationSuccess(
                t("organization_modal.organization_removed"),
                dark
              );
              setLoading(false);
              close();
            })
            .catch((err) => {
              setLoading(false);
            });
        }
      })
      .catch((err) => {
        notificationError(helpers.formatErrorMessage(err), dark);
        setLoading(false);
      });
  };

  return (
    <Modal>
      <div className="notme-modal">
        <div className="notme-modal-header">
          <h1>{t("organization")}</h1>
          <span className="notme-modal-header-close" onClick={close}>
            <FontAwesomeIcon icon={faTimes} className="times-icon" />
          </span>
        </div>
        {!showConfirmation ? (
          <div className="notme-modal-body">
            <div className="notme-modal-body-content">
              <CompanyLogo company={company} size={50}></CompanyLogo>
              <div>
                <h4 className="company-banner-modal-text">{company?.name}</h4>
                <h6 className="company-banner-modal-text">
                  {t("organization_modal.linked_since", {
                    date: dayjs(company_added_at).format("LLL")
                  })}
                </h6>
                {company_email ? (
                  <p className="company-banner-modal-text">
                    {t("organization_modal.added_via") + " "}
                    <b>{t("user_data.email")}</b> - {company_email}
                  </p>
                ) : (
                  <p className="company-banner-modal-text">
                    {t("organization_modal.added_via") + " "}{" "}
                    <b>{t("organization_modal.pin")}</b>
                  </p>
                )}
                <p className="company-banner-modal-icon-text">
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    color={"var(--green)"}
                  />
                  {t("organization_modal.you_re_all_set")}
                </p>
              </div>
            </div>
            <IconCircleButton
              icon={faTrashAlt}
              color={"var(--red)"}
              onClick={() => setShowConfirmation(true)}
            />
          </div>
        ) : (
          <div className="company-banner-modal-remove-company">
            <h4>{t("organization_modal.organization_remove")}</h4>
            <p>{t("organization_modal.organization_remove_confirmation")}</p>
            <div className="notme-modal-footer">
              <Button onClick={removeCompany} context={"red"} loading={loading}>
                {t("organization_modal.unlink_my_organization")}
              </Button>
              <Button onClick={() => setShowConfirmation(false)} outline>
                {t("action_button.cancel")}
              </Button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default CompanyModal;
