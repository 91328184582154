import { useOutlet } from "react-router-dom";

import { AuthProvider } from "contexts/auth/useAuth";
import { InterceptorsProvider } from "contexts/auth/useInterceptors";
import { ThemeProvider } from "contexts/theme/ThemeContext";
import { ToastContainer } from "react-toastify";
import { EntityProvider } from "contexts/entity/useEntity";

import TopBar from "components/TopBar";
import ScrollToTop from "navigation/helpers/ScrollToTop";

const AppProviders = () => {
  const outlet = useOutlet();

  return (
    <>
      <ThemeProvider>
        <AuthProvider>
          <EntityProvider>
            <InterceptorsProvider>
              <ScrollToTop />
              <TopBar />
              {outlet}
            </InterceptorsProvider>
          </EntityProvider>
        </AuthProvider>
      </ThemeProvider>
      <ToastContainer />
    </>
  );
};

export default AppProviders;
