import React, { useState, useContext, useEffect } from "react";
import "./styles.scss";

import { useAuth } from "contexts/auth/useAuth";
import { useTranslation } from "react-i18next";
import ThemeContext from "contexts/theme/ThemeContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  notificationError,
  notificationSuccess
} from "utils/custom_notifications";
import role_utils from "utils/role_utils";
import permissions_utils from "utils/permissions_utils";
import helpers from "api/helpers/helpers";
import companyApi from "api/companyApi";
import UserInviteSender from "models/app/UserInviteSender";
import Invite from "models/Invite";
import UserSimple from "models/UserSimple";

import Button from "components/ui-kit/Buttons/Button";
import Input from "components/ui-kit/Input";
import { Link } from "react-router-dom";

const PermissionEditModal: React.FC<{
  Modal: React.FC<{
    children: React.ReactNode;
  }>;
  close: () => void;
  invite: Invite | null;
  member?: UserSimple | null;
  updateList?: () => void;
}> = ({ Modal, close, invite, member, updateList }) => {
  const auth = useAuth();
  const { dark } = useContext(ThemeContext);
  const { t } = useTranslation();

  const i18n_page = "settings_sections.permissions";

  const [selectedRole, setSelectedRole] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const roles = role_utils.getUserDashboardRoleOptions(t);

  useEffect(() => {
    if (invite) {
      setSelectedRole(invite.role);
    } else if (member) {
      setSelectedRole(member.role);
    }
  }, [invite, member]);

  const isValid = () => {
    return (
      (invite && selectedRole !== invite.role) ||
      (member && selectedRole !== member.role)
    );
  };

  const updateRole = (e: any) => {
    const role_selected = roles[e.target.options.selectedIndex];
    setSelectedRole(role_selected.value);
  };

  const updateInvite = async () => {
    if (!isValid()) return;
    if (!invite) return;

    const params: UserInviteSender = {
      email: invite.email,
      role: selectedRole
    };

    if (can("invite subscribers to any company")) {
      params["company_id"] = invite.company.id;
    }

    try {
      setLoading(true);
      await companyApi.inviteCompanySubscribers(params);
      updateList && updateList();
      notificationSuccess(t(`${i18n_page}.role_updated`), dark);
      close();
    } catch (err) {
      notificationError(helpers.formatErrorMessage(err), dark);
    } finally {
      setLoading(false);
    }
  };

  const updateMember = async () => {
    if (!isValid()) return;
    if (!member) return;

    try {
      setLoading(true);
      await companyApi.setRole(member.id, selectedRole);
      updateList && updateList();
      notificationSuccess(t(`${i18n_page}.role_updated`), dark);
      close();
    } catch (err) {
      notificationError(helpers.formatErrorMessage(err), dark);
    } finally {
      setLoading(false);
    }
  };

  const updateUser = () => {
    if (invite) {
      updateInvite();
    } else if (member) {
      updateMember();
    }
  };

  const can = (permission: string) => {
    return permissions_utils.can(permission, auth);
  };

  return (
    <Modal>
      <div className="notme-modal permission-edit-modal">
        <div className="notme-modal-header">
          <h1>{t(`${i18n_page}.${invite ? "edit_invite" : "edit_member"}`)}</h1>
          <span className="notme-modal-header-close" onClick={close}>
            <FontAwesomeIcon icon={faTimes} className="times-icon" />
          </span>
        </div>
        <div className="notme-modal-body permission-edit-modal-container">
          <Input
            label={t("role.role")}
            inputType="select"
            options={roles}
            value={selectedRole}
            onChange={updateRole}
          />
          <p className="permission-edit-indications">
            {t(`${i18n_page}.learn_more_about`) + " "}
            <Link
              className="permission-edit-indications-link"
              to={"/settings/permissions/table"}
              target="_blank"
            >
              {t(`${i18n_page}.roles_permissions`)}
            </Link>
          </p>
        </div>
        <div className="notme-modal-footer">
          <Button onClick={updateUser} disabled={!isValid()} loading={loading}>
            {t("action_button.update")}
          </Button>
          <Button outline onClick={close}>
            {t("action_button.cancel")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default PermissionEditModal;
