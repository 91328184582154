import React from "react";
import clsx from "clsx";
import "./styles.scss";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Input from "../Input";
import DropdownContainer from "../Dropdowns/DropdownContainer";
import DropdownOption from "models/app/DropdownOption";
import { useTranslation } from "react-i18next";

export interface InputSearchProps {
  label?: string;
  placeholder?: string;
  className?: string;
  value: string;
  customListItem?: React.FC<any>;
  selectedValue: DropdownOption | null;
  options?: DropdownOption[];

  onChange?: (event: any) => void;
  onSelect?: (option: DropdownOption) => void;
  onRemove?: () => void;
  onEnter?: () => void;
  onEnterPressed?: () => void;
}

const InputSearch: React.FC<InputSearchProps> = ({
  label = "Search",
  placeholder,
  value,
  customListItem,
  selectedValue,
  options,
  className,
  onChange = (e) => {},
  onRemove = () => {},
  onSelect = (option) => {},
  ...otherProps
}) => {
  const baseClass = "notme-input-search";

  const { t } = useTranslation();

  const rootClass = clsx(baseClass, className, {});

  const handleOptionClick = (
    closeContainer: () => void,
    option: DropdownOption
  ) => {
    closeContainer();
    onSelect(option);
  };

  const optionList = (closeContainer: () => void) => {
    return (
      <div className={`${baseClass}--option-container`}>
        <p className={`${baseClass}--option-container-text`}>
          {t("ui-kit.search")}: {value}
        </p>
        <ul className="notme-input-search--option-container-list">
          {filterOptions(value)?.map(
            (option: DropdownOption, index: number) => (
              <li
                key={option.value}
                className={`${baseClass}--option`}
                onClick={() => handleOptionClick(closeContainer, option)}
              >
                {customListItem ? customListItem(option) : option.label}
              </li>
            )
          )}
        </ul>
      </div>
    );
  };

  const filterOptions = (value: string) => {
    return options?.filter((option) =>
      option.label.toLowerCase().includes(value.toLowerCase())
    );
  };

  return (
    <>
      <DropdownContainer
        className={rootClass}
        optionContainer={(closeContainer) => optionList(closeContainer)}
        full={true}
      >
        {(isFocused, setIsFocused) => (
          <Input
            value={value}
            label={label}
            placeholder={placeholder}
            className={className}
            inputType="custom"
            customSelection={
              selectedValue && (
                <div className={`${baseClass}--selected-item`}>
                  <p className={`${baseClass}--selected-item-text`}>
                    {selectedValue?.label}
                  </p>
                  <FontAwesomeIcon
                    className={`${baseClass}--selected-item-icon`}
                    icon={faTimes}
                    onClick={() => onRemove()}
                  />
                </div>
              )
            }
            onChange={(e) => {
              onChange(e);
            }}
            onFocus={() => setIsFocused(true)}
          />
        )}
      </DropdownContainer>
    </>
  );
};

InputSearch.displayName = "InputSearch";

InputSearch.defaultProps = {
  label: "Search",
  placeholder: "Search",
  className: "",
  value: "",
  selectedValue: null,
  options: [],
  onChange: (e) => {},
  onSelect: (option) => {},
  onRemove: () => {},
  onEnter: () => {},
  onEnterPressed: () => {}
};

InputSearch.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  selectedValue: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  }),
  options: PropTypes.array,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  onRemove: PropTypes.func,
  onEnter: PropTypes.func,
  onEnterPressed: PropTypes.func
};

export default InputSearch;
