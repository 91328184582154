import React, { useEffect, useState, useContext } from "react";
import "./styles.scss";
import ThemeContext from "contexts/theme/ThemeContext";

import { useTranslation } from "react-i18next";

import { ReportInfo } from "models/ReportInfo";
import QuestionStep from "models/QuestionStep";
import ReportQuestionnaireStep from "models/app/ReportQuestionnaireStep";
import {
  faUser,
  faBullhorn,
  faMap,
  faCalendarAlt,
  faUsers,
  faCheckCircle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Card from "components/ui-kit/Card";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ReportStepIcon from "components/ReportStepIcon";
import { useModal } from "react-hooks-use-modal";
import ReportStepModal from "components/ReportStepModal";
import { notificationError } from "utils/custom_notifications";
import ReportInternalStatus from "models/app/ReportInternalStatus";

const ReportQuestionnaire: React.FC<{
  report: ReportInfo;
  steps: QuestionStep[];
  status?: ReportInternalStatus | null;
}> = ({ report, steps, status }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { dark } = useContext(ThemeContext);

  const [Modal, open, close, isOpen] = useModal("root", {
    closeOnOverlayClick: true
  });

  const [selectedQuestionnaireStep, setSelectedQuestionnaireStep] =
    useState<ReportQuestionnaireStep>();

  const [reportSteps, setReportSteps] = useState<ReportQuestionnaireStep[]>([]);
  const [completedSteps, setCompletedSteps] = useState(0);

  const setupReportQuestionnaireStep = () => {
    let reportQuestionnaireStep: ReportQuestionnaireStep[] = [];
    if (steps) {
      let nbCompletedSteps = 0;
      steps.forEach((element, index) => {
        const step_answers = report.report_steps.find((found) => {
          return (
            found.step.name.toLowerCase() === element.name.toLowerCase() || null
          );
        });
        if (step_answers) {
          nbCompletedSteps += 1;
        }

        switch (element.name.toLowerCase()) {
          case "reporter":
            reportQuestionnaireStep.push({
              id: 0,
              title: t("report_page.lets_start_here"),
              icon: faUser,
              color: "var(--light_blue)",
              question_step: element,
              report_step: step_answers
            });
            break;
          case "what":
            reportQuestionnaireStep.push({
              id: 1,
              title: t("report_page.what"),
              icon: faBullhorn,
              color: "var(--blue)",
              question_step: element,
              report_step: step_answers
            });
            break;
          case "where":
            reportQuestionnaireStep.push({
              id: 2,
              title: t("report_page.where"),
              icon: faMap,
              color: "var(--green)",
              question_step: element,
              report_step: step_answers
            });
            break;
          case "when":
            reportQuestionnaireStep.push({
              id: 3,
              title: t("report_page.when"),
              icon: faCalendarAlt,
              color: "var(--dark_blue)",
              question_step: element,
              report_step: step_answers
            });
            break;
          case "who":
            reportQuestionnaireStep.push({
              id: 4,
              title: t("report_page.who"),
              icon: faUsers,
              color: "var(--red)",
              question_step: element,
              report_step: step_answers
            });
            break;
          default:
            break;
        }
        reportQuestionnaireStep.sort((a, b) => a.id - b.id);
      });
      setReportSteps(reportQuestionnaireStep);
      setCompletedSteps(nbCompletedSteps);
    }
  };

  useEffect(() => {
    setupReportQuestionnaireStep();
  }, []);

  const openReportStep = (questionnaireStep: ReportQuestionnaireStep) => {
    if (questionnaireStep.question_step.questions.length === 0) {
      notificationError("This step doesn't contain any question.", dark);
    } else {
      navigate(`step/${questionnaireStep.question_step.id}`);
    }
  };

  const openReportModal = (questionnaireStep: ReportQuestionnaireStep) => {
    setSelectedQuestionnaireStep(questionnaireStep);
    open();
  };

  const openReport = (questionnaireStep: ReportQuestionnaireStep) => {
    if (questionnaireStep.report_step?.completed) {
      openReportModal(questionnaireStep);
    } else {
      if (
        !(
          status === ReportInternalStatus.OUTDATED_QUESTIONNAIRE ||
          status === ReportInternalStatus.INVALID_COMPANY
        )
      ) {
        openReportStep(questionnaireStep);
      }
    }
  };

  const ReportQuestionnaireList = () => {
    return (
      <>
        <div className="report-questionnaire-list">
          {reportSteps.length > 0 ? (
            reportSteps.map((item) => (
              <Card key={item.id} onClick={() => openReport(item)}>
                <div className="report-questionnaire-row">
                  <div className="report-questionnaire-row-content">
                    <ReportStepIcon
                      icon={item.icon}
                      color={item.color}
                      isCompleted={
                        item.report_step ? item.report_step?.completed : null
                      }
                    />
                    <p className="report-questionnaire-row-text">
                      {item.title}
                    </p>
                  </div>
                  {item.report_step && item.report_step.completed ? (
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      color={"var(--green)"}
                    />
                  ) : null}
                </div>
              </Card>
            ))
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </>
    );
  };

  return (
    <div className="report-questionnaire">
      <div className="report-questionnaire-title">
        <h5 className="header-section-title">{t("report_page.report")}</h5>
        <h5 className="header-section-title">{completedSteps}/5</h5>
      </div>
      <ReportQuestionnaireList />

      <ReportStepModal
        questionnaireStep={selectedQuestionnaireStep}
        Modal={Modal}
        open={open}
        close={close}
        isOpen={isOpen}
      />
    </div>
  );
};

export default ReportQuestionnaire;
