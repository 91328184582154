import React from "react";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft
} from "@fortawesome/free-solid-svg-icons";
import "./styles.scss";

type CarouselArrowProps = {
  color?: string;
  disabled?: boolean;
  direction: "right" | "left";
  onClick: () => void;
};

const CarouselArrow: React.FC<CarouselArrowProps> = ({
  color = "blue",
  disabled = false,
  direction,
  onClick
}) => {
  const icon = direction === "right" ? faChevronRight : faChevronLeft;

  const baseClassName = `carousel-arrow carousel-arrow-${direction}`;
  const colorClassName = color ? `carousel-arrow-${color}` : "";

  return (
    <div
      className={clsx(
        baseClassName,
        colorClassName,
        disabled && "carousel-arrow-disabled"
      )}
      onClick={onClick}
    >
      <FontAwesomeIcon data-testid="carousel-arrow" icon={icon} />
    </div>
  );
};

export default CarouselArrow;
