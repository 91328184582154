import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";

import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import file_utils from "utils/file_utils";
import { NodeViewWrapper } from "@tiptap/react";
import Button from "components/ui-kit/Buttons/Button";
import NewsfeedFile from "models/NewsfeedFile";
import ActionModal from "components/ui-kit/Modals/ActionModal";
import { useModal } from "react-hooks-use-modal";

interface PostDocumentProps {
  deleteNode: () => void;
  node?: any;
  files?: NewsfeedFile[];
  isEditable: boolean;
}

interface FileTypeProps {
  icon: typeof faFile;
  color: string;
}

const PostDocument: React.FC<PostDocumentProps> = ({
  deleteNode,
  node,
  files,
  isEditable = false
}) => {
  const { t } = useTranslation();
  const [url, setUrl] = useState("");
  const [title, setTitle] = useState("");
  const [fileType, setFileType] = useState<FileTypeProps>({
    icon: faFile,
    color: "#000"
  });

  const [
    DeletePostDocumentModal,
    openDeletePostDocumentModal,
    closeDeletePostDocumentModal
  ] = useModal("root", {
    closeOnOverlayClick: true
  });

  const hiddenLinkRef = useRef<HTMLAnchorElement | null>(null);

  useEffect(() => {
    if (node?.content?.content?.[0]) {
      const id = parseInt(node.content.content[0].text);

      let fileFound = files?.find((element) => element.id === id);
      if (fileFound) {
        setUrl(fileFound.media_url);
        setTitle(fileFound.title);

        const fileExt = fileFound?.media_url.split("?")[0].split(".").pop();
        const newFileType = {
          icon: file_utils.getUrlTypeIcon(fileExt),
          color: file_utils.getUrlTypeColor(fileExt)
        };

        setFileType(newFileType);
      }
    }
  }, [node, files]);

  const handleButtonClick = () => {
    hiddenLinkRef.current?.click();
  };

  return (
    <NodeViewWrapper
      className="post-document"
      contentEditable="false"
      suppressContentEditableWarning={true}
    >
      <div
        className="post-document__icon"
        style={{ backgroundColor: fileType.color }}
      >
        <div className="post-document__icon-image">
          <FontAwesomeIcon icon={fileType.icon} />
        </div>
      </div>
      <div className="post-document__title">{title}</div>
      <div className="post-document__button">
        {!isEditable ? (
          <Button outline onClick={handleButtonClick}>
            {t("post_page.view_document")}
          </Button>
        ) : (
          <Button context="red" onClick={openDeletePostDocumentModal}>
            {t("action_button.delete")}
          </Button>
        )}
      </div>
      <a
        href={url}
        target="_blank"
        ref={hiddenLinkRef}
        style={{ display: "none" }}
      />
      <ActionModal
        Modal={DeletePostDocumentModal}
        close={closeDeletePostDocumentModal}
        title={t("post_page.delete_post_document")}
        description={t("post_page.delete_post_document_confirmation")}
      >
        <Button context="red" onClick={deleteNode}>
          {t("action_button.delete")}
        </Button>
      </ActionModal>
    </NodeViewWrapper>
  );
};

export default PostDocument;
