import React, { Fragment } from "react";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight
} from "@fortawesome/free-solid-svg-icons";
import Button from "components/ui-kit/Buttons/Button";
import "./styles.scss";
import { useTranslation } from "react-i18next";

export interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (newPage: number) => void;
  className?: string;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
  className
}) => {
  const { t } = useTranslation();
  const baseClass = "notme-pagination";
  const rootClass = clsx(baseClass, className);

  const getDisplayRange = (current: number, total: number) => {
    const radius = 2; // The number of pages to show on either side of the current page
    let start = Math.max(current - radius, 1); // The first page number to display
    let end = Math.min(current + radius, total); // The last page number to display

    // If the current page is close to the start of the range, adjust the end of the range
    if (current - start < radius) {
      end = Math.min(end + (radius - (current - start)), total);
    }

    // If the current page is close to the end of the range, adjust the start of the range
    if (end - current < radius) {
      start = Math.max(start - (radius - (end - current)), 1);
    }

    // Return an array of page numbers to display
    return Array.from({ length: end - start + 1 }, (_, i) => i + start);
  };

  if (totalPages === 1) {
    return null; // No pagination needed for a single page
  }

  const pageRange = getDisplayRange(currentPage, totalPages);

  return (
    <div className={rootClass}>
      <Button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        plain
        data-testid="pagination-left-arrow"
        className={`${baseClass}-arrow`}
        context="grey"
      >
        <FontAwesomeIcon icon={faChevronLeft} />
        <span>{t("feed.previous")}</span>
      </Button>
      {pageRange.map((page, index) => (
        <Fragment key={`pageRange-${page}`}>
          {index === 0 && page !== 1 && (
            <>
              <Button
                onClick={() => onPageChange(1)}
                plain={currentPage !== 1}
                context="dark_blue"
              >
                1
              </Button>
              {page !== 2 && <span>...</span>}
            </>
          )}
          <Button
            onClick={() => onPageChange(page)}
            plain={page !== currentPage}
            context="dark_blue"
          >
            {page}
          </Button>
          {index === pageRange.length - 1 && page !== totalPages && (
            <>
              {page !== totalPages - 1 && <span>...</span>}
              <Button
                onClick={() => onPageChange(totalPages)}
                plain={currentPage !== totalPages}
                context="dark_blue"
              >
                {totalPages}
              </Button>
            </>
          )}
        </Fragment>
      ))}
      <Button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        plain
        data-testid="pagination-right-arrow"
        className={`${baseClass}-arrow`}
        context="grey"
      >
        <span>{t("feed.next")}</span>
        <FontAwesomeIcon icon={faChevronRight} />
      </Button>
    </div>
  );
};

export default Pagination;
