import React, { useState, useEffect } from "react";
import "./styles.scss";
import permissions_utils from "utils/permissions_utils";

import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/auth/useAuth";
import { useModal } from "react-hooks-use-modal";
import { useNavigate, Location } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch, faTimes } from "@fortawesome/free-solid-svg-icons";
import reportApi from "api/reportApi";

import Button from "components/ui-kit/Buttons/Button";
import CompanyLogo from "components/ui-kit/CompanyLogo";

const SpeakUpButton: React.FC<{ location: Location }> = ({ location }) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const navigate = useNavigate();

  const [Modal, open, close] = useModal("root", {
    closeOnOverlayClick: true
  });

  const [loadingIntro, setLoadingIntro] = useState(false);
  const [loading, setLoading] = useState(false);

  const [reportIntro, setReportIntro] = useState<string>("");

  useEffect(() => {
    if (location.pathname === "/new-report") {
      openSpeakUpModal();
      navigate("/");
    }
  }, []);

  const getReportIntro = () => {
    setLoadingIntro(true);
    reportApi
      .getIntroReport()
      .then((response) => {
        if (response.status === 200) {
          setReportIntro(response.data);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setLoadingIntro(false);
      });
  };

  const openSpeakUpModal = () => {
    if (can("create third-party report")) {
      setReportIntro(t("main_page.company_intro"));
    } else {
      getReportIntro();
    }
    open();
  };

  const createReport = () => {
    setLoading(true);
    reportApi
      .postReport()
      .then((response) => {
        let report_id = response.data.id;
        setLoading(false);
        navigate("report/" + report_id);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const can = (permission: string) => {
    return permissions_utils.can(permission, auth);
  };

  return (
    <>
      <Button onClick={openSpeakUpModal}>
        {can("create third-party report")
          ? t("main_page.create_walk_in_report")
          : t("main_page.speak_up")}
      </Button>
      <Modal>
        <div className="notme-modal">
          <div className="notme-modal-header">
            <h1>
              {can("create third-party report")
                ? t("main_page.create_walk_in_report")
                : t("main_page.speak_up")}
            </h1>
            <span className="notme-modal-header-close" onClick={close}>
              <FontAwesomeIcon icon={faTimes} className="times-icon" />
            </span>
          </div>
          <div className="notme-modal-content">
            <div className="speak-up-modal-content">
              <CompanyLogo company={auth?.user?.company} size={60} />
              {!loadingIntro ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: reportIntro
                  }}
                  className="speak-up-modal-text-container"
                />
              ) : (
                <div className="speak-up-modal-loading">
                  <FontAwesomeIcon icon={faCircleNotch} spin size="2x" />
                </div>
              )}
            </div>
          </div>
          <div className="notme-modal-footer">
            <Button context="blue" onClick={createReport} loading={loading}>
              {t("main_page.get_started")}
            </Button>
            <Button context="blue" outline onClick={close}>
              {t("action_button.cancel")}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SpeakUpButton;
