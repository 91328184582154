import "./styles/App.scss";
import "react-toastify/dist/ReactToastify.min.css";

import AppNavigator from "./navigation/App.navigator";

function App() {
  return <AppNavigator />;
}

export default App;
