import {
  createBrowserRouter,
  RouterProvider,
  Navigate
} from "react-router-dom";
import AppProviders from "contexts/app/AppProviders";
import AppRouteObject from "models/app/AppRouteObject";
import AppLayout from "layouts/AppLayout";

import authRoutes from "./Auth.routes";
import mainRoutes from "./Main.routes";
import settingsRoutes from "./Settings.routes";

const router = createBrowserRouter([
  {
    path: "/",
    element: <AppProviders />,
    children: [
      {
        path: "*",
        element: <AppLayout />,
        children: [
          ...mainRoutes,
          ...settingsRoutes,
          {
            path: "*",
            element: <Navigate to="/index" replace />
          }
        ]
      },
      {
        path: "*",
        children: authRoutes
      },
      {
        path: "",
        element: <Navigate to="/index" replace />
      }
    ]
  }
] as AppRouteObject[]);

const AppNavigator = () => {
  return <RouterProvider router={router} />;
};

export default AppNavigator;
