import React, { useContext, useState } from "react";
import ThemeContext from "contexts/theme/ThemeContext";
import "./styles.scss";

import Tippy from "@tippyjs/react";
import { emojiMap } from "utils/reaction_utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSmile } from "@fortawesome/free-regular-svg-icons";
import { ReactionCount } from "models/app/ReactionCount";

type ReactionItemsProps = {
  initialReactions: ReactionCount[];
  addReaction: (type: any) => void;
  deleteReaction: () => void;
};

const ReactionItems: React.FC<ReactionItemsProps> = ({
  initialReactions,
  addReaction,
  deleteReaction
}) => {
  const { dark } = useContext(ThemeContext);
  const [reactions, setReactions] = useState<ReactionCount[]>(initialReactions);
  const [showReactionsPopup, setShowReactionsPopup] = useState<boolean>(false);

  const handleReaction = (selectedName: string) => {
    // Convert the reaction name to the corresponding emoji
    const selectedType = emojiMap.get(selectedName) || selectedName;

    // Check if the user is selecting their existing reaction
    const existingUserReaction = reactions.find(
      (reaction) => reaction.isUserReaction
    );

    let updatedReactions;

    if (existingUserReaction && existingUserReaction.type === selectedType) {
      // Remove the user's reaction by decrementing the count
      updatedReactions = reactions
        .map((reaction) => {
          if (reaction.type === selectedType) {
            return {
              ...reaction,
              count: reaction.count - 1,
              isUserReaction: false
            };
          }
          return reaction;
        })
        .filter((reaction) => reaction.count > 0);
    } else {
      // Handle adding a new reaction or changing to a different reaction
      updatedReactions = reactions
        .map((reaction) => {
          if (reaction.type === selectedType) {
            return {
              ...reaction,
              count: reaction.count + 1,
              isUserReaction: true
            };
          } else if (
            existingUserReaction &&
            reaction.type === existingUserReaction.type
          ) {
            // Decrement count for the previously selected reaction
            return {
              ...reaction,
              count: reaction.count - 1,
              isUserReaction: false
            };
          }
          return reaction;
        })
        .filter((reaction) => reaction.count > 0);

      if (
        !updatedReactions.some((reaction) => reaction.type === selectedType)
      ) {
        updatedReactions.push({
          type: selectedType,
          count: 1,
          isUserReaction: true
        });
      }
    }

    // Update the state with the new reactions
    setReactions(updatedReactions);

    // Close the popup
    setShowReactionsPopup(false);

    // Call the passed in addReaction or a new removeReaction function
    if (existingUserReaction && existingUserReaction.type === selectedType) {
      deleteReaction();
    } else {
      addReaction(selectedName);
    }
  };

  const renderReactions = () => {
    return reactions.map((reaction, index) => (
      <div
        key={index}
        className={`reaction__card ${
          reaction.isUserReaction ? "reaction__card--active" : ""
        }`}
        onClick={() => handleReaction(reaction.type)}
      >
        {reaction.type}{" "}
        <span className="reaction__count">{reaction.count}</span>
      </div>
    ));
  };

  const reactionOptions = (
    <div className="reaction-options">
      {Array.from(emojiMap.entries()).map(([name, emoji]) => (
        <button
          key={name}
          className="reaction-option"
          onClick={() => handleReaction(name)}
        >
          {emoji}
        </button>
      ))}
    </div>
  );

  return (
    <div className="reaction">
      {renderReactions()}
      <Tippy
        content={reactionOptions}
        visible={showReactionsPopup}
        onClickOutside={() => setShowReactionsPopup(false)}
        interactive={true}
        arrow={false}
        placement="top"
        theme={dark ? "" : "light"}
      >
        <button
          className="reaction__button reaction__card reaction__card--add"
          onClick={() => setShowReactionsPopup(true)}
        >
          <FontAwesomeIcon icon={faSmile} size="lg" />
        </button>
      </Tippy>
    </div>
  );
};

export default ReactionItems;
