import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState
} from "react";
import axios from "axios";
import { useAuth } from "./useAuth";
import { notificationError } from "utils/custom_notifications";
import { useTranslation } from "react-i18next";
import ThemeContext from "contexts/theme/ThemeContext";

type InterceptorsContextType = {
  interceptorsConfigured: boolean;
};

const InterceptorsContext = createContext<InterceptorsContextType>({
  interceptorsConfigured: false
});

type InterceptorsProviderProps = {
  children: ReactNode;
};

export const InterceptorsProvider: React.FC<InterceptorsProviderProps> = ({
  children
}) => {
  const auth = useAuth();
  const { t } = useTranslation();
  const { dark } = useContext(ThemeContext);
  const [interceptorsConfigured, setInterceptorsConfigured] =
    useState<boolean>(false);

  useEffect(() => {
    const responseInterceptorInstance = axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const isAnalytics = isAnalyticsUrl(error.config.url);
        if (error.response.status === 401 && auth?.isLoggedIn && !isAnalytics) {
          notificationError(t("user_disconnected_message"), dark);
          auth?.logout();
        }
        return Promise.reject(error);
      }
    );

    setInterceptorsConfigured(true);

    return () => {
      axios.interceptors.response.eject(responseInterceptorInstance);
    };
  }, [auth?.isLoggedIn]);

  const isAnalyticsUrl = (url: string) => {
    const parsedUrl = new URL(url);
    const splittedUrl = parsedUrl.hostname.split(".")[0];
    return splittedUrl === "analytics";
  };

  const contextValue = {
    interceptorsConfigured
  };

  return (
    <InterceptorsContext.Provider value={contextValue}>
      {children}
    </InterceptorsContext.Provider>
  );
};

export const useInterceptors = (): InterceptorsContextType => {
  return useContext(InterceptorsContext);
};
