import AppRouteObject from "models/app/AppRouteObject";
import RequireNoAuth from "contexts/auth/RequireNoAuth";
import RequireAuthPhoneVerified from "contexts/auth/RequireAuthPhoneVerified";

import Login from "pages/LoginPage";
import SignUp from "pages/SignUpPage";
import ForgotPassword from "pages/ForgotPasswordPage";
import VerifyPhone from "pages/VerifyPhonePage";
import ChallengePhone from "pages/ChallengePhonePage";

const routes: AppRouteObject[] = [
  { path: "login", element: <RequireNoAuth component={Login} /> },
  { path: "sign-up", element: <RequireNoAuth component={SignUp} /> },
  {
    path: "forgot-password",
    element: <RequireNoAuth component={ForgotPassword} />
  },
  {
    path: "verify-phone",
    element: <RequireAuthPhoneVerified component={VerifyPhone} />
  },
  {
    path: "challenge",
    element: <RequireAuthPhoneVerified component={ChallengePhone} />
  }
];

export default routes;
