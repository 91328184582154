import React, { useEffect, useState } from "react";
import "./styles.scss";

import clsx from "clsx";
import FeaturedNewsCell from "../NewsFeaturedSection";
import NewsVerticalSection from "../NewsVerticalSection";
import NewsfeedPost from "models/NewsfeedPost";

export interface NewsTopSectionProps {
  loading: boolean;
  featuredPosts: NewsfeedPost[];
  recentPosts: NewsfeedPost[];
}

const NewsTopSection: React.FC<NewsTopSectionProps> = ({
  loading,
  featuredPosts,
  recentPosts
}) => {
  const [showComponent, setShowComponent] = useState<boolean>(true);
  const [featuredContent, setFeaturedContent] = useState<NewsfeedPost[]>([]);
  const [recentContent, setRecentContent] = useState<NewsfeedPost[]>([]);

  useEffect(() => {
    // If loading, we don't adjust content
    if (loading) {
      setShowComponent(true);
      return;
    }
    // By default, use the provided posts
    let tempFeatured = featuredPosts;
    let tempRecent = [...recentPosts].slice(0, 3);

    // Handle special cases
    if (!featuredPosts.length) {
      if (recentPosts.length === 4) {
        tempFeatured = [recentPosts[0]];
        tempRecent = [...recentPosts].slice(1);
      } else {
        setShowComponent(false);
        return;
      }
    }

    setShowComponent(true);
    setFeaturedContent(tempFeatured);
    setRecentContent(tempRecent);
  }, [loading, featuredPosts, recentPosts]);

  if (!showComponent) return null;

  const isFullWidthFeatured = recentContent.length < 3 && !loading;

  return (
    <div className="notme-news-top-section">
      {/* Left featured post */}
      <div
        className={clsx(
          "notme-news-top-section__item",
          "notme-news-top-section__item--featured",
          { "full-width": isFullWidthFeatured }
        )}
      >
        <FeaturedNewsCell
          posts={featuredContent}
          loading={loading}
          usedFallback={featuredPosts.length === 0}
        />
      </div>
      {/* Right vertical side */}
      {!isFullWidthFeatured && (
        <div className="notme-news-top-section__item notme-news-top-section__item--right">
          <NewsVerticalSection
            posts={recentContent}
            loading={loading}
            showHeader={featuredPosts.length > 0}
          />
        </div>
      )}
    </div>
  );
};

export default NewsTopSection;
