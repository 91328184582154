import { initializeApp } from "firebase/app";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import {
  getFirestore,
  collection,
  addDoc,
  onSnapshot,
  query,
  limit,
  orderBy,
  QueryDocumentSnapshot,
  DocumentData,
  where,
  startAfter
} from "firebase/firestore";
import Message from "models/app/Message";

const env_firebase_credentials = process.env.REACT_APP_FIREBASE_CREDENTIALS;
let config = {};

if (env_firebase_credentials) {
  const firebase_credentials = JSON.parse(env_firebase_credentials);

  config = {
    apiKey: firebase_credentials.api_key,
    authDomain: `${firebase_credentials.project_id}.firebaseapp.com`,
    databaseURL: `https://${firebase_credentials.project_id}.firebaseio.com`,
    projectId: firebase_credentials.project_id,
    storageBucket: `${firebase_credentials.project_id}.appspot.com`,
    messagingSenderId: firebase_credentials.sender_id
  };
}

const app = initializeApp(config);
const db = getFirestore(app);

async function authFirebase(token: string) {
  try {
    const auth = getAuth(app);
    return signInWithCustomToken(auth, token);
  } catch (err) {
    return null;
  }
}
function getMessages(
  report_id: number,
  collection_id: string,
  callback: (messages: QueryDocumentSnapshot<DocumentData>[]) => void
) {
  let _query = query(
    collection(db, collection_id),
    where("report_id", "==", report_id),
    orderBy("created_at", "desc"),
    limit(25)
  );
  return onSnapshot(_query, (querySnapshot) => {
    const messages = querySnapshot.docs;
    callback(messages);
  });
}

function getMessagesPagination(
  report_id: number,
  collection_id: string,
  start: QueryDocumentSnapshot<DocumentData>,
  callback: (messages: QueryDocumentSnapshot<DocumentData>[]) => void
) {
  let _query = query(
    collection(db, collection_id),
    where("report_id", "==", report_id),
    orderBy("created_at", "desc"),
    startAfter(start),
    limit(25)
  );
  return onSnapshot(_query, (querySnapshot) => {
    const messages = querySnapshot.docs;
    callback(messages);
  });
}

async function sendMessage(collection_id: string, message: Message) {
  try {
    const messageToSend = {
      content: message.content,
      subscriber: message.subscriber,
      created_at: message.created_at,
      report_id: message.report_id
    };
    await addDoc(collection(db, collection_id), messageToSend);
  } catch (err) {}
}

export { authFirebase, getMessages, getMessagesPagination, sendMessage };
