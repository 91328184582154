import React, { useState, useEffect, useContext } from "react";
import "./styles.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { geocodeByPlaceId } from "react-google-places-autocomplete";
import Button from "components/ui-kit/Buttons/Button";

import data_img from "assets/img/data.png";
import userApi from "api/userApi";
import {
  notificationError,
  notificationSuccess
} from "utils/custom_notifications";
import ThemeContext from "contexts/theme/ThemeContext";
import helpers from "api/helpers/helpers";

const DataModal: React.FC<{
  Modal: React.FC<{
    children: React.ReactNode;
  }>;
  close: () => void;
  email?: string;
}> = ({ Modal, close, email }) => {
  const { t } = useTranslation();
  const { dark } = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);

  const requestDownload = () => {
    setLoading(true);
    userApi
      .getUserData()
      .then((response) => {
        setLoading(false);
        if (response.status === 200) {
          notificationSuccess(t("data.request_sent"), dark, {
            autoClose: 5000
          });
          close();
        }
      })
      .catch((err) => {
        notificationError(helpers.formatErrorMessage(err), dark);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal>
      <div className={clsx("notme-modal", "data-modal")}>
        <div className="notme-modal-header">
          <h1>{t("data.get_copy")}</h1>
          <span className="notme-modal-header-close" onClick={close}>
            <FontAwesomeIcon icon={faTimes} className="times-icon" />
          </span>
        </div>
        <div className="notme-modal-body data-modal-body">
          <div className="notme-modal-body-content-column">
            <h6>{t("data.email_link_to_download")}</h6>
            <p>{t("data.sent_to", { email: email })}</p>
          </div>
          <img
            alt={"Data"}
            className="data-modal-image"
            width={220}
            src={data_img}
          />
        </div>
        <div className="notme-modal-footer">
          <Button onClick={requestDownload} loading={loading}>
            {t("data.request_download")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DataModal;
