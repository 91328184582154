import Status from "models/app/Status";

const report_status: Status[] = [
  {
    id: 0,
    title: "report_status.created",
    color: "light_blue"
  },
  {
    id: 1,
    title: "report_status.in_progress",
    color: "blue"
  },
  {
    id: 2,
    title: "report_status.saved",
    color: "green"
  },
  {
    id: 3,
    title: "report_status.submitted",
    color: "dark_blue"
  },
  {
    id: 4,
    title: "report_status.in_review",
    color: "dark_red"
  },
  {
    id: 5,
    title: "report_status.under_review",
    color: "red"
  },
  {
    id: 6,
    title: "report_status.resolved",
    color: "dark_green"
  }
];

const report_steps: Status[] = [
  {
    title: "report_page.lets_start_here",
    id: 4,
    color: "light_blue"
  },
  {
    title: "report_page.what",
    id: 5,
    color: "blue"
  },
  {
    title: "report_page.where",
    id: 1,
    color: "green"
  },
  {
    title: "report_page.when",
    id: 2,
    color: "dark_blue"
  },
  {
    title: "report_page.who",
    id: 3,
    color: "red"
  }
];

export const QUESTION_TYPES = {
  TAGS: 0,
  UNIQUE_TAG: 1,
  DATES: 2,
  PLACES: 3,
  TEXT: 4
};

function getStatusColor(id: number): string {
  const element = report_status?.find((element) => element.id === id);
  if (element) {
    return element.color;
  } else {
    return "blue";
  }
}

function getStatusTitle(id: number): string {
  const element = report_status?.find((element) => element.id === id);
  if (element) {
    return element.title;
  } else {
    return "no_status";
  }
}

function getReportStep(id: number): Status | null {
  const element = report_steps.find((step) => step.id === id);
  if (element) {
    return element;
  } else {
    return null;
  }
}

const report_utils = {
  getStatusColor,
  getStatusTitle,
  getReportStep
};

export default report_utils;
