import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";

import { useTranslation } from "react-i18next";
import NewsCell from "../NewsCell";
import CarouselArrow from "components/CarouselArrow";
import { NavLink } from "react-router-dom";
import Loader from "components/ui-kit/Loader";
import NewsfeedPost from "models/NewsfeedPost";

interface FeaturedNewsCellProps {
  posts: NewsfeedPost[];
  loading?: boolean;
  usedFallback?: boolean;
}

const FeaturedNewsCell: React.FC<FeaturedNewsCellProps> = ({
  posts,
  loading,
  usedFallback
}) => {
  const { t } = useTranslation();
  const scrollRef = useRef<HTMLDivElement>(null);
  const [canScrollLeft, setCanScrollLeft] = useState<boolean>(false);
  const [canScrollRight, setCanScrollRight] = useState<boolean>(false);

  useEffect(() => {
    if (scrollRef.current) {
      setCanScrollRight(
        scrollRef.current.scrollWidth > scrollRef.current.clientWidth
      );
    }
  }, [posts]);

  const handleScroll = () => {
    if (scrollRef.current) {
      setCanScrollLeft(scrollRef.current.scrollLeft > 0);
      setCanScrollRight(
        scrollRef.current.scrollWidth - scrollRef.current.scrollLeft - 10 >
          scrollRef.current.clientWidth
      );
    }
  };

  const handleScrollClick = (direction: string) => {
    if (scrollRef.current) {
      const containerWidth = scrollRef.current.clientWidth;
      const newScrollPosition =
        direction === "right"
          ? Math.min(
              scrollRef.current.scrollLeft + containerWidth,
              scrollRef.current.scrollWidth
            )
          : Math.max(scrollRef.current.scrollLeft - containerWidth, 0);

      scrollRef.current.scrollTo({
        left: newScrollPosition,
        behavior: "smooth"
      });
    }
  };

  const loadingPosts = new Array(5).fill({});

  return (
    <div className="notme-news-section-featured">
      <div className="header-section">
        <h5 className="header-section-title">
          {loading ? (
            <Loader style={{ height: "17px", width: "120px" }} />
          ) : (
            t(!usedFallback ? "feed.featured" : "feed.latest_posts")
          )}
        </h5>
        {posts.length > 1 && (
          <NavLink
            to="/feed/posts/list?featured=true"
            className="header-section-action"
          >
            {t("feed.show_more")}
          </NavLink>
        )}
      </div>
      <div
        className="notme-news-section-featured__container"
        ref={scrollRef}
        onScroll={handleScroll}
      >
        {(loading ? loadingPosts : posts).map((post, index) => (
          <NewsCell
            key={loading ? `loading-${index}` : post.id}
            layout="featured"
            post={post}
            loading={loading}
          />
        ))}
      </div>
      {!loading && posts.length > 1 && (
        <div className="notme-news-section-featured__buttons">
          <CarouselArrow
            direction="left"
            disabled={!canScrollLeft}
            onClick={() => handleScrollClick("left")}
          />
          <CarouselArrow
            direction="right"
            disabled={!canScrollRight}
            onClick={() => handleScrollClick("right")}
          />
        </div>
      )}
    </div>
  );
};

export default FeaturedNewsCell;
