import React, { MouseEventHandler } from "react";
import "./styles.scss";

import Company from "models/Company";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export interface EntityLogoCollapsedProps {
  entity?: Company;
  nb_entities: number;
  clearEntitiesSelected: MouseEventHandler<HTMLDivElement>;
}

const EntityLogoCollapsed: React.FC<EntityLogoCollapsedProps> = ({
  entity,
  nb_entities,
  clearEntitiesSelected
}) => {
  const baseClass = "entity-logo-collapsed";

  return (
    <div
      className={baseClass}
      style={nb_entities > 0 ? { width: "40px" } : { width: "40px" }}
    >
      <img
        alt={entity?.name}
        className={`${baseClass}-img`}
        height={nb_entities > 0 ? 36 : 40}
        width={nb_entities > 0 ? 36 : 40}
        src={entity?.logo}
      />
      {nb_entities > 0 ? (
        <>
          <span className={`${baseClass}-subentities`}>+{nb_entities}</span>
          <span
            className={`${baseClass}-clear`}
            onClick={clearEntitiesSelected}
          >
            <FontAwesomeIcon icon={faTimes} />
          </span>
        </>
      ) : null}
    </div>
  );
};

EntityLogoCollapsed.displayName = "EntityLogo";

export default EntityLogoCollapsed;
