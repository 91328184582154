import React from "react";
import "./styles.scss";
import app_store from "assets/img/app_store.png";
import play_store from "assets/img/play_store.png";

import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
  faAlignCenter,
  faAt,
  faQuestion,
  faUserShield
} from "@fortawesome/free-solid-svg-icons";

import SocialLinks from "components/SocialLinks";

const FooterLink: React.FC<{
  title: string;
  icon: IconDefinition;
  url: string;
}> = ({ title, icon, url }) => (
  <div className="footer-link" onClick={() => openLink(url)}>
    <div className="footer-link-icon">
      <FontAwesomeIcon icon={icon} />
    </div>
    <p className="footer-link-text">{title}</p>
  </div>
);

const openLink = (url: string) => {
  window.open(url, "_blank", "noopener,noreferrer");
};

const Footer: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="footer">
      <div className="row">
        <div className="col-md-3">
          <FooterLink
            title={t("authentication_pages.terms_of_use")}
            icon={faAlignCenter}
            url={"https://not-me.com/terms-of-use"}
          />
          <FooterLink
            title={t("authentication_pages.privacy_policy")}
            icon={faUserShield}
            url={"https://not-me.com/privacy-policy"}
          />
        </div>
        <div className="col-md-3">
          <FooterLink
            title={t("profile_page.help_center")}
            icon={faQuestion}
            url={"https://help.not-me.com/"}
          />
          <FooterLink
            title={t("profile_page.contact_us")}
            icon={faAt}
            url={"https://not-me.com/contact"}
          />
        </div>
        <div className="col-md-3">
          <p>{t("profile_page.download_the_app")}</p>
          <div className="store-icons">
            <a
              href="https://apps.apple.com/app/apple-store/id1434123324?pt=119243071&ct=Website&mt=8"
              target="_blank"
            >
              <img
                className="store-icon"
                alt={"App store"}
                height={30}
                src={app_store}
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.notme.notme&utm_source=website&utm_medium=referral&utm_campaign=download"
              target="_blank"
            >
              <img
                className="store-icon"
                alt={"Play store"}
                height={30}
                src={play_store}
              />
            </a>
          </div>
        </div>
        <div className="col-md-3">
          <p>{t("profile_page.follow_us")}</p>
          <SocialLinks />
        </div>
      </div>
    </div>
  );
};

export default Footer;
