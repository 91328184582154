import { useCallback, useEffect, useRef, useState, useContext } from "react";
import "./styles.scss";
import QuestionStep from "models/QuestionStep";
import StepComponentProps from "models/app/StepComponentProps";
import Answer from "models/Answer";
import ReportAnswers from "models/ReportAnswers";

import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ThemeContext from "contexts/theme/ThemeContext";
import { notificationError } from "utils/custom_notifications";
import helpers from "api/helpers/helpers";
import reportApi from "api/reportApi";

import Button from "components/ui-kit/Buttons/Button";
import HeaderTitle from "components/HeaderTitle";
import ReportStepTags from "./components/ReportStepTags";
import ReportStepDates from "./components/ReportStepDates";
import ReportStepPlaces from "./components/ReportStepPlaces";
import ReportStepSentence from "./components/ReportStepSentence";
import ReportStepOther from "./components/ReportStepOther";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import report_utils from "utils/report_utils";
import CenterLayout from "layouts/CenterLayout";
import Status from "models/app/Status";

const ReportStepPage = () => {
  const { reportId, stepId } = useParams();
  const navigate = useNavigate();
  const { dark } = useContext(ThemeContext);
  const { t } = useTranslation();

  const children = useRef<StepComponentProps>(null);
  const childrenOther = useRef<StepComponentProps>(null);

  const [step, setStep] = useState<QuestionStep | null>(null);
  const [reportStepInfo, setReportStepInfo] = useState<Status | null>(null);

  const [loading, setLoading] = useState(false);

  const [reportAnswers, setReportAnswers] = useState<ReportAnswers[]>([]);
  const [userAnswers, setUserAnswers] = useState<Answer[][]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [stepLength, setStepLength] = useState(0);

  const [preventUserLeave, setPreventUserLeave] = useState(true);

  const getStep = useCallback(async (step_id: number) => {
    const steps = await reportApi.getSteps(reportId);
    const current_step: QuestionStep = steps.data.find(
      (element: QuestionStep) => element.id === step_id
    );
    setStep(current_step);
    setStepLength(current_step.questions.length);
  }, []);

  //On component mounted
  useEffect(() => {
    if (stepId) {
      const current_step_id = +stepId;
      setReportStepInfo(report_utils.getReportStep(current_step_id));
      getStep(current_step_id).catch((err) => {
        navigate(-1);
        notificationError(helpers.formatErrorMessage(err), dark);
      });
    } else {
      navigate(-1);
      notificationError("No step id", dark);
    }
  }, []);

  //On reportAnswers updated
  useEffect(() => {
    if (reportAnswers.length > 0) {
      next();
    }
  }, [reportAnswers]);

  const prev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };
  const next = () => {
    if (currentIndex < step!.questions.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else {
      setLoading(true);
      setPreventUserLeave(false);
      let answers = reportAnswers.filter((element) => element != null);
      reportApi
        .postReportStep(reportId, step?.id, answers)
        .then((response) => {
          if (response.status === 200) {
            navigate(-1);
          }
        })
        .catch((err) => {
          notificationError(helpers.formatErrorMessage(err), dark);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const fillAnswer = () => {
    let element = {};

    if (check() === true) {
      element = { ...element, ...getFormattedData() };
    }

    if (checkOther() === true) {
      element = { ...element, ...getFormattedOther() };
    }

    if (Object.entries(element).length === 0) {
      notificationError(t("report_page.forgot_answer_question"), dark);
    } else {
      const answer = {
        id: step!.questions[currentIndex].id,
        answers: element
      };

      const currentAnswers = [...reportAnswers];
      currentAnswers[currentIndex] = answer;
      setReportAnswers(currentAnswers);

      const user_answer = getData();
      if (user_answer) {
        const currentUserAnswers = [...userAnswers];
        currentUserAnswers[currentIndex] = user_answer;
        setUserAnswers(currentUserAnswers);
      }
    }
  };

  const check = () => {
    if (children) {
      return children.current?.check();
    }
  };

  const getFormattedData = () => {
    if (children) {
      return children.current?.getFormattedData();
    }
  };

  const getData = () => {
    if (children && children.current) {
      if (children.current.getData) {
        return children.current.getData();
      }
    }
  };

  const checkOther = () => {
    if (childrenOther) {
      return childrenOther.current?.check();
    }
  };

  const getFormattedOther = () => {
    if (childrenOther) {
      return childrenOther.current?.getFormattedData();
    }
  };

  const generateStepContent = () => {
    switch (step!.questions[currentIndex].type) {
      case 0:
        return (
          <ReportStepTags
            color={reportStepInfo?.color}
            key={`${currentIndex}-tags`}
            ref={children}
            answers={step!.questions[currentIndex].answers}
            categories={step!.questions[currentIndex].categories}
            user_answers={userAnswers[currentIndex] || []}
          />
        );
      case 1:
        return (
          <ReportStepTags
            color={reportStepInfo?.color}
            key={`${currentIndex}-unique-tags`}
            is_unique={true}
            ref={children}
            answers={step!.questions[currentIndex].answers}
            categories={step!.questions[currentIndex].categories}
            user_answers={userAnswers[currentIndex] || []}
          />
        );
      case 2:
        return <ReportStepDates ref={children} key={`${currentIndex}-dates`} />;
      case 3:
        return (
          <ReportStepPlaces ref={children} key={`${currentIndex}-places`} />
        );
      case 4:
        let user_answer = "";
        if (reportAnswers[currentIndex]) {
          user_answer =
            reportAnswers[currentIndex]?.answers?.new?.length > 0
              ? reportAnswers[currentIndex]?.answers?.new[0]
              : "";
        }
        return (
          <ReportStepSentence
            ref={children}
            user_answer={user_answer}
            key={`${currentIndex}-sentence`}
          />
        );
      default:
        return null;
    }
  };

  const generateOther = () => {
    if (step!.questions[currentIndex].allow_other) {
      let user_answer = "";
      if (reportAnswers[currentIndex]) {
        user_answer =
          reportAnswers[currentIndex]?.answers?.other?.length > 0
            ? reportAnswers[currentIndex]?.answers?.other[0]
            : "";
      }
      return (
        <ReportStepOther
          color=""
          user_answer={user_answer}
          ref={childrenOther}
          key={`${currentIndex}-other`}
        />
      );
    }
  };

  if (step) {
    return (
      <CenterLayout>
        <main style={{ padding: "1rem 0" }} className="report-step-page">
          <div className="report-step-header">
            <HeaderTitle
              title={reportStepInfo ? t(reportStepInfo.title) : step.name}
              canGoBack={true}
              color={`var(--${reportStepInfo?.color})`}
            />
          </div>
          <div className="report-step-body">
            <p>{step.questions[currentIndex].question}</p>
            {generateStepContent()}
            {generateOther()}
          </div>
          <div className="report-step-bar-container">
            <div className="report-step-bar">
              {currentIndex > 0 ? (
                <Button
                  context="grey"
                  onClick={prev}
                  className="report-step-button"
                >
                  {t("action_button.back")}
                </Button>
              ) : null}
              <div className="report-step-progress">
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: `${((currentIndex + 1) / stepLength) * 100}%`,
                      backgroundColor: `var(--${reportStepInfo?.color})`
                    }}
                    aria-valuenow={20}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
              </div>
              {step?.questions[currentIndex].allow_skip ? (
                <Button
                  context="dark_blue"
                  outline
                  onClick={next}
                  className="report-step-button"
                  loading={loading}
                >
                  {t("action_button.skip")}
                </Button>
              ) : null}
              <Button
                onClick={fillAnswer}
                className="report-step-button"
                loading={loading}
                context={reportStepInfo?.color}
              >
                {currentIndex < step!.questions.length - 1
                  ? t("action_button.next")
                  : t("action_button.submit")}
              </Button>
            </div>
          </div>
        </main>
      </CenterLayout>
    );
  } else {
    return (
      <CenterLayout>
        <main className="report-step-loading">
          <FontAwesomeIcon icon={faCircleNotch} spin size="2x" />
        </main>
      </CenterLayout>
    );
  }
};

export default ReportStepPage;
