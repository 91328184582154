import React from "react";
import "./styles.scss";

import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import UserSimple from "models/UserSimple";
import { faPencil, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "contexts/auth/useAuth";
import role_utils from "utils/role_utils";
import permissions_utils from "utils/permissions_utils";

import Tag from "components/ui-kit/Tag";
import CompanyLogo from "components/ui-kit/CompanyLogo";
import Avatar from "components/ui-kit/Avatar";
import Card from "components/ui-kit/Card";
import IconCircleButton from "components/IconCircleButton";

export interface MemberCellProps {
  member: UserSimple;
  showEditMemberModal: (member: UserSimple) => void;
  showDeleteMemberModal: (member: UserSimple) => void;
}

const MemberCell: React.FC<MemberCellProps> = ({
  member,
  showEditMemberModal,
  showDeleteMemberModal
}) => {
  const auth = useAuth();
  const { t } = useTranslation();

  const userName = `${member.firstname} ${member.lastname}`;

  const isMemberEditable = () => {
    return can("update company subscribers") && member.id !== auth?.user?.id;
  };

  const isMemberDeletable = () => {
    // Member deletion is only allowed for Super Admin role
    return can("SUPER - remove company subscribers");
  };

  const editMember = () => {
    showEditMemberModal(member);
  };

  const deleteMember = () => {
    showDeleteMemberModal(member);
  };

  const can = (permission: string) => {
    return permissions_utils.can(permission, auth);
  };

  return (
    <Card className="permission-member-cell">
      <div className="permission-member-cell-info-left">
        <Avatar
          className="permission-member-cell-avatar"
          name={userName}
          id={member.id}
        />
        <div className="permission-member-cell-name-role-container">
          <div className="permission-member-cell-name">
            <p className="permission-member-cell-name-text">{userName}</p>
            <p className="permission-member-cell-title-text">
              {member.job_title}
            </p>
          </div>
          <div className="permission-member-cell-role-container">
            <Tag
              context={role_utils.getRoleColor(member.role)}
              round
              small
              subtle
            >
              {t("role." + member.role)}
            </Tag>
            <div className="permission-member-cell-date-text-small">
              <p className="permission-member-cell-date-text">
                {dayjs(member.company_added_at).format("ll")}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="permission-member-cell-date-container">
        <p className="permission-member-cell-date-text">
          {dayjs(member.company_added_at).format("LL")}
        </p>
      </div>
      <div className="permission-member-cell-button-container">
        <CompanyLogo company={member.company} />
        {isMemberEditable() && (
          <IconCircleButton
            icon={faPencil}
            outline={false}
            onClick={editMember}
          />
        )}
        {isMemberDeletable() && (
          <IconCircleButton
            icon={faTimes}
            color="var(--red)"
            onClick={deleteMember}
          />
        )}
      </div>
    </Card>
  );
};

export default MemberCell;
