import React from "react";
import { useTranslation } from "react-i18next";
import ActionModal from "components/ui-kit/Modals/ActionModal";
import Button from "components/ui-kit/Buttons/Button";

export enum PostActionModalType {
  DRAFT = "draft",
  PUBLISH = "publish",
  CANCEL = "cancel"
}

const PostActionModal: React.FC<{
  Modal: React.FC<{
    children: React.ReactNode;
  }>;
  type: PostActionModalType;
  actionLoading: boolean;
  actionButton: () => void;
  close: () => void;
}> = ({ Modal, type, actionButton, actionLoading, close }) => {
  const { t } = useTranslation();

  const actionModalTitle = () => {
    switch (type) {
      case PostActionModalType.DRAFT:
        return t("post_page.save_as_draft_title");
      case PostActionModalType.PUBLISH:
        return t("post_page.publish_title");
      case PostActionModalType.CANCEL:
        return t("post_page.delete_post");
    }
  };

  const actionModalDescription = () => {
    switch (type) {
      case PostActionModalType.DRAFT:
        return t("post_page.save_as_draft_description");
      case PostActionModalType.PUBLISH:
        return t("post_page.publish_description");
      case PostActionModalType.CANCEL:
        return t("post_page.cancel_post_description");
    }
  };

  const actionModalButton = () => {
    switch (type) {
      case PostActionModalType.DRAFT:
        return t("post_page.save_as_draft");
      case PostActionModalType.PUBLISH:
        return t("post_page.publish");
      case PostActionModalType.CANCEL:
        return t("action_button.delete");
    }
  };

  return (
    <ActionModal
      Modal={Modal}
      close={close}
      title={actionModalTitle()}
      description={actionModalDescription()}
    >
      <Button
        context={type === PostActionModalType.CANCEL ? "red" : "blue"}
        onClick={actionButton}
        loading={actionLoading}
      >
        {actionModalButton()}
      </Button>
    </ActionModal>
  );
};

export default PostActionModal;
