import React, { useEffect, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import "./styles.scss";
import report_utils from "utils/report_utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardList, faCircle } from "@fortawesome/free-solid-svg-icons";

export interface ReportStatusIconProps {
  status_id: number;

  otherProps?: Object;
}

const ReportStatusIcon: React.FC<ReportStatusIconProps> = ({
  status_id,
  ...otherProps
}) => {
  const baseClass = "report-status-icon";

  const [color, setColor] = useState("");

  useEffect(() => {
    setColor(report_utils.getStatusColor(status_id));
  }, []);

  const rootClass = clsx(baseClass);

  return (
    <span
      className={rootClass}
      style={{ position: "relative" }}
      {...otherProps}
    >
      <FontAwesomeIcon icon={faClipboardList} className="report-status-icon" />
      <FontAwesomeIcon
        icon={faCircle}
        className="report-status-icon-bullet"
        style={{ color: `var(--${color})` }}
      />
    </span>
  );
};

ReportStatusIcon.displayName = "ReportStatusIcon";

ReportStatusIcon.propTypes = {
  status_id: PropTypes.number.isRequired
};

export default ReportStatusIcon;
