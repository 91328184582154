import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

import "./styles.scss";

const Circular: React.FC<{
  percentage: number;
  size: number;
  color: string;
}> = ({ percentage, size, color }) => {
  const strokeWidthRatio = size >= 100 ? 0.1 : 0.2;
  const strokeWidth = size * strokeWidthRatio;
  const radius = size / 2 - strokeWidth / 2;
  const circumference = 2 * Math.PI * radius;
  const strokeDasharray = `${circumference}px`;
  const strokeDashoffset = `${((100 - percentage) / 100) * circumference}px`;

  const strokeColor: string = color;

  return (
    <path
      d={`
        M ${size / 2} ${size / 2}
        m 0 -${radius}
        a ${radius} ${radius} 0 1 1 0 ${2 * radius}
        a ${radius} ${radius} 0 1 1 0 -${2 * radius}
      `}
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      fill="none"
      strokeDasharray={strokeDasharray}
      strokeDashoffset={strokeDashoffset}
      strokeLinecap="round"
      style={{
        transition: "stroke-dasharray 0.6s ease 0s, stroke 0.6s ease 0s"
      }}
    />
  );
};

export interface ProgressCircularProps {
  percentage: number;
  size?: number;
  className?: string;
}

const ProgressCircular: React.FC<ProgressCircularProps> = ({
  percentage,
  size = 40,
  className
}) => {
  const baseClass = "notme-progress-circle";
  const rootClass = clsx(baseClass, className);

  let color: string;

  if (percentage < 80) {
    color = "light_blue";
  } else if (percentage < 100) {
    color = "yellow";
  } else {
    color = "red";
  }
  return (
    <div
      role="progressbar"
      aria-valuenow={percentage}
      aria-valuemin={0}
      aria-valuemax={100}
      className={rootClass}
    >
      <svg width={size} height={size}>
        <Circular percentage={100} size={size} color="var(--border)" />
        <Circular
          size={size}
          percentage={percentage}
          color={`var(--${color})`}
        />
      </svg>
    </div>
  );
};

ProgressCircular.displayName = "ProgressCircular";

ProgressCircular.defaultProps = {
  percentage: 0,
  size: 40,
  className: ""
};

ProgressCircular.propTypes = {
  percentage: PropTypes.number.isRequired,
  size: PropTypes.number,
  className: PropTypes.string
};

export default ProgressCircular;
