import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

import "./styles.scss";

export interface CardProps {
  className?: string;
  otherProps?: Object;

  children: React.ReactNode;
  onClick?: () => void;
}

const Card: React.FC<CardProps> = ({
  className,
  children,
  onClick,
  ...otherProps
}) => {
  const baseClass = "notme-card";

  const rootClass = clsx(baseClass, className);

  return (
    <div className={rootClass} onClick={onClick} {...otherProps}>
      {children}
    </div>
  );
};

Card.displayName = "Card";

Card.defaultProps = {
  className: "",
  onClick: () => {}
};

Card.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func
};

export default Card;
