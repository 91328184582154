import React from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./styles.scss";

interface EmptyDataProps {
  title: string;
  description?: string;
  icon: IconProp;
}

const EmptyData: React.FC<EmptyDataProps> = ({ title, description, icon }) => {
  return (
    <div className="empty-data">
      <FontAwesomeIcon icon={icon} className="empty-data__icon" />
      <div>
        <h6 className="empty-data__title">{title}</h6>
        {description && <p className="empty-data__subtitle">{description}</p>}
      </div>
    </div>
  );
};

export default EmptyData;
