import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

import "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";

export interface LoadingProps {
  context?: string;
  full?: boolean;
  size?: SizeProp;
  className?: string;
}

const Loading: React.FC<LoadingProps> = ({
  context,
  full = false,
  size = "1x",
  className
}) => {
  const baseClass = "notme-loading";

  const rootClass = clsx(
    baseClass,
    context && `${baseClass}-${context}`,
    full && `${baseClass}-full`,
    className
  );

  return (
    <div className={rootClass}>
      <FontAwesomeIcon icon={faCircleNotch} spin size={size} />
    </div>
  );
};

Loading.displayName = "Loading";

Loading.defaultProps = {
  context: "subtitle",
  full: false,
  size: "1x",
  className: ""
};

Loading.propTypes = {
  context: PropTypes.string,
  full: PropTypes.bool,
  className: PropTypes.string
};

export default Loading;
