import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./styles.scss";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import InformationBannerType from "models/app/InformationBannerType";

interface InformationBannerProps {
  icon: IconDefinition;
  title: string;
  type?: InformationBannerType;
  style?: React.CSSProperties;
}

const InformationBanner: React.FC<InformationBannerProps> = ({
  icon,
  title,
  type = InformationBannerType.information,
  style
}) => {
  const bannerClass = `information-banner--${type}`;

  return (
    <div className={`information-banner ${bannerClass}`} style={style}>
      <FontAwesomeIcon icon={icon} className="information-banner__icon" />
      <span className="information-banner__text">{title}</span>
    </div>
  );
};

export default React.memo(InformationBanner);
