import React from "react";
import NewsCell from "../NewsCell";
import "./styles.scss";

import { useTranslation } from "react-i18next";
import Loader from "components/ui-kit/Loader";
import NewsfeedPost from "models/NewsfeedPost";

export interface NewsVerticalSectionProps {
  posts: NewsfeedPost[];
  loading?: boolean;
  showHeader?: boolean;
}

/**
 * NewsVerticalSection component
 * This component renders a vertical list of NewsCell components.
 * Each NewsCell is wrapped in a container with a background.
 */
const NewsVerticalSection: React.FC<NewsVerticalSectionProps> = ({
  posts = [],
  loading,
  showHeader = true
}) => {
  const { t } = useTranslation();

  // Skeleton loading array
  const loadingPosts = new Array(3).fill({});

  return (
    <div className="notme-news-section-vertical">
      <h5 className="header-section-title">
        {showHeader && !loading && t("feed.latest_posts")}
        {loading && <Loader style={{ height: "17px", width: "120px" }} />}
      </h5>
      {/* If the section is loading, map over the skeleton array, else map over the posts */}
      {(loading ? loadingPosts : posts).map((post, index) => (
        <div
          key={loading ? `loading-${index}` : post.id}
          className="notme-news-section-vertical__container"
        >
          <NewsCell layout="vertical" post={post} loading={loading} />
        </div>
      ))}
    </div>
  );
};

export default NewsVerticalSection;
