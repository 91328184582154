import { FC } from "react";
import { useAuth } from "./useAuth";
import { Navigate } from "react-router-dom";

interface PropType {
  component: React.FC;
}

const RequireAuthPhoneVerified: FC<PropType> = ({ component: Component }) => {
  const auth = useAuth();

  if (auth?.isLoggedIn && !auth?.isPhoneVerified) return <Component />;
  return <Navigate to="/login" />;
};

export default RequireAuthPhoneVerified;
