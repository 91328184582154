import React, { useMemo } from "react";
import "./styles.scss";

import { faWarning, faTools } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import ReportInternalStatus from "models/app/ReportInternalStatus";
import InformationBanner from "components/InformationBanner";
import InformationBannerType from "models/app/InformationBannerType";

const ReportBanner: React.FC<{ status?: ReportInternalStatus | null }> = ({
  status
}) => {
  const { t } = useTranslation();

  const statusToIconTextMap = useMemo(
    () => ({
      [ReportInternalStatus.OUTDATED_QUESTIONNAIRE]: {
        icon: faWarning,
        text: t("report_page.outdated_questionnaire")
      },
      [ReportInternalStatus.INVALID_COMPANY]: {
        icon: faWarning,
        text: t("report_page.invalid_company")
      },
      [ReportInternalStatus.CHAT_MAINTENANCE]: {
        icon: faTools,
        text: "report_page.chat_maintenance_description"
      }
    }),
    [t]
  );

  if (!status) {
    return null;
  }

  const { icon, text } =
    statusToIconTextMap[status as keyof typeof statusToIconTextMap] || {};

  return (
    <InformationBanner
      icon={icon}
      title={text}
      type={InformationBannerType.alert}
    />
  );
};

export default ReportBanner;
