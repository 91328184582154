import React from "react";
import "./styles.scss";

import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import InformationPopover from "components/ui-kit/InformationPopover";
import { Trans, useTranslation } from "react-i18next";
import { useAuth } from "contexts/auth/useAuth";
import permissions_utils from "utils/permissions_utils";

export interface HeaderTitleProps {
  title: string;
  description?: string;
  descriptionLink?: string;
  permissionDescriptionLink?: string;
  color?: string;
  canGoBack?: boolean;
  customBackNavigation?: () => void;
}

const HeaderTitle: React.FC<HeaderTitleProps> = ({
  title,
  description = null,
  descriptionLink = null,
  permissionDescriptionLink,
  color,
  canGoBack = false,
  customBackNavigation
}) => {
  const auth = useAuth();
  const baseClass = "header-title";

  const navigate = useNavigate();
  const { t } = useTranslation();

  const goBack = () => {
    if (customBackNavigation) {
      customBackNavigation();
    } else {
      navigate(-1);
    }
  };

  const can = (permission: string) => {
    return permissions_utils.can(permission, auth);
  };

  const canShowDescriptionLink = () => {
    return (
      descriptionLink &&
      (!permissionDescriptionLink ||
        (permissionDescriptionLink && can(permissionDescriptionLink)))
    );
  };

  return (
    <div className={baseClass}>
      {canGoBack ? (
        <div className={`${baseClass}-back-button`} onClick={goBack}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>
      ) : null}
      <div className={`${baseClass}-info-title-container`}>
        <h1 style={{ color: color }}>{title}</h1>
        {(description || canShowDescriptionLink()) && (
          <InformationPopover
            text={
              <>
                <p>{description}</p>
                {canShowDescriptionLink() && (
                  <p>
                    <Trans
                      i18nKey="pages.more_info_link"
                      values={{ description_link: t("pages.help_center") }}
                      components={[
                        <a href={descriptionLink!} target="_blank"></a>
                      ]}
                    />
                  </p>
                )}
              </>
            }
            className={`${baseClass}-info-container`}
          >
            <div style={{ width: "16px", height: "20px" }}>
              <FontAwesomeIcon
                icon={faInfoCircle}
                className={`${baseClass}-info`}
              />
            </div>
          </InformationPopover>
        )}
      </div>
    </div>
  );
};

HeaderTitle.defaultProps = {
  canGoBack: false
};

export default HeaderTitle;
