import React, { useEffect, useState } from "react";
import CompanyInfo from "models/CompanyInfo";
import { useTranslation } from "react-i18next";
import EntityLogo from "components/EntityLogo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faWarning } from "@fortawesome/free-solid-svg-icons";
import InformationBanner from "components/InformationBanner";
import InformationBannerType from "models/app/InformationBannerType";
import Input, { OptionType } from "components/ui-kit/Input";

interface ValidateOrganizationStepProps {
  company: CompanyInfo;
  close: () => void;
  addOrganizationButtonDisabled: boolean;
  setSelectedLanguage: (language: string) => void;
  selectedLanguage: string;
  setSelectedLanguageKey: (language: string) => void;
}

const ValidateOrganizationStep: React.FC<ValidateOrganizationStepProps> = ({
  company,
  close,
  addOrganizationButtonDisabled,
  setSelectedLanguage,
  selectedLanguage,
  setSelectedLanguageKey
}) => {
  const { t, i18n } = useTranslation();
  const [availableLanguages, setAvailableLanguages] = useState<OptionType[]>(
    []
  );
  const updateLanguage = (e: any) => {
    const language_selected =
      availableLanguages[e.target.options.selectedIndex];
    setSelectedLanguage(language_selected.value);
    setSelectedLanguageKey(language_selected.key);
  };

  useEffect(() => {
    const getFilteredLanguageList = () => {
      const languages = Object.entries(i18n.services.resourceStore.data)
        .filter(([key]) => company.locales.includes(key)) // Filter based on company.locales
        .map(([key, { meta }]: any) => ({
          key: key,
          value: meta.title,
          title: meta.title
        }));

      setSelectedLanguage(languages[0]?.value);
      setSelectedLanguageKey(languages[0]?.key);
      setAvailableLanguages(languages);
    };

    if (company && company.locales) {
      getFilteredLanguageList();
    }
  }, [company, i18n]);

  return (
    <>
      <div className="notme-modal-header">
        <h1>{t("organization_modal.validate_organization")}</h1>
        <span className="notme-modal-header-close" onClick={close}>
          <FontAwesomeIcon icon={faTimes} className="times-icon" />
        </span>
      </div>
      <div className="notme-modal__validate-info">
        <EntityLogo company={company} />
        <h6 className="notme-modal__validate-title">
          {t("organization_modal.join_x_company", {
            company: company.name
          })}
        </h6>

        <p className="notme-modal__validate-description">
          {t("organization_modal.validate_description", {
            company: company.name
          })}
        </p>
      </div>
      {addOrganizationButtonDisabled && (
        <>
          <InformationBanner
            icon={faWarning}
            title={t("organization_modal.language_not_supported")}
            type={InformationBannerType.alert}
          />
          <Input
            inputType="select"
            value={selectedLanguage}
            options={availableLanguages}
            onChange={updateLanguage}
          />
        </>
      )}
    </>
  );
};

export default ValidateOrganizationStep;
