import React from "react";
import "./styles.scss";
import clsx from "clsx";

type LoaderProps = {
  style?: React.CSSProperties;
  className?: string;
};

const Loader: React.FC<LoaderProps> = ({ style, className }) => (
  <div className={clsx("notme-loader", className ?? className)} style={style} />
);

export default Loader;
