import React, { useContext } from "react";
import ThemeContext from "contexts/theme/ThemeContext";

import Tippy from "@tippyjs/react";

import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import "./styles.scss";
import clsx from "clsx";

export interface InformationPopoverProps {
  text: React.ReactNode;
  disabled?: boolean;
  className?: string;
  children: any;
  otherProps?: Object;
}

const InformationPopover: React.FC<InformationPopoverProps> = ({
  text,
  disabled = false,
  className,
  children,
  ...otherProps
}) => {
  const { dark } = useContext(ThemeContext);

  const baseClass = "information-popover";

  const rootClass = clsx(baseClass, className);

  return (
    <Tippy
      content={text}
      className={rootClass}
      disabled={disabled}
      placement="bottom"
      theme={dark ? "" : "light"}
      interactive={true}
      {...otherProps}
    >
      {children}
    </Tippy>
  );
};

InformationPopover.displayName = "InformationPopover";

InformationPopover.defaultProps = {
  text: ""
};

export default InformationPopover;
