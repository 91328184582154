import React from "react";
import "./styles.scss";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEntity } from "contexts/entity/useEntity";
import dayjs from "dayjs";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import organizationUtils from "utils/organization_utils";

import CompanyLogo from "components/ui-kit/CompanyLogo";
import Tag from "components/ui-kit/Tag";
import SubCompany from "models/SubCompany";
import IconCircleButton from "components/IconCircleButton";

interface EntityCellProps {
  company: SubCompany;
}

const EntityCell: React.FC<EntityCellProps> = ({ company }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const entity = useEntity();

  const selectEntity = () => {
    entity?.addToEntitiesSelected(company);
    navigate("/settings/entitlements");
  };

  return (
    <div className="entity-cell">
      <div className="entity-cell-logo-name-container">
        <CompanyLogo size={42} company={company} />
        <div>
          <p className="entity-cell-name">{company.name}</p>
          <p className="entity-cell-industry">{company.industry}</p>
        </div>
      </div>

      <div className="entity-cell-status-button-container">
        <p className="entity-cell-date">
          {dayjs(company.created_at).format("ll")}
        </p>
        <Tag
          round
          subtle
          context={organizationUtils.getOrganizationStatusColor(
            company.status_id
          )}
        >
          {t(organizationUtils.getOrganizationStatusTitle(company.status_id))}
        </Tag>
        <IconCircleButton icon={faPencil} onClick={selectEntity} />
      </div>
    </div>
  );
};

export default EntityCell;
