import React from "react";
import Tag from "components/ui-kit/Tag";
import "./styles.scss";

export interface SectionTabProps {
  count?: number;
  label: string;
  active: boolean;
  setActive: () => void;
}

const SectionTab: React.FC<SectionTabProps> = ({
  count,
  label,
  active,
  setActive
}) => {
  return (
    <div className="section-tab" onClick={setActive}>
      <Tag
        round
        subtle
        context={active ? "light_blue" : "card"}
        className="section-tab__tag"
      >
        {count !== null && (
          <Tag
            round
            subtle={!active}
            solid={active}
            context={active ? "light_blue" : "grey"}
          >
            {String(count)}
          </Tag>
        )}
        {label}
      </Tag>
    </div>
  );
};

export default SectionTab;
