import React, { SyntheticEvent, useRef, useState } from "react";
import clsx from "clsx";

import "./styles.scss";

export interface TextAreaProps {
  /** Indicate what the user should write */
  label?: string;
  placeholder?: string;
  value: string;
  maxLength?: number;
  rows?: number;
  resizable?: boolean;
  className?: string;

  style?: Object;
  otherProps?: Object;

  onChange?: (event: any) => void;
  onKeyDown?: (event: any) => void;
}

const TextArea: React.FC<TextAreaProps> = ({
  label,
  placeholder,
  value,
  maxLength,
  rows = 4,
  resizable = true,
  className = "",
  style = {},
  onChange = (e) => {},
  onKeyDown = (e) => {},
  ...otherProps
}) => {
  const baseClass = "notme-text-area";
  const rootClass = clsx(baseClass, className, {});

  const baseTextAreaClass = "notme-text-area--textarea";
  const rootTextAreaClass = clsx(
    baseTextAreaClass,
    !resizable ? `${baseTextAreaClass}-not-resizable` : null
  );

  return (
    <div className={rootClass}>
      <label className="notme-text-area--label">{label}</label>
      <textarea
        className={rootTextAreaClass}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        rows={rows}
        style={style}
        maxLength={maxLength}
        {...otherProps}
      />
      {maxLength ? (
        <p
          className={clsx(
            "notme-text-area--counter",
            value.length === maxLength && "notme-text-area--counter-limit",
            value.length < 1 && "notme-text-area--counter-limit-invisible"
          )}
        >
          {value.length}/{maxLength}
        </p>
      ) : null}
    </div>
  );
};

export default TextArea;
