import React, { useContext, useState } from "react";
import "./styles.scss";

import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/auth/useAuth";
import { notificationError } from "utils/custom_notifications";
import ThemeContext from "contexts/theme/ThemeContext";
import flashApi from "api/flashApi";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import helpers from "api/helpers/helpers";
import { Editor } from "@tiptap/react";
import NewsfeedFile from "models/NewsfeedFile";
import Input from "components/ui-kit/Input";
import Button from "components/ui-kit/Buttons/Button";
import DocumentDropzone from "components/DocumentDropzone";

const DocumentUploaderModal: React.FC<{
  Modal: React.FC<{
    children: React.ReactNode;
  }>;
  editor: Editor;
  postId: number;
  isImage: boolean;
  onFileAdded: (file: NewsfeedFile) => void;
  close: () => void;
}> = ({ Modal, editor, postId, isImage, onFileAdded, close }) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const { dark } = useContext(ThemeContext);

  const [documentToUpload, setDocumentToUpload] = useState<File | null>(null);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [showUploadProgress, setShowUploadProgress] = useState<boolean>(false);
  const [title, setTitle] = useState("");

  const uploadDocument = () => {
    setShowUploadProgress(true);
    flashApi
      .uploadFileToPost(
        postId,
        documentToUpload,
        title || documentToUpload?.name,
        auth?.user?.id,
        (progress: ProgressEvent) => {
          setUploadProgress(
            Math.floor((progress.loaded * 100) / progress.total)
          );
        }
      )
      .then((response) => {
        onFileAdded(response.data);
        if (isImage) {
          editor.commands.insertContent([
            {
              type: "image",
              attrs: { src: response.data.id, alt: null, title: null }
            },
            {
              type: "paragraph"
            }
          ]);
          editor.commands.focus();
        } else {
          editor.commands.insertContent([
            {
              type: "document",
              content: [
                {
                  type: "text",
                  text: `${response.data.id}`
                }
              ]
            },
            {
              type: "paragraph"
            }
          ]);
          editor.commands.focus();
        }
        closeModal();
      })
      .catch((err) => {
        notificationError(helpers.formatErrorMessage(err), dark);
      })
      .finally(() => {
        setShowUploadProgress(false);
      });
  };

  const closeModal = () => {
    if (!showUploadProgress) {
      setTitle("");
      setDocumentToUpload(null);
      setUploadProgress(0);
      setShowUploadProgress(false);
      close();
    }
  };

  const handleFileImport = (file: File | null) => {
    setDocumentToUpload(file);
  };

  return (
    <Modal>
      <div className="notme-modal document-uploader-modal">
        <div className="notme-modal-header">
          <h1>
            {isImage
              ? t("post_page.upload_image")
              : t("post_page.upload_document")}
          </h1>
          <span className="notme-modal-header-close" onClick={closeModal}>
            <FontAwesomeIcon icon={faTimes} className="times-icon" />
          </span>
        </div>
        <div className="notme-modal-body">
          <div className="document-uploader-modal-body">
            {!isImage && (
              <Input
                type="text"
                label={t("post_page.title")}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            )}
            <DocumentDropzone
              onFileAdded={handleFileImport}
              maxFileSize={20}
              isImage={isImage}
            />
          </div>
        </div>
        {showUploadProgress && (
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{
                width: `${uploadProgress}%`,
                backgroundColor: "var(--blue)"
              }}
              aria-valuenow={uploadProgress}
              aria-valuemin={0}
              aria-valuemax={100}
            ></div>
          </div>
        )}
        <div className="notme-modal-footer">
          <Button outline onClick={closeModal} disabled={showUploadProgress}>
            {t("action_button.cancel")}
          </Button>
          <Button
            onClick={uploadDocument}
            disabled={
              !documentToUpload || (!isImage && !title) || showUploadProgress
            }
          >
            {t("action_button.upload")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DocumentUploaderModal;
