import React, { useEffect, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import "./styles.scss";

import Dropdown from "../Dropdown";
import Button from "components/ui-kit/Buttons/Button";
import DropdownOption from "models/app/DropdownOption";
import DropdownSortOption from "models/app/DropdownSortOption";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSortAmountDown,
  faSortAmountUp
} from "@fortawesome/free-solid-svg-icons";

export interface DropdownSortProps {
  sortOptions: DropdownOption[];
  onSelect: (option: DropdownSortOption) => void;
  initialSortBy: DropdownOption;
  initialOrderDesc?: boolean;
}

const DropdownSort: React.FC<DropdownSortProps> = ({
  sortOptions,
  onSelect,
  initialSortBy,
  initialOrderDesc
}) => {
  const baseClass = "notme-dropdown-sort";

  const [currentSortBy, setCurrentSortBy] = useState<DropdownOption>(
    sortOptions[0]
  );
  const [currentIsOrderDesc, setCurrentIsOrderDesc] = useState<boolean>(true);

  const rootClass = clsx(baseClass);

  const handleOnSelect = (obj?: any) => {
    onSelect({
      sort_by: obj.sort_by,
      order_by: obj.order_by ? "desc" : "asc"
    });
  };

  const handleSortChange = (option: DropdownOption) => {
    setCurrentSortBy(option);
    handleOnSelect({ sort_by: option.value, order_by: currentIsOrderDesc });
  };

  const handleOrderChange = () => {
    setCurrentIsOrderDesc((prevState) => !prevState);
    handleOnSelect({
      sort_by: currentSortBy.value,
      order_by: !currentIsOrderDesc
    });
  };

  useEffect(() => {
    setCurrentSortBy(initialSortBy);
  }, [initialSortBy]);

  useEffect(() => {
    if (initialOrderDesc !== undefined) {
      setCurrentIsOrderDesc(initialOrderDesc);
    }
  }, [initialOrderDesc]);

  return (
    <div className={rootClass}>
      <Dropdown
        title={currentSortBy.label}
        options={sortOptions}
        onSelect={handleSortChange}
      />
      <Button className={`${rootClass}--button`} onClick={handleOrderChange}>
        <FontAwesomeIcon
          icon={currentIsOrderDesc ? faSortAmountDown : faSortAmountUp}
        />
      </Button>
    </div>
  );
};

DropdownSort.displayName = "DropdownSort";

DropdownSort.defaultProps = {
  sortOptions: [] as DropdownOption[],
  onSelect: () => {}
};

DropdownSort.propTypes = {
  sortOptions: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired
};

export default DropdownSort;
