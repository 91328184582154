import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";

import ButtonArrow from "components/ui-kit/Buttons/ButtonArrow";
import DropdownOption from "models/app/DropdownOption";
import DropdownContainer from "../DropdownContainer";

export interface DropdownProps {
  title?: string;
  options: DropdownOption[];
  alignment?: "left" | "right";
  full?: boolean;
  dropdownContainerClassName?: string;

  onSelect: (option: DropdownOption) => void;
}

const Dropdown: React.FC<DropdownProps> = ({
  title,
  options,
  alignment = "right",
  full,
  dropdownContainerClassName,
  onSelect
}) => {
  const baseClass = "notme-dropdown";

  const handleOptionClick = (
    closeContainer: () => void,
    option: DropdownOption
  ) => {
    closeContainer();
    onSelect(option);
  };

  const optionList = (closeContainer: () => void) => {
    return options?.map((option: DropdownOption, index: number) => (
      <li
        key={option.value}
        className={`${baseClass}--option`}
        onClick={() => handleOptionClick(closeContainer, option)}
      >
        {option.label}
      </li>
    ));
  };

  return (
    <DropdownContainer
      alignment={alignment}
      full={full}
      optionContainer={(closeContainer) => optionList(closeContainer)}
      dropdownContainerClassName={dropdownContainerClassName}
    >
      {(isOpen, setIsOpen) => (
        <ButtonArrow
          title={title}
          onClick={() => setIsOpen(!isOpen)}
          isOpen={isOpen}
        />
      )}
    </DropdownContainer>
  );
};

Dropdown.displayName = "Dropdown";

Dropdown.defaultProps = {
  title: "Title",
  options: [],
  alignment: "right",
  full: false,
  onSelect: () => {}
};

Dropdown.propTypes = {
  title: PropTypes.string,
  options: PropTypes.array.isRequired,
  alignment: PropTypes.oneOf(["left", "right"]),
  full: PropTypes.bool,
  onSelect: PropTypes.func as PropTypes.Validator<
    (option: DropdownOption) => void
  >
};

export default Dropdown;
