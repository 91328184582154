import { toast, ToastOptions } from "react-toastify";

const toast_config = {
  position: toast.POSITION.TOP_CENTER,
  pauseOnFocusLoss: false,
  pauseOnHover: false,
  hideProgressBar: true,
  draggable: false,
  autoClose: 2500,
  closeButton: false
};

export function notificationError(
  content: string,
  isDark = false,
  customConfig?: ToastOptions<{}> | undefined
) {
  return toast.error(content, {
    theme: isDark ? "dark" : "light",
    ...toast_config,
    ...customConfig
  });
}

export function notificationSuccess(
  content: string,
  isDark = false,
  customConfig?: ToastOptions<{}> | undefined
) {
  return toast.success(content, {
    theme: isDark ? "dark" : "light",
    ...toast_config,
    ...customConfig
  });
}

export function notificationWarning(
  content: string,
  isDark = false,
  customConfig?: ToastOptions<{}> | undefined
) {
  return toast.warning(content, {
    theme: isDark ? "dark" : "light",
    ...toast_config,
    ...customConfig
  });
}

export function notificationInfo(
  content: string,
  isDark = false,
  customConfig?: {}
) {
  return toast.info(content, {
    theme: isDark ? "dark" : "light",
    ...toast_config,
    ...customConfig
  });
}
