import Status from "models/app/Status";
import i18next from "i18next";
import logo_dark from "../assets/img/logo_dark.png";

const organization_status: Status[] = [
  {
    id: 0,
    title: "organization_status.pending",
    color: "green"
  },
  {
    id: 1,
    title: "organization_status.active",
    color: "blue"
  },
  {
    id: 2,
    title: "organization_status.suspended",
    color: "grey"
  },
  {
    id: 3,
    title: "organization_status.ended",
    color: "dark_red"
  }
];

const getOrganizationStatus = (id: number) => {
  return organization_status.find((status) => status.id === id);
};

const getOrganizationStatusTitle = (id: number) => {
  const element = organization_status?.find((status) => status.id === id);
  if (element) {
    return element.title;
  } else {
    return "no_status";
  }
};

const getOrganizationStatusColor = (id: number) => {
  const element = organization_status?.find((status) => status.id === id);
  if (element) {
    return element.color;
  } else {
    return "dark_grey";
  }
};

const getOpenPlatformEntity = () => {
  return {
    id: 0,
    name: i18next.t("open_platform"),
    logo: logo_dark
  };
};

const organizationUtils = {
  getOrganizationStatus,
  getOrganizationStatusTitle,
  getOrganizationStatusColor,
  getOpenPlatformEntity
};

export default organizationUtils;
