import { faFile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { memo, useEffect, useState } from "react";
import file_utils from "utils/file_utils";
import "./styles.scss";

interface ReportEvidenceIconProps {
  url?: string;
  file?: File;
}

const ReportEvidenceIcon: React.FC<ReportEvidenceIconProps> = ({
  url,
  file
}) => {
  const [isImage, setIsImage] = useState(false);
  const [fileTypeIcon, setFileTypeIcon] = useState(faFile);

  const getUrlTypeIcon = (url: string) => {
    const fileExt = url.split("?")[0].split(".").pop();
    const isImage = file_utils.isImage(fileExt);
    setIsImage(isImage);
    setFileTypeIcon(file_utils.getUrlTypeIcon(fileExt));
  };

  const getFileTypeIcon = (file: File) => {
    const fileType = file.type;
    setIsImage(false);
    setFileTypeIcon(file_utils.getFileTypeIcon(fileType));
  };

  useEffect(() => {
    if (url) {
      getUrlTypeIcon(url);
    } else if (file) {
      getFileTypeIcon(file);
    }
  }, []);

  return (
    <div className="report-evidence-icon">
      {isImage ? (
        <img
          alt={"Evidence"}
          className={"report-evidence-image"}
          height={42}
          width={42}
          src={url}
        />
      ) : (
        <div className="report-evidence-image-icon">
          <FontAwesomeIcon icon={fileTypeIcon} />
        </div>
      )}
    </div>
  );
};

export default ReportEvidenceIcon;
