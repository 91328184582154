import "./styles.scss";

import { ReportInfo } from "models/ReportInfo";
import ReportInternalStatus from "models/app/ReportInternalStatus";
import QuestionStep from "models/QuestionStep";

import ReportHeader from "components/ReportHeader";
import ReportQuestionnaire from "components/ReportQuestionnaire";
import ReportEvidence from "components/ReportEvidence";
import ReportBanner from "components/ReportBanner";

const ReportSubmittedSidebar: React.FC<{
  report: ReportInfo;
  currentStatus: ReportInternalStatus | null;
  steps: QuestionStep[];
}> = ({ report, currentStatus, steps }) => (
  <div className="report-submitted-sidebar">
    <ReportBanner status={currentStatus} />
    <ReportHeader report={report} />
    <ReportQuestionnaire report={report} steps={steps} status={currentStatus} />
    <ReportEvidence report={report} status={currentStatus} />
  </div>
);

export default ReportSubmittedSidebar;
