import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

import "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import Button from "components/ui-kit/Buttons/Button";

export interface ButtonArrowProps {
  title?: string;
  isOpen?: boolean;
  className?: string;
  onClick?: () => void;
}

const ButtonArrow: React.FC<ButtonArrowProps> = ({
  title,
  isOpen,
  className,
  onClick,
  ...otherProps
}) => {
  const baseClass = "notme-button-arrow";

  const rootClass = clsx(baseClass, className);

  return (
    <Button
      className={rootClass}
      onClick={onClick}
      {...otherProps}
      context="card"
    >
      {title}
      <FontAwesomeIcon
        icon={faChevronDown}
        className={
          isOpen ? "notme-button-arrow-icon opened" : "notme-button-arrow-icon"
        }
      />
    </Button>
  );
};

ButtonArrow.displayName = "ButtonArrow";

ButtonArrow.defaultProps = {
  title: "Title",
  isOpen: false,
  className: "",
  onClick: () => {}
};

ButtonArrow.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func
};

export default ButtonArrow;
