import React, { Dispatch, SetStateAction, useState } from "react";
import "./styles.scss";
import { EntityType } from "models/app/EntityType";

import EntityListCell from "../EntityListCell";

export interface EntityListProps {
  entities: EntityType[];
  entitiesSelected?: EntityType[] | undefined;
  updateEntitySelected: (entity: EntityType, shouldAdd: boolean) => void;
}

const EntityList: React.FC<EntityListProps> = ({
  entities,
  entitiesSelected,
  updateEntitySelected
}) => {
  const isEntitySelected = (entity: EntityType | null) => {
    return (
      entitiesSelected?.findIndex(
        (subentity) => subentity.id === entity?.id
      ) !== -1
    );
  };

  return (
    <ul className="entity-list">
      {entities.map((subentity, index) => (
        <EntityListCell
          key={"entity-" + subentity?.id}
          entity={subentity}
          selected={isEntitySelected(subentity)}
          indentation={subentity?.parent_id ? 1 : 0}
          onChange={(e) => updateEntitySelected(subentity!, e.target.checked)}
        />
      ))}
    </ul>
  );
};

export default EntityList;
