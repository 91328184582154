import Auth from "contexts/auth/useAuth";

const can = (permission: string = "", auth: Auth | null) => {
  return auth?.can(permission) ?? false;
};

const hasCompany = (auth: Auth | null) => {
  return auth?.user?.company !== null && auth?.user?.company?.id;
};

const permissions_utils = {
  can,
  hasCompany
};

export default permissions_utils;
