import React from "react";
import "./styles.scss";

import PropTypes from "prop-types";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import Tag from "components/ui-kit/Tag";
import IconCircleButton from "components/IconCircleButton";

export interface TagIconCircleButtonProps {
  title: string;
  color?: string;
  icon?: IconDefinition;
  isEditable?: boolean;
  onClick?: () => void;

  className?: string;
}

const TagIconCircleButton: React.FC<TagIconCircleButtonProps> = ({
  title,
  color = "dark_blue",
  icon,
  isEditable = true,
  onClick
}) => {
  const baseClass = "tag-icon-circle-button";

  return (
    <div className={`${baseClass}-container`}>
      <Tag context={color} round subtle>
        {title}
      </Tag>
      {isEditable && icon && <IconCircleButton icon={icon} onClick={onClick} />}
    </div>
  );
};

TagIconCircleButton.displayName = "TagIconCircleButton";

TagIconCircleButton.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.any,
  color: PropTypes.string,
  onClick: PropTypes.func
};

export default TagIconCircleButton;
