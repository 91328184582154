import React from "react";

import "./styles.scss";

export interface SettingSectionProps {
  title: string;
  description?: string;
  children: React.ReactNode;
}

const SettingSection: React.FC<SettingSectionProps> = ({
  title = "",
  description,
  children
}) => {
  return (
    <div className="setting-section">
      <div className="setting-section-title-container">
        <p className="setting-section-title">{title}</p>
        {description ? (
          <p className="setting-section-description">{description}</p>
        ) : null}
      </div>
      <div className="setting-section-children">{children}</div>
    </div>
  );
};

export default SettingSection;
