import {
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useState
} from "react";
import "./styles.scss";
import StepComponentProps from "models/app/StepComponentProps";

import { useTranslation } from "react-i18next";

import TextArea from "components/ui-kit/TextArea";

export interface ReportStepOtherProps {
  color?: string;
  user_answer: string;
}

const ReportStepOther = forwardRef(
  (props: ReportStepOtherProps, ref: Ref<StepComponentProps>) => {
    const { color } = props;
    useImperativeHandle(ref, () => ({ check, setupData, getFormattedData }));
    const { t } = useTranslation();

    const [value, setValue] = useState<string>("");

    useEffect(() => {
      setupData();
    }, [props.user_answer]);

    const setupData = () => {
      if (props.user_answer) {
        setValue(props.user_answer);
      }
    };
    const check = () => {
      return value.trim() !== "";
    };
    const getFormattedData = () => {
      return { other: value.trimStart() !== "" ? [value.trimStart()] : [] };
    };

    return (
      <div className="report-step-other">
        <TextArea
          label={t("report_page.other")}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          maxLength={255}
        />
      </div>
    );
  }
);

export default ReportStepOther;
