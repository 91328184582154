import React from "react";
import "./styles.scss";

import { useEntity } from "contexts/entity/useEntity";
import { EntityType } from "models/app/EntityType";
import { EntityOption } from "models/app/EntityOption";
import { useTranslation } from "react-i18next";

import InputSearch from "components/ui-kit/InputSearch";
import DropdownOption from "models/app/DropdownOption";

const EntityItem: React.FC<{ option: EntityOption }> = ({ option }) => {
  return (
    <div className="search-entity-item">
      <img
        className="search-entity-item-img"
        height={32}
        width={32}
        src={option.logo}
      />
      <p className="search-entity-item-title">{option.name}</p>
    </div>
  );
};

interface InputSearchEntitiesProps {
  label?: string;
  value: string;
  selectedValue: EntityOption | null;

  onChange?: (event: any) => void;
  onSelect?: (option: EntityOption) => void;
  onRemove?: () => void;
}

const InputSearchEntities: React.FC<InputSearchEntitiesProps> = ({
  label,
  value,
  selectedValue,
  onChange,
  onSelect,
  onRemove
}) => {
  const { t } = useTranslation();
  const entity = useEntity();

  const convertEntityTypeToEntityOptions = (
    entityOptions: EntityType[]
  ): EntityOption[] => {
    return entityOptions.map((entityOption) => ({
      id: entityOption.id,
      name: entityOption.name,
      logo: entityOption.logo,
      status_id: entityOption.status_id,
      value: entityOption.id.toString(),
      label: entityOption.name
    }));
  };

  const dropdownOptionToEntityOption = (
    dropdownOption: DropdownOption
  ): EntityOption => {
    return entities.find((entity) => entity.value === dropdownOption.value)!;
  };

  const entities = convertEntityTypeToEntityOptions(entity?.entitiesList || []);

  return (
    <InputSearch
      label={label ? label : t("ui-kit.search")}
      placeholder={t("ui-kit.search")}
      value={value}
      options={entities}
      customListItem={(option: EntityOption) => <EntityItem option={option} />}
      selectedValue={selectedValue}
      onChange={onChange}
      onSelect={(option) => {
        const selectedOption = dropdownOptionToEntityOption(option);
        if (onSelect) {
          onSelect(selectedOption);
        }
      }}
      onRemove={onRemove}
    />
  );
};

export default InputSearchEntities;
