import React, { useState, useContext } from "react";

import { useTranslation } from "react-i18next";
import ThemeContext from "contexts/theme/ThemeContext";
import Invite from "models/Invite";
import {
  notificationError,
  notificationSuccess
} from "utils/custom_notifications";
import companyApi from "api/companyApi";
import helpers from "api/helpers/helpers";

import Button from "components/ui-kit/Buttons/Button";
import ActionModal from "components/ui-kit/Modals/ActionModal";

const DeleteInviteModal: React.FC<{
  Modal: React.FC<{
    children: React.ReactNode;
  }>;
  invite: Invite;
  updateInvitedList: () => void;
  close: () => void;
}> = ({ Modal, invite, updateInvitedList, close }) => {
  const { dark } = useContext(ThemeContext);
  const { t } = useTranslation();
  const i18n_page = "settings_sections.permissions";

  const [loading, setLoading] = useState(false);

  const deleteInvite = async () => {
    try {
      setLoading(true);
      await companyApi.deleteCompanyDashboardInvited(invite.id);
      notificationSuccess(
        t(`${i18n_page}.email_invitation_canceled`, { email: invite.email }),
        dark
      );
      setLoading(false);
      updateInvitedList();
      close();
    } catch (err) {
      notificationError(helpers.formatErrorMessage(err), dark);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ActionModal
      Modal={Modal}
      close={close}
      title={t(`${i18n_page}.cancel_invitation_confirm`)}
      description={t(`${i18n_page}.cancel_invitation_confirm_description`, {
        email: invite.email
      })}
    >
      <Button context="red" onClick={deleteInvite} loading={loading}>
        {t("action_button.yes_delete")}
      </Button>
    </ActionModal>
  );
};

export default DeleteInviteModal;
