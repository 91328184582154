import { useState, useContext } from "react";
import "./styles.scss";

import { useAuth } from "../../contexts/auth/useAuth";
import ThemeContext from "../../contexts/theme/ThemeContext";
import {
  notificationError,
  notificationSuccess
} from "utils/custom_notifications";
import { useTranslation } from "react-i18next";
import helpers from "api/helpers/helpers";
import challengeApi from "api/challengeApi";

import AuthLayout from "layouts/AuthLayout";
import Input from "components/ui-kit/Input";
import Button from "components/ui-kit/Buttons/Button";

function ChallengePhonePage() {
  const { dark } = useContext(ThemeContext);
  const auth = useAuth();
  const { t } = useTranslation();

  const [code, setCode] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const handleSendCode = () => {
    setIsLoading(true);
    challengeApi
      .postPhoneVerify(code)
      .then((response) => {
        auth?.getUser().then((res) => {
          notificationSuccess(
            t("authentication_pages.account_validated"),
            dark
          );
        });
      })
      .catch((err) => {
        notificationError(helpers.formatErrorMessage(err), dark);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const sendCodeButtonDisabled = () => {
    return code.length !== 6;
  };

  return (
    <AuthLayout
      title={t("authentication_pages.enter_confirmation_code")}
      className="challenge-phone-page"
    >
      <h6>{t("authentication_pages.confirmation_code_sent")}</h6>
      <div className="auth-layout-inputs">
        <Input
          type="code"
          maxLength={6}
          label={t("authentication_pages.confirmation_code")}
          value={code}
          onChange={(e) => setCode(e.target.value)}
          onEnterPressed={() =>
            !sendCodeButtonDisabled() && !isLoading && handleSendCode()
          }
        />
      </div>
      <div className="auth-layout-buttons">
        <Button
          full
          onClick={handleSendCode}
          loading={isLoading}
          disabled={sendCodeButtonDisabled()}
        >
          {t("authentication_pages.verify_confirmation_code")}
        </Button>
      </div>
    </AuthLayout>
  );
}

export default ChallengePhonePage;
