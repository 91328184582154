import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import "./styles.scss";

export interface ReportStepIconProps {
  icon: IconDefinition;
  color: string;
  isCompleted?: boolean | null;
}

const ReportStepIcon: React.FC<ReportStepIconProps> = ({
  icon,
  color,
  isCompleted
}) => {
  return (
    <div
      className="report-step-icon-container"
      style={isCompleted ? { backgroundColor: color } : {}}
    >
      <FontAwesomeIcon
        icon={icon}
        className="report-step-icon"
        color={isCompleted ? "var(--white)" : color}
      />
    </div>
  );
};

export default ReportStepIcon;
