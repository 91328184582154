import React, { useContext } from "react";
import "./styles.scss";
import ThemeContext from "contexts/theme/ThemeContext";
import clsx from "clsx";

const ContentOverlay: React.FC = () => {
  const { showSidebar, toggleShowSidebar } = useContext(ThemeContext);
  return (
    <div
      className={clsx(
        "content-overlay",
        showSidebar ? "content-overlay-sidebar" : ""
      )}
      onClick={(e) => {
        e.preventDefault();
        toggleShowSidebar();
      }}
    />
  );
};

export default ContentOverlay;
