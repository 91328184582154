import { faPencil } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import IconCircleButton from "components/IconCircleButton";
import React from "react";

import "./styles.scss";

export interface ProfileItemProps {
  title: string;
  value?: string;
  required?: boolean;
  canEdit?: boolean;
  onEdit?: () => void;
  className?: string;
}

const ProfileItem: React.FC<ProfileItemProps> = ({
  title,
  value,
  required,
  canEdit = true,
  onEdit,
  className
}) => {
  if (value || required) {
    let rootClass = clsx("profile-item", className);
    return (
      <div className={rootClass}>
        <div className="profile-item-title-value">
          <h4 className="profile-item-title">{title}</h4>
          {value ? <p className="profile-item-value">{value}</p> : <br />}
        </div>
        {canEdit && onEdit ? (
          <IconCircleButton icon={faPencil} onClick={onEdit} />
        ) : null}
      </div>
    );
  } else {
    return null;
  }
};

export default ProfileItem;
