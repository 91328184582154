import React, { useEffect, useState } from "react";
import "./styles.scss";
import { NodeViewWrapper } from "@tiptap/react";
import Button from "components/ui-kit/Buttons/Button";
import { useTranslation } from "react-i18next";
import ActionModal from "components/ui-kit/Modals/ActionModal";
import { useModal } from "react-hooks-use-modal";

interface PostYoutubeProps {
  deleteNode: () => void;
  node?: any;
  isEditable: boolean;
}

const PostYoutube: React.FC<PostYoutubeProps> = ({
  deleteNode,
  node,
  isEditable
}) => {
  const { t } = useTranslation();
  const [videoUrl, setVideoUrl] = useState("");

  const [
    DeletePostYoutubeModal,
    openDeletePostYoutubeModal,
    closeDeletePostYoutubeModal
  ] = useModal("root", {
    closeOnOverlayClick: true
  });

  useEffect(() => {
    node.content.forEach((contentNode: any) => {
      if (contentNode.type.name === "text") {
        const youTubeRegExp =
          /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        const match = contentNode.text.match(youTubeRegExp);
        if (match && match[7].length == 11) {
          setVideoUrl(`https://www.youtube.com/embed/${match[7]}`);
        }
      }
    });
  }, [node]);

  return (
    <NodeViewWrapper className="post-youtube">
      <div className="youtube-container">
        <iframe
          src={videoUrl}
          frameBorder={0}
          allowFullScreen={true}
          width="100%"
          className="youtube-iframe"
        />
      </div>
      {isEditable && (
        <Button
          context="red"
          onClick={openDeletePostYoutubeModal}
          className="delete-node-button"
        >
          {t("action_button.delete")}
        </Button>
      )}
      <ActionModal
        Modal={DeletePostYoutubeModal}
        close={closeDeletePostYoutubeModal}
        title={t("post_page.delete_post_youtube")}
        description={t("post_page.delete_post_youtube_confirmation")}
      >
        <Button context="red" onClick={deleteNode}>
          {t("action_button.delete")}
        </Button>
      </ActionModal>
    </NodeViewWrapper>
  );
};

export default PostYoutube;
