import React from "react";
import "./styles.scss";
import clsx from "clsx";

import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import url_utils from "utils/url_utils";

interface SidebarCellProps {
  title: string;
  icon: IconDefinition;
  color?: string;
  active?: boolean;
  to?: string;
  addSeparator?: boolean;
  isExternal?: boolean;
  expanded: boolean;
}

const SidebarCellContainer: React.FC<SidebarCellProps> = ({
  title,
  icon,
  color,
  active,
  expanded
}) => (
  <li
    className={clsx("sidebar-cell", active ? "active" : "")}
    style={active ? { backgroundColor: `var(--${color})` } : {}}
  >
    <span
      className="sidebar-cell-icon-container"
      style={{ backgroundColor: active ? `var(--white)` : `var(--${color})` }}
    >
      <FontAwesomeIcon
        icon={icon}
        className="sidebar-cell-icon"
        style={
          color
            ? { color: active ? `var(--${color})` : "" }
            : { color: `var(--text)` }
        }
      />
    </span>
    {expanded ? (
      <p
        className="sidebar-cell-title"
        style={{ color: active ? `var(--white})` : "" }}
      >
        {title}
      </p>
    ) : null}
  </li>
);

const SidebarCell: React.FC<SidebarCellProps> = ({
  title,
  icon,
  color,
  to,
  addSeparator,
  isExternal = false,
  expanded
}) => {
  if (to) {
    return (
      <>
        <NavLink
          key={title}
          to={isExternal ? url_utils.getDashboardUrl() + "/" + to : `/${to}`}
          target="_self"
          style={{ textDecoration: "none" }}
          reloadDocument={isExternal ? true : false}
        >
          {({ isActive }) => (
            <SidebarCellContainer
              title={title}
              icon={icon}
              color={color}
              expanded={expanded}
              active={isActive}
            />
          )}
        </NavLink>
        {addSeparator && <div style={{ height: 26 }}></div>}
      </>
    );
  }
  return <SidebarCellContainer title={title} icon={icon} expanded={expanded} />;
};

export default SidebarCell;
