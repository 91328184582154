import React, { useState, useContext } from "react";

import { useAuth } from "contexts/auth/useAuth";
import { useTranslation } from "react-i18next";
import ThemeContext from "contexts/theme/ThemeContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  notificationError,
  notificationSuccess
} from "utils/custom_notifications";
import userApi from "api/userApi";
import helpers from "api/helpers/helpers";

import Input from "components/ui-kit/Input";
import Button from "components/ui-kit/Buttons/Button";
import PasswordCheckerInput, {
  PasswordData
} from "components/ui-kit/PasswordCheckerInput";

const EditPasswordModal: React.FC<{
  Modal: React.FC<{
    children: React.ReactNode;
  }>;
  close: () => void;
}> = ({ Modal, close }) => {
  const auth = useAuth();
  const { dark } = useContext(ThemeContext);
  const { t } = useTranslation();

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const [loading, setLoading] = useState(false);

  const firstname = auth?.user?.firstname.toLowerCase() || "";
  const lastname = auth?.user?.lastname.toLowerCase() || "";

  const closeModal = () => {
    setCurrentPassword("");
    setNewPassword("");
    close();
  };

  const handlePasswordChange = (passwordData: PasswordData) => {
    setNewPassword(passwordData.password);
    setIsPasswordValid(passwordData.isValid);
  };

  const editPassword = () => {
    let params: any = {
      current_password: currentPassword,
      new_password: newPassword
    };
    if (!editPasswordButtonDisabled()) {
      setLoading(true);

      userApi
        .editUser(params)
        .then((response) => {
          if (response.status === 200) {
            notificationSuccess(t("profile_page.user_updated"), dark);
            closeModal();
          }
        })
        .catch((err) => {
          notificationError(helpers.formatErrorMessage(err), dark);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const editPasswordButtonDisabled = () => {
    return currentPassword.length < 8 || !isPasswordValid;
  };

  return (
    <Modal>
      <div className="notme-modal">
        <div className="notme-modal-header">
          <h1>{t("profile_page.edit_password")}</h1>
          <span className="notme-modal-header-close" onClick={closeModal}>
            <FontAwesomeIcon icon={faTimes} className="times-icon" />
          </span>
        </div>
        <Input
          type="password"
          label={t("user_data.current_password")}
          placeholder={"••••••••"}
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
        />
        <PasswordCheckerInput
          label={t("user_data.password")}
          firstname={firstname}
          lastname={lastname}
          onPasswordChange={handlePasswordChange}
          backgroundColor={"card"}
        />

        <div className="notme-modal-footer">
          <Button
            context="blue"
            onClick={editPassword}
            disabled={editPasswordButtonDisabled()}
            loading={loading}
          >
            {t("action_button.validate")}
          </Button>
          <Button context="blue" outline onClick={closeModal}>
            {t("action_button.cancel")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default EditPasswordModal;
