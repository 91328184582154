import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

import "./styles.scss";

export interface TagProps {
  outline?: boolean;
  round?: boolean;
  small?: boolean;
  solid?: boolean;
  subtle?: boolean;

  context?: string;
  className?: string;

  otherProps?: Object;

  children: React.ReactNode;
  onClick?: () => void;
}

const Tag: React.FC<TagProps> = ({
  outline = false,
  round = false,
  small = false,
  solid = false,
  subtle = false,
  context = "blue",
  className,
  children,
  onClick,
  ...otherProps
}) => {
  const baseClass = "notme-tag";

  const rootClass = clsx(
    baseClass,
    context && `${baseClass}-${context}`,
    outline && `${baseClass}-${context}-outline`,
    round && `${baseClass}-${context}-round`,
    small && `${baseClass}-small`,
    solid && `${baseClass}-${context}-solid`,
    subtle && `${baseClass}-${context}-subtle`,
    onClick && `${baseClass}-clickable`,
    className
  );

  return children ? (
    <span className={rootClass} {...otherProps} onClick={onClick}>
      {children}
    </span>
  ) : null;
};

Tag.displayName = "Tag";

Tag.defaultProps = {
  outline: false,
  round: false,
  small: false,
  solid: false,
  subtle: false,
  context: "blue",
  className: ""
};

Tag.propTypes = {
  outline: PropTypes.bool,
  round: PropTypes.bool,
  small: PropTypes.bool,
  solid: PropTypes.bool,
  subtle: PropTypes.bool,
  context: PropTypes.string,
  className: PropTypes.string
};

export default Tag;
