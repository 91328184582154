import React, { useState, useContext } from "react";
import "./styles.scss";

import { useAuth } from "contexts/auth/useAuth";
import ThemeContext from "contexts/theme/ThemeContext";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import role_utils from "utils/role_utils";
import permissions_utils from "utils/permissions_utils";
import url_utils from "utils/url_utils";
import {
  notificationError,
  notificationSuccess
} from "utils/custom_notifications";
import companyApi from "api/companyApi";
import helpers from "api/helpers/helpers";
import { EntityOption } from "models/app/EntityOption";
import UserInviteSender from "models/app/UserInviteSender";

import Button from "components/ui-kit/Buttons/Button";
import Input from "components/ui-kit/Input";
import InputSearchEntities from "components/InputSearchEntities";
import { Link, useLocation } from "react-router-dom";

const PermissionModal: React.FC<{
  Modal: React.FC<{
    children: React.ReactNode;
  }>;
  close: () => void;
  updateInvitedTabNumber?: () => void;
  updateInvitedList?: () => void;
}> = ({ Modal, close, updateInvitedTabNumber, updateInvitedList }) => {
  const auth = useAuth();
  const { dark } = useContext(ThemeContext);
  const { t } = useTranslation();
  const location = useLocation();
  const { pathname } = location;
  const path = pathname.split("/").pop();

  const i18n_page = "settings_sections.permissions";

  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [value, setValue] = useState("");
  const [selectedCompany, setSelectedCompany] = useState<EntityOption | null>(
    null
  );
  const [selectedRole, setSelectedRole] = useState<string>("");

  const roles = role_utils.getUserDashboardRoleOptions(t);

  const closeModal = () => {
    setEmail("");
    setValue("");
    setSelectedCompany(null);
    setSelectedRole("");
    close();
  };

  const isValid = () => {
    return (
      email &&
      selectedRole &&
      url_utils.isEmailValid(email) &&
      (can("invite subscribers to any company") ? selectedCompany : true)
    );
  };

  const updateRole = (e: any) => {
    const role_selected = roles[e.target.options.selectedIndex];
    setSelectedRole(role_selected.value);
  };

  const sendInvite = async () => {
    if (!isValid()) return;

    const params: UserInviteSender = {
      email,
      role: selectedRole
    };
    if (can("invite subscribers to any company") && selectedCompany) {
      params["company_id"] = selectedCompany?.id;
    }

    try {
      setLoading(true);
      await companyApi.inviteCompanySubscribers(params);
      notificationSuccess(t(`${i18n_page}.user_invited`), dark);
      updateInvitedTabNumber && updateInvitedTabNumber();
      if (path === "invited") {
        updateInvitedList && updateInvitedList();
      }
      closeModal();
    } catch (err) {
      notificationError(helpers.formatErrorMessage(err), dark);
    } finally {
      setLoading(false);
    }
  };

  const can = (permission: string) => {
    return permissions_utils.can(permission, auth);
  };

  return (
    <Modal>
      <div className="notme-modal permission-modal">
        <div className="notme-modal-header">
          <h1>{t(`${i18n_page}.invite_dashboard_user`)}</h1>
          <span className="notme-modal-header-close" onClick={closeModal}>
            <FontAwesomeIcon icon={faTimes} className="times-icon" />
          </span>
        </div>
        <div className="notme-modal-body permission-modal-container">
          {can("invite subscribers to any company") && (
            <InputSearchEntities
              label={t("organization")}
              value={value}
              selectedValue={selectedCompany}
              onChange={(e) => setValue(e.target.value)}
              onSelect={(option) => {
                setSelectedCompany(option);
              }}
              onRemove={() => {
                setValue("");
                setSelectedCompany(null);
              }}
            />
          )}
          <Input
            type="email"
            label={t("user_data.email")}
            placeholder="john@example.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            label={t("role.role")}
            inputType="select"
            options={roles}
            value={selectedRole}
            onChange={updateRole}
          />
          <p className="permissions-indications">
            {t(`${i18n_page}.learn_more_about`) + " "}
            <Link
              className="permissions-indications-link"
              to={"/settings/permissions/table"}
              target="_blank"
            >
              {t(`${i18n_page}.roles_permissions`)}
            </Link>
          </p>
        </div>
        <div className="notme-modal-footer">
          <Button onClick={sendInvite} disabled={!isValid()} loading={loading}>
            {t(`${i18n_page}.invite`)}
          </Button>
          <Button outline onClick={closeModal}>
            {t("action_button.cancel")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default PermissionModal;
