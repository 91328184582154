import React, { useContext, useState } from "react";
import "./styles.scss";
import clsx from "clsx";
import { EntityType } from "models/app/EntityType";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import ThemeContext from "contexts/theme/ThemeContext";

import EntityList from "../EntityList";
import { useTranslation } from "react-i18next";

export interface EntitySelectorDropdownProps {
  entities: EntityType[];
  entitiesSelected: EntityType[] | undefined;
  updateEntitySelected: (entity: EntityType, shouldAdd: boolean) => void;
  isOpen: boolean;
  isMobile: boolean;
  selectedFirst?: boolean;
}

const EntitySelectorDropdown: React.FC<EntitySelectorDropdownProps> = ({
  entities,
  entitiesSelected,
  updateEntitySelected,
  isOpen,
  isMobile,
  selectedFirst
}) => {
  const { sidebarExpanded } = useContext(ThemeContext);
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState("");

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const isSelected = (entity: EntityType) => {
    return entitiesSelected?.some((selected) => selected.id === entity.id);
  };

  const sortedEntities = selectedFirst
    ? [...entities].sort((a, b) => {
        const aSelected = isSelected(a);
        const bSelected = isSelected(b);
        if (aSelected && !bSelected) return -1;
        if (!aSelected && bSelected) return 1;
        return 0;
      })
    : entities;

  const entitiesFiltered = sortedEntities.filter((item) =>
    item?.name.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <div
      className={clsx(
        "entity-selector-dropdown",
        isOpen ? "visible" : "",
        sidebarExpanded ? "" : "entity-selector-dropdown-reduced",
        isMobile ? "entity-selector-dropdown-mobile" : ""
      )}
      role="menu"
    >
      <div className="entity-selector-dropdown-input-container">
        <FontAwesomeIcon
          className="entity-selector-dropdown-icon"
          icon={faSearch}
        />
        <input
          className="entity-selector-dropdown-input"
          placeholder={t("entity_selector.search")}
          value={searchText}
          onChange={handleInputChange}
        ></input>
      </div>
      {entitiesFiltered && entitiesFiltered.length > 0 ? (
        <EntityList
          entities={entitiesFiltered}
          entitiesSelected={entitiesSelected}
          updateEntitySelected={updateEntitySelected}
        />
      ) : (
        <div className="entity-selector-dropdown-empty-container">
          <div className="entity-selector-dropdown-empty-icon-container">
            <FontAwesomeIcon
              icon={faSearch}
              className="entity-selector-dropdown-empty-icon"
            />
          </div>
          <div className="entity-selector-dropdown-empty-text">
            <p>{t("entity_selector.no_entity_found")}</p>
            <p className="entity-selector-dropdown-empty-subtitle">
              {t("entity_selector.no_entity_found_description", { searchText })}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default EntitySelectorDropdown;
