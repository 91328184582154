import { useContext, useEffect, useMemo, useState } from "react";
import "./styles.scss";

import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import flashApi from "api/flashApi";
import {
  notificationError,
  notificationSuccess
} from "utils/custom_notifications";
import helpers from "api/helpers/helpers";
import ThemeContext from "contexts/theme/ThemeContext";
import CenterLayout from "layouts/CenterLayout";
import VoicePriority from "models/app/VoicePriority";
import ExtendedAnswer from "models/app/ExtendedAnswer";
import Voice from "models/Voice";

import Button from "components/ui-kit/Buttons/Button";
import HeaderTitle from "components/HeaderTitle";
import Switch from "components/ui-kit/Switch";
import Input, { OptionType } from "components/ui-kit/Input";
import TextArea from "components/ui-kit/TextArea";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserShield } from "@fortawesome/free-solid-svg-icons";
import Loader from "components/ui-kit/Loader";
import TagSelect from "components/TagSelect";
import { getPriorities, getPriority } from "utils/voice_utils";

const JustSayinCreate = () => {
  const navigate = useNavigate();
  const { dark } = useContext(ThemeContext);
  const { voiceId } = useParams();
  const { t } = useTranslation();
  const [isAnonymous, setIsAnonymous] = useState<boolean>(false);
  const [voiceType, setVoiceType] = useState<string>("feedback");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPublishing, setIsPublishing] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  /* Question type to be enabled back once interaction features are available */
  const [voiceTypes, setVoiceTypes] = useState<OptionType[]>([]);

  const voicePriorities = getPriorities();
  const [voicePriority, setVoicePriority] = useState<ExtendedAnswer[]>([]);
  const availablePriorities = voicePriorities.map(
    (priority: VoicePriority) => ({
      id: priority.priority,
      answer: t(priority.title),
      icon: priority.icon,
      color: priority.color
    })
  );

  // Computed state for enabling the publish button
  const enablePublish = useMemo(() => {
    return Boolean(title) && Boolean(description) && Boolean(voiceType);
  }, [title, description, voiceType]);

  const updateVoiceType = (e: any) => {
    setVoiceType(voiceTypes[e.target.options.selectedIndex].value);
  };

  const handleDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setDescription(e.target.value);
  };

  const handlePublish = async () => {
    setIsPublishing(true);
    try {
      const voiceData = {
        title,
        type: voiceType,
        content: description,
        is_anonymous: isAnonymous,
        priority: voicePriority[0]?.id ?? null
      } as Voice;

      if (voiceId) {
        await flashApi.updateVoice(voiceId, voiceData);
      } else {
        await flashApi.createVoice(voiceData);
      }
      notificationSuccess(
        t(`just_sayin_page.voice_${voiceId ? "updated" : "created"}`),
        dark
      );
      navigate(`/just-sayin`, {
        state: { fromEditOrNew: true }
      });
    } catch (error) {
      notificationError(helpers.formatErrorMessage(error), dark);
    } finally {
      setIsPublishing(false);
    }
  };

  useEffect(() => {
    getAllVoiceTypes();
    if (voiceId) {
      const fetchVoiceDetails = async () => {
        setIsLoading(true);
        try {
          const voiceRes = await flashApi.getVoiceById(voiceId);
          const fetchedVoice = voiceRes.data;
          setIsAnonymous(fetchedVoice.is_anonymous);
          setVoiceType(fetchedVoice.type);
          setTitle(fetchedVoice.title);
          setDescription(fetchedVoice.content);
          if (
            fetchedVoice.priority !== null &&
            fetchedVoice.priority !== undefined
          ) {
            const fetchedPriority = getPriority(
              fetchedVoice.priority
            ) as VoicePriority;
            setVoicePriority([
              {
                id: fetchedPriority.priority,
                answer: t(fetchedPriority.title),
                icon: fetchedPriority.icon,
                color: fetchedPriority.color
              }
            ] as ExtendedAnswer[]);
          }
        } catch (error) {
          notificationError(helpers.formatErrorMessage(error), dark);
        } finally {
          setIsLoading(false);
        }
      };
      fetchVoiceDetails();
    }
  }, [voiceId]);

  const getAllVoiceTypes = () => {
    flashApi
      .getAllVoiceTypes()
      .then((response) => {
        if (response.data) {
          const voiceTypesData = response.data.map(
            (type: [string, string]) => ({
              key: type[0],
              value: type[0],
              title: type[1]
            })
          );
          setVoiceTypes(voiceTypesData);
        }
      })
      .catch((err) => {});
  };

  const selectPriority = (priorityOption: ExtendedAnswer[]) => {
    setVoicePriority(priorityOption);
  };

  return (
    <CenterLayout>
      <main className="just-sayin-create">
        <div className="just-sayin-create__header">
          <HeaderTitle
            title={
              voiceId
                ? t(`just_sayin_page.edit_just_sayin_title`)
                : t(`just_sayin_page.new_just_sayin_title`)
            }
            canGoBack={true}
          />
          <Button
            onClick={handlePublish}
            loading={isPublishing}
            disabled={!enablePublish || isLoading}
          >
            {t(`action_button.${voiceId ? "save" : "publish"}`)}
          </Button>
        </div>
        <div className="just-sayin-create__form">
          {isLoading ? (
            <>
              <div>
                <Loader style={{ height: "33px", width: "100%" }} />
              </div>
              {Array(3)
                .fill(null)
                .map((_, idx) => (
                  <div key={idx}>
                    <Loader
                      style={{
                        height: "26px",
                        width: "120px",
                        marginBottom: 6
                      }}
                    />
                    <Loader
                      style={{
                        height: idx < 2 ? "48px" : "120px",
                        width: "100%"
                      }}
                    />
                  </div>
                ))}
            </>
          ) : (
            <>
              <div className="just-sayin-create__toggle">
                <div className="just-sayin-create__toggle-content">
                  <FontAwesomeIcon
                    icon={faUserShield}
                    className="just-sayin-create__toggle-icon"
                  />
                  <p className="just-sayin-create__toggle-text">
                    {t("just_sayin_page.send_anonymously")}
                  </p>
                </div>
                <Switch
                  className="just-sayin-create__toggle-switch"
                  checked={isAnonymous}
                  onClick={() => setIsAnonymous(!isAnonymous)}
                />
              </div>
              <Input
                label={t("type")}
                inputType="select"
                value={voiceType}
                options={voiceTypes}
                onChange={updateVoiceType}
              />
              <Input
                label={t("just_sayin_page.title")}
                placeholder={t("just_sayin_page.title")}
                value={title}
                maxLength={255}
                showLength={true}
                onChange={(e) => setTitle(e.target.value)}
              />
              <TextArea
                label={t("just_sayin_page.description")}
                placeholder={t("just_sayin_page.description_placeholder")}
                value={description}
                onChange={handleDescriptionChange}
                maxLength={1024}
              />
              <div className="just-sayin-create__priority">
                <p className="just-sayin-create__priority-title">
                  {t("just_sayin_page.priority_title")}
                </p>
                <div className="just-sayin-create__priority-options">
                  <TagSelect
                    availableAnswers={availablePriorities as ExtendedAnswer[]}
                    answersSelected={voicePriority as ExtendedAnswer[]}
                    setAnswersSelected={
                      selectPriority as (answers: ExtendedAnswer[]) => void
                    }
                    max={1}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </main>
    </CenterLayout>
  );
};

export default JustSayinCreate;
