import React from "react";
import clsx from "clsx";
import "./styles.scss";

import Category from "models/Category";

interface CategoryCellProps {
  category: Category;
  index: number;
  context?: string;
  selected: boolean;
  nb_selected: number;
  selectCategory: (index: number) => void;
}

const CategoryCell: React.FC<CategoryCellProps> = ({
  category,
  index,
  context,
  selected,
  nb_selected = 10,
  selectCategory
}) => {
  return (
    <div key={index} className="category" onClick={() => selectCategory(index)}>
      <div className="category-container">
        <div
          className={clsx(
            "category-icon-container",
            context && `category-icon-container-${context}`,
            selected && "category-icon-container-blue--selected",
            selected &&
              context &&
              `category-icon-container-${context}--selected`
          )}
        >
          <img
            className={clsx(
              "category-icon",
              selected ? "category-icon--selected" : "",
              context && `category-icon-${context}`
            )}
            src={category.icon}
            alt={category.name}
            height="24px"
            width="24px"
          />
        </div>
        {nb_selected ? (
          <div
            className={clsx(
              "selected-container",
              context && `selected-container-${context}`
            )}
          >
            <p className="selected-container-text">{nb_selected}</p>
          </div>
        ) : null}
      </div>
      <p
        className={clsx("category-name", context && `category-name-${context}`)}
      >
        {category.name}
      </p>
    </div>
  );
};

export default CategoryCell;
