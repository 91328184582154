import React, { useState, useContext } from "react";

import { useTranslation } from "react-i18next";
import ThemeContext from "contexts/theme/ThemeContext";

import Button from "components/ui-kit/Buttons/Button";
import { useAuth } from "contexts/auth/useAuth";
import ActionModal from "components/ui-kit/Modals/ActionModal";
import Invite from "models/Invite";
import permissions_utils from "utils/permissions_utils";
import UserInviteSender from "models/app/UserInviteSender";
import {
  notificationError,
  notificationSuccess
} from "utils/custom_notifications";
import companyApi from "api/companyApi";
import helpers from "api/helpers/helpers";

const ResendInviteModal: React.FC<{
  Modal: React.FC<{
    children: React.ReactNode;
  }>;
  invite: Invite;
  close: () => void;
}> = ({ Modal, invite, close }) => {
  const auth = useAuth();
  const { dark } = useContext(ThemeContext);
  const { t } = useTranslation();
  const i18n_page = "settings_sections.permissions";

  const [loading, setLoading] = useState(false);

  const resendInvite = async () => {
    let params: UserInviteSender = {
      email: invite.email,
      role: invite.role
    };

    if (can("invite subscribers to any company")) {
      params["company_id"] = invite.company.id;
    }

    try {
      setLoading(true);
      await companyApi.inviteCompanySubscribers(params);
      notificationSuccess(t(`${i18n_page}.invitation_resent`), dark);
      setLoading(false);
      close();
    } catch (err) {
      notificationError(helpers.formatErrorMessage(err), dark);
    } finally {
      setLoading(false);
    }
  };

  const can = (permission: string) => {
    return permissions_utils.can(permission, auth);
  };

  return (
    <ActionModal
      Modal={Modal}
      close={close}
      title={t(`${i18n_page}.resend`)}
      description={t(`${i18n_page}.resend_invitation`, {
        email: invite.email
      })}
    >
      <Button onClick={resendInvite} loading={loading}>
        {t("action_button.yes")}
      </Button>
    </ActionModal>
  );
};

export default ResendInviteModal;
