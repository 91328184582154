import React, { createContext, useContext, useEffect, useState } from "react";
import companyApi from "api/companyApi";
import CompanyInfo from "models/CompanyInfo";
import { useAuth } from "contexts/auth/useAuth";
import permissions_utils from "utils/permissions_utils";
import { EntityType } from "models/app/EntityType";
import SubCompany from "models/SubCompany";

interface Entity {
  entitiesList?: EntityType[] | null;
  entitiesSelected?: EntityType[];

  isUniqueSelection?: boolean;
  setIsUniqueSelection: (value: boolean) => void;
  setEntityUniqueSelection: () => void;

  getEntitiesList: () => Promise<any>;

  addToEntitiesSelected: (item: EntityType) => void;
  removeFromEntitiesSelected: (item: EntityType) => void;
  getEntitiesSelected: () => EntityType[];
  clearEntitiesSelected: () => void;
  hasSubEntities: () => boolean;
  hasEntitiesSelected: () => boolean;
  hasStrictlyEntitiesSelected: () => boolean;
}

const entityContext = createContext<Entity | null>(null);

export function EntityProvider({ children }: { children: React.ReactNode }) {
  const auth = useAuth();

  const [isUniqueSelection, setIsUniqueSelection] = useState(false);

  //MARK: Variable initialization

  const initialEntitiesList: EntityType[] = localStorage.getItem(
    "entities_list"
  )
    ? JSON.parse(localStorage.getItem("entities_list") || "")
    : null;

  const initialEntitiesSelected: EntityType[] = localStorage.getItem(
    "entities_selected"
  )
    ? JSON.parse(localStorage.getItem("entities_selected") || "")
    : [];

  const [entitiesSelected, setEntitiesSelected] = useState<EntityType[]>(
    initialEntitiesSelected
  );
  const [entitiesList, setEntitiesList] = useState<EntityType[] | null>(
    initialEntitiesList
  );

  //MARK: Provider functions
  const getEntitiesList = async (): Promise<any> => {
    if (can("invite subscribers to any company")) {
      getAllEntitiesList();
    } else {
      getSubEntitiesList();
    }
  };

  const getSubEntitiesList = async (): Promise<any> => {
    return new Promise((resolve, reject) => {
      companyApi
        .getCompany()
        .then((response: any) => {
          if (response.status === 200) {
            let companyInfo: CompanyInfo = response.data;
            let entitiesFormatted = formatEntities(companyInfo);
            setEntitiesList(entitiesFormatted);
            localStorage.setItem(
              "entities_list",
              JSON.stringify(entitiesFormatted)
            );
            resolve(companyInfo);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const getAllEntitiesList = async (): Promise<any> => {
    return new Promise((resolve, reject) => {
      companyApi
        .getCompanies()
        .then((response: any) => {
          if (response.status === 200) {
            let allCompanies: SubCompany[] = response.data;
            setEntitiesList(allCompanies);
            localStorage.setItem("entities_list", JSON.stringify(allCompanies));
            resolve(allCompanies);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const addToEntitiesSelected = (item: EntityType) => {
    if (!isUniqueSelection) {
      setEntitiesSelected((prevArray) => {
        return [...prevArray, item];
      });
    } else {
      setEntitiesSelected([item]);
    }
  };

  const removeFromEntitiesSelected = (item: EntityType) => {
    if (!isUniqueSelection) {
      setEntitiesSelected((prevArray) => {
        const newArray = [...prevArray];

        const index = prevArray.findIndex((element) => element.id === item.id);

        if (index > -1) {
          newArray.splice(index, 1);
        }
        return newArray;
      });
    } else {
      setEntitiesSelected([]);
    }
  };

  const setEntityUniqueSelection = () => {
    if (entitiesSelected && entitiesSelected.length > 0) {
      setEntitiesSelected([entitiesSelected[0]]);
    }
  };

  const getEntitiesSelected = () => {
    return entitiesSelected;
  };

  const clearEntitiesSelected = () => {
    setEntitiesSelected([]);
  };

  const hasSubEntities = () => {
    if (entitiesList && entitiesList.length > 0) {
      return entitiesList.length > 1;
    }
    return false;
  };

  const hasEntitiesSelected = () => {
    if (entitiesSelected && entitiesSelected.length > 0) {
      return entitiesSelected.length >= 1;
    }
    return false;
  };

  const hasStrictlyEntitiesSelected = () => {
    if (entitiesSelected) {
      return entitiesSelected.length > 1;
    }
    return false;
  };

  //MARK: Helpers

  const formatEntities = (entity?: CompanyInfo) => {
    let entities: EntityType[] = [];
    if (entity) {
      entities.push(entity);
    }
    if (entity && entity?.subcompanies && entity?.subcompanies.length > 0) {
      let subentities = entity?.subcompanies;
      subentities.map((company) => {
        company.parent_id = entity.id;
      });
      entities = [...entities, ...subentities];
    }
    return entities;
  };

  const can = (permission: string) => {
    return permissions_utils.can(permission, auth);
  };

  const canUpdateEntitySelected =
    (can("view company subsidiaries") && can("read subsidiary report")) ||
    can("invite subscribers to any company");

  const refreshEntities = () => {
    if (canUpdateEntitySelected) {
      getEntitiesList();
    }
  };

  //MARK: Watchers / useEffect

  //Save entities selected on localStorage on update
  useEffect(() => {
    if (entitiesSelected.length >= 0) {
      localStorage.setItem(
        "entities_selected",
        JSON.stringify(entitiesSelected)
      );
    }
  }, [entitiesSelected]);

  useEffect(() => {
    if (
      auth?.isLoggedIn &&
      auth?.permissions &&
      auth?.isPermissionsLoaded === true
    ) {
      refreshEntities();
    }
  }, [auth?.isPermissionsLoaded]);

  useEffect(() => {
    if (auth?.isLoggedIn === false) {
      setEntitiesList([]);
      setEntitiesSelected([]);
      localStorage.removeItem("entities_list");
      localStorage.removeItem("entities_selected");
    }
  }, [auth?.isLoggedIn]);

  const contextValue = {
    entitiesSelected,
    entitiesList,
    isUniqueSelection,
    setIsUniqueSelection,
    setEntityUniqueSelection,
    getEntitiesList,
    addToEntitiesSelected,
    removeFromEntitiesSelected,
    getEntitiesSelected,
    clearEntitiesSelected,
    hasSubEntities,
    hasEntitiesSelected,
    hasStrictlyEntitiesSelected
  };

  const entityContextProvider = () => {
    return (
      <entityContext.Provider value={contextValue}>
        {children}
      </entityContext.Provider>
    );
  };

  return entityContextProvider();
}

export const useEntity = () => {
  return useContext(entityContext);
};

export default Entity;
