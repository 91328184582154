import Reaction from "models/Reaction";
import User from "models/User";
import { ReactionCount } from "models/app/ReactionCount";

const emojiMap = new Map<string, string>([
  ["thumbs-up", "👍"],
  ["thumbs-down", "👎"],
  ["heart", "❤️"],
  ["cry", "😭"],
  ["laugh", "😂"],
  ["open-mouth", "😮"]
]);

const aggregateReactions = (
  reactions: Reaction[],
  user: User
): ReactionCount[] => {
  // Use reduce to process reactions into the final array of aggregated reactions
  const aggregatedReactions: ReactionCount[] = reactions.reduce(
    (acc: ReactionCount[], reaction: Reaction) => {
      // Get the type of the reaction (emoji or name)
      const reactionType = emojiMap.get(reaction.name) || reaction.name;

      // Find the reaction entry in the accumulator
      let reactionEntry = acc.find((r) => r.type === reactionType);

      // If not, create one
      if (!reactionEntry) {
        reactionEntry = {
          type: reactionType,
          count: 0,
          isUserReaction: false
        };
        acc.push(reactionEntry);
      }

      // Increment the count for the reaction type
      reactionEntry.count += 1;

      // Flag if the current user has this type of reaction
      if (reaction.user_id === user?.id) {
        reactionEntry.isUserReaction = true;
      }

      return acc;
    },
    []
  );
  return aggregatedReactions;
};

export { emojiMap, aggregateReactions };
