import { forwardRef, Ref, useImperativeHandle, useState } from "react";
import "./styles.scss";
import "styles/react-calendar.scss";
import "styles/react-date-picker.scss";
import StepComponentProps from "models/app/StepComponentProps";

import { useTranslation } from "react-i18next";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";

import Card from "components/ui-kit/Card";
import IconCircleButton from "components/IconCircleButton";
import DatePicker from "react-date-picker/dist/entry.nostyle";

export interface ReportStepDatesProps {
  color?: string;
}

const ReportStepDates = forwardRef(
  (props: ReportStepDatesProps, ref: Ref<StepComponentProps>) => {
    const { color } = props;
    useImperativeHandle(ref, () => ({ check, setupData, getFormattedData }));

    const { t, i18n } = useTranslation();

    const [value, setValue] = useState<Array<Date>>([]);
    const [selectedDate] = useState<any>(null);

    const setupData = () => {};
    const check = () => {
      return value.length > 0;
    };
    const getFormattedData = () => {
      return { new: value.map((date) => dayjs(date).format("YYYY-MM-DD")) };
    };

    const onChange = (event: any) => {
      if (event !== null) {
        setValue([...value, event]);
      }
    };

    const deleteIndex = (index: number) => {
      if (index > -1) {
        let newValue = value.filter((_, i) => i !== index);
        setValue(newValue);
      }
    };

    const DatesList = () => {
      return (
        <div className="dates-list">
          {value.map((date, index) => (
            <Card className="date-cell" key={index}>
              <div className="date-cell-content">
                <p>{dayjs(date).format("LL")}</p>
              </div>
              <IconCircleButton
                icon={faTimes}
                color={"var(--red)"}
                onClick={() => deleteIndex(index)}
              />
            </Card>
          ))}
        </div>
      );
    };

    return (
      <div className="report-step-dates">
        <DatePicker
          value={selectedDate}
          onChange={onChange}
          locale={i18n.language}
          maxDate={new Date()}
          clearIcon={null}
          calendarIcon={
            <p className="date-picker-placeholder">
              {t("report_page.add_a_date")}
            </p>
          }
        />
        {value.length > 0 ? (
          <div className="dates-content">
            <h5 className="uppercase">{t("report_page.selected_dates")}</h5>
            <DatesList />
          </div>
        ) : null}
      </div>
    );
  }
);

export default ReportStepDates;
