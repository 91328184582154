import React, { useState, useContext, useEffect } from "react";

import { useAuth } from "contexts/auth/useAuth";
import { useTranslation } from "react-i18next";
import ThemeContext from "contexts/theme/ThemeContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  notificationError,
  notificationSuccess
} from "utils/custom_notifications";
import helpers from "api/helpers/helpers";
import { getIndustriesOptions } from "utils/industry_utils";
import companyApi from "api/companyApi";

import Input from "components/ui-kit/Input";
import Button from "components/ui-kit/Buttons/Button";

const OrganizationIndustryModal: React.FC<{
  Modal: React.FC<{
    children: React.ReactNode;
  }>;
  close: () => void;
  company_id: number | null;
  organizationIndustry?: string;
  updateIndustry: (industry: string) => void;
}> = ({
  Modal,
  close,
  company_id = null,
  organizationIndustry,
  updateIndustry
}) => {
  const auth = useAuth();
  const { dark } = useContext(ThemeContext);
  const { t } = useTranslation();

  const [selectedIndustry, setSelectedIndustry] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const industries = getIndustriesOptions(t);

  const updateSelectedIndustry = (e: any) => {
    setSelectedIndustry(industries[e.target.options.selectedIndex].value);
  };

  useEffect(() => {
    setSelectedIndustry(organizationIndustry || "");
  }, [organizationIndustry]);

  const closeModal = () => {
    setSelectedIndustry(organizationIndustry || "");
    close();
  };

  const handleIndustrySave = async () => {
    let params: any = {
      industry: selectedIndustry
    };

    if (!isOrganizationIndustryValid()) {
      return;
    }

    setLoading(true);
    try {
      await companyApi.updateCompanyById(company_id, params);
      notificationSuccess(
        t("settings_sections.general.industry_updated"),
        dark
      );
      updateIndustry(params.industry);
    } catch (err) {
      notificationError(helpers.formatErrorMessage(err), dark);
    } finally {
      closeModal();
      setLoading(false);
    }
  };

  const isOrganizationIndustryValid = () => {
    return selectedIndustry && organizationIndustry != selectedIndustry;
  };

  return (
    <Modal>
      <div className="notme-modal">
        <div className="notme-modal-header">
          <h1>{t("settings_sections.general.organization_industry")}</h1>
          <span className="notme-modal-header-close" onClick={closeModal}>
            <FontAwesomeIcon icon={faTimes} className="times-icon" />
          </span>
        </div>

        <Input
          inputType="select"
          value={selectedIndustry}
          options={industries}
          onChange={updateSelectedIndustry}
        />
        <div className="notme-modal-footer">
          <Button
            context="blue"
            onClick={handleIndustrySave}
            loading={loading}
            disabled={!isOrganizationIndustryValid()}
          >
            {t("action_button.save")}
          </Button>
          <Button context="blue" outline onClick={closeModal}>
            {t("action_button.cancel")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default OrganizationIndustryModal;
