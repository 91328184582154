import { useCallback, useEffect, useState, useContext } from "react";
import "./styles.scss";
import { ReportInfo } from "models/ReportInfo";
import QuestionStep from "models/QuestionStep";

import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import ThemeContext from "contexts/theme/ThemeContext";
import {
  notificationError,
  notificationSuccess
} from "utils/custom_notifications";
import helpers from "api/helpers/helpers";
import reportApi from "api/reportApi";

import Button from "components/ui-kit/Buttons/Button";
import HeaderTitle from "components/HeaderTitle";
import ReportInternalStatus from "models/app/ReportInternalStatus";
import { useAuth } from "contexts/auth/useAuth";
import dayjs from "dayjs";
import { authFirebase } from "utils/firebase-config";
import { useModal } from "react-hooks-use-modal";
import ReportSubmitModal from "./components/ReportSubmitModal";
import ReportDeleteModal from "./components/ReportDeleteModal";
import ReportContent from "./components/ReportContent";
import url_utils from "utils/url_utils";
import CenterLayout from "layouts/CenterLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";

const ReportPage = () => {
  let { reportId } = useParams();
  const { dark } = useContext(ThemeContext);
  const auth = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [Modal, open, close, isOpen] = useModal("root", {
    closeOnOverlayClick: true
  });

  const [DeleteReportModal, openDeleteReportModal, closeDeleteReportModal] =
    useModal("root", {
      closeOnOverlayClick: false
    });

  const [report, setReport] = useState<ReportInfo | null>(null);
  const [steps, setSteps] = useState<QuestionStep[]>([]);

  const [saveReportLoading, setSaveReportLoading] = useState(false);
  const [submitReportLoading, setSubmitReportLoading] = useState(false);
  const [deleteReportLoading, setDeleteReportLoading] = useState(false);

  const [isFirebaseAuth, setIsFirebaseAuth] = useState(false);

  const [currentStatus, setCurrentStatus] =
    useState<ReportInternalStatus | null>(null);

  const [isAnonymously, setIsAnonymously] = useState<boolean | null>(null);

  const getReport = useCallback(async () => {

    const report = await reportApi.getReport(reportId);

    if (auth?.user?.company?.id !== report.data.company?.id) {
      setCurrentStatus(ReportInternalStatus.INVALID_COMPANY);
    }

    if (dayjs(report.data.created_at).isBefore("2021-03-01", "day")) {
      setCurrentStatus(ReportInternalStatus.OUTDATED_QUESTIONNAIRE);
    }

    setReport(report.data);
    setIsAnonymously(report.data.is_anonymous);

  }, []);

  const getSteps = useCallback(async () => {
    const steps = await reportApi.getSteps(reportId);
    setSteps(steps.data);
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        await getReport();
      }
      catch (err) {
        notificationError(helpers.formatErrorMessage(err), dark);
      }

      try {
        await getSteps()
      }
      catch (err) {
        notificationError(helpers.formatErrorMessage(err), dark);
      }
    }
    fetchData();

  }, []);

  useEffect(() => {
    if (report && steps.length > 0) {
      if (!isReportSubmittable() && !isFirebaseAuth) {
        initFirebase(report?.id);
      }
    }
  }, [report, steps]);

  const initFirebase = (report_id?: number) => {
    reportApi
      .getFirebaseToken(report_id)
      .then((response) => {
        let token = response.data.token;
        authFirebase(token).then(() => {
          setIsFirebaseAuth(true);
        });
      })
      .catch((err) => {
        setCurrentStatus(ReportInternalStatus.CHAT_MAINTENANCE);
      });
  };

  const updateReport = (params: any, successMessage: string) => {
    return new Promise((resolve, reject) => {
      reportApi
        .updateReport(report?.id, params)
        .then((response) => {
          if (response.status) {
            resolve(response.status);
            notificationSuccess(successMessage, dark);
            getReport().catch((err) => {
              reject();
              notificationError(helpers.formatErrorMessage(err), dark);
            });
          }
        })
        .catch((err) => {
          reject();
          notificationError(helpers.formatErrorMessage(err), dark);
        });
    });
  };

  const deleteReport = async () => {
    setDeleteReportLoading(true);
    try {
      await reportApi.deleteReport(report?.id);
      notificationSuccess(t("report_page.report_deleted"), dark);
      navigate(-1);
    } catch (err) {
      notificationError(helpers.formatErrorMessage(err), dark);
    } finally {
      setDeleteReportLoading(false);
    }
  };

  const saveReport = () => {
    setSaveReportLoading(true);
    updateReport({ status_id: 2 }, t("report_page.report_saved")).finally(
      () => {
        setSaveReportLoading(false);
        navigate(-1);
      }
    );
  };

  const openSubmitReportModal = () => {
    open();
  };

  const isReportSaveable = () => {
    return report?.status_id === 0 || report?.status_id === 1;
  };

  const isReportSubmittable = () => {
    return (
      report?.status_id === 0 ||
      report?.status_id === 1 ||
      report?.status_id === 2
    );
  };

  const isReportCompleted = () => {
    if (report?.report_steps.length === 5) {
      report?.report_steps.forEach((step) => {
        if (!step.completed) {
          return false;
        }
      });
      return true;
    }
    return false;
  };

  const DeleteButton = () => {
    return isReportSubmittable() ? (
      <Button
        className="delete-report-button"
        context="red"
        outline
        onClick={openDeleteReportModal}
        loading={deleteReportLoading}
      >
        <FontAwesomeIcon icon={faTrashCan} className="times-icon" />
      </Button>
    ) : null;
  };

  const SaveButton = () => {
    return isReportSaveable() ? (
      <Button
        context="green"
        onClick={saveReport}
        loading={saveReportLoading}
        disabled={!isReportCompleted()}
      >
        {t("action_button.save")}
      </Button>
    ) : null;
  };

  const SubmitButton = () => {
    return isReportSubmittable() &&
      currentStatus !== ReportInternalStatus.OUTDATED_QUESTIONNAIRE &&
      currentStatus !== ReportInternalStatus.INVALID_COMPANY ? (
      <Button
        context="blue"
        onClick={openSubmitReportModal}
        disabled={!isReportCompleted()}
      >
        {t("action_button.submit")}
      </Button>
    ) : null;
  };

  return (
    <CenterLayout>
      <main style={{ padding: "1rem 0" }} className="report-page">
        <div className="report-title">
          <HeaderTitle
            title={t("report_page.report") + " #" + reportId}
            canGoBack={true}
          />
          <div className="report-buttons">
            <DeleteButton />
            <SaveButton />
            <SubmitButton />
          </div>
        </div>
        <ReportContent
          report={report}
          steps={steps}
          isReportSubmittable={isReportSubmittable()}
          currentStatus={currentStatus}
          isAnonymously={isAnonymously}
          setIsAnonymously={setIsAnonymously}
          isFirebaseAuth={isFirebaseAuth}
        />
        <ReportSubmitModal
          Modal={Modal}
          close={close}
          isAnonymously={isAnonymously}
          report_id={report?.id}
          auth={auth}
          updateReport={updateReport}
        />
        <ReportDeleteModal
          Modal={DeleteReportModal}
          close={closeDeleteReportModal}
          deleteReport={deleteReport}
          loading={deleteReportLoading}
        />
      </main>
    </CenterLayout>
  );
};

export default ReportPage;
