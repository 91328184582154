import React, { useContext, useEffect, useState } from "react";
import logo_dark from "../../assets/img/logo_dark.png";
import logo_white from "../../assets/img/logo_white.png";
import ThemeContext from "../../contexts/theme/ThemeContext";
import Card from "components/ui-kit/Card";
import "./styles.scss";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faComment } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { OptionType } from "components/ui-kit/Input";

export interface AuthQuery {
  canGoBack?: boolean;
}

const AuthLayout: React.FC<{
  title: string;
  className?: string;
  canGoBack?: boolean;
  children: React.ReactNode;
}> = ({ title, className, canGoBack, children }) => {
  const { dark } = useContext(ThemeContext);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [availableLanguages, setAvailableLanguages] = useState<OptionType[]>(
    []
  );

  const rootClass = clsx("auth-layout", className);

  useEffect(() => {
    getLanguageList();
  }, []);

  const getLanguageList = () => {
    if (i18n && i18n.services) {
      let languages = Object.entries(i18n.services.resourceStore.data);
      let languageOptions: OptionType[] = [];
      languages.forEach((element: any) => {
        const meta = element[1].meta;
        languageOptions.push({
          key: meta.key,
          value: meta.title,
          title: meta.title
        });
        if (meta.key === i18n.language) {
          setSelectedLanguage(meta.title);
        }
      });
      setAvailableLanguages(languageOptions);
    }
  };

  const updateLanguage = (e: any) => {
    let language_selected = availableLanguages[e.target.options.selectedIndex];
    setSelectedLanguage(language_selected.value);
    i18n.changeLanguage(language_selected.key);
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className={rootClass}>
      <img
        alt="#NotMe logo"
        src={dark ? logo_white : logo_dark}
        className="img-fluid"
        style={{ height: "36px" }}
      />
      <Card className="auth-layout-card">
        <div className="auth-layout-card-header">
          {canGoBack ? (
            <div className="auth-layout-card-back-button" onClick={goBack}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </div>
          ) : null}
          <h1>{title}</h1>
        </div>
        <div className="auth-layout-card-body">{children}</div>
        <div className="auth-layout-card-footer">
          <div className="user-info-selector">
            <FontAwesomeIcon icon={faComment} />
            <select
              className="user-info-selector-select"
              name="select-language"
              value={selectedLanguage}
              onChange={updateLanguage}
            >
              {availableLanguages.map((language) => {
                return (
                  <option value={language.value} key={language.key}>
                    {language.title}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default AuthLayout;
