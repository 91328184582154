import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

import "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

export interface ButtonProps {
  outline?: boolean;
  plain?: boolean;
  full?: boolean;

  context?: string;
  className?: string;

  loading?: boolean;
  disabled?: boolean;

  otherProps?: Object;

  children: React.ReactNode;
  onClick?: () => void;
}

const Button: React.FC<ButtonProps> = ({
  outline = false,
  plain = false,
  full = false,
  context = "blue",
  className,
  loading = false,
  disabled = false,
  children,
  onClick,
  ...otherProps
}) => {
  const baseClass = "notme-button";

  const rootClass = clsx(
    baseClass,
    context && `${baseClass}-${context}`,
    outline && `${baseClass}-${context}-outline`,
    plain && `${baseClass}-${context}-plain`,
    full && `${baseClass}-full`,
    className
  );

  return (
    <button
      className={rootClass}
      onClick={onClick}
      disabled={disabled || loading}
      {...otherProps}
    >
      {loading ? (
        <div className="icon-spacing">
          <FontAwesomeIcon icon={faCircleNotch} spin />
        </div>
      ) : null}
      {children}
    </button>
  );
};

Button.displayName = "Button";

Button.defaultProps = {
  outline: false,
  plain: false,
  full: false,
  context: "blue",
  className: "",
  loading: false,
  disabled: false,
  onClick: () => {}
};

Button.propTypes = {
  outline: PropTypes.bool,
  plain: PropTypes.bool,
  full: PropTypes.bool,
  context: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func
};

export default Button;
