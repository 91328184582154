import React from "react";
import User from "models/User";
import Comment from "models/Comment";

import { faMessage, faUserShield } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import EmptyData from "components/ui-kit/EmptyData";
import Loader from "components/ui-kit/Loader";
import CommentCell from "../CommentCell";
import InformationBanner from "components/InformationBanner";
import InformationBannerType from "models/app/InformationBannerType";
import "./styles.scss";

interface CommentSectionProps {
  comments: Comment[];
  isLoading: boolean;
  isAddingComment: boolean;
  isLoadingUsers: boolean;
  users: {
    [key: number]: User | null;
  };
  authUser: User | null;
  showAnonymityBanner?: boolean;
  isVoiceAnonymous?: boolean;
  userKey: keyof Pick<Comment, "created_by" | "user_id">;
  onDelete: (id: number) => Promise<void>;
}

const CommentSection: React.FC<CommentSectionProps> = ({
  comments,
  isLoading,
  isAddingComment,
  isLoadingUsers,
  users,
  authUser,
  showAnonymityBanner = false,
  isVoiceAnonymous = false,
  userKey,
  onDelete
}) => {
  const { t } = useTranslation();

  return (
    <div className="comment-section">
      <div className="comment-section__header">
        <div className="comment-section__counter">
          <h5 className="comment-section__counter-title">
            {t("just_sayin_page.comments")}{" "}
            {comments.length > 0 ? `(${comments.length})` : ""}
          </h5>
        </div>
        {showAnonymityBanner && (
          <div className="comment-section__banner">
            <InformationBanner
              title={t("just_sayin_page.anonymity_banner_description")}
              type={InformationBannerType.success}
              icon={faUserShield}
            />
          </div>
        )}
      </div>
      {!isLoading && !isAddingComment && comments.length < 1 ? (
        <EmptyData
          title={t("just_sayin_page.no_comments_yet")}
          description={t("just_sayin_page.be_the_first_to_comment")}
          icon={faMessage}
        />
      ) : (
        <div className="comment-section__comments">
          {comments.map((comment) =>
            isLoadingUsers ? (
              <Loader
                key={comment.id}
                style={{ height: "66px", width: "100%" }}
              />
            ) : (
              <CommentCell
                key={comment.id}
                commentData={comment}
                user={comment[userKey] ? users[comment[userKey]!] : null}
                viewer={authUser}
                isVoiceAnonymous={isVoiceAnonymous}
                onDelete={onDelete}
              />
            )
          )}
          {isAddingComment && (
            <Loader style={{ height: "66px", width: "100%" }} />
          )}
        </div>
      )}
    </div>
  );
};

export default CommentSection;
