import { useContext, useEffect, useMemo, useState } from "react";
import "./styles.scss";

import { useTranslation } from "react-i18next";

import SettingSection from "components/SettingSection";
import Button from "components/ui-kit/Buttons/Button";
import Input, { OptionType } from "components/ui-kit/Input";
import Footer from "components/Footer";
import { useModal } from "react-hooks-use-modal";
import DataModal from "./components/DataModal";
import { useAuth } from "contexts/auth/useAuth";
import User from "models/User";
import ProfileItem from "./components/ProfileItem";
import EditPasswordModal from "./components/EditPasswordModal";
import EditPhoneNumberModal from "./components/EditPhoneNumberModal";
import DeleteAccountModal from "./components/DeleteAccountModal";
import Checkbox from "components/ui-kit/Checkbox";
import challengeApi from "api/challengeApi";
import userApi from "api/userApi";
import {
  notificationError,
  notificationSuccess
} from "utils/custom_notifications";
import ThemeContext from "contexts/theme/ThemeContext";
import helpers from "api/helpers/helpers";
import permissions_utils from "utils/permissions_utils";
import CenterLayout from "layouts/CenterLayout";
import HeaderTitle from "components/HeaderTitle";

const ProfilePage = () => {
  const auth = useAuth();
  const { dark } = useContext(ThemeContext);
  const { t, i18n } = useTranslation();

  const [DataModalContainer, openDataModal, closeDataModal] = useModal("root", {
    closeOnOverlayClick: true
  });
  const [
    EditPasswordModalContainer,
    openEditPasswordModal,
    closeEditPasswordModal
  ] = useModal("root", {
    closeOnOverlayClick: false
  });
  const [
    EditPhoneNumberModalContainer,
    openEditPhoneNumberModal,
    closeEditPhoneNumberModal
  ] = useModal("root", {
    closeOnOverlayClick: false
  });
  const [
    DeleteAccountModalContainer,
    openDeleteAccountModal,
    closeDeleteAccountModal
  ] = useModal("root", {
    closeOnOverlayClick: true
  });

  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [isSavingLanguage, setIsSavingLanguage] = useState<boolean>(false);
  const [availableLanguages, setAvailableLanguages] = useState<OptionType[]>(
    []
  );
  const [toggle2FA, setToggle2FA] = useState(false);
  const [loading2FA, setloading2FA] = useState(false);
  const isSaveLanguageDisabled = useMemo(() => {
    return (
      i18n.language ===
      availableLanguages.find((option) => option.value === selectedLanguage)
        ?.key
    );
  }, [selectedLanguage, i18n.language, availableLanguages]);

  useEffect(() => {
    setloading2FA(true);
    if (auth?.user?.mfa_enabled && auth?.user?.mfa_enabled === true) {
      setToggle2FA(true);
      setloading2FA(false);
    } else {
      setloading2FA(false);
    }
  }, []);

  useEffect(() => {
    if (availableLanguages.length === 0) {
      getLanguageList();
    }
  }, [auth?.user?.company, availableLanguages]);

  const enable2FA = () => {
    let toggle = toggle2FA;
    setToggle2FA((toggle = !toggle));
    setloading2FA(true);

    userApi
      .editUser({ mfa_enabled: toggle })
      .then((response) => {
        auth?.getUser().then((res) => {
          let notificationText = toggle
            ? t("profile_page.mfa_enabled")
            : t("profile_page.mfa_disabled");
          notificationSuccess(notificationText, dark);
          setloading2FA(false);
        });
      })
      .catch((err) => {
        notificationError(helpers.formatErrorMessage(err), dark);
        setToggle2FA((toggle = !toggle));
        setloading2FA(false);
      });
  };

  const getLanguageList = () => {
    const companyLocales =
      Array.isArray(auth?.user?.company?.locales) &&
      auth?.user?.company?.locales.length! > 0
        ? auth?.user?.company?.locales
        : ["en"];
    const languages = Object.entries(i18n.services.resourceStore.data);
    const languageOptions: OptionType[] = languages.reduce(
      (acc: OptionType[], element: any) => {
        const meta = element[1].meta;
        // Check if the language is included in the company's locales
        if (companyLocales!.includes(meta.key) || !auth?.user?.company?.id) {
          acc.push({
            key: meta.key,
            value: meta.title,
            title: meta.title
          });
          // Automatically select the language if it matches the current i18n language
          if (meta.key === i18n.language) {
            setSelectedLanguage(meta.title);
          }
        }
        return acc;
      },
      []
    );
    setAvailableLanguages(languageOptions);
  };

  const updateLanguage = (e: any) => {
    const language_selected =
      availableLanguages[e.target.options.selectedIndex];
    setSelectedLanguage(language_selected.value);
  };

  const saveLanguage = async () => {
    const selectedOption = availableLanguages.find(
      (option) => option.value === selectedLanguage
    );
    if (!selectedOption) return;
    try {
      setIsSavingLanguage(true);
      await userApi.editUser({ locale: selectedOption.key });
      await auth?.getUser();
    } catch (error) {
      notificationError(helpers.formatErrorMessage(error), dark);
    } finally {
      setIsSavingLanguage(false);
    }
  };

  const can = (permission: string) => {
    return permissions_utils.can(permission, auth);
  };

  return (
    <CenterLayout>
      <main style={{ padding: "1rem 0" }} className="profile-page">
        <HeaderTitle title={t("pages.settings.profile")} canGoBack={false} />
        <SettingSection title={t("profile_page.profile")}>
          <div className="profile-container">
            <div className="profile-container-duo">
              <ProfileItem
                title={t("user_data.first_name")}
                value={auth?.user?.firstname}
                className="profile-container-duo-item"
              />
              <ProfileItem
                title={t("user_data.last_name")}
                value={auth?.user?.lastname}
                className="profile-container-duo-item"
              />
            </div>

            <ProfileItem
              title={
                can("view dashboard")
                  ? t("user_data.organization_email")
                  : t("user_data.personal_email")
              }
              value={auth?.user?.email}
            />
            {!can("view dashboard") && (
              <ProfileItem
                title={t("user_data.organization_email")}
                value={auth?.user?.company_email}
              />
            )}
            <ProfileItem
              title={t("user_data.phone_number")}
              value={auth?.user?.phone}
              required
              onEdit={openEditPhoneNumberModal}
            />
            <ProfileItem
              title={t("user_data.password")}
              value="****"
              onEdit={openEditPasswordModal}
            />
            {can("view app") ? (
              <Button
                outline
                full
                context="red"
                onClick={openDeleteAccountModal}
              >
                {t("profile_page.delete_account")}
              </Button>
            ) : null}
          </div>
        </SettingSection>
        <SettingSection title={t("profile_page.language")}>
          <div className="profile-container__language">
            <Input
              inputType="select"
              value={selectedLanguage}
              options={availableLanguages}
              onChange={updateLanguage}
            />
            <Button
              onClick={saveLanguage}
              loading={isSavingLanguage}
              disabled={isSaveLanguageDisabled}
            >
              {t("action_button.save")}
            </Button>
          </div>
        </SettingSection>
        <SettingSection title={t("profile_page.security")}>
          <Checkbox
            checked={toggle2FA}
            onChange={enable2FA}
            disabled={loading2FA}
          >
            <h4 style={{ fontSize: "16px" }}>{t("profile_page.enable_2FA")}</h4>
          </Checkbox>
          <p className="subtitle" style={{ fontSize: "14px" }}>
            {t("profile_page.enable_2FA_description")}
          </p>
        </SettingSection>
        {can("view app") ? (
          <SettingSection title={t("profile_page.data")}>
            <Button outline full onClick={openDataModal}>
              {t("profile_page.get_notme_data")}
            </Button>
          </SettingSection>
        ) : null}
        <Footer />
        <DataModal
          Modal={DataModalContainer}
          close={closeDataModal}
          email={auth?.user?.email}
        />
        <EditPasswordModal
          Modal={EditPasswordModalContainer}
          close={closeEditPasswordModal}
        />
        <EditPhoneNumberModal
          Modal={EditPhoneNumberModalContainer}
          close={closeEditPhoneNumberModal}
        />
        <DeleteAccountModal
          Modal={DeleteAccountModalContainer}
          close={closeDeleteAccountModal}
        />
      </main>
    </CenterLayout>
  );
};

export default ProfilePage;
