import React, { useState } from "react";
import "./styles.scss";
import { EntityType } from "models/app/EntityType";

import Checkbox from "components/ui-kit/Checkbox";

export interface EntityListCellProps {
  entity: EntityType | null;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selected: boolean;
  indentation?: number;
}

const EntityListCell: React.FC<EntityListCellProps> = ({
  entity,
  onChange,
  selected,
  indentation = 0
}) => {
  return (
    <li className="entity-list-cell">
      <span style={{ flex: "0 0 " + indentation * 10 + "px" }}></span>
      <Checkbox
        className="entity-list-cell-checkbox"
        checkboxClassName="entity-list-cell-checkbox--checkbox"
        size={16}
        checked={selected}
        onChange={onChange}
      >
        <span className="entity-list-cell-container">
          <img
            alt={entity?.name}
            className="entity-list-cell-img"
            height={32}
            width={32}
            src={entity?.logo}
          />
          <p className="entity-list-cell-title">{entity?.name}</p>
        </span>
      </Checkbox>
    </li>
  );
};

export default EntityListCell;
