import React, { useState, useContext, useMemo } from "react";

import { useAuth } from "contexts/auth/useAuth";
import { useTranslation } from "react-i18next";
import ThemeContext from "contexts/theme/ThemeContext";
import {
  notificationError,
  notificationSuccess
} from "utils/custom_notifications";
import userApi from "api/userApi";
import helpers from "api/helpers/helpers";

import Button from "components/ui-kit/Buttons/Button";
import CompanyInfo from "models/CompanyInfo";
import LinkOrganizationStep from "./LinkOrganizationStep";
import ValidateOrganizationStep from "./ValidateOrganizationStep";

const AddCompanyModal: React.FC<{
  Modal: React.FC<{
    children: React.ReactNode;
  }>;
  open: () => void;
  close: () => void;
  isOpen: boolean;
}> = ({ Modal, close: closeModal }) => {
  const auth = useAuth();
  const { dark } = useContext(ThemeContext);
  const { t, i18n } = useTranslation();

  const [companyPin, setCompanyPin] = useState("");
  const [companyTitle, setCompanyTitle] = useState("");
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [companyInfo, setCompanyInfo] = useState<CompanyInfo | null>(null);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedLanguageKey, setSelectedLanguageKey] = useState("");
  const [loading, setLoading] = useState(false);
  const checkOrganizationButtonDisabled = useMemo(() => {
    return companyPin === "";
  }, [companyPin]);
  const addOrganizationButtonDisabled = useMemo(() => {
    return (
      !Array.isArray(companyInfo?.locales) ||
      !companyInfo?.locales.includes(i18n.language)
    );
  }, [companyInfo, i18n]);

  const addOrganization = async () => {
    if (!companyPin) {
      return;
    }

    const params = {
      access_code: companyPin,
      ...(companyTitle !== "" && { job_title: companyTitle }) // Conditionally add job_title if it exists
    };

    setLoading(true);
    try {
      const promises = [userApi.addCompany(params)]; // Add company to user

      // Conditionally editUser locale if locale does not match
      if (auth?.user?.locale !== selectedLanguageKey) {
        promises.push(userApi.editUser({ locale: selectedLanguageKey }));
      }

      // Execute promises concurrently
      await Promise.all(promises);

      await auth?.getUser(); // Refresh user data
      notificationSuccess(t("organization_modal.organization_added"), dark);
      close();
    } catch (err) {
      notificationError(helpers.formatErrorMessage(err), dark);
    } finally {
      setLoading(false);
    }
  };

  const checkOrganization = async () => {
    setLoading(true);
    try {
      const response = await userApi.checkCompany({ access_code: companyPin });
      if (response.data.locales && response.data.locales.length > 0) {
        setCompanyInfo(response.data);
      } else {
        setCompanyInfo({ ...response.data, locales: ["en"] });
      }
      setCurrentStep(1);
    } catch (err) {
      notificationError(helpers.formatErrorMessage(err), dark);
    } finally {
      setLoading(false);
    }
  };

  const close = () => {
    setCompanyPin("");
    setCompanyTitle("");
    setCurrentStep(0);
    setCompanyInfo(null);
    closeModal();
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <LinkOrganizationStep
            companyPin={companyPin}
            setCompanyPin={setCompanyPin}
            companyTitle={companyTitle}
            setCompanyTitle={setCompanyTitle}
            checkOrganizationButtonDisabled={checkOrganizationButtonDisabled}
            loading={loading}
            checkOrganization={checkOrganization}
            close={close}
          />
        );
      case 1:
        return (
          <ValidateOrganizationStep
            company={companyInfo!}
            close={close}
            addOrganizationButtonDisabled={addOrganizationButtonDisabled}
            setSelectedLanguage={setSelectedLanguage}
            selectedLanguage={selectedLanguage}
            setSelectedLanguageKey={setSelectedLanguageKey}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Modal>
      <div className="notme-modal">
        {renderStepContent()}
        <div className="notme-modal-footer">
          <Button
            context="blue"
            onClick={currentStep === 0 ? checkOrganization : addOrganization}
            disabled={
              currentStep === 0
                ? checkOrganizationButtonDisabled
                : addOrganizationButtonDisabled && selectedLanguage === ""
            }
            loading={loading}
          >
            {currentStep === 0
              ? t("action_button.next")
              : t("action_button.validate")}
          </Button>
          <Button context="blue" outline onClick={close}>
            {t("action_button.cancel")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AddCompanyModal;
