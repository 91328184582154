import React, { useState, useEffect, useContext, useRef } from "react";
import "./styles.scss";
import { ReportInfo } from "models/ReportInfo";
import Message from "models/app/Message";

import {
  getMessages,
  getMessagesPagination,
  sendMessage
} from "utils/firebase-config";
import {
  QueryDocumentSnapshot,
  DocumentData,
  Timestamp
} from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleNotch,
  faPaperPlane,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import { notificationError } from "utils/custom_notifications";
import ThemeContext from "contexts/theme/ThemeContext";
import { useTranslation } from "react-i18next";

import Card from "components/ui-kit/Card";
import TextArea from "components/ui-kit/TextArea";
import Button from "components/ui-kit/Buttons/Button";
import ChatBubble from "./components/ChatBubble";
import ReactTooltip from "react-tooltip";

const ReportChat: React.FC<{
  report: ReportInfo;
  isFirebaseAuth: Boolean;
  onTopButtonAction?: () => void;
}> = ({ report, isFirebaseAuth, onTopButtonAction }) => {
  const { dark } = useContext(ThemeContext);
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [currentMessage, setCurrentMessage] = useState<string>("");
  const [pastHeight, setPastHeight] = useState(0);

  /*FIRESTORE*/
  const [messages, setMessages] = useState<
    QueryDocumentSnapshot<DocumentData>[]
  >([]);
  const [canLoadMoreMessages, setCanLoadMoreMessages] = useState(false);
  const [startAt, setStartAt] =
    useState<QueryDocumentSnapshot<DocumentData> | null>(null);
  const messagesCollection = "messages-eu";
  /* */

  const ref =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    if (report.id && isFirebaseAuth === true) {
      const unsubscribe = getMessages(
        report.id,
        messagesCollection,
        _setMessages
      );
      return unsubscribe;
    }
  }, [isFirebaseAuth]);

  useEffect(() => {
    if (ref.current) {
      ReactTooltip.rebuild();
      if (messages.length <= 25) {
        ref.current.scrollTo(0, ref.current.scrollHeight);
      } else {
        const currentScroll = ref.current.scrollHeight - pastHeight;
        ref.current.scrollTo(0, currentScroll);
      }
    }
  }, [messages]);

  const _setMessages = (newMessages: QueryDocumentSnapshot<DocumentData>[]) => {
    setMessages([...messages, ...newMessages]);
    setLoading(false);
    if (newMessages[newMessages.length - 1] !== undefined) {
      if (!canLoadMoreMessages) {
        setCanLoadMoreMessages(true);
      }
      setStartAt(newMessages[newMessages.length - 1]);
    } else {
      setCanLoadMoreMessages(false);
    }
  };

  const getMoreMessages = () => {
    if (startAt) {
      setLoading(true);
      const unsubscribe = getMessagesPagination(
        report.id,
        messagesCollection,
        startAt,
        _setMessages
      );
      return unsubscribe;
    }
  };

  const handleSubmit = async () => {
    if (currentMessage.length === 0) {
      return;
    }
    const message: Message = {
      content: currentMessage,
      created_at: Timestamp.now(),
      report_id: report.id,
      subscriber: false
    };
    let _currentMessage = currentMessage;
    setCurrentMessage("");
    try {
      await sendMessage(messagesCollection, message);
    } catch (err) {
      setCurrentMessage(_currentMessage);
      notificationError("An error occured", dark);
    }
  };

  const onKeyDown = (e: any) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      handleSubmit();
    }
  };

  const onScroll = async () => {
    if (canLoadMoreMessages) {
      if (ref.current) {
        const { scrollTop, scrollHeight } = ref.current;
        if (scrollTop === 0) {
          setPastHeight(scrollHeight);
          await getMoreMessages();
        }
      }
    }
  };

  return (
    <Card className="report-chat">
      <div className="report-chat-header">
        <h2 className="report-chat-header-title">{t("report_page.chat")}</h2>
        {onTopButtonAction ? (
          <FontAwesomeIcon icon={faTimes} onClick={onTopButtonAction} />
        ) : null}
      </div>
      <div className="report-chat-content" ref={ref} onScroll={onScroll}>
        {/* <div className="report-chat-banner">
          <p className="report-chat-banner-text">
            Messages to this chat are secured. If you reported anonymously, know
            that your anonymity is strictly maintained.
          </p>
        </div> */}
        {loading ? (
          <div className="report-chat-loading">
            <FontAwesomeIcon icon={faCircleNotch} spin />
          </div>
        ) : canLoadMoreMessages ? (
          <div style={{ height: "36px" }} />
        ) : null}
        <ul className="report-chat-messages-list">
          {messages
            .slice()
            .reverse()
            .map((message) => {
              let message_data = message.data();
              return (
                <ChatBubble
                  key={message.id}
                  content={message_data.content}
                  created_at={message_data.created_at}
                  isSubscriber={message_data.subscriber}
                />
              );
            })}
          <ReactTooltip
            id="reference-for-tooltip"
            type="dark"
            effect="solid"
            place="top"
          />
        </ul>
      </div>
      <div className="report-chat-footer">
        <TextArea
          className="report-chat-footer-textarea"
          value={currentMessage}
          onChange={(e) => setCurrentMessage(e.target.value)}
          onKeyDown={(e) => onKeyDown(e)}
          rows={2}
          resizable={false}
        />
        <Button
          className="report-chat-footer-send-button"
          onClick={handleSubmit}
          disabled={currentMessage.length === 0}
        >
          <FontAwesomeIcon icon={faPaperPlane} />
        </Button>
      </div>
    </Card>
  );
};

export default ReportChat;
