import { useContext, useEffect, useRef, useState } from "react";
import "./styles.scss";

import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/auth/useAuth";
import ThemeContext from "contexts/theme/ThemeContext";
import { useModal } from "react-hooks-use-modal";
import {
  faBuilding,
  faPaste,
  faPencil
} from "@fortawesome/free-solid-svg-icons";
import Contact from "models/Contact";
import Status from "models/app/Status";
import CompanyInfo from "models/CompanyInfo";
import url_utils from "utils/url_utils";
import permissions_utils from "utils/permissions_utils";
import organization_utils from "utils/organization_utils";
import {
  notificationError,
  notificationSuccess
} from "utils/custom_notifications";
import helpers from "api/helpers/helpers";
import companyApi from "api/companyApi";

import CenterLayout from "layouts/CenterLayout";
import HeaderTitle from "components/HeaderTitle";
import SettingSection from "components/SettingSection";
import SettingItem from "components/SettingItem";
import IconCircleButton from "components/IconCircleButton";
import OrganizationSizeModal from "./components/OrganizationSizeModal";
import OrganizationIndustryModal from "./components/OrganizationIndustryModal";
import OrganizationLogoModal from "./components/OrganizationLogoModal";
import OrganizationEmailList from "./components/OrganizationEmailList";
import TagIconCircleButton from "components/TagIconCircleButton";
import Loading from "components/ui-kit/Loading";
import Button from "components/ui-kit/Buttons/Button";
import Input from "components/ui-kit/Input";
import { EntityType } from "models/app/EntityType";
import { useEntity } from "contexts/entity/useEntity";
import EmptyData from "components/ui-kit/EmptyData";

const GeneralPage = () => {
  const auth = useAuth();
  const { dark } = useContext(ThemeContext);
  const { t } = useTranslation();
  const entity = useEntity();

  const i18n_page = "settings_sections.general";

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [company, setCompany] = useState<CompanyInfo | null>(null);
  const [companyStatus, setCompanyStatus] = useState<Status>();
  const [contactEmails, setContactEmails] = useState<string[]>([]);
  const [entitySelected, setEntitySelected] = useState<EntityType | null>(null);
  const [emailValue, setEmailValue] = useState<string>("");
  const [file, setFile] = useState<File>();
  const [saveLoading, setSaveLoading] = useState(false);

  const [initialContactEmails, setInitialContactEmails] = useState<string[]>(
    []
  );

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getCompanyInfo();
  }, [entitySelected]);

  useEffect(() => {
    if (
      can("update any company") &&
      entity &&
      entity.entitiesSelected &&
      Array.isArray(entity.entitiesSelected) &&
      entity.entitiesSelected.length > 0
    ) {
      setEntitySelected(entity.entitiesSelected[0]);
    } else {
      setEntitySelected(null);
    }
  }, [entity]);

  const [
    OrganizationSizeModalContainer,
    openOrganizationSizeModal,
    closeOrganizationSizeModal
  ] = useModal("root", {
    closeOnOverlayClick: false
  });

  const [
    OrganizationIndustryModalContainer,
    openOrganizationIndustryModal,
    closeOrganizationIndustryModal
  ] = useModal("root", {
    closeOnOverlayClick: false
  });

  const [
    OrganizationLogoModalContainer,
    openOrganizationLogoModal,
    closeOrganizationLogoModal
  ] = useModal("root", {
    closeOnOverlayClick: false
  });

  const getCompanyInfo = async () => {
    setIsLoading(true);

    try {
      const companyResponse = await companyApi.getCompanyById(
        entitySelected ? entitySelected.id : null
      );
      const emails: string[] = companyResponse.data.contacts.map(
        (contact: Contact) => contact.email
      );
      setCompanyStatus(
        organization_utils.getOrganizationStatus(companyResponse.data.status_id)
      );
      setCompany({
        ...companyResponse.data,
        headcount: companyResponse.data.headcount
          ? String(companyResponse.data.headcount)
          : "0"
      });
      setInitialContactEmails(JSON.parse(JSON.stringify(emails)));
      setContactEmails(emails);
    } catch (error) {
      setCompany(null);
    } finally {
      setIsLoading(false);
    }
  };

  const updateHeadcountCallback = (headcount: number) => {
    setCompany((prevState: any) => {
      return { ...prevState, headcount: headcount };
    });
  };

  const updateIndustryCallback = (industry: string) => {
    setCompany((prevState: any) => {
      return { ...prevState, industry: industry };
    });
  };

  const copyPinToClipboard = () => {
    notificationSuccess(t(`${i18n_page}.organization_pin_copied`), dark);
    navigator.clipboard.writeText(company?.access_code ?? "");
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files && event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      openOrganizationLogoModal();
      event.target.value = "";
    }
  };

  const updateLogoCallback = () => {
    getCompanyInfo();
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const addContactToList = () => {
    if (!url_utils.isEmailValid(emailValue)) {
      notificationError(t(`${i18n_page}.email_invalid`), dark);
    } else if (contactEmails.includes(emailValue)) {
      notificationError(t(`${i18n_page}.email_already_in_list`), dark);
    } else {
      setContactEmails((prevState) => [...prevState, emailValue]);
      setEmailValue("");
    }
  };

  const updateContactEmails = async () => {
    let params = {
      contact_email_addresses: contactEmails.length !== 0 ? contactEmails : []
    };

    try {
      setSaveLoading(true);
      await companyApi.updateSubcompany(
        params,
        entitySelected ? entitySelected.id : company?.id
      );
      notificationSuccess(t(`${i18n_page}.contact_emails_updated`), dark);
      setInitialContactEmails(contactEmails);
    } catch (err) {
      notificationError(helpers.formatErrorMessage(err), dark);
    } finally {
      setSaveLoading(false);
    }
  };

  const cancelContactEmails = () => {
    setContactEmails(initialContactEmails);
  };

  const can = (permission: string) => {
    return permissions_utils.can(permission, auth);
  };

  if (isLoading) {
    return (
      <CenterLayout>
        <main className="general-page">
          <HeaderTitle
            title={t("pages.settings.general")}
            canGoBack={false}
            descriptionLink={"https://help.not-me.com/settings-general"}
          />
          <Loading />
        </main>
      </CenterLayout>
    );
  }

  if (!company) {
    return (
      <CenterLayout>
        <main className="general-page">
          <HeaderTitle
            title={t("pages.settings.general")}
            canGoBack={false}
            descriptionLink={"https://help.not-me.com/settings-general"}
          />
          <EmptyData
            title={t(`${i18n_page}.no_organization_selected`)}
            description={t(`${i18n_page}.no_organization_selected_description`)}
            icon={faBuilding}
          />
        </main>
      </CenterLayout>
    );
  }

  return (
    <CenterLayout>
      <main className="general-page">
        <HeaderTitle
          title={t("pages.settings.general")}
          canGoBack={false}
          descriptionLink={"https://help.not-me.com/settings-general"}
        />
        {company && (
          <>
            <SettingSection
              title={t(`${i18n_page}.dashboard_customization_title`)}
              description={t(
                `${i18n_page}.dashboard_customization_description`
              )}
            >
              <div className="general-page-container-grid">
                <SettingItem title={t(`${i18n_page}.organization_name`)}>
                  <p className="general-page-subtitle">{company.name}</p>
                </SettingItem>
                <SettingItem title={t(`${i18n_page}.organization_status`)}>
                  <TagIconCircleButton
                    color={companyStatus?.color ?? "grey"}
                    title={t(companyStatus?.title ?? "no_status")}
                  />
                </SettingItem>
                <SettingItem title={t(`${i18n_page}.organization_size`)}>
                  <TagIconCircleButton
                    title={company.headcount.toString()}
                    icon={faPencil}
                    isEditable={
                      can("update company profile") || can("update any company")
                    }
                    onClick={openOrganizationSizeModal}
                  />
                </SettingItem>
                <SettingItem
                  title={t(`${i18n_page}.organization_linked_employees_users`)}
                >
                  <p className="general-page-subtitle">{company.user_count}</p>
                </SettingItem>
                <SettingItem title={t(`${i18n_page}.organization_industry`)}>
                  <TagIconCircleButton
                    title={
                      company.industry
                        ? t(`${i18n_page}.industries.${company.industry}`)
                        : t(`${i18n_page}.industries.not_selected`)
                    }
                    icon={faPencil}
                    isEditable={
                      can("update company profile") || can("update any company")
                    }
                    onClick={openOrganizationIndustryModal}
                  />
                </SettingItem>
                <SettingItem title={t(`${i18n_page}.organization_logo`)}>
                  <div className="organization-logo-container">
                    <img
                      src={company.logo}
                      alt={t(`${i18n_page}.organization_logo`)}
                      className="organization-logo"
                    />
                    {(can("update company profile") ||
                      can("update any company")) && (
                      <IconCircleButton
                        icon={faPencil}
                        onClick={handleButtonClick}
                      />
                    )}
                  </div>
                  <input
                    type="file"
                    accept="image/*"
                    id="logoUpload"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                </SettingItem>
              </div>
            </SettingSection>
            <SettingSection
              title={t(`${i18n_page}.onboarding_title`)}
              description={t(`${i18n_page}.onboarding_description`)}
            >
              <div className="general-page-container">
                <div className="organization-logo-container">
                  <img
                    src={company.qr_code}
                    alt={t(`${i18n_page}.organization_qr`)}
                    className="organization-logo"
                  />
                  <SettingItem title={t(`${i18n_page}.organization_qr`)}>
                    <p className="general-page-subtitle">
                      {t(`${i18n_page}.organization_qr_description`)}
                    </p>
                  </SettingItem>
                </div>
                <SettingItem title={t(`${i18n_page}.organization_pin`)}>
                  <p className="general-page-subtitle">
                    {t(`${i18n_page}.organization_pin_description`)}
                  </p>
                  <TagIconCircleButton
                    title={company.access_code}
                    icon={faPaste}
                    onClick={copyPinToClipboard}
                  />
                </SettingItem>
              </div>
            </SettingSection>
            <SettingSection
              title={t(`${i18n_page}.notifications_alerts_title`)}
              description={t(`${i18n_page}.notifications_alerts_description`)}
            >
              {(can("update company profile") || can("update any company")) && (
                <SettingItem title={t(`${i18n_page}.add_email_address`)}>
                  <Input
                    type="email"
                    placeholder={t(`${i18n_page}.contact_email`)}
                    value={emailValue}
                    onChange={(e) => setEmailValue(e.target.value)}
                  />
                  <Button
                    context="blue"
                    outline
                    className="email-address-button"
                    onClick={addContactToList}
                    disabled={!url_utils.isEmailValid(emailValue)}
                  >
                    {t(`${i18n_page}.add_email`)}
                  </Button>
                </SettingItem>
              )}
              {contactEmails && contactEmails.length > 0 && (
                <SettingItem title={t(`${i18n_page}.contact_emails`)}>
                  <OrganizationEmailList
                    contacts={contactEmails}
                    setContactEmails={setContactEmails}
                    isEditable={
                      can("update company profile") || can("update any company")
                    }
                  />
                </SettingItem>
              )}
              {JSON.stringify(initialContactEmails) !==
                JSON.stringify(contactEmails) &&
                (can("update company profile") ||
                  can("update any company")) && (
                  <div className="email-button-container">
                    <Button
                      context="blue"
                      onClick={updateContactEmails}
                      loading={saveLoading}
                    >
                      {t("action_button.save")}
                    </Button>
                    <Button
                      outline
                      context="blue"
                      onClick={cancelContactEmails}
                    >
                      {t("action_button.cancel")}
                    </Button>
                  </div>
                )}
            </SettingSection>
          </>
        )}
        <OrganizationSizeModal
          Modal={OrganizationSizeModalContainer}
          close={closeOrganizationSizeModal}
          company_id={entitySelected ? entitySelected.id : null}
          organizationSize={company?.headcount}
          updateHeadcount={updateHeadcountCallback}
        />
        <OrganizationIndustryModal
          Modal={OrganizationIndustryModalContainer}
          close={closeOrganizationIndustryModal}
          company_id={entitySelected ? entitySelected.id : null}
          organizationIndustry={company?.industry}
          updateIndustry={updateIndustryCallback}
        />
        <OrganizationLogoModal
          Modal={OrganizationLogoModalContainer}
          close={closeOrganizationLogoModal}
          company_id={entitySelected ? entitySelected.id : null}
          file={file!}
          updateLogo={updateLogoCallback}
        />
      </main>
    </CenterLayout>
  );
};

export default GeneralPage;
