import dayjs from "dayjs";
import { flashApi } from "./api";
import constants from "./constants";

const getPosts = (params = {}) => {
  return flashApi.request_get_auth(`${constants.POSTS_URL}`, params);
};

const getPostById = (id) => {
  return flashApi.request_get_auth(`${constants.POSTS_BASE_URL}${id}`);
};

const getPostComments = (id) => {
  return flashApi.request_get_auth(
    `${constants.POSTS_BASE_URL}${id}/${constants.FLASH_COMMENTS_URL}`
  );
};

const createPostComment = (id, params) => {
  return flashApi.request_post_auth(
    `${constants.POSTS_BASE_URL}${id}/${constants.FLASH_COMMENTS_URL}`,
    params
  );
};

const deletePostComment = (postId, commentId) => {
  return flashApi.request_delete_auth(
    `${constants.POSTS_BASE_URL}${postId}/${constants.FLASH_COMMENTS_URL}/${commentId}`
  );
};

const getPostReactions = (id) => {
  return flashApi.request_get_auth(
    `${constants.POSTS_BASE_URL}${id}/${constants.FLASH_REACT_URL}`
  );
};

const createPostReaction = (id, params) => {
  return flashApi.request_post_auth(
    `${constants.POSTS_BASE_URL}${id}/${constants.FLASH_REACT_URL}`,
    params
  );
};

const deletePostReaction = (id, params) => {
  return flashApi.request_delete_auth(
    `${constants.POSTS_BASE_URL}${id}/${constants.FLASH_REACT_URL}`,
    params
  );
};

const getPostTypes = (params = {}) => {
  return flashApi.request_get_auth(`${constants.POST_TYPES_URL}`, params);
};

const getAllPostTypes = () => {
  return flashApi.request_get_auth(`${constants.ALL_POST_TYPES_URL}`);
};

const createPost = (data) => {
  let formData = new FormData();
  formData.append("title", data.title);
  formData.append("company_ids", data.company_ids);
  formData.append("type", data.type);
  formData.append("featured", data.featured);
  formData.append("created_by", data.created_by);
  formData.append("published", data.published);
  formData.append("thumbnail_url", data.thumbnail_url);
  formData.append("content", JSON.stringify(data.content));

  return flashApi.request_post_multipart_auth(
    `${constants.POSTS_URL}`,
    formData
  );
};

const createEmptyPost = (user_id, company_ids, default_language) => {
  let formData = new FormData();
  let currentDate = dayjs().format("ll").toString();
  formData.append("title", `Post ${currentDate}`);
  formData.append("content", null);
  formData.append("company_ids", company_ids);
  formData.append("created_by", user_id);
  formData.append("type", "article");
  formData.append("published", false);
  formData.append("default_language", default_language);

  return flashApi.request_post_multipart_auth(
    `${constants.POSTS_URL}`,
    formData
  );
};

const createPostView = (postId) => {
  return flashApi.request_post_auth(
    `${constants.POSTS_BASE_URL}${postId}/${constants.POST_VIEWS_URL}`
  );
};

const updatePost = (postId, data) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (Array.isArray(data[key])) {
      // If the value is an array, append each item separately
      data[key].forEach((item) => {
        formData.append(key, item);
      });
    } else if (key === "content") {
      formData.append(key, JSON.stringify(data[key]));
    } else {
      formData.append(key, data[key]);
    }
  });

  return flashApi.request_patch_multipart_auth(
    `${constants.POSTS_BASE_URL}${postId}`,
    formData
  );
};

const uploadFileToPost = (
  postId,
  file,
  title,
  created_by,
  onUploadProgress
) => {
  let formData = new FormData();
  formData.append("media_url", file);
  formData.append("title", title);
  formData.append("created_by", created_by);

  return flashApi.request_post_multipart_auth(
    `${constants.POSTS_BASE_URL}${postId}/files`,
    formData,
    onUploadProgress
  );
};

const getPostFiles = (postId) => {
  return flashApi.request_get_auth(
    `${constants.POSTS_BASE_URL}${postId}/files`
  );
};

const deletePost = (postId) => {
  return flashApi.request_delete_auth(`${constants.POSTS_BASE_URL}${postId}`);
};

const getVoices = (params = {}) => {
  return flashApi.request_get_auth(`${constants.VOICES_URL}`, params);
};

const getVoiceById = (id, params = {}) => {
  return flashApi.request_get_auth(`${constants.VOICES_BASE_URL}${id}`, params);
};

const getVoiceComments = (id, params = {}) => {
  return flashApi.request_get_auth(
    `${constants.VOICES_BASE_URL}${id}/${constants.FLASH_COMMENTS_URL}`,
    params
  );
};

const createVoiceComment = (id, params) => {
  return flashApi.request_post_auth(
    `${constants.VOICES_BASE_URL}${id}/${constants.FLASH_COMMENTS_URL}`,
    params
  );
};

const deleteVoiceComment = (voiceId, commentId, params = {}) => {
  return flashApi.request_delete_auth(
    `${constants.VOICES_BASE_URL}${voiceId}/${constants.FLASH_COMMENTS_URL}/${commentId}`,
    params
  );
};

const getVoiceReactions = (id, params = {}) => {
  return flashApi.request_get_auth(
    `${constants.VOICES_BASE_URL}${id}/${constants.FLASH_REACT_URL}`,
    params
  );
};

const createVoiceReaction = (id, params) => {
  return flashApi.request_post_auth(
    `${constants.VOICES_BASE_URL}${id}/${constants.FLASH_REACT_URL}`,
    params
  );
};

const deleteVoiceReaction = (id, params) => {
  return flashApi.request_delete_auth(
    `${constants.VOICES_BASE_URL}${id}/${constants.FLASH_REACT_URL}`,
    params
  );
};

const createVoice = (params) => {
  return flashApi.request_post_auth(`${constants.VOICES_URL}`, params);
};

const updateVoice = (voiceId, params = {}) => {
  return flashApi.request_patch_json_auth(
    `${constants.VOICES_BASE_URL}${voiceId}`,
    params
  );
};

const deleteVoice = (voiceId, params = {}) => {
  return flashApi.request_delete_auth(
    `${constants.VOICES_BASE_URL}${voiceId}`,
    params
  );
};

const getAllVoiceTypes = (params = {}) => {
  return flashApi.request_get_auth(`${constants.ALL_VOICE_TYPES_URL}`, params);
};

export default {
  getPosts,
  getPostById,
  getPostComments,
  createPostComment,
  deletePostComment,
  getPostReactions,
  createPostReaction,
  deletePostReaction,
  createPostView,
  updatePost,
  getPostTypes,
  getAllPostTypes,
  createEmptyPost,
  createPost,
  uploadFileToPost,
  getPostFiles,
  deletePost,
  getVoices,
  getVoiceById,
  getVoiceComments,
  createVoiceComment,
  deleteVoiceComment,
  getVoiceReactions,
  createVoiceReaction,
  deleteVoiceReaction,
  createVoice,
  updateVoice,
  deleteVoice,
  getAllVoiceTypes
};
