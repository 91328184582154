import { useContext, useEffect, useRef, useState } from "react";
import "./styles.scss";

import { useAuth } from "contexts/auth/useAuth";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import ThemeContext from "contexts/theme/ThemeContext";
import { notificationError } from "utils/custom_notifications";
import flashApi from "api/flashApi";
import companyApi from "api/companyApi";
import userApi from "api/userApi";
import helpers from "api/helpers/helpers";
import permissions_utils from "utils/permissions_utils";

import CenterLayout from "layouts/CenterLayout";
import VoiceCell from "components/Voices/VoiceCell";
import Voice from "models/Voice";
import Company, { CompanyObject } from "models/Company";
import User from "models/User";
import SectionTabsGroup from "components/SectionTabsGroup";
import Button from "components/ui-kit/Buttons/Button";
import Pagination from "components/ui-kit/Pagination";
import EmptyData from "components/ui-kit/EmptyData";
import { faInfoCircle, faNewspaper } from "@fortawesome/free-solid-svg-icons";
import InformationPopover from "components/ui-kit/InformationPopover";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HeaderTitle from "components/HeaderTitle";

type VoiceCompanies = {
  [key: number]: Company | null;
};

type VoiceUsers = {
  [key: number]: User | null;
};

const JustSayin = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const { dark } = useContext(ThemeContext);
  const { t } = useTranslation();
  const [voices, setVoices] = useState<Voice[]>([]);
  const [voiceCompanies, setVoiceCompanies] = useState<VoiceCompanies>({});
  const [voiceUsers, setVoiceUsers] = useState<VoiceUsers>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [activeFilter, setActiveFilter] = useState<string>("all");

  const [filterCounts, setFilterCounts] = useState<{
    [key in string]: number;
  }>({
    all: 0
  });
  const [filters, setFilters] = useState<{ type: string; label: string }[]>([
    { type: "all", label: t("just_sayin_page.all") }
  ]);

  const ITEMS_PER_PAGE = 10;
  const previousFilter = useRef(activeFilter);

  const can = (permission: string) => {
    return permissions_utils.can(permission, auth);
  };

  const companyById = (id: number) => {
    if (id) {
      let companyFound = voiceCompanies[id];
      if (companyFound) {
        return companyFound;
      } else {
        return new CompanyObject(
          t("just_sayin_page.organization_not_linked"),
          "",
          id
        );
      }
    }
    return null;
  };

  const userById = (id: number) => {
    return voiceUsers[id];
  };

  const setupVoiceCompanies = async (voices: Voice[]) => {
    // Get unique companyIds from the voices and filter out those that are already fetched
    const companyIds = Array.from(
      new Set(voices.map((voice: any) => voice.company_id))
    ).filter((id) => !voiceCompanies[id] && voiceCompanies[id] !== null);

    // If all companies are already fetched, simply return
    if (companyIds.length === 0) return;

    // Determine the correct API call based on permission, to be removed later
    const getCompanyMethod = can("view company voices")
      ? companyApi.getCompanyById
      : companyApi.getUserCompanyById;

    try {
      const promises = companyIds.map((id) => {
        return getCompanyMethod(id)
          .then((response) => {
            return { id, data: response.data };
          })
          .catch((_) => {
            return { id, data: null };
          });
      });

      const results = await Promise.allSettled(promises);
      // Transform array to an object
      const companiesMap: VoiceCompanies = { ...voiceCompanies };
      results.forEach((result) => {
        if (result.status === "fulfilled") {
          companiesMap[result.value.id] = result.value.data;
        }
      });
      setVoiceCompanies(companiesMap);
    } catch (_) {}
  };

  const setupVoiceUsers = async (voices: Voice[]) => {
    // Don't fetch users if the user doesn't have permission to view company voices
    if (!can("view company voices")) return;

    const userIds = Array.from(
      new Set(voices.map((voice: any) => voice.user_id))
    ).filter((id) => !voiceUsers[id] && voiceUsers[id] !== null && id !== null);

    if (userIds.length === 0) return;

    try {
      const promises = userIds.map((id) => {
        return userApi
          .adminGetUserById(id)
          .then((response) => {
            return { id, data: response.data };
          })
          .catch((_) => {
            return { id, data: null };
          });
      });

      const results = await Promise.allSettled(promises);
      const usersMap: VoiceUsers = { ...voiceUsers };
      results.forEach((result) => {
        if (result.status === "fulfilled") {
          usersMap[result.value.id] = result.value.data;
        }
      });
      setVoiceUsers(usersMap);
    } catch (_) {}
  };

  const fetchVoices = async (offset: number = 0) => {
    setLoading(true);
    try {
      const response = await flashApi.getVoices({
        limit: ITEMS_PER_PAGE,
        offset,
        type: activeFilter === "all" ? undefined : activeFilter
      });
      const { data, page } = response.data;
      setVoices(data);
      setTotalPages(page.total_pages);
      if (activeFilter === "all")
        setFilterCounts((prev) => ({ ...prev, all: page.total }));
      await Promise.all([setupVoiceCompanies(data), setupVoiceUsers(data)]);
    } catch (err) {
      notificationError(helpers.formatErrorMessage(err), dark);
    } finally {
      setLoading(false);
    }
  };

  const getAllVoiceTypes = () => {
    flashApi
      .getAllVoiceTypes()
      .then((response) => {
        if (response.data) {
          const voiceTypesData: { type: string; label: string }[] =
            response.data.map((type: [string, string]) => ({
              type: type[0],
              label: type[1]
            }));
          let voiceTypes = [...filters, ...voiceTypesData];
          setFilters(voiceTypes);
        }
      })
      .catch((err) => {});
  };

  // Fetch voice types
  useEffect(() => {
    getAllVoiceTypes();
  }, []);

  // Fetch counts for each filter
  useEffect(() => {
    const fetchCounts = async () => {
      const countsPromises: Promise<void>[] = filters.map(async (filter) => {
        if (filter.type !== "all") {
          const response = await flashApi.getVoices({
            limit: 1,
            type: filter.type
          });
          setFilterCounts((prev) => ({
            ...prev,
            [filter.type]: response.data.page.total
          }));
        }
      });
      await Promise.all(countsPromises);
    };
    fetchCounts();
  }, [filters]);

  useEffect(() => {
    const activeFilterChanged = previousFilter.current !== activeFilter;
    previousFilter.current = activeFilter;

    const fetchNewVoices = () => {
      if (currentPage !== 1) {
        setCurrentPage(1);
      } else {
        fetchVoices((currentPage - 1) * ITEMS_PER_PAGE);
      }
    };

    if (activeFilterChanged) {
      fetchNewVoices();
    } else {
      fetchVoices((currentPage - 1) * ITEMS_PER_PAGE);
    }
  }, [activeFilter, currentPage]);

  return (
    <CenterLayout>
      <main className="just-sayin-page">
        <div className="just-sayin-page__header">
          <div className="just-sayin-page__header-text">
            <HeaderTitle
              title={t("pages.just_sayin")}
              canGoBack={false}
              description={t("just_sayin_page.page_description")}
            />
          </div>
          {can("create voice") && (
            <Button
              onClick={() => {
                navigate(`/just-sayin/new`);
              }}
            >
              {t("just_sayin_page.new_just_sayin_title")}
            </Button>
          )}
        </div>

        <SectionTabsGroup
          filters={filters}
          filterCounts={filterCounts}
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
        />

        {loading &&
          Array.from({ length: ITEMS_PER_PAGE / 2 }, (_, i) => (
            <VoiceCell key={`loading-${i}`} loading={true} />
          ))}

        {!loading && voices.length > 0 && (
          <>
            <div className="just-sayin-page__content">
              {voices.map((voice) => (
                <VoiceCell
                  key={voice.id}
                  voice={voice}
                  company={companyById(voice.company_id)}
                  user={userById(voice.user_id)}
                  loading={false}
                />
              ))}
            </div>
            <Pagination
              totalPages={totalPages}
              currentPage={currentPage}
              onPageChange={setCurrentPage}
            />
          </>
        )}

        {!loading && voices.length === 0 && (
          <EmptyData
            title={t("just_sayin_page.no_voices")}
            description={t("just_sayin_page.no_voices_description")}
            icon={faNewspaper}
          />
        )}
      </main>
    </CenterLayout>
  );
};

export default JustSayin;
