import "./styles.scss";

import { ReportInfo } from "models/ReportInfo";
import ReportInternalStatus from "models/app/ReportInternalStatus";
import QuestionStep from "models/QuestionStep";

import { useState } from "react";
import ReportSubmittedSidebar from "../ReportSubmittedSidebar";
import Button from "components/ui-kit/Buttons/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import ReportChat from "components/ReportChat";

const ReportSubmittedMobile: React.FC<{
  report: ReportInfo;
  currentStatus: ReportInternalStatus | null;
  steps: QuestionStep[];
  isFirebaseAuth: boolean;
}> = ({ report, currentStatus, steps, isFirebaseAuth }) => {
  const [isToggle, setIsToggle] = useState(false);
  const toggleChat = () => {
    let toggle = isToggle;
    setIsToggle((toggle = !toggle));
  };

  return (
    <div className="report-submitted-mobile">
      {isToggle ? (
        <ReportChat
          report={report}
          isFirebaseAuth={isFirebaseAuth}
          onTopButtonAction={toggleChat}
        />
      ) : (
        <>
          <ReportSubmittedSidebar
            report={report}
            currentStatus={currentStatus}
            steps={steps}
          />
          <Button className="message-button" onClick={toggleChat}>
            <FontAwesomeIcon icon={faComment} />
          </Button>
        </>
      )}
    </div>
  );
};

export default ReportSubmittedMobile;
