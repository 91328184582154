import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

import "./styles.scss";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface TagSelectItemProps {
  label: string;
  selected: boolean;

  context?: string;
  color?: string;
  icon?: IconDefinition;
  className?: string;

  onClick?: () => void;

  otherProps?: Object;
}

const TagSelectItem: React.FC<TagSelectItemProps> = ({
  label,
  selected,
  context = "blue",
  color,
  icon,
  className,
  onClick,
  ...otherProps
}) => {
  const baseClass = "notme-tag-select-item";
  const baseIconClass = "notme-tag-select-icon";

  const rootClass = clsx(
    baseClass,
    context && !color && `${baseClass}-${context}`,
    selected && `${baseClass}-selected`,
    context && selected && `${baseClass}-selected-${context}`,
    icon && `${baseIconClass}`,
    selected && color && icon && `${baseIconClass}-selected`,
    className
  );
  const rootStyle =
    selected && color && icon
      ? { backgroundColor: `var(--${color})`, borderColor: `var(--${color})` }
      : {};

  return (
    <span
      className={rootClass}
      style={rootStyle}
      {...otherProps}
      onClick={onClick}
    >
      {icon && (
        <FontAwesomeIcon
          icon={icon}
          color={`var(--${selected ? "white" : color}`}
        />
      )}
      {label}
    </span>
  );
};

TagSelectItem.displayName = "TagSelectItem";

TagSelectItem.defaultProps = {
  label: "",
  selected: false,
  context: "blue",
  className: "",
  onClick: () => {}
};

TagSelectItem.propTypes = {
  label: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  context: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func
};

export default TagSelectItem;
