import React, { useEffect, useState } from "react";
import "./styles.scss";
import { NodeViewWrapper } from "@tiptap/react";
import NewsfeedFile from "models/NewsfeedFile";
import Button from "components/ui-kit/Buttons/Button";
import { useTranslation } from "react-i18next";
import ActionModal from "components/ui-kit/Modals/ActionModal";
import { useModal } from "react-hooks-use-modal";

interface PostImageProps {
  deleteNode: () => void;
  node?: any;
  files?: NewsfeedFile[];
  isEditable: boolean;
}

const PostImage: React.FC<PostImageProps> = ({
  deleteNode,
  node,
  files,
  isEditable
}) => {
  const { t } = useTranslation();
  const [file, setFile] = useState<NewsfeedFile | null>(null);

  const [
    DeletePostImageModal,
    openDeletePostImageModal,
    closeDeletePostImageModal
  ] = useModal("root", {
    closeOnOverlayClick: true
  });

  useEffect(() => {
    if (node.attrs.src) {
      let fileFound = files?.find((element) => element.id === node.attrs.src);
      setFile(fileFound || null);
    }
  }, [node, files]);

  return (
    <NodeViewWrapper className="post-image">
      <img src={file?.media_url} />
      {isEditable && (
        <Button
          context="red"
          onClick={openDeletePostImageModal}
          className="delete-node-button"
        >
          {t("action_button.delete")}
        </Button>
      )}
      <ActionModal
        Modal={DeletePostImageModal}
        close={closeDeletePostImageModal}
        title={t("post_page.delete_post_image")}
        description={t("post_page.delete_post_image_confirmation")}
      >
        <Button context="red" onClick={deleteNode}>
          {t("action_button.delete")}
        </Button>
      </ActionModal>
    </NodeViewWrapper>
  );
};

export default PostImage;
