interface Company {
  name: string;
  logo: string;
  id: number;
  locales?: string[];
}

class CompanyObject implements Company {
  constructor(public name: string, public logo: string, public id: number) {}
}

export { CompanyObject };
export default Company;
