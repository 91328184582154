import React, { useState } from "react";
import { useAuth } from "contexts/auth/useAuth";
import { useTranslation } from "react-i18next";

import "./styles.scss";
import Avatar from "components/ui-kit/Avatar";
import Button from "components/ui-kit/Buttons/Button";
import TextArea from "components/ui-kit/TextArea";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";

interface CommentInputProps {
  onSubmit: (comment: string) => void;
}

const CommentInput: React.FC<CommentInputProps> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const [comment, setComment] = useState("");
  const auth = useAuth();

  const handleCommentChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setComment(event.target.value);
  };

  const handleSubmit = () => {
    onSubmit(comment);
    setComment("");
  };

  return (
    <div className="comment-input">
      <Avatar
        className="comment-input__avatar"
        name={auth?.user?.firstname + " " + auth?.user?.lastname}
        id={auth?.user?.id || 0}
      />
      <TextArea
        className="comment-input__comment-area"
        placeholder={t("just_sayin_page.type_here")}
        value={comment}
        onChange={handleCommentChange}
        maxLength={1024}
        rows={2}
        resizable={false}
      />
      <Button
        outline={!comment}
        className="comment-input__submit-button"
        onClick={handleSubmit}
        disabled={!comment}
      >
        <FontAwesomeIcon icon={faPaperPlane} />
      </Button>
    </div>
  );
};

export default CommentInput;
