import api from "./api";
import constants from "./constants";

const postMfa = (email, password) => {
  return api.request_post_auth(constants.MFA_URL, {
    email: email,
    password: password
  });
};

const postPhone = (phone) => {
  return api.request_post_auth(constants.PHONE_URL, { phone: phone });
};

const postPhoneVerify = (challenge_code) => {
  return api.request_post_auth(constants.PHONE_VERIFY_URL, {
    challenge_code: challenge_code
  });
};

const postPhoneDashboard = (phone) => {
  return api.request_post_auth(constants.PHONE_DASHBOARD_URL, { phone: phone });
};

const postPhoneDashboardVerify = (challenge_code) => {
  return api.request_post_auth(constants.PHONE_DASHBOARD_VERIFY_URL, {
    challenge_code: challenge_code
  });
};

const challengeApi = {
  postMfa,
  postPhone,
  postPhoneVerify,
  postPhoneDashboard,
  postPhoneDashboardVerify
};

export default challengeApi;
