import {
  faFileImage,
  faFileAudio,
  faFileVideo,
  faFilePdf,
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faFileAlt,
  faFileCode,
  faFileArchive,
  faFileText,
  faFile
} from "@fortawesome/free-regular-svg-icons";

const getFileTypeIcon = (type: string) => {
  switch (type) {
    case "image":
    case "image/gif":
    case "image/jpeg":
    case "image/png":
      return faFileImage;
    case "audio":
    case "audio/aac":
    case "audio/wav":
    case "audio/mpeg":
    case "audio/mp4":
    case "audio/ogg":
      return faFileAudio;
    case "video":
    case "video/x-msvideo":
    case "video/mpeg":
    case "video/mp4":
    case "video/ogg":
    case "video/quicktime":
    case "video/webm":
      return faFileVideo;
    case "application/pdf":
      return faFilePdf;
    case "application/msword":
    case "application/vnd.ms-word":
    case "application/vnd.oasis.opendocument.text":
    case "application/vnd.openxmlformatsfficedocument.wordprocessingml":
      return faFileWord;
    case "application/vnd.ms-excel":
    case "application/vnd.openxmlformatsfficedocument.spreadsheetml":
    case "application/vnd.oasis.opendocument.spreadsheet":
      return faFileExcel;
    case "application/vnd.ms-powerpoint":
    case "application/vnd.openxmlformatsfficedocument.presentationml":
    case "application/vnd.oasis.opendocument.presentation":
      return faFilePowerpoint;
    case "text/plain":
      return faFileAlt;
    case "text/html":
    case "application/json":
    case "text/csv":
    case "text/css":
    case "text/html":
    case "text/javascript":
    case "application/javascript":
      return faFileCode;
    case "application/zip":
    case "application/gzip":
      return faFileArchive;
    case "text/plain":
    case "text/richtext":
    case "text/rtf":
      return faFileText;
    default:
      return faFile;
  }
};

const getUrlTypeIcon = (type?: string) => {
  switch (type) {
    case "gif":
    case "png":
    case "jpg":
    case "jpeg":
      return faFileImage;
    case "aac":
    case "wav":
    case "mpeg":
    case "ogg":
      return faFileAudio;
    case "x-msvideo":
    case "mp4":
    case "quicktime":
    case "webm":
    case "avi":
    case "mov":
    case "wmv":
      return faFileVideo;
    case "pdf":
      return faFilePdf;
    case "msword":
    case "wordprocessingml":
    case "rtf":
    case "doc":
    case "docx":
      return faFileWord;
    case "excel":
    case "ms-excel":
    case "spreadsheetml":
    case "spreadsheet":
    case "xls":
    case "xlsx":
      return faFileExcel;
    case "powerpoint":
    case "ms-powerpoint":
    case "presentationml":
    case "presentation":
    case "ppt":
    case "pptx":
      return faFilePowerpoint;
    case "text":
    case "txt":
    case "plain":
    case "richtext":
      return faFile;
    case "html":
    case "json":
    case "csv":
    case "css":
    case "javascript":
    case "js":
    case "webarchive":
      return faFileCode;
    case "zip":
    case "gzip":
    case "rar":
      return faFileArchive;
    default:
      return faFile;
  }
};

const getUrlTypeColor = (type?: string) => {
  switch (type) {
    case "gif":
    case "png":
    case "jpg":
    case "jpeg":
      return "#1F5688";
    case "aac":
    case "wav":
    case "mpeg":
    case "ogg":
      return "#fc3c44";
    case "x-msvideo":
    case "mp4":
    case "quicktime":
    case "webm":
    case "avi":
    case "mov":
    case "wmv":
      return "#9063BD";
    case "pdf":
      return "#F40F02";
    case "msword":
    case "wordprocessingml":
    case "rtf":
    case "doc":
    case "docx":
    case "text":
    case "txt":
    case "plain":
    case "richtext":
      return "#2b579a";
    case "excel":
    case "ms-excel":
    case "spreadsheetml":
    case "spreadsheet":
    case "xls":
    case "xlsx":
      return "#217346";
    case "powerpoint":
    case "ms-powerpoint":
    case "presentationml":
    case "presentation":
    case "ppt":
    case "pptx":
      return "#d24726";
    case "html":
    case "json":
    case "csv":
    case "css":
    case "javascript":
    case "js":
    case "webarchive":
      return "#0072c6";
    case "zip":
    case "gzip":
    case "rar":
      return "#dfa227";
    default:
      return "#1F5688";
  }
};
const isImage = (type?: string) => {
  const image_type = ["gif", "png", "jpg", "jpeg"];
  if (type) {
    return image_type.includes(type);
  } else {
    return false;
  }
};

const getFileTypeFromURL = (url: any) => {
  return url.split(/[#?]/)[0].split(".").pop().trim();
};

const getFileFormat = (file_type: string) => {
  switch (file_type) {
    case "aac":
    case "mid":
    case "midi":
    case "mp2":
    case "mp3":
    case "oga":
    case "wav":
    case "weba":
    case "ogx":
      return "audio";
    case "apng":
    case "bmp":
    case "gif":
    case "jpeg":
    case "jpg":
    case "png":
    case "svg":
    case "tif":
    case "tiff":
    case "webp":
    case "ico":
      return "image";
    case "avi":
    case "mp4":
    case "mpa":
    case "mpe":
    case "mpeg":
    case "ogv":
    case "webm":
    case "3gp":
    case "3gp2":
    case "x-msvideo":
    case "quicktime":
    case "mov":
    case "wmv":
      return "video";
    case "pdf":
      return "pdf";
    case "doc":
    case "docx":
    case "odt":
      return "word";
    case "ods":
    case "xls":
    case "xlsx":
      return "excel";
    case "odp":
    case "ppt":
      return "powerpoint";
    case "txt":
    case "rtf":
    case "epub":
      return "text";
    case "css":
    case "csv":
    case "htm":
    case "html":
    case "js":
    case "json":
    case "ts":
    case "xhtml":
    case "xml":
    case "xul":
      return "code";
    case "zip":
    case "7z":
    case "tar":
    case "bz":
    case "bz2":
    case "jar":
    case "mpkg":
    case "rar":
      return "archive";
    default:
      return "document";
  }
};

const dataURItoFile = (dataURI: string, filename: string) => {
  try {
    const [, mime] = dataURI.match(/:(.*?);/) ?? [];
    const bstr = atob(dataURI.split(",")[1]);
    const n = bstr.length;
    const u8arr = new Uint8Array(n);

    for (let i = 0; i < n; i++) {
      u8arr[i] = bstr.charCodeAt(i);
    }

    return new File([u8arr], filename, { type: mime });
  } catch (error) {
    console.error("Error converting data URI to file:", error);
    return null;
  }
};

const file_utils = {
  getFileTypeIcon,
  getUrlTypeIcon,
  getUrlTypeColor,
  isImage,
  getFileTypeFromURL,
  getFileFormat,
  dataURItoFile
};

export default file_utils;
