import React, { KeyboardEventHandler, useState, ReactNode } from "react";
import clsx from "clsx";
import PhoneInput, { CountryData } from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

import "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faEye,
  faEyeSlash
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

export interface OptionType {
  key: string;
  value: string;
  title?: string;
}

export interface InputProps {
  /** Indicate what the user should write */
  label?: string;
  placeholder?: string;
  /** Type of input */
  type?: string;
  /** Custom types are: `select`, `phone` */
  inputType?: string;
  value: string;
  className?: string;

  maxLength?: number;
  showLength?: boolean;

  phoneCountry?: string;

  autoComplete?: string;
  reference?: string;

  /** Optional: Right label action */
  rightLabel?: string;
  rightAction?: () => void;

  /** Array of `{key | value | title}`, need to specify type `select` */
  options?: OptionType[];
  error?: boolean;
  errorMessage?: string;

  customSelection?: ReactNode;

  style?: Object;
  otherProps?: Object;

  onChange?: (event: any) => void;
  onPhoneChange?: (
    value: string,
    data: CountryData | {},
    event: React.ChangeEvent<HTMLInputElement>,
    formattedValue: string
  ) => void;
  onEnter?: () => void;
  onEnterPressed?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
}

const Input: React.FC<InputProps> = ({
  label,
  placeholder,
  type = "text",
  inputType = null,
  value,
  className = "",
  maxLength,
  showLength,
  phoneCountry = "us",
  autoComplete = "true",
  reference,
  rightLabel,
  rightAction,
  options,
  error = false,
  errorMessage = null,
  customSelection,
  style = {},
  onChange = (e) => {},
  onPhoneChange = (value, data, event, formattedValue) => {},
  onEnter,
  onEnterPressed,
  onFocus,
  onBlur,
  ...otherProps
}) => {
  const baseClass = "notme-input";
  const { t } = useTranslation();

  const [eye, setEye] = useState(true);

  const rootClass = clsx(baseClass, className, {});

  let input_element: ReactNode | null = null;

  const handleKeyDown = (e: any) => {
    if (onEnterPressed) {
      if (e.key === "Enter") {
        onEnterPressed();
      }
    }
  };

  const onSelectChange = (e: any) => {
    const selectedIndex = e.target.selectedIndex - 1;
    e.target.selectedIndex = selectedIndex >= 0 ? selectedIndex : 0;
    onChange(e);
  };

  switch (inputType) {
    case "select":
      input_element = (
        <div className="notme-input--container">
          <FontAwesomeIcon
            icon={faChevronDown}
            className="notme-input--select-icon"
          />
          <select
            className="notme-input--input"
            value={value}
            onChange={onSelectChange}
          >
            <option value="" disabled>
              {t("select")}
            </option>
            {options
              ? options.map((option) => (
                  <option key={option.key} value={option.value}>
                    {option.title}
                  </option>
                ))
              : null}
          </select>
        </div>
      );
      break;
    case "phone":
      let inputClassNames = clsx(
        "notme-input--input",
        "notme-input--phone-input"
      );
      input_element = (
        <PhoneInput
          inputClass={inputClassNames}
          dropdownClass="notme-input--dropdown"
          buttonClass="notme-input--dropdown-button"
          value={value}
          country={phoneCountry}
          onChange={onPhoneChange}
          onKeyDown={handleKeyDown}
          enableAreaCodeStretch
          masks={{ au: "(.) .... ...." }}
        />
      );
      break;
    case "custom":
      input_element = (
        <div className="notme-input--input-custom-container">
          {customSelection ? (
            customSelection
          ) : (
            <input
              className="notme-input--input-custom"
              maxLength={maxLength}
              type={type}
              placeholder={placeholder}
              value={value}
              autoComplete={autoComplete}
              onChange={onChange}
              onKeyDown={handleKeyDown}
              onFocus={onFocus && onFocus}
              onBlur={onBlur && onBlur}
              ref={reference}
              {...otherProps}
            />
          )}
        </div>
      );
      break;
    default:
      if (type === "password") {
        input_element = (
          <div className="notme-input--container">
            <input
              className={clsx(
                "notme-input--input",
                "notme-input--input-password"
              )}
              maxLength={maxLength}
              type={eye === true ? "password" : "text"}
              placeholder={placeholder}
              value={value}
              autoComplete={autoComplete}
              onChange={onChange}
              onKeyDown={handleKeyDown}
              ref={reference}
              {...otherProps}
            />
            <span
              className="notme-input--input-eye"
              onClick={() => showPassword()}
            >
              {eye ? (
                <FontAwesomeIcon icon={faEye} style={{ paddingRight: 1 }} />
              ) : (
                <FontAwesomeIcon icon={faEyeSlash} />
              )}
            </span>
          </div>
        );
      } else {
        input_element = (
          <input
            className="notme-input--input"
            maxLength={maxLength}
            type={type}
            placeholder={placeholder}
            value={value}
            autoComplete={autoComplete}
            onChange={onChange}
            onKeyDown={handleKeyDown}
            onFocus={onFocus && onFocus}
            onBlur={onBlur && onBlur}
            ref={reference}
            {...otherProps}
          />
        );
      }
      break;
  }

  const showPassword = () => {
    setEye(!eye);
  };

  return (
    <div className={rootClass}>
      {label ? <label className="notme-input--label">{label}</label> : null}
      {rightLabel ? (
        <label
          onClick={rightAction}
          className={clsx("notme-input--label", "notme-input--right-label")}
        >
          {rightLabel}
        </label>
      ) : showLength ? (
        <label
          className={clsx("notme-input--label", "notme-input--right-label")}
        >
          {value.length}/{maxLength}
        </label>
      ) : null}
      {input_element}
      {/* {passwordToggle()} */}
      {error ? (
        <p className="notme-input--error-message">{errorMessage}</p>
      ) : null}
    </div>
  );
};

export default Input;
