import i18n, { Resource } from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import dayjs from "dayjs";
import dayJsPlugin from "dayjs/plugin/localizedFormat";

import "dayjs/locale/de";
import "dayjs/locale/en";
import "dayjs/locale/es";
import "dayjs/locale/fr";
import "dayjs/locale/it";

const getAvailableLanguages = (): Record<string, string> => {
  const AVAILABLE_LANGUAGES = process.env.REACT_APP_AVAILABLE_LANGUAGES;
  if (AVAILABLE_LANGUAGES) {
    const availableLanguagesArray = AVAILABLE_LANGUAGES.split(",");
    const languages: Record<string, string> = availableLanguagesArray.reduce(
      (acc: Record<string, string>, pair: string) => {
        const [key, value] = pair.split(":");
        acc[key] = value;
        return acc;
      },
      {}
    );
    return languages;
  }
  return {};
};

const generateResources = (languages: Record<string, string>) => {
  const resources: Resource = {};
  for (const key in languages) {
    if (Object.hasOwnProperty.call(languages, key)) {
      const translationsContext = require(`./locales/${key}.json`);

      resources[key] = {
        translation: translationsContext,
        meta: {
          key,
          title: languages[key]
        }
      };
    }
  }
  return resources;
};

const isLanguageDetectedAvailable = () => {
  const detectedLanguage = i18n.language;
  const availableLanguages = getAvailableLanguages();
  return Object.keys(availableLanguages).includes(detectedLanguage);
};

const languages = getAvailableLanguages();
const resources = generateResources(languages);

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    }
  })
  .then(() => {
    dayjs.extend(dayJsPlugin);
  });

if (!isLanguageDetectedAvailable()) {
  i18n.changeLanguage("en");
}

if (i18n.language === "en-US") {
  i18n.changeLanguage("en");
}

i18n.on("languageChanged", (lng) => {
  dayjs.locale(lng);
});

export default i18n;
