import role_utils from "utils/role_utils";
import api from "./api";
import constants from "./constants";

const getCompany = (params, customHeaders) => {
  return api.request_get_auth(constants.COMPANY_URL, params, customHeaders);
};

const getCompanyById = (id, params = {}, customHeaders = {}) => {
  const url = id ? constants.COMPANY_URL + id : constants.COMPANY_URL;
  return api.request_get_auth(url, params, customHeaders);
};

const getUserCompanyById = (id) => {
  return api.request_get_auth(constants.USER_COMPANY_URL + id);
};

const updateCompany = (params, customHeaders) => {
  return api.request_put_auth(constants.COMPANY_URL, params, customHeaders);
};

const updateCompanyById = (id, params, customHeaders) => {
  const url = id ? constants.COMPANY_URL + id : constants.COMPANY_URL;
  return api.request_put_auth(url, params, customHeaders);
};

const updateSubcompany = (params, id) => {
  const url = id ? constants.COMPANY_URL + id : constants.COMPANY_URL;
  return api.request_put_auth(url, params);
};

const updateCompanyLogo = (formData) => {
  return api.request_post_multipart_auth(constants.COMPANY_URL, formData);
};

const updateSubcompanyLogo = (formData, id) => {
  const url = id ? constants.COMPANY_URL + id : constants.COMPANY_URL;
  return api.request_post_multipart_auth(url, formData);
};

const getCompanyUsers = (params) => {
  return api.request_get_auth(
    constants.COMPANY_USERS_URL + "?role=user",
    params
  );
};

const getCompanySubscribers = (params) => {
  return api.request_get_auth(
    constants.COMPANY_USERS_URL + "?role=subscriber&limit=100",
    params
  );
};

const inviteCompanySubscribers = (params) => {
  return api.request_post_auth(constants.COMPANY_USERS_INVITES_URL, params);
};

const getCompanyDashboardInvited = (params) => {
  return api.request_get_auth(constants.COMPANY_USERS_INVITES_URL, params);
};

const getCompanies = (params) => {
  return api.request_get_auth(constants.COMPANIES_URL, params);
};

const getCompanyEntitlements = (id) => {
  return api.request_get_auth(
    constants.COMPANIES_USERS_URL + id + "/" + constants.ENTITLEMENTS_URL
  );
};

const updateCompanyEntitlements = (id, params) => {
  return api.request_post_auth(
    constants.COMPANIES_USERS_URL + id + "/" + constants.ENTITLEMENTS_URL,
    params
  );
};

const deleteCompanyDashboardInvited = (id) => {
  return api.request_delete_auth(
    constants.COMPANY_USERS_INVITES_URL + "/" + id
  );
};

const getCompanyDashboardUsers = (params) => {
  let roles = role_utils.DASHBOARD_ROLES;
  return api.request_get_auth(constants.COMPANY_USERS_URL, {
    ...params,
    roles: roles
  });
};

const kickCompanyUser = (id) => {
  return api.request_delete_auth(constants.COMPANY_USERS_URL + "/" + id);
};

const setRole = (id, role) => {
  return api.request_post_auth(constants.COMPANY_USERS_URL + "/" + id, {
    role: role
  });
};

export default {
  getCompany,
  getCompanyById,
  getUserCompanyById,
  updateCompany,
  updateCompanyById,
  updateSubcompany,
  updateCompanyLogo,
  updateSubcompanyLogo,
  getCompanyUsers,
  getCompanySubscribers,
  inviteCompanySubscribers,
  getCompanyDashboardInvited,
  getCompanies,
  getCompanyEntitlements,
  updateCompanyEntitlements,
  deleteCompanyDashboardInvited,
  kickCompanyUser,
  getCompanyDashboardUsers,
  setRole
};
