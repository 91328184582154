import { useState, useEffect, useContext } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./styles.scss";

import { useAuth } from "contexts/auth/useAuth";
import { useEntity } from "contexts/entity/useEntity";
import ThemeContext from "contexts/theme/ThemeContext";

import flashApi from "api/flashApi";
import helpers from "api/helpers/helpers";
import { notificationError } from "utils/custom_notifications";
import permissions_utils from "utils/permissions_utils";
import PostParameters from "models/PostParameters";
import NewsfeedPost from "models/NewsfeedPost";

import NewsCell from "components/Newsfeed/NewsCell";
import CenterLayout from "layouts/CenterLayout";
import Pagination from "components/ui-kit/Pagination";
import { faNewspaper } from "@fortawesome/free-solid-svg-icons";
import HeaderTitle from "components/HeaderTitle";
import EmptyData from "components/ui-kit/EmptyData";
import { getCompanyToFetch, shouldFilterByCompany } from "utils/post_utils";
import Loader from "components/ui-kit/Loader";

const PostsPage = () => {
  const { dark } = useContext(ThemeContext);

  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const auth = useAuth();
  const entities = useEntity();
  const companyId = auth?.user?.company?.id;

  const params = queryStringToObject(searchParams.toString());

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [postTypes, setPostTypes] = useState([]);
  const [posts, setPosts] = useState<NewsfeedPost[]>([]);

  const [title, setTitle] = useState("");

  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const companyToFetch = getCompanyToFetch(entities, companyId);

  const can = (permission: string) => {
    return permissions_utils.can(permission, auth);
  };

  const getPosts = async (offset: number) => {
    try {
      let parameters: PostParameters = params;

      parameters.limit = 12;
      parameters.offset = offset;
      parameters.sort_by = "created";
      parameters.order_by = "desc";

      if (can("read unpublished feed") && params.published === "false") {
        parameters.published = false;
      } else {
        parameters.published = true;
      }

      if (shouldFilterByCompany(entities, can)) {
        parameters.company_id = companyToFetch;
      }

      const { data } = await flashApi.getPosts(parameters);
      return data;
    } catch (err) {
      throw err;
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const offset = (currentPage - 1) * 12;
      const postData = await getPosts(offset);

      // Use total_pages from the response directly
      setTotalPages(postData.page.total_pages);

      setPosts(postData.data);
    } catch (err) {
      notificationError(helpers.formatErrorMessage(err), dark);
      setPosts([]);
    } finally {
      setIsLoading(false);
    }
  };

  function queryStringToObject(queryString: string): { [key: string]: string } {
    const obj: { [key: string]: string } = {};
    if (!queryString) return obj;
    const params = queryString.split("&");
    for (const param of params) {
      const [key, value] = param.split("=");
      obj[key] = value;
    }
    return obj;
  }

  useEffect(() => {
    const fetchPostTypes = async () => {
      try {
        const { data } = await flashApi.getAllPostTypes();
        setPostTypes(data || []);
      } catch (err) {
        notificationError(helpers.formatErrorMessage(err), dark);
      }
    };

    fetchPostTypes();
  }, []);

  useEffect(() => {
    // Setup title
    if (params.type) {
      // Look up the post type by slug in the fetched data
      const matchingPostType = postTypes.find(
        (postType) => postType[0] === params.type
      );
      setTitle(matchingPostType ? matchingPostType[1] : params.type);
    } else if (params.published === "false") {
      setTitle(t("post_page.drafts"));
    } else if (params.featured === "true") {
      setTitle(t("feed.featured"));
    } else {
      setTitle(t(`feed.posts`));
    }
  }, [postTypes, params]);

  useEffect(() => {
    fetchData();
  }, [currentPage, companyToFetch]);

  return (
    <CenterLayout>
      <main className="posts-page">
        {isLoading ? (
          <Loader style={{ height: "33px", width: "100px", marginBottom: 8 }} />
        ) : (
          <HeaderTitle title={title} canGoBack={true} />
        )}
        {posts.length > 0 || isLoading ? (
          <>
            <div className="posts-page__grid">
              {(isLoading
                ? Array.from({ length: 12 }, () => ({} as NewsfeedPost))
                : posts
              ).map((post, index) => (
                <NewsCell
                  key={isLoading ? `loading-${index}` : post.id}
                  layout="horizontal"
                  post={post}
                  loading={isLoading}
                />
              ))}
            </div>
            <Pagination
              totalPages={totalPages}
              currentPage={currentPage}
              onPageChange={setCurrentPage}
            />
          </>
        ) : (
          <EmptyData
            title={t("feed.no_posts")}
            description={t("feed.no_posts_organization")}
            icon={faNewspaper}
          />
        )}
      </main>
    </CenterLayout>
  );
};

export default PostsPage;
