import React, { useEffect, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import Tag from "components/ui-kit/Tag";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import report_utils from "utils/report_utils";

export interface ReportStatusTagProps {
  status_id: number;

  otherProps?: Object;
}

const ReportStatusTag: React.FC<ReportStatusTagProps> = ({
  status_id,
  ...otherProps
}) => {
  const { t } = useTranslation();
  const baseClass = "report-status-tag";

  const [color, setColor] = useState("");
  const [title, setTitle] = useState("");

  useEffect(() => {
    setColor(report_utils.getStatusColor(status_id));
    setTitle(report_utils.getStatusTitle(status_id));
  }, []);

  const rootClass = clsx(baseClass);

  return (
    <Tag context={color} round className={rootClass} {...otherProps}>
      {title ? t(title) : null}
    </Tag>
  );
};

ReportStatusTag.displayName = "ReportStatusTag";

ReportStatusTag.propTypes = {
  status_id: PropTypes.number.isRequired
};

export default ReportStatusTag;
