import React from "react";
import "./styles.scss";

import Company from "models/Company";
import clsx from "clsx";

export interface EntityLogoProps {
  company?: Company;
  sub_company?: Company;
}

const EntityLogo: React.FC<EntityLogoProps> = ({ company, sub_company }) => {
  const baseClass = "entity-logo";

  return (
    <div className={clsx(baseClass, sub_company && `${baseClass}-subcompany`)}>
      <img
        alt={sub_company ? sub_company?.name : company?.name}
        className={`${baseClass}-img`}
        height={sub_company ? 40 : 44}
        width={sub_company ? 40 : 44}
        src={sub_company ? sub_company?.logo : company?.logo}
      />
      {sub_company ? (
        <img
          alt={company?.name}
          className={`sub${baseClass}-img`}
          height={22}
          width={22}
          src={company?.logo}
        />
      ) : null}
    </div>
  );
};

EntityLogo.displayName = "EntityLogo";

export default EntityLogo;
