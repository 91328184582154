import React from "react";
import "./styles.scss";

import SectionTab from "components/ui-kit/SectionTab";

interface FilterObjectType {
  type: string;
  label: string;
}

interface SectionTabsGroupProps {
  filters: FilterObjectType[];
  filterCounts?: { [key in string]: number };
  hideEmptyFilters?: boolean;
  activeFilter: string;
  setActiveFilter: (filter: string) => void;
}

const SectionTabsGroup: React.FC<SectionTabsGroupProps> = ({
  filters,
  filterCounts,
  hideEmptyFilters = true,
  activeFilter,
  setActiveFilter
}) => {
  let myFilters = filters;
  if (hideEmptyFilters && filterCounts) {
    myFilters = filters.filter((filter) => filterCounts[filter.type] > 0);
  }

  return (
    <div className="section-tabs-grouped">
      {myFilters.map((filter) => (
        <SectionTab
          key={filter.type}
          label={filter.label}
          count={filterCounts ? filterCounts[filter.type] : undefined}
          active={activeFilter === filter.type}
          setActive={() => setActiveFilter(filter.type)}
        />
      ))}
    </div>
  );
};

export default SectionTabsGroup;
