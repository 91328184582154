import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import "./styles.scss";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface IconCircleButtonProps {
  icon: IconDefinition;
  color?: string;
  outline?: boolean;
  onClick?: () => void;

  className?: string;

  otherProps?: Object;
}

const IconCircleButton: React.FC<IconCircleButtonProps> = ({
  icon,
  color = "var(--blue)",
  outline = true,
  onClick,
  className,
  ...otherProps
}) => {
  const baseClass = "icon-circle-button";

  const rootClass = clsx(baseClass, className);

  const iconColor = outline ? color : "var(--white)";

  return (
    <div
      className={rootClass}
      style={{
        borderColor: color,
        backgroundColor: outline ? "transparent" : color
      }}
      onClick={onClick}
    >
      <span className="icon">
        <FontAwesomeIcon icon={icon} color={iconColor} />
      </span>
    </div>
  );
};

IconCircleButton.displayName = "IconCircleButton";

IconCircleButton.propTypes = {
  icon: PropTypes.any.isRequired,
  color: PropTypes.string,
  onClick: PropTypes.func
};

export default IconCircleButton;
