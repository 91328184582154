import "./styles.scss";

import { useTranslation } from "react-i18next";
import permissions_table from "utils/permissions_table.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import PermissionRow from "models/app/PermissionRow";

import CenterLayout from "layouts/CenterLayout";
import HeaderTitle from "components/HeaderTitle";

const PermissionsTablePage = () => {
  const { t } = useTranslation();

  return (
    <CenterLayout>
      <main className="permissions-table-page">
        <HeaderTitle
          title={t(
            "settings_sections.permissions.permissions_table.permissions_table"
          )}
        />
        <table className="permissions-table">
          <thead>
            <tr key="permission-top" className="table-row-column">
              <th className="table-column-left">
                <p className="feature-heading-title--section">
                  {t("role.role")}
                </p>
              </th>
              <td className="table-column feature-heading-title">
                {t("role.viewer")}
              </td>
              <td className="table-column feature-heading-title">
                {t("role.editor")}
              </td>
              <td className="table-column feature-heading-title">
                {t("role.subscriber")}
              </td>
              <td className="table-column feature-heading-title">
                {t("role.manager")}
              </td>
              <td className="table-column feature-heading-title">
                {t("role.admin")}
              </td>
            </tr>
          </thead>
          <tbody>
            {permissions_table.map(
              (permission: PermissionRow, index: number) => {
                return (
                  <tr key={"permission-" + index}>
                    <th className="table-column-left">
                      <div className="feature-heading">
                        <p className="feature-heading-title">
                          <span className="feature-heading-title--section">
                            {t(permission.section)} -{" "}
                          </span>
                          {t(permission.name)}
                        </p>
                      </div>
                    </th>
                    <td className="table-column">
                      {permission.roles.includes("viewer") && (
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          color={"var(--dark_red)"}
                        />
                      )}
                    </td>
                    <td className="table-column">
                      {permission.roles.includes("editor") && (
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          color={"var(--green)"}
                        />
                      )}
                    </td>
                    <td className="table-column">
                      {permission.roles.includes("subscriber") && (
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          color={"var(--dark_blue)"}
                        />
                      )}
                    </td>
                    <td className="table-column">
                      {permission.roles.includes("manager") && (
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          color={"var(--blue)"}
                        />
                      )}
                    </td>
                    <td className="table-column">
                      {permission.roles.includes("admin") && (
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          color={"var(--red)"}
                        />
                      )}
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </main>
    </CenterLayout>
  );
};

export default PermissionsTablePage;
