import axios from "axios";
import requests from "./helpers/requests";

axios.interceptors.request.use((request) => {
  return request;
});

const apiFactory = (base_url) => ({
  request_get: (url) => requests.request_GET(base_url + url),
  request_get_auth: (url, parameters = null, customHeaders) =>
    requests.request_GET(base_url + url, parameters, true, customHeaders),
  request_post: (url, parameters) =>
    requests.request_POST(base_url + url, parameters),
  request_post_auth: (url, parameters) =>
    requests.request_POST(base_url + url, parameters, true),
  request_post_multipart_auth: (url, parameters, onUploadProgress) =>
    requests.request_POST_multipart(
      base_url + url,
      parameters,
      onUploadProgress,
      true
    ),
  request_put_json_auth: (url, parameters) =>
    requests.request_PUT_json(base_url + url, parameters, true),
  request_patch_auth: (url, parameters) =>
    requests.request_PATCH(base_url + url, parameters, true),
  request_patch_json_auth: (url, parameters) =>
    requests.request_PATCH_json(base_url + url, parameters, true),
  request_patch_multipart_auth: (url, parameters, onUploadProgress) =>
    requests.request_PATCH_multipart(
      base_url + url,
      parameters,
      onUploadProgress,
      true
    ),
  request_put_auth: (url, parameters, customHeaders) =>
    requests.request_PUT(base_url + url, parameters, true, customHeaders),
  request_delete_auth: (url, parameters) =>
    requests.request_DELETE(base_url + url, parameters, true),
  request_delete_body_auth: (url, body) =>
    requests.request_DELETE_body(base_url + url, body, true),
  request_get_auth_url: (url, parameters = null) =>
    requests.request_GET(url, parameters, true)
});

const api = apiFactory(process.env.REACT_APP_API_URL);
const flashApi = apiFactory(process.env.REACT_APP_FLASH_API_URL);
const analyticsApi = apiFactory(process.env.REACT_APP_ANALYTICS_API_URL);

export default api;
export { flashApi, analyticsApi };
