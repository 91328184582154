import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

import "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

export interface CheckboxProps {
  disabled?: boolean;
  checked: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  children?: React.ReactNode;
  className?: string;
  checkboxClassName?: string;
  size?: number;
  context?: string;
  otherProps?: Object;
}

const Checkbox: React.FC<CheckboxProps> = ({
  disabled,
  checked,
  onChange,
  children,
  className,
  checkboxClassName,
  size = 20,
  context = "blue",
  ...otherProps
}) => {
  const baseClass = "notme-checkbox";

  const rootClass = clsx(baseClass, className, {});

  return (
    <label className={`${rootClass} ${disabled ? "disabled" : "enabled"}`}>
      {children}
      <input
        type="checkbox"
        className={`${baseClass}--input`}
        onChange={onChange}
        checked={checked}
        disabled={disabled}
        {...otherProps}
      />
      <FontAwesomeIcon
        icon={faCheck}
        className={`${baseClass}--checkmark ${baseClass}-${context}--checkmark ${checkboxClassName}`}
        style={{ width: size + "px", height: size + "px" }}
      />
    </label>
  );
};

Checkbox.displayName = "Checkbox";

Checkbox.defaultProps = {
  disabled: false,
  checked: false,
  onChange: () => {},
  children: "",
  className: "",
  checkboxClassName: "",
  size: 20,
  context: "blue"
};

Checkbox.propTypes = {
  disabled: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  checkboxClassName: PropTypes.string,
  size: PropTypes.number,
  context: PropTypes.string
};

export default Checkbox;
