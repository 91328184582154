import {
  faTwitter,
  faLinkedin,
  faInstagram,
  faFacebookF,
  faYoutube,
  IconDefinition
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

import "./styles.scss";

const SocialLink: React.FC<{
  altTitle: string;
  icon: IconDefinition;
  url: string;
}> = ({ altTitle, icon, url }) => {
  const baseClass = "social-link";
  const rootClass = clsx(baseClass, altTitle && `${baseClass}-${altTitle}`);

  const openLink = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <div className={rootClass} onClick={() => openLink(url)}>
      <FontAwesomeIcon icon={icon} />
    </div>
  );
};

const SocialLinks: React.FC = () => {
  const social_links = [
    {
      title: "instagram",
      icon: faInstagram,
      url: "https://www.instagram.com/notme.app/"
    },
    {
      title: "facebook",
      icon: faFacebookF,
      url: "https://www.facebook.com/NotMeApp/"
    },
    {
      title: "twitter",
      icon: faTwitter,
      url: "https://twitter.com/notmeapp"
    },
    {
      title: "linkedin",
      icon: faLinkedin,
      url: "https://www.linkedin.com/company/11816589/"
    },
    {
      title: "youtube",
      icon: faYoutube,
      url: "https://www.youtube.com/channel/UCMIUm9zLfxT3NU4qKk6KjzQ"
    }
  ];

  return (
    <div className="social-links">
      {social_links.map((link, index) => (
        <SocialLink
          key={index}
          altTitle={link.title}
          icon={link.icon}
          url={link.url}
        />
      ))}
    </div>
  );
};

export default SocialLinks;
