import React, { MouseEventHandler } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

import "./styles.scss";

export interface SwitchProps {
  disabled?: boolean;
  checked: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  className?: string;
  context?: string;
  otherProps?: Object;
}

const Switch: React.FC<SwitchProps> = ({
  disabled,
  checked,
  onClick,
  className,
  context = "green",
  ...otherProps
}) => {
  const baseClass = "notme-switch";

  const rootClass = clsx(baseClass, className, {});

  return (
    <button
      className={`${rootClass} ${
        checked ? `${baseClass}-checked ${rootClass}-checked-${context}` : ""
      } `}
      onClick={onClick}
      disabled={disabled}
    >
      <span
        className={`${baseClass}-dot ${
          checked ? `${baseClass}-dot-checked` : ""
        }`}
      ></span>
    </button>
  );
};

Switch.displayName = "Switch";

Switch.defaultProps = {
  disabled: false,
  checked: false,
  onClick: () => {},
  className: "",
  context: "green"
};

Switch.propTypes = {
  disabled: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  context: PropTypes.string
};

export default Switch;
