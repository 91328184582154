import { useContext, useEffect, useState } from "react";
import "./styles.scss";

import { useModal } from "react-hooks-use-modal";
import { useTranslation } from "react-i18next";
import { useEntity } from "contexts/entity/useEntity";
import ThemeContext from "contexts/theme/ThemeContext";
import Entitlements from "models/Entitlements";
import { EntityType } from "models/app/EntityType";
import helpers from "api/helpers/helpers";
import {
  notificationError,
  notificationSuccess
} from "utils/custom_notifications";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import companyApi from "api/companyApi";

import HeaderTitle from "components/HeaderTitle";
import CenterLayout from "layouts/CenterLayout";
import EntitlementsCell from "./components/EntitlementsCell";
import EntitlementsModal from "./components/EntitlementsModal";
import EmptyData from "components/ui-kit/EmptyData";
import Button from "components/ui-kit/Buttons/Button";
import Loader from "components/ui-kit/Loader";

const EntitlementsPage = () => {
  const { t } = useTranslation();
  const { dark } = useContext(ThemeContext);
  const entity = useEntity();

  const [ActionModalContainer, openActionModal, closeActionModal, isOpen] =
    useModal("root", {
      closeOnOverlayClick: true
    });

  const [entitySelected, setEntitySelected] = useState<EntityType | null>(null);
  const [entitlements, setEntitlements] = useState<Entitlements | null>(null);
  const [initialEntitlements, setInitialEntitlements] =
    useState<Entitlements | null>(null);
  const [isEntitlementsUpdatable, setIsEntitlementsUpdatable] = useState(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [isPublishing, setIsPublishing] = useState<boolean>(false);

  useEffect(() => {
    getEntitlements();
  }, [entitySelected]);

  useEffect(() => {
    if (
      entity &&
      entity?.entitiesSelected &&
      Array.isArray(entity.entitiesSelected) &&
      entity.entitiesSelected.length > 0
    ) {
      setEntitySelected(entity.entitiesSelected[0]);
    } else {
      setEntitySelected(null);
    }
  }, [entity]);

  useEffect(() => {
    if (entitlements !== null && initialEntitlements !== null) {
      setIsEntitlementsUpdatable(
        !isEntitlementsEqual(entitlements, initialEntitlements)
      );
    }
  }, [entitlements, initialEntitlements]);

  const getEntitlements = () => {
    if (entitySelected?.id) {
      setLoading(true);
      companyApi
        .getCompanyEntitlements(entitySelected.id)
        .then((response: any) => {
          if (response.status === 200) {
            setInitialEntitlements(response.data);
            setEntitlements(response.data);
            setLoading(false);
          }
        })
        .catch((err) => {})
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const updateEntitlements = () => {
    if (entitySelected?.id) {
      setIsPublishing(true);
      companyApi
        .updateCompanyEntitlements(entitySelected.id, entitlements)
        .then((response: any) => {
          notificationSuccess(
            t("entitlements_page.entitlements_updated"),
            dark
          );
          setInitialEntitlements(entitlements);
          closeActionModal();
        })
        .catch((err) => {
          notificationError(helpers.formatErrorMessage(err), dark);
        })
        .finally(() => {
          setIsPublishing(false);
        });
    }
  };

  const isEntitlementsEqual = (
    entitlements1: Entitlements,
    entitlements2: Entitlements
  ) => {
    for (const key in entitlements1) {
      if (
        entitlements1[key as keyof Entitlements] !==
        entitlements2[key as keyof Entitlements]
      ) {
        return false;
      }
    }
    return true;
  };

  const entitlementsUpdate = (entitlement: keyof Entitlements) => {
    if (entitlements) {
      setEntitlements({
        ...entitlements,
        [entitlement]: !entitlements[entitlement]
      });
    }
  };

  const closeEntitlementsModal = () => {
    setEntitlements(initialEntitlements);
    closeActionModal();
  };

  return (
    <CenterLayout>
      <div className="entitlements-page">
        <main className="entitlements-page-header">
          <HeaderTitle
            title={
              entitySelected
                ? t("pages.settings.entitlements") +
                  " (" +
                  entitySelected?.name +
                  ")"
                : t("pages.settings.entitlements")
            }
            canGoBack={false}
          />
          {isEntitlementsUpdatable && (
            <Button onClick={openActionModal}>
              {t("action_button.update")}
            </Button>
          )}
        </main>
        <div className="entitlements-container">
          {loading &&
            entitySelected &&
            Array.from({ length: 4 }, (_, i) => (
              <Loader key={`loading-${i}`} style={{ height: "83px" }} />
            ))}

          {!loading &&
            entitlements &&
            entitySelected &&
            (Object.keys(entitlements) as (keyof Entitlements)[]).map((key) => {
              return (
                <EntitlementsCell
                  key={key as string}
                  entitlement={key}
                  title={t(`entitlements_page.entitlements_${key}_title`)}
                  description={t(
                    `entitlements_page.entitlements_${key}_description`
                  )}
                  isEnabled={entitlements[key]}
                  updateEntitlement={entitlementsUpdate}
                />
              );
            })}

          {!loading && !entitySelected && (
            <EmptyData
              title={t("entitlements_page.no_entity_selected")}
              description={t(
                "entitlements_page.no_entity_selected_description"
              )}
              icon={faUsers}
            />
          )}
        </div>
      </div>
      <EntitlementsModal
        Modal={ActionModalContainer}
        actionLoading={isPublishing}
        actionButton={updateEntitlements}
        close={closeEntitlementsModal}
      />
    </CenterLayout>
  );
};

export default EntitlementsPage;
