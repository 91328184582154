import React from "react";
import clsx from "clsx";

import "./styles.scss";
import Tag from "components/ui-kit/Tag";
import ImageLoading from "components/ui-kit/ImageLoading";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import NewsfeedPost from "models/NewsfeedPost";

export interface NewsCellProps {
  className?: string;
  layout: "featured" | "vertical" | "horizontal";
  post: NewsfeedPost;
  loading?: boolean;
}

const NewsCell: React.FC<NewsCellProps> = ({
  className,
  layout,
  post,
  loading,
  ...otherProps
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // Destructuring the post object
  const { title, thumbnail_url, type, type_name, published, user_views_count } =
    post || {}; // Make sure this won't crash if post is null/undefined

  // Define the base class for CSS
  const baseClass = "notme-news-cell";

  // Define the root class with potential variations
  const rootClass = clsx(baseClass, className, {
    [`${baseClass}--featured`]: layout === "featured",
    [`${baseClass}--vertical`]: layout === "vertical",
    [`${baseClass}--horizontal`]: layout === "horizontal",
    [`${baseClass}--clickable`]: !loading
  });

  // Function to handle the click on the cell
  const handleClick = () => {
    !loading && navigate(`/feed/posts/${post.id}`);
  };

  return (
    <div className={rootClass} {...otherProps} onClick={handleClick}>
      {/* Image container */}
      <div className={`${baseClass}__image-wrapper`}>
        <ImageLoading src={thumbnail_url} alt={title} loading={loading} />
      </div>
      {loading ? (
        <div className={`${baseClass}__content`}>
          <div
            className={`${baseClass}__skeleton ${baseClass}__skeleton--tag`}
          />
          <div
            className={`${baseClass}__skeleton ${baseClass}__skeleton--title`}
          />
        </div>
      ) : (
        <div className={`${baseClass}__content`}>
          <div className={`${baseClass}__content-header`}>
            <div className={`${baseClass}__tag-container`}>
              <Tag round className={`${baseClass}__tag`}>
                {type_name}
              </Tag>
              {!published && (
                <Tag round className={`${baseClass}__tag`} subtle>
                  {t("post_page.draft")}
                </Tag>
              )}
            </div>
            {layout === "featured" || published ? (
              <div className={`${baseClass}__date`}>
                {post ? dayjs.utc(post.created).local().fromNow() : ""}
              </div>
            ) : null}
          </div>
          <p
            className={clsx(`${baseClass}__title`, {
              [`${baseClass}__title-read`]: user_views_count > 0
            })}
          >
            {title}
          </p>
        </div>
      )}
    </div>
  );
};

export default NewsCell;
