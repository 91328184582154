import React, { useEffect, useState } from "react";
import "./styles.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faPaperPlane,
  faUser,
  faUserSecret,
  faBuilding
} from "@fortawesome/free-solid-svg-icons";
import CompanyLogo from "components/ui-kit/CompanyLogo";
import ReportStatusTag from "components/ReportStatusTag";
import { ReportInfo } from "models/ReportInfo";
import ReportHeaderInfo from "models/app/ReportHeaderInfo";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const ReportHeader: React.FC<{ report: ReportInfo }> = ({ report }) => {
  const { t } = useTranslation();
  const [headerContent, setHeaderContent] = useState<ReportHeaderInfo[]>([]);

  useEffect(() => {
    let headerContentTemp = [];
    if (report.created_at) {
      headerContentTemp.push({
        id: 1,
        icon: faCalendarAlt,
        content: dayjs(report.created_at).format("LLL")
      });
    }
    if (report.submitted_at) {
      headerContentTemp.push({
        id: 2,
        icon: faPaperPlane,
        content: dayjs(report.submitted_at).format("LLL")
      });
      if (report.is_anonymous) {
        headerContentTemp.push({
          id: 4,
          icon: faUser,
          content: t("sent_anonymously")
        });
      } else {
        headerContentTemp.push({
          id: 4,
          icon: faUserSecret,
          content: t("report_page.sent_using_identity")
        });
      }
    }
    headerContentTemp.push({
      id: 3,
      icon: faBuilding,
      content: report.company?.name || "#NotMe"
    });
    headerContentTemp.sort((a, b) => a.id - b.id);
    setHeaderContent(headerContentTemp);
  }, []);

  const ReportHeaderInfo = () => {
    return (
      <>
        <div className="report-header-info">
          {headerContent.length > 0
            ? headerContent.map((item) => (
                <div className="report-header-info-row" key={item.id}>
                  <FontAwesomeIcon
                    icon={item.icon}
                    className="report-header-info-row-icon"
                  />
                  <p className="report-header-info-row-text">{item.content}</p>
                </div>
              ))
            : null}
        </div>
      </>
    );
  };

  return (
    <div className="report-header">
      <div className="report-header-info-container">
        <div>
          <ReportStatusTag status_id={report?.status_id} />
          <ReportHeaderInfo />
        </div>
        <CompanyLogo company={report?.company} size={54} />
      </div>
    </div>
  );
};

export default ReportHeader;
