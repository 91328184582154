interface DropdownSortOption {
  sort_by: string;
  order_by: string;
}

const dropdownSortOptionToRecord = (
  option: DropdownSortOption
): Record<string, string> => {
  return Object.entries(option).reduce((acc, [key, value]) => {
    acc[key] = value.toString();
    return acc;
  }, {} as Record<string, string>);
};

export default DropdownSortOption;
export { dropdownSortOptionToRecord };
