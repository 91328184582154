import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

import "./styles.scss";
import Answer from "models/Answer";
import TagSelectItem from "./TagSelectItem";
import ExtendedAnswer from "models/app/ExtendedAnswer";

interface TagSelectProps {
  availableAnswers: ExtendedAnswer[];
  answersSelected: ExtendedAnswer[];
  setAnswersSelected: (answers: ExtendedAnswer[]) => void;

  max?: number | null;

  context?: string;
  className?: string;
}

const TagSelect: React.FC<TagSelectProps> = ({
  availableAnswers,
  answersSelected,
  setAnswersSelected,
  max,
  context = "blue",
  className
}) => {
  const baseClass = "notme-tag-select";

  const rootClass = clsx(baseClass, className);

  const selectAnswer = (answer: Answer) => {
    const answer_id = answer.id;
    let currentAnswersSelected = [...answersSelected];
    const foundIndex = currentAnswersSelected!.findIndex(
      (x) => x.id === answer_id
    );

    if (foundIndex !== -1) {
      currentAnswersSelected!.splice(foundIndex, 1);
    } else {
      if (max && answersSelected!.length >= max) {
        currentAnswersSelected = [];
      }
      let elementToAdd = availableAnswers.find((x) => x.id === answer_id);
      if (elementToAdd) {
        currentAnswersSelected.push(elementToAdd);
      }
    }
    setAnswersSelected(currentAnswersSelected);
  };

  const isAnswerSelected = (id: number) => {
    return answersSelected.find((item) => item.id === id) !== undefined
      ? true
      : false;
  };

  return (
    <div className={rootClass}>
      {availableAnswers.length > 0
        ? availableAnswers.map((answer, index) => (
            <TagSelectItem
              key={index}
              label={answer.answer}
              selected={isAnswerSelected(answer.id)}
              onClick={() => selectAnswer(answer)}
              context={context}
              color={answer.color}
              icon={answer.icon}
            />
          ))
        : null}
    </div>
  );
};

TagSelect.displayName = "TagSelect";

TagSelect.defaultProps = {
  availableAnswers: [],
  answersSelected: [],
  context: "blue",
  className: ""
};

TagSelect.propTypes = {
  availableAnswers: PropTypes.array.isRequired,
  answersSelected: PropTypes.array.isRequired,
  setAnswersSelected: PropTypes.func.isRequired,
  max: PropTypes.number,
  context: PropTypes.string,
  className: PropTypes.string
};

export default TagSelect;
