import { useState } from "react";
import "./styles.scss";
import ReportFile from "models/ReportFile";

import { useTranslation } from "react-i18next";
import { useModal } from "react-hooks-use-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import ReportEvidenceCell from "../ReportEvidenceCell";
import file_utils from "utils/file_utils";

const ReportEvidenceList: React.FC<{
  evidenceList: ReportFile[];
}> = ({ evidenceList }) => {
  const { t } = useTranslation();
  const [selectedEvidence, setSelectedEvidence] = useState<ReportFile | null>(
    null
  );

  const [Modal, open, close, isOpen] = useModal("root", {
    closeOnOverlayClick: true
  });

  const previewFile = (evidence: ReportFile) => {
    let file_type = file_utils.getFileTypeFromURL(evidence.file);
    if (file_utils.isImage(file_type)) {
      openModal(evidence);
    } else {
      downloadAction(evidence.file);
    }
  };

  const openModal = (evidence: ReportFile) => {
    setSelectedEvidence(evidence);
    open();
  };

  const downloadAction = (file: any) => {
    window.open(file, "_blank");
  };

  return (
    <div className="report-evidence-list">
      {evidenceList.length > 0
        ? evidenceList.map((evidence, index) => (
            <ReportEvidenceCell
              evidence={evidence}
              key={index}
              onClick={() => previewFile(evidence)}
            />
          ))
        : null}
      <Modal>
        <div className="notme-modal evidence-modal">
          <div className="notme-modal-header">
            <h1>{t("report_page.evidence") + " #" + selectedEvidence?.id}</h1>
            <span className="notme-modal-header-close" onClick={close}>
              <FontAwesomeIcon icon={faTimes} className="times-icon" />
            </span>
          </div>
          <div className="evidence-body">
            <img className="evidence-img" src={selectedEvidence?.file} />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ReportEvidenceList;
