import React, { useState, useContext } from "react";

import { useAuth } from "contexts/auth/useAuth";
import { useTranslation } from "react-i18next";
import ThemeContext from "contexts/theme/ThemeContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  notificationError,
  notificationSuccess
} from "utils/custom_notifications";
import permissions_utils from "utils/permissions_utils";
import helpers from "api/helpers/helpers";
import challengeApi from "api/challengeApi";

import Input from "components/ui-kit/Input";
import Button from "components/ui-kit/Buttons/Button";
import { CountryData } from "react-phone-input-2";
import { isValidPhoneNumber, CountryCode } from "libphonenumber-js";

const EditPhoneNumberModal: React.FC<{
  Modal: React.FC<{
    children: React.ReactNode;
  }>;
  close: () => void;
}> = ({ Modal, close }) => {
  const auth = useAuth();
  const { dark } = useContext(ThemeContext);
  const { t } = useTranslation();

  const [phoneNumber, setPhoneNumber] = useState("");
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState("");
  const [isValidPhone, setIsValidPhone] = useState(
    isValidPhoneNumber(phoneNumber)
  );

  const [code, setCode] = useState("");

  const [challenge, setChallenge] = useState(false);

  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    setPhoneNumber("");
    setFormattedPhoneNumber("");
    setCode("");
    setChallenge(false);
    close();
  };

  const challengePhoneNumber = () => {
    setLoading(true);
    postPhoneRequest()
      .then((response) => {
        setChallenge(true);
      })
      .catch((err) => {
        notificationError(helpers.formatErrorMessage(err), dark);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSendCode = () => {
    setLoading(true);
    postPhoneVerifyRequest()
      .then((response) => {
        auth?.getUser().then((res) => {
          notificationSuccess(t("profile_page.phone_number_edited"), dark);
          closeModal();
        });
      })
      .catch((err) => {
        notificationError(helpers.formatErrorMessage(err), dark);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const postPhoneRequest = () => {
    const phone = "+" + phoneNumber.replace(/\s/g, "");
    if (can("view dashboard")) {
      return challengeApi.postPhoneDashboard(phone);
    } else {
      return challengeApi.postPhone(phone);
    }
  };

  const postPhoneVerifyRequest = () => {
    if (can("view dashboard")) {
      return challengeApi.postPhoneDashboardVerify(code);
    } else {
      return challengeApi.postPhoneVerify(code);
    }
  };

  const sendCodeButtonDisabled = () => {
    return code.length !== 6;
  };

  const challengePhoneNumberButtonDisabled = () => {
    return !isValidPhone;
  };

  const onPhoneChange = (
    value: any,
    data: any,
    event: any,
    formattedValue: any
  ) => {
    setPhoneNumber(value);
    setFormattedPhoneNumber(formattedValue);
    setIsValidPhone(
      isValidPhoneNumber(
        formattedValue,
        (data as CountryData).countryCode.toUpperCase() as CountryCode
      )
    );
  };

  const can = (permission: string) => {
    return permissions_utils.can(permission, auth);
  };

  return (
    <Modal>
      <div className="notme-modal">
        <div className="notme-modal-header">
          <h1>{t("profile_page.edit_phone_number")}</h1>
          <span className="notme-modal-header-close" onClick={closeModal}>
            <FontAwesomeIcon icon={faTimes} className="times-icon" />
          </span>
        </div>
        {!challenge ? (
          <div className="notme-modal-body-content-column">
            <h5>{t("authentication_pages.check_phone_confirm_text")}</h5>
            <Input
              type="phone"
              inputType="phone"
              label={t("user_data.phone_number")}
              value={phoneNumber}
              onPhoneChange={onPhoneChange}
              onEnterPressed={() =>
                !challengePhoneNumberButtonDisabled() &&
                !loading &&
                challengePhoneNumber()
              }
            />
            <div className="notme-modal-footer">
              <Button
                context="blue"
                onClick={challengePhoneNumber}
                disabled={challengePhoneNumberButtonDisabled()}
                loading={loading}
              >
                {t("authentication_pages.check_phone_text_button")}
              </Button>
              <Button context="blue" outline onClick={closeModal}>
                {t("action_button.cancel")}
              </Button>
            </div>
          </div>
        ) : (
          <div className="notme-modal-body-content-column">
            <h6>{t("authentication_pages.confirmation_code_sent")}</h6>
            <Input
              type="code"
              maxLength={6}
              label={t("authentication_pages.confirmation_code")}
              value={code}
              onChange={(e) => setCode(e.target.value)}
              onEnterPressed={() =>
                !sendCodeButtonDisabled() && !loading && handleSendCode()
              }
            />
            <div className="notme-modal-footer">
              <Button
                onClick={handleSendCode}
                loading={loading}
                disabled={sendCodeButtonDisabled()}
              >
                {t("authentication_pages.verify_confirmation_code")}
              </Button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default EditPhoneNumberModal;
