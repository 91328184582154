import React, { useMemo } from "react";
import "./styles.scss";
import clsx from "clsx";

import { useAuth } from "contexts/auth/useAuth";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import Voice from "models/Voice";
import Company from "models/Company";
import User from "models/User";
import CompanyLogo from "components/ui-kit/CompanyLogo";
import Tag from "components/ui-kit/Tag";
import Loader from "components/ui-kit/Loader";
import {
  getPriority,
  getStatusLabel,
  statusToContext
} from "utils/voice_utils";
import permissions_utils from "utils/permissions_utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faUserShield } from "@fortawesome/free-solid-svg-icons";

interface VoiceCellProps {
  voice?: Voice;
  company?: Company | null;
  user?: User | null;
  loading?: boolean;
  isConflict?: boolean;
}

const VoiceCell: React.FC<VoiceCellProps> = ({
  voice,
  company,
  user,
  loading,
  isConflict = false
}) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const navigate = useNavigate();

  const displayName = useMemo(() => {
    if (voice?.is_anonymous) return t("sent_anonymously");
    if (user?.firstname && user?.lastname)
      return user.firstname + " " + user.lastname;
    return null; // Return null if there's no appropriate name to display
  }, [voice, user]);

  const voicePriority = useMemo(() => {
    return voice?.priority !== null && voice?.priority !== undefined
      ? getPriority(voice.priority)
      : null;
  }, [voice]);

  if (loading || !voice) {
    return <Loader style={{ height: "70px", width: "100%" }} />;
  }

  const handleClick = () => {

    if (isConflict) {
      !loading && navigate(`/conflict-of-interest/${voice.id}`);
    }
    else {
      !loading && navigate(`/just-sayin/${voice.id}`);
    }

  };

  const can = (permission: string) => {
    return permissions_utils.can(permission, auth);
  };

  return (
    <div className="voice-cell" onClick={handleClick}>
      <div className="voice-cell__left-section">
        <h4>{voice.title}</h4>
        <div className="voice-cell__details">
          <div className="voice-cell__tags">
            <Tag round className="voice-cell__tag" subtle context="grey">
              {voice.type}
            </Tag>
            {voicePriority && (
              <Tag round className="voice-cell__status-tag" subtle>
                <FontAwesomeIcon
                  icon={voicePriority.icon}
                  color={`var(--${voicePriority.color})`}
                />
              </Tag>
            )}
          </div>
          <div className="voice-cell__date-container">
            <span className="voice-cell__header-date">
              {dayjs(voice.created).format("ll")}
            </span>
            {(voice.is_anonymous || displayName) && (
              <div className="voice-cell__user">
                <FontAwesomeIcon
                  icon={voice.is_anonymous ? faUserShield : faUser}
                />
                {displayName && (
                  <span
                    className={clsx("voice-cell__user-name", {
                      "voice-cell__user-anonymous": voice.is_anonymous
                    })}
                  >
                    {displayName}
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="voice-cell__right-section">
        <Tag
          round
          className="voice-cell__tag"
          context={statusToContext[voice.status]}
          subtle
        >
          {t(getStatusLabel(voice.status, can("view company voices")))}
        </Tag>
        <CompanyLogo size={42} company={company ?? undefined} />
      </div>
    </div>
  );
};

export default VoiceCell;
