import React from "react";
import "./styles.scss";

interface CenterLayoutProps {
  children: React.ReactNode;
}

const CenterLayout: React.FC<CenterLayoutProps> = ({ children }) => {
  return <div className="content-wrapper">{children}</div>;
};

export default CenterLayout;
