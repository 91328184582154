import { useContext, useEffect, useState } from "react";
import "./styles.scss";

import { useTranslation } from "react-i18next";
import {
  useLocation,
  useOutletContext,
  useSearchParams
} from "react-router-dom";
import { useModal } from "react-hooks-use-modal";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import ThemeContext from "contexts/theme/ThemeContext";
import UserSimple from "models/UserSimple";
import Invite from "models/Invite";
import PermissionUsers from "models/PermissionUsers";
import PermissionInvites from "models/PermissionInvites";
import url_utils from "utils/url_utils";
import { notificationError } from "utils/custom_notifications";
import helpers from "api/helpers/helpers";
import companyApi from "api/companyApi";

import MemberCell from "./components/MemberCell";
import InviteCell from "./components/InviteCell";
import Pagination from "components/ui-kit/Pagination";
import Loading from "components/ui-kit/Loading";
import EmptyData from "components/ui-kit/EmptyData";
import ResendInviteModal from "./components/ResendInviteModal";
import DeleteInviteModal from "./components/DeleteInviteModal";
import PermissionEditModal from "./components/PermissionEditModal";
import DeleteMemberModal from "./components/DeleteMemberModal";

const PermissionsUsersPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { pathname } = location;
  const { dark } = useContext(ThemeContext);

  const type = pathname.split("/").pop();

  const LIMIT = 10;

  const [permissionData, setPermissionData] = useState<
    PermissionUsers | PermissionInvites
  >();
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [selectedInvite, setSelectedInvite] = useState<Invite | null>(null);
  const [selectedMember, setSelectedMember] = useState<UserSimple | null>(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const [updateCount, shouldRefresh, setShouldRefresh]: any[] =
    useOutletContext();

  const [
    PermissionEditModalContainer,
    openPermissionEditModal,
    closePermissionEditModal
  ] = useModal("root", {
    closeOnOverlayClick: false
  });

  const [
    DeleteMemberModalContainer,
    openDeleteMemberModal,
    closeDeleteMemberModal
  ] = useModal("root", {
    closeOnOverlayClick: false
  });

  const [
    ResendInviteModalContainer,
    openResendInviteModal,
    closeResendInviteModal
  ] = useModal("root", {
    closeOnOverlayClick: true
  });

  const [
    DeleteInviteModalContainer,
    openDeleteInviteModal,
    closeDeleteInviteModal
  ] = useModal("root", {
    closeOnOverlayClick: true
  });

  useEffect(() => {
    const offset = searchParams.get("offset");
    if (offset) {
      const offsetValue = parseInt(offset);
      setCurrentPage(offsetValue / LIMIT + 1);
    }
    getPermissionsUsers();
  }, []);

  useEffect(() => {
    if (isFirstLoad) {
      return;
    }
    if (searchParams.size === 4) {
      getPermissionsUsers();
    } else {
      setCurrentPage(1);
      updatePaginationSearchParams();
    }
  }, [searchParams]);

  useEffect(() => {
    if (isFirstLoad) {
      return;
    }
    if (searchParams.get("offset") === "0") {
      getPermissionsUsers();
    } else {
      setSearchParams({
        ...url_utils.URLSearchParamsToObject(searchParams),
        offset: "0"
      });
      setCurrentPage(1);
    }
  }, [pathname]);

  useEffect(() => {
    if (isFirstLoad) {
      return;
    }
    const offset = searchParams.get("offset");
    if (currentPage === 0) {
      if (offset) {
        const offsetValue = parseInt(offset);
        setCurrentPage(offsetValue / LIMIT + 1);
      } else {
        setCurrentPage(1);
      }
    } else {
      updatePaginationSearchParams();
    }
  }, [currentPage]);

  useEffect(() => {
    if (shouldRefresh == true) {
      getPermissionsUsers();
      setShouldRefresh(false);
    }
  }, [shouldRefresh]);

  const updatePaginationSearchParams = () => {
    const offset = (currentPage - 1) * LIMIT;

    setSearchParams({
      ...url_utils.URLSearchParamsToObject(searchParams),
      offset: offset.toString(),
      limit: LIMIT.toString()
    });
  };

  const getPermissionsUsers = async () => {
    setIsLoading(true);
    const params = Array.from(searchParams).reduce(
      (obj: Record<string, string>, [key, value]) => {
        obj[key] = value;
        return obj;
      },
      {}
    );

    if (type === "invited" && params["sort_by"] === "date_joined") {
      params["sort_by"] = "created_at";
    }

    if (type === "invited" && params["sort_by"] === "name") {
      params["sort_by"] = "email";
    }

    if (type) {
      try {
        const permissionsResponse = await getPermissionsUsersRequest(
          type,
          params
        );

        if (type === "members") {
          setPermissionData(permissionsResponse.data);
        } else {
          setPermissionData({
            page: permissionsResponse.data.page,
            users: permissionsResponse.data.invites
          });
        }
      } catch (error) {
        notificationError(helpers.formatErrorMessage(error), dark);
      } finally {
        setIsLoading(false);
        if (isFirstLoad) {
          setIsFirstLoad(false);
        }
      }
    }
  };

  const getPermissionsUsersRequest = (
    type: string,
    params?: Record<string, string>
  ) => {
    if (type === "members") {
      return companyApi.getCompanyDashboardUsers(params);
    } else {
      return companyApi.getCompanyDashboardInvited(params);
    }
  };

  const isInvite = (users: UserSimple[] | Invite[]): users is Invite[] => {
    return (users as Invite[])[0].email !== undefined;
  };

  const showEditMemberModal = (member: UserSimple) => {
    setSelectedMember(member);
    openPermissionEditModal();
  };

  const showDeleteMemberModal = (member: UserSimple) => {
    setSelectedMember(member);
    openDeleteMemberModal();
  };

  const showEditInviteModal = (invite: Invite) => {
    setSelectedInvite(invite);
    openPermissionEditModal();
  };

  const showResendInviteModal = (invite: Invite) => {
    setSelectedInvite(invite);
    openResendInviteModal();
  };

  const showDeleteInviteModal = (invite: Invite) => {
    setSelectedInvite(invite);
    openDeleteInviteModal();
  };

  const updateInvitedList = () => {
    getPermissionsUsers();
    updateCount("invited", false);
  };

  const MembersList = () => {
    if (
      isLoading ||
      !permissionData ||
      !permissionData?.users ||
      permissionData?.users.length === 0
    ) {
      return (
        <EmptyData
          title={t("settings_sections.permissions.no_members")}
          icon={faUsers}
        />
      );
    }
    return (
      <div className="permissions-users-list">
        {permissionData.users.map((user) => {
          if (isInvite(permissionData?.users)) {
            let invite = user as Invite;
            return (
              <InviteCell
                key={invite.id}
                invite={invite}
                showEditInviteModal={showEditInviteModal}
                showResendInviteModal={showResendInviteModal}
                showDeleteInviteModal={showDeleteInviteModal}
              />
            );
          } else {
            let member = user as UserSimple;
            return (
              <MemberCell
                key={member.id}
                member={member}
                showEditMemberModal={showEditMemberModal}
                showDeleteMemberModal={showDeleteMemberModal}
              />
            );
          }
        })}
      </div>
    );
  };

  const closeEditModal = () => {
    closePermissionEditModal();
    setSelectedInvite(null);
    setSelectedMember(null);
  };

  return (
    <div className="permissions-users-page">
      {isLoading && <Loading />}

      {!isLoading && permissionData && (
        <div className="permissions-users-container">
          <MembersList />

          <Pagination
            currentPage={currentPage}
            totalPages={permissionData?.page.total_pages}
            onPageChange={setCurrentPage}
          />
        </div>
      )}
      {selectedInvite && (
        <ResendInviteModal
          Modal={ResendInviteModalContainer}
          close={closeResendInviteModal}
          invite={selectedInvite}
        />
      )}
      {selectedInvite && (
        <DeleteInviteModal
          Modal={DeleteInviteModalContainer}
          close={closeDeleteInviteModal}
          invite={selectedInvite}
          updateInvitedList={updateInvitedList}
        />
      )}
      <PermissionEditModal
        Modal={PermissionEditModalContainer}
        close={closeEditModal}
        invite={selectedInvite}
        updateList={getPermissionsUsers}
        member={selectedMember}
      />
      {selectedMember && (
        <DeleteMemberModal
          Modal={DeleteMemberModalContainer}
          member={selectedMember}
          updateList={getPermissionsUsers}
          close={closeDeleteMemberModal}
        />
      )}
    </div>
  );
};

export default PermissionsUsersPage;
