import React, { useState, useLayoutEffect } from "react";

const ThemeContext = React.createContext({
  sidebarExpanded: true,
  toggleExpandSidebar: () => {},
  showSidebar: false,
  toggleShowSidebar: () => {},
  dark: false,
  toggleDarkMode: () => {}
});

export default ThemeContext;

export function ThemeProvider(props: any) {
  // keeps state of the current theme
  const [dark, setDark] = useState(false);

  const initialSidebarExpanded = localStorage.getItem("sidebarExpanded")
    ? JSON.parse(localStorage.getItem("sidebarExpanded") || "")
    : true;

  const [sidebarExpanded, setSidebarExpanded] = useState(
    initialSidebarExpanded
  );
  const [showSidebar, setShowSidebar] = useState(false);

  // paints the app before it renders elements
  useLayoutEffect(() => {
    let lastTheme = window.localStorage.getItem("darkTheme");

    if (lastTheme === null) {
      let userTheme = window.matchMedia("(prefers-color-scheme: dark)").matches;
      lastTheme = userTheme.toString();
    }

    if (lastTheme === "true") {
      setDark(true);
      applyTheme(darkTheme);
    } else {
      setDark(false);
      applyTheme(lightTheme);
    }
    // if state changes, repaints the app
  }, [dark]);

  // rewrites set of css variablels/colors
  const applyTheme = (theme: any) => {
    const root = document.getElementsByTagName("html")[0];
    root.style.cssText = theme.join(";");
    if (dark) {
      document
        .querySelector('meta[name="theme-color"]')
        ?.setAttribute("content", "#121212");
    } else {
      document
        .querySelector('meta[name="theme-color"]')
        ?.setAttribute("content", "#FFFFFF");
    }
  };

  const toggleDarkMode = () => {
    const body = document.getElementsByTagName("body")[0];
    body.style.cssText = "transition: background .5s ease";

    setDark(!dark);
    window.localStorage.setItem("darkTheme", (!dark).toString());
  };

  const toggleExpandSidebar = () => {
    setSidebarExpanded(!sidebarExpanded);
    localStorage.setItem("sidebarExpanded", JSON.stringify(!sidebarExpanded));
  };

  const toggleShowSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  return (
    <ThemeContext.Provider
      value={{
        sidebarExpanded,
        toggleExpandSidebar,
        showSidebar,
        toggleShowSidebar,
        dark,
        toggleDarkMode
      }}
    >
      {props.children}
    </ThemeContext.Provider>
  );
}

// styles
const lightTheme = [
  //Texts
  "--text: #001B39",
  "--subtitle: #637482",

  //App
  "--background: #F7F7F7",
  "--card: #FFFFFF",
  "--border: #d7d7d9",
  "--shadow: 0px 0px 7px 1px #e4e4e4;",

  //Colors
  "--blue: #1F5688",
  "--dark_blue: #001B39",
  "--light_blue: #1c78a5",
  "--red: #C1473A",
  "--dark_red: #441313",
  "--light_red: #C69C85",
  "--green: #5F9E83",
  "--dark_green: #3A6E58",
  "--yellow: #D8AD00",
  "--orange: #C47300",
  "--grey: #637482",
  "--dark_grey: #787070",
  "--light_grey: #F5F6FA",
  "--lighter_grey: #F0F0F0",
  "--white: #FFFFFF",
  "--cream: #F8EBEA",

  //Colors a10
  "--blue-a10: #1F56881A",
  "--dark_blue-a10: #001B391A",
  "--light_blue-a10: #1c78a51A",
  "--red-a10: #C1473A1A",
  "--dark_red-a10: #4413131A",
  "--light_red-a10: #C69C851A",
  "--green-a10: #5F9E831A",
  "--dark_green-a10: #3A6E581A",
  "--yellow-a10: #D8AD001A",
  "--orange-a10: #C473001A",
  "--grey-a10: #6374821A",
  "--dark_grey-a10: #7870701A",
  "--light_grey-a10: #F5F6FA1A",

  "--toastify-icon-color-info: #1F5688",
  "--toastify-icon-color-success: #5F9E83",
  "--toastify-icon-color-warning: #1F5688",
  "--toastify-icon-color-error: #C1473A"
];

const darkTheme = [
  //Texts
  "--text: #FFFFFF",
  "--subtitle: #6d7f8d",

  //App
  "--background: #000000",
  "--card: #121212",
  "--border: #434248",
  "--shadow: none",

  //Colors
  "--blue: #4c78a0",
  "--dark_blue: #556377",
  "--light_blue: #96C3D9",
  "--red: #C1473A",
  "--dark_red: #441313",
  "--light_red: #C69C85",
  "--green: #5F9E83",
  "--dark_green: #3A6E58",
  "--yellow: #D8AD00",
  "--orange: #C47300",
  "--grey: #6d7f8d",
  "--dark_grey: #979797",
  "--light_grey: #F5F6FA",
  "--lighter_grey: #6d7f8d",
  "--white: #FFFFFF",
  "--cream: #270E0C",

  //Colors a10
  "--blue-a10: #4c78a01A",
  "--dark_blue-a10: #5563771A",
  "--light_blue-a10: #96C3D91A",
  "--red-a10: #C1473A1A",
  "--dark_red-a10: #4413131A",
  "--light_red-a10: #C69C851A",
  "--green-a10: #5F9E831A",
  "--dark_green-a10: #3A6E581A",
  "--yellow-a10: #D8AD001A",
  "--orange-a10: #C473001A",
  "--grey-a10: #6d7f8d1A",
  "--dark_grey-a10: #9797971A",
  "--light_grey-a10: #F5F6FA1A",

  "--toastify-icon-color-info: #1F5688",
  "--toastify-icon-color-success: #5F9E83",
  "--toastify-icon-color-warning: #1F5688",
  "--toastify-icon-color-error: #C1473A"
];
