import { useState, useContext } from "react";
import "./styles.scss";

import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ThemeContext from "../../contexts/theme/ThemeContext";
import {
  notificationError,
  notificationSuccess
} from "utils/custom_notifications";
import helpers from "api/helpers/helpers";
import userApi from "api/userApi";
import regex_utils from "utils/regex_utils";

import AuthLayout, { AuthQuery } from "layouts/AuthLayout";
import Input from "components/ui-kit/Input";
import Button from "components/ui-kit/Buttons/Button";

function ForgotPasswordPage() {
  const { dark } = useContext(ThemeContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const locationState = useLocation().state as AuthQuery;
  const canGoBack =
    locationState && locationState.canGoBack ? locationState.canGoBack : false;

  const [email, setEmail] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const handleSendEmail = () => {
    setIsLoading(true);
    userApi
      .postRecover(email)
      .then((response) => {
        if (response.status === 200) {
          notificationSuccess(t("authentication_pages.message_sent"), dark, {
            autoClose: 5000
          });
          navigate(-1);
        }
      })
      .catch((err) => {
        notificationError(helpers.formatErrorMessage(err), dark);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const sendEmailButtonDisabled = () => {
    return email.length === 0 || regex_utils.emailRegex.test(email) === false;
  };

  return (
    <AuthLayout
      title={t("authentication_pages.forgot_password")}
      className="forgot-password-page"
      canGoBack={canGoBack}
    >
      <h6>{t("authentication_pages.email_reset_instruction")}</h6>
      <div className="auth-layout-inputs">
        <Input
          type="email"
          label={t("user_data.email")}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onEnterPressed={() =>
            !sendEmailButtonDisabled() && !isLoading && handleSendEmail()
          }
        />
      </div>
      <div className="auth-layout-buttons">
        <Button
          full
          onClick={handleSendEmail}
          loading={isLoading}
          disabled={sendEmailButtonDisabled()}
        >
          {t("authentication_pages.send_email")}
        </Button>
      </div>
    </AuthLayout>
  );
}

export default ForgotPasswordPage;
