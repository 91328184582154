export const getCompanyToFetch = (
  entities: any,
  defaultCompanyId: number | undefined
) => {
  return entities?.entitiesSelected &&
    Array.isArray(entities.entitiesSelected) &&
    entities.entitiesSelected.length > 0
    ? entities.entitiesSelected[0].id
    : defaultCompanyId;
};

export const shouldFilterByCompany = (
  entities: any,
  can: (permission: string) => boolean
) => {
  return (
    !can("update any post") ||
    (entities?.entitiesSelected && entities?.entitiesSelected.length > 0)
  );
};
