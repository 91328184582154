import React, { useState, useContext, useEffect, useRef } from "react";
import "./styles.scss";

import { useTranslation } from "react-i18next";
import ThemeContext from "contexts/theme/ThemeContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faTimes,
  faWarning
} from "@fortawesome/free-solid-svg-icons";
import {
  notificationError,
  notificationSuccess
} from "utils/custom_notifications";
import file_utils from "utils/file_utils";
import helpers from "api/helpers/helpers";
import companyApi from "api/companyApi";

import Button from "components/ui-kit/Buttons/Button";
import InformationBanner from "components/InformationBanner";
import InformationBannerType from "models/app/InformationBannerType";
import AvatarEditor from "react-avatar-editor";

const MAX_LOGO_SIZE = 2;

const OrganizationLogoModal: React.FC<{
  Modal: React.FC<{
    children: React.ReactNode;
  }>;
  close: () => void;
  company_id: number | null;
  file: File;
  updateLogo: () => void;
}> = ({ Modal, close, company_id = null, file, updateLogo }) => {
  const { dark } = useContext(ThemeContext);
  const { t } = useTranslation();

  const i18n_page = "settings_sections.general";

  const editor = useRef<AvatarEditor>(null);

  const [isLogoSizeValid, setIsLogoSizeValid] = useState(true);
  const [loading, setLoading] = useState(false);

  const [scale, setScale] = useState(1);

  useEffect(() => {
    if (file) {
      updateLogoSizeValid();
    }
  }, [file]);

  const closeModal = () => {
    setScale(1);
    close();
  };

  const editLogo = async () => {
    let logo = getResizedLogo();

    if (!logo || !isLogoValid(logo)) {
      notificationError(t(`${i18n_page}.logo_exceeds_size`), dark);
      return;
    }

    let formData = new FormData();
    formData.append("logo", logo);
    formData.append("_method", "PUT");

    setLoading(true);
    try {
      const response = await companyApi.updateSubcompanyLogo(
        formData,
        company_id
      );
      if (response.status === 200) {
        notificationSuccess(t(`${i18n_page}.organization_logo_updated`), dark);
        updateLogo();
      }
    } catch (err) {
      notificationError(helpers.formatErrorMessage(err), dark);
    } finally {
      closeModal();
      setLoading(false);
    }
  };

  const updateLogoSizeValid = () => {
    const logo = getResizedLogo();
    if (logo) {
      setIsLogoSizeValid(isLogoValid(logo));
    }
  };

  const getResizedLogo = () => {
    const imageScaledToCanvas = editor.current
      ?.getImageScaledToCanvas()
      .toDataURL("image/png", 1);
    if (imageScaledToCanvas) {
      const file = file_utils.dataURItoFile(imageScaledToCanvas, "logo.png");

      return file;
    }
    return null;
  };

  const isLogoValid = (file: File) => {
    return file && file.size / 1024 / 1024 < MAX_LOGO_SIZE;
  };

  const handleScale = (e: React.ChangeEvent<HTMLInputElement>) => {
    const scale = parseFloat(e.target.value);
    setScale(scale);
  };

  const handleScaleEnd = () => {
    updateLogoSizeValid();
  };

  return (
    <Modal>
      <div className="notme-modal organization-logo-modal">
        <div className="notme-modal-header">
          <h1>{t(`${i18n_page}.organization_logo`)}</h1>
          <span className="notme-modal-header-close" onClick={closeModal}>
            <FontAwesomeIcon icon={faTimes} className="times-icon" />
          </span>
        </div>
        <InformationBanner
          icon={faInfoCircle}
          title={t(`${i18n_page}.organization_logo_description`, {
            size: MAX_LOGO_SIZE
          })}
          type={InformationBannerType.information}
        />

        {!isLogoSizeValid && (
          <InformationBanner
            icon={faWarning}
            title={t(`${i18n_page}.logo_exceeds_size`, {
              size: MAX_LOGO_SIZE
            })}
            type={InformationBannerType.alert}
          />
        )}
        {file && (
          <>
            <AvatarEditor
              ref={editor}
              image={file}
              width={340}
              height={340}
              border={10}
              color={[255, 255, 255, 0.6]}
              scale={scale}
              rotate={0}
            />
            <input
              name="scale"
              type="range"
              onChange={handleScale}
              onMouseUp={handleScaleEnd}
              onBlur={handleScaleEnd}
              min="1"
              max="2"
              step="0.01"
              defaultValue="1"
            />
          </>
        )}

        <div className="notme-modal-footer">
          <Button
            onClick={editLogo}
            disabled={!isLogoSizeValid}
            loading={loading}
          >
            {t("action_button.save")}
          </Button>
          <Button outline onClick={closeModal}>
            {t("action_button.cancel")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default OrganizationLogoModal;
