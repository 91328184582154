const urls = {
  authentication: {
    BASE: "user/",
    LOGIN: "login",
    LOGOUT: "logout",
    SIGNUP: "signup",
    TERMS: "terms",
    PRIVACY: "privacy",
    ACCEPT: "accept",
    ACTIVE: "active",
    WELCOME: "welcome",
    RECOVER: "recover",
    VERIFY: "verify",
    USER: "user",
    DATA: "data",
    ARCHIVE: "archive",
    VALIDATE: "validate",
    PERMISSIONS: "permissions"
  },
  dashboard: {
    BASE: "dashboard/"
  },
  analytics: {
    BASE: "analytics/",
    GRAPHS: "graphs",
    ADMIN: "admin",
    ALL: "all"
  },
  company: {
    BASE: "company/",
    VALIDATE: "validate",
    EMAIL: "email",
    JOIN: "join",
    EXISTING: "existing",
    USERS: "users",
    COMPANIES: "companies",
    INVITES: "invites",
    ENTITLEMENTS: "entitlements"
  },
  report: {
    BASE: "report/",
    CONTENT: "content",
    WHAT: "what",
    HISTORY: "history",
    STEPS: "steps",
    STEP: "step",
    INTRO: "intro",
    REPORTS: "reports"
  },
  feed: {
    BASE: "feed/",
    POST: "post/"
  },
  challenge: {
    BASE: "challenge/",
    MFA: "2fa",
    PHONE: "phone",
    VERIFY: "verify"
  },
  notifications: {
    BASE: "notifications"
  },
  posts: {
    BASE: "posts/",
    POSTS: "posts",
    TYPES: "post-types",
    ALL_TYPES: "all-post-types",
    VIEWS: "views"
  },
  voices: {
    BASE: "voices/",
    VOICES: "voices",
    ALL_VOICE_TYPES: "all-voice-types"
  },
  flash: {
    COMMENT: "comment",
    REACT: "react"
  }
};

const constants = {
  LOGIN_URL: `${urls.authentication.BASE}${urls.authentication.LOGIN}`,
  LOGOUT_URL: `${urls.authentication.BASE}${urls.authentication.LOGOUT}`,
  SIGNUP_URL: `${urls.authentication.BASE}${urls.authentication.SIGNUP}`,
  VALIDATE_SIGNUP_URL: `${urls.authentication.BASE}${urls.authentication.SIGNUP}/${urls.authentication.VALIDATE}`,
  TERMS_URL: `${urls.authentication.BASE}${urls.authentication.TERMS}`,
  PRIVACY_URL: `${urls.authentication.BASE}${urls.authentication.PRIVACY}`,
  USER_URL: `${urls.authentication.USER}`,
  DASHBOARD_USER_URL: `${urls.dashboard.BASE}${urls.authentication.USER}`,
  USER_PERMISSIONS_URL: `${urls.authentication.BASE}${urls.authentication.PERMISSIONS}`,
  ACCEPT_TERMS_URL: `${urls.authentication.BASE}${urls.authentication.TERMS}/${urls.authentication.ACCEPT}`,
  ACCEPT_PRIVACY_URL: `${urls.authentication.BASE}${urls.authentication.PRIVACY}/${urls.authentication.ACCEPT}`,
  ACTIVE_URL: `${urls.authentication.BASE}${urls.authentication.ACTIVE}`,
  RECOVER_URL: `${urls.authentication.BASE}${urls.authentication.RECOVER}`,
  CHECK_COMPANY_URL: `${urls.company.BASE}${urls.company.VALIDATE}`,
  ADD_COMPANY_URL: `${urls.company.BASE}${urls.company.JOIN}`,
  REPORTS_URL: `${urls.report.BASE}`,
  REMOVE_COMPANY_URL: `${urls.authentication.BASE}${urls.company.BASE}`,
  VERIFY_MAIL_URL: `${urls.authentication.BASE}${urls.authentication.VERIFY}`,
  USER_DATA_URL: `${urls.authentication.BASE}${urls.authentication.ARCHIVE}`,
  INTRO_URL: `${urls.company.BASE}${urls.report.INTRO}`,
  CREATE_REPORT_URL: `${urls.report.BASE}`,
  STEPS_URL: `${urls.report.STEPS}`,
  REPORT_URL: `${urls.report.BASE}`,
  REPORTS_DASHBOARD_URL: `${urls.dashboard.BASE}${urls.report.REPORTS}`,
  FEED_URL: `${urls.feed.BASE}`,
  MFA_URL: `${urls.challenge.BASE}${urls.challenge.MFA}`,
  PHONE_URL: `${urls.challenge.BASE}${urls.challenge.PHONE}`,
  PHONE_VERIFY_URL: `${urls.challenge.BASE}${urls.challenge.PHONE}/${urls.challenge.VERIFY}`,
  PHONE_DASHBOARD_URL: `${urls.dashboard.BASE}${urls.challenge.BASE}${urls.challenge.PHONE}`,
  PHONE_DASHBOARD_VERIFY_URL: `${urls.dashboard.BASE}${urls.challenge.BASE}${urls.challenge.PHONE}/${urls.challenge.VERIFY}`,
  NOTIFICATIONS_URL: `${urls.notifications.BASE}`,
  USER_COMPANY_URL: `${urls.company.BASE}`,
  COMPANY_URL: `${urls.dashboard.BASE}${urls.company.BASE}`,
  COMPANY_USERS_URL: `${urls.dashboard.BASE}${urls.company.BASE}${urls.company.USERS}`,
  COMPANY_USERS_INVITES_URL: `${urls.dashboard.BASE}${urls.company.BASE}${urls.company.USERS}/${urls.company.INVITES}`,
  COMPANIES_URL: `${urls.dashboard.BASE}${urls.company.COMPANIES}`,
  COMPANIES_USERS_URL: `${urls.company.COMPANIES}/`,
  ENTITLEMENTS_URL: `${urls.company.ENTITLEMENTS}`,
  POSTS_BASE_URL: `${urls.posts.BASE}`,
  POSTS_URL: `${urls.posts.POSTS}`,
  POST_TYPES_URL: `${urls.posts.TYPES}`,
  ALL_POST_TYPES_URL: `${urls.posts.ALL_TYPES}`,
  POST_VIEWS_URL: `${urls.posts.VIEWS}`,
  VOICES_BASE_URL: `${urls.voices.BASE}`,
  FLASH_COMMENTS_URL: `${urls.flash.COMMENT}`,
  FLASH_REACT_URL: `${urls.flash.REACT}`,
  VOICES_URL: `${urls.voices.VOICES}`,
  ALL_VOICE_TYPES_URL: `${urls.voices.ALL_VOICE_TYPES}`,
  ANALYTICS_GRAPHS_URL: `${urls.analytics.BASE}${urls.analytics.GRAPHS}`,
  ANALYTICS_REPORT_URL: `${urls.analytics.BASE}${urls.analytics.ALL}`,
  ANALYTICS_ADMIN_REPORT_URL: `${urls.analytics.BASE}${urls.analytics.ADMIN}/${urls.analytics.ALL}`
};

export default constants;
