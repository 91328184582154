import React from "react";

import "./styles.scss";

export interface SettingItemProps {
  title: string;
  children: React.ReactNode;
}

const SettingItem: React.FC<SettingItemProps> = ({ title = "", children }) => {
  return (
    <div className="setting-item">
      <p className="setting-item-title">{title}</p>
      {children}
    </div>
  );
};

export default SettingItem;
