import { forwardRef, Ref, useImperativeHandle, useState } from "react";
import "./styles.scss";
import StepComponentProps from "models/app/StepComponentProps";

import { useTranslation } from "react-i18next";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import Card from "components/ui-kit/Card";
import IconCircleButton from "components/IconCircleButton";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

export interface ReportStepPlacesProps {
  color?: string;
}

const ReportStepPlaces = forwardRef(
  (props: ReportStepPlacesProps, ref: Ref<StepComponentProps>) => {
    const { color } = props;
    useImperativeHandle(ref, () => ({ check, setupData, getFormattedData }));
    const { t } = useTranslation();

    const [value, setValue] = useState<Array<any>>([]);
    const [selectedPlace] = useState<any>(null);

    const setupData = () => {};
    const check = () => {
      return value.length > 0;
    };
    const getFormattedData = () => {
      return { new: value.map((place) => place.value.place_id) };
    };

    const onChange = (event: any) => {
      setValue([...value, event]);
    };

    const deleteIndex = (index: number) => {
      if (index > -1) {
        let newValue = value.filter((_, i) => i !== index);
        setValue(newValue);
      }
    };

    const PlacesList = () => {
      return (
        <div className="places-list">
          {value.map((place, index) => (
            <Card className="place-cell" key={index}>
              <div className="place-cell-content">
                <p>{place.label}</p>
              </div>
              <IconCircleButton
                icon={faTimes}
                color={"var(--red)"}
                onClick={() => deleteIndex(index)}
              />
            </Card>
          ))}
        </div>
      );
    };

    return (
      <div className="report-step-places">
        <GooglePlacesAutocomplete
          apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
          selectProps={{
            value: selectedPlace,
            placeholder: t("report_page.add_an_address"),
            onChange: onChange,
            styles: {
              container: (provided) => ({
                ...provided,
                outline: "none",
                color: "var(--text)",
                border: "1px solid var(--dark_grey)",
                borderRadius: 6,
                backgroundColor: "var(--card)",
                padding: "2px",
                width: "100%",
                fontSize: "16px",
                fontFamily: "Campton-Medium"
              }),
              control: (provided) => ({
                ...provided,
                outline: "none",
                border: "none",
                boxShadow: "none",
                cursor: "pointer",
                backgroundColor: "var(--card)",
                color: "var(--text)"
              }),
              placeholder: (provided) => ({
                ...provided,
                color: "var(--dark_grey)"
              }),
              menu: (provided) => ({
                ...provided,
                backgroundColor: "var(--card)",
                border: "1px solid var(--dark_grey)"
              }),
              input: (provided) => ({
                ...provided,
                color: "var(--text)"
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isFocused
                  ? "var(--background)"
                  : "var(--card)",
                "&:active": {
                  backgroundColor: "var(--background)"
                }
              })
            }
          }}
        />
        {value.length > 0 ? (
          <div className="places-content">
            <h5 className="uppercase">{t("report_page.selected_addresses")}</h5>
            <PlacesList />
          </div>
        ) : null}
      </div>
    );
  }
);

export default ReportStepPlaces;
