function extractDomainUrl(url: string): string {
  const current_url = url;

  let current_url_array = current_url.split(".");
  current_url_array.shift();
  const base_url = current_url_array.join(".");
  const base_url_first_element = base_url.split("/")[0];

  return base_url_first_element;
}

function getBaseUrl(path: string): string {
  const current_url = window.location.host;
  let domain_url = "";

  if (current_url.includes("localhost")) {
    // Remove port before sending the base URL back if exists

    // Return the dashboard URL if the path is dashboard
    return (
      (path && path === "dashboard" && process.env.REACT_APP_DASHBOARD_URL) ||
      current_url.split(":")[0]
    );
  } else {
    domain_url = extractDomainUrl(current_url);

    if (path === "") {
      return "." + domain_url;
    }

    return "https://" + path + "." + domain_url;
  }
}

function getDashboardUrl(): string {
  return getBaseUrl("dashboard");
}

function getAppUrl(): string {
  return getBaseUrl("app");
}

function getDomainUrl(): string {
  return getBaseUrl("");
}

function getCurrentEnv(): string {
  const currentUrl = window.location.host;
  const currentApiUrl = process.env.REACT_APP_API_URL || "";

  if (currentUrl.includes("localhost")) {
    return currentUrl.split(":")[0];
  }

  if (currentApiUrl) {
    return currentApiUrl.split(".")[1];
  }
  return "";
}

function getTokenName(): string {
  const currentEnv = getCurrentEnv();

  if (currentEnv === "prod") {
    return "token";
  } else {
    return `token-${getCurrentEnv()}`;
  }
}

function isValidVideo(url: string) {
  let match =
    url.match(
      /^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtube\.com\/watch.*v=([a-zA-Z0-9_-]+)/
    ) ||
    url.match(
      /^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtu\.be\/([a-zA-Z0-9_-]+)/
    ) ||
    url.match(/^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#\&\?]*).*/);
  if (match && match[2].length === 11) {
    return "https" + "://www.youtube.com/embed/" + match[2] + "?showinfo=0";
  }
  if ((match = url.match(/^(?:(https?):\/\/)?(?:www\.)?vimeo\.com\/(\d+)/))) {
    return (
      (match[1] || "https") + "://player.vimeo.com/video/" + match[2] + "/"
    );
  }
  return null;
}

function getUrlToken() {
  const url = window.location.href;
  const urlObj = new URL(url);
  const urlParams = new URLSearchParams(urlObj.search);
  const token = urlParams.get("token");
  return token;
}

const isEmailValid = (email: string) => {
  if (email !== "") {
    var re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
  return false;
};

const URLSearchParamsToObject = (params: URLSearchParams) => {
  return Object.fromEntries(params.entries());
};

const url_utils = {
  getDashboardUrl,
  getAppUrl,
  getDomainUrl,
  isValidVideo,
  getCurrentEnv,
  getTokenName,
  getUrlToken,
  isEmailValid,
  URLSearchParamsToObject
};

export default url_utils;
