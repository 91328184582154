import React from "react";
import "./styles.scss";
import ReactTooltip from "react-tooltip";
import { Timestamp } from "firebase/firestore";
import clsx from "clsx";
import dayjs from "dayjs";

interface ChatBubbleProps {
  content: string;
  created_at: Timestamp;
  isSubscriber: boolean;
}

const ChatBubble: React.FC<ChatBubbleProps> = ({
  content,
  created_at,
  isSubscriber = false
}) => {
  const rootClass = "chat-bubble";
  let baseClass = clsx(
    rootClass,
    isSubscriber ? "subscriber" : "not-subscriber"
  );
  return (
    <li
      className={baseClass}
      data-tip={dayjs(created_at.toDate()).format("lll")}
      data-for="reference-for-tooltip"
    >
      {content}
    </li>
  );
};

export default ChatBubble;
