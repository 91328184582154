import React, { memo, useEffect, useRef, useState } from "react";
import "./styles.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";

import ReportFile from "models/ReportFile";
import Card from "components/ui-kit/Card";
import dayjs from "dayjs";
import ReportEvidenceIcon from "components/ReportEvidenceIcon";
import { useModal } from "react-hooks-use-modal";
import file_utils from "utils/file_utils";
import { useTranslation } from "react-i18next";

interface ReportEvidenceCellProps {
  evidence: ReportFile;
  onClick: () => void;
}

const ReportEvidenceCell: React.FC<ReportEvidenceCellProps> = ({
  evidence,
  onClick
}) => {
  const { t } = useTranslation();

  const getFileType = (file_url: string) => {
    let file_type = file_utils.getFileTypeFromURL(file_url);
    return file_utils.getFileFormat(file_type);
  };

  return (
    <Card className="report-evidence-cell" onClick={onClick}>
      <ReportEvidenceIcon url={evidence.file} />
      <div className="report-evidence-cell-body">
        <p className="report-evidence-cell-body-title">
          {t("file_type." + getFileType(evidence.file))} - #{evidence.id}
        </p>
        <p className="report-evidence-cell-body-date">
          <FontAwesomeIcon
            icon={faCalendarAlt}
            className="report-evidence-cell-body-icon"
          />
          {dayjs(evidence.created_at).format("LLL")}
        </p>
      </div>
    </Card>
  );
};

export default ReportEvidenceCell;
